import {FC} from "react";
import {DeleteConfirmationModal} from "../../../../components/modal/DeleteConfirmationModal";

type OnClick = () => void

type Props = {
    onClose: OnClick
    onConfirmation: OnClick
    itemsToDelete: { label: string, reference: string }[]
}

export const DeleteRoleConfirmationModal: FC<Props> = ({onClose, onConfirmation, itemsToDelete}) => {
    return <DeleteConfirmationModal
        visible={true}
        onClose={onClose}
        onConfirmation={onConfirmation}
        entityTitle="cargo(s)"
        description={`Cargos seleccionados para eliminación (${itemsToDelete.length} en total): \n${itemsToDelete.map(it => (`* ${it.label}`)).join("\n")}`}
    />
}
