import {FC, useCallback, useRef} from "react";
import {ButtonGroup, IconButton, Table} from "rsuite";
import {useTableWidth} from "../../../../../hook/width";
import {TableInstance} from "rsuite/Table";
import {toRelativeCalendar} from "../../../../../lib/date";
import {UserLocation} from "../../../../../domain/location/UserLocation";
import {EmptyState} from "../../../../components/state/empty";
import {faEye, faHistory, faMap} from "@fortawesome/free-solid-svg-icons";
import {TableHeaderStyle} from "../../../../components/table/style";
import {FaIcon} from "../../../../components/fontawesome/icon";

type Props = {
    userLocationList?: UserLocation[];
    onOpenWithMapsActionClicked: (item: UserLocation) => void;
    onOpenActionClicked: (item: UserLocation) => void;
};

export const LocationTableView: FC<Props> = ({
                                                 userLocationList,
                                                 onOpenWithMapsActionClicked,
                                                 onOpenActionClicked,
                                             }) => {
    const actionRef = useRef<string | null>(null);
    const tableRef = useRef<TableInstance<any,any>>(null);
    const relation = useTableWidth(tableRef, {
        0: {
            timestamp: 0.7,
            actions: 0.3,
        },
    });

    const onOpenWithMapsAction = useCallback(() => {
        actionRef.current = "maps";
    }, []);

    const onOpenAction = useCallback(() => {
        actionRef.current = "open";
    }, []);

    const onRowSelected = useCallback((item: UserLocation) => {
        if (actionRef.current === "maps") {
            onOpenWithMapsActionClicked(item);
        } else if (actionRef.current === "open") {
            onOpenActionClicked(item);
        }
        actionRef.current = null;
    }, []);

    return (
        <Table
            onRowClick={onRowSelected as any}
            autoHeight
            data={userLocationList}
            ref={tableRef}
            renderEmpty={() => (
                <EmptyState
                    title={"Historial de Ubicaciones Vacío"}
                    message={"No se recibieron actualizaciones de ubicación del usuario."}
                    icon={faHistory}
                />
            )}
            cellBordered
            className={"rounded md:rounded-xl"}
        >
            <Table.Column resizable width={relation.timestamp}>
                <Table.HeaderCell style={TableHeaderStyle}>Hora</Table.HeaderCell>
                <Table.Cell>
                    {(data: any) => {
                        return <span>{toRelativeCalendar(data.timestamp, true)}</span>;
                    }}
                </Table.Cell>
            </Table.Column>
            <Table.Column
                resizable
                width={relation.actions}
                align={"center"}
                verticalAlign={"middle"}
            >
                <Table.HeaderCell style={TableHeaderStyle}>Acciones</Table.HeaderCell>
                <Table.Cell
                    style={{
                        padding: 0,
                        margin: 0,
                    }}
                >
                    {(_: any) => {
                        return (
                            <ButtonGroup>
                                <IconButton
                                    onClick={onOpenWithMapsAction}
                                    icon={<FaIcon icon={faMap}/>}
                                    appearance={"ghost"}
                                />
                                <IconButton
                                    onClick={onOpenAction}
                                    icon={<FaIcon icon={faEye}/>}
                                    appearance={"ghost"}
                                />
                            </ButtonGroup>
                        );
                    }}
                </Table.Cell>
            </Table.Column>
        </Table>
    );
};
