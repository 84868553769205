import React, { FC, useContext, useEffect } from "react";
import { PrimaryHeader } from "../../components/header/primary";
import { DashboardHeaderContext } from "../../../hook/header";

type Props = {};

export const DocumentationPage: FC<Props> = () => {
  const headerContext = useContext(DashboardHeaderContext);

  useEffect(() => {
    headerContext.setTheme({
      className: "bg-secondary",
      dark: true,
      title: "Documentación"
    });
  }, []);
  return (
    <div className={"w-full overflow-x-hidden"}>
      <PrimaryHeader breadcrumbItems={[{
        label: "Documentación", active: true
      }]} title={"Documentación"}
                     description={`Manuales de uso para Pentracker y Pentracker Control`}
      />
      <div className={"p-3 divide-neutral-300 divide-y-2"}>

      </div>
    </div>
  );
};
