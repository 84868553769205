import {GetShiftDto} from "../../network/user/Shift";

export enum ShiftProperties {
    reference = "reference",
    id = "id",
    label = "label",
    schedule = "schedule",
}

export type Shift = {
    [ShiftProperties.label]: string;
    [ShiftProperties.reference]: string;
    [ShiftProperties.schedule]: string;
    [ShiftProperties.id]: number
};

export type NewShift = Omit<Shift, ShiftProperties.reference>;

export function shiftDtoAsDomain(
    shiftDto: GetShiftDto,
    reference: string
): Shift {
    return {...shiftDto, reference: reference};
}
