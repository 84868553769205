import {FC} from "react";
import {Legend, Pie, PieChart, Tooltip} from "recharts";
import {UnityOperatorCount} from "../../../../viewmodel/home/DashboardHome";
import {Loader} from "rsuite";
import {AutoSizer} from "react-virtualized";
import {AppTimestamp} from "../../../../domain/app/Timestamp";
import {DashboardDataTimestamp} from "./data-timestamp";

type Props = {
    data?: UnityOperatorCount[];
    unityDataLoading?: boolean;
    timestamp?: AppTimestamp;
    onRefreshButtonClicked: () => void;
};
export const DashboardUnityPieCard: FC<Props> = ({
                                                     data,
                                                     unityDataLoading,
                                                     timestamp,
                                                     onRefreshButtonClicked,
                                                 }) => {
    if (unityDataLoading === true) {
        return (
            <div className={"w-full h-full flex items-center justify-center"}>
                <Loader size={"md"}/>
            </div>
        );
    }
    if (!data || data.length <= 0) {
        return (
            <div
                className={"w-full h-full p-2 md:p-4 flex items-center justify-center"}
            >
        <span className={"text-lg font-normal text-neutral-700"}>
          No hay unidades registradas
        </span>
            </div>
        );
    }
    return (
        <div
            className={
                "w-full h-full flex flex-col justify-between items-center relative p-4"
            }
        >
            <DashboardDataTimestamp
                position={"tr"}
                timestamp={timestamp}
                loading={unityDataLoading}
                onRefreshButtonClicked={onRefreshButtonClicked}
            />
            <span className={"text-lg font-semibold text-neutral-800"}>
        Distribución de Usuarios por Unidad
      </span>
            <div className={"w-full flex-1"}>
                {/*@ts-ignore*/}
                <AutoSizer>
                    {({width, height}) => {
                        return (
                            <PieChart width={width} height={height}>
                                <Pie
                                    data={data}
                                    dataKey="count"
                                    cx="50%"
                                    cy="50%"
                                    fill="#8884d8"
                                    label
                                />
                                <Tooltip label={"name"}/>
                                <Legend align={"center"} layout={"horizontal"}/>
                            </PieChart>
                        );
                    }}
                </AutoSizer>
            </div>
        </div>
    );
};
