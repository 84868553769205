import {FC} from "react";
import {ByUnityPatrol} from "../../../../../viewmodel/patrol-group/OverviewPatrolGroup";

type Props = {
    unityPatrolList: ByUnityPatrol[]
}

export const PatrolOverviewByUnity: FC<Props> = ({unityPatrolList}) => {
    return <div className={"w-full h-fit bg-white rounded shadow-sm flex flex-col py-4"}>
        <div className={"px-4 md:px-12"}>
            <span className={"text-lg font-semibold text-neutral-800"}>
              Hoy en cada unidad
            </span>
        </div>
        <div
            className={
                "flex flex-col divide-y divide-neutral-200 overflow-y-scroll"
            }
        >
            {unityPatrolList.map((patrol) => {
                return (
                    <div
                        key={`patrol-${patrol.name}`}
                        className={
                            "px-4 md:px-12 py-2 flex flex-col"
                        }
                    >
                  <span className={"text-neutral-800 font-semibold text-md"}>
                    {patrol.name}
                  </span>
                        <span className={"text-md"}>
                    {patrol.total} asistente(s)
                  </span>
                    </div>
                );
            })}
        </div>
    </div>
}
