import { FC, useContext } from "react";
import { BreakPointContext } from "../../../../context/BreakPointContext";
import { UserVehicleControl } from "../../../../../domain/control/Vehicle";
import { VehicleControlList } from "../../../data-display/vehicle-control-list";
import { VehicleControlTable } from "../../../data-display/vehicle-control-table";

type Props = {
  vehicleControlList?: UserVehicleControl[];
  onSelect: (item: UserVehicleControl) => void;
};

export const UnityVehicleControlDataName = "UnityVehicleControlData";

export const UnityVehicleControlData: FC<Props> = ({
  vehicleControlList,
  onSelect,
}) => {
  const screenSize = useContext(BreakPointContext);
  if (screenSize.isMobile) {
    return (
      <VehicleControlList
        name={UnityVehicleControlDataName}
        onSelect={onSelect}
        vehicleControlList={vehicleControlList}
      />
    );
  } else {
    return (
      <VehicleControlTable
        name={UnityVehicleControlDataName}
        vehicleControlList={vehicleControlList}
        onSelect={onSelect}
      />
    );
  }
};
