import React, {FC, Fragment} from "react";
import {Zone} from "../../../../../../domain/patrol/Zone";
import {EmptyState} from "../../../../../components/state/empty";
import {Unity} from "../../../../../../domain/unity";
import {Pagination} from "rsuite";
import {useDataPager} from "../../../../../../hook/pager";
import {ZoneRow} from "./row";

type ZoneListProps = {
    zoneList?: Zone[];
    onSelect: (item: Zone) => void;
    unity: Unity
};

export const ZoneList: FC<ZoneListProps> = ({zoneList, onSelect, unity}) => {
    /* eslint-disable */

    const {partialData, changePage, page, changeLimit, limits, limit} = useDataPager({
        items: zoneList, id: `zone_list_${unity.id}`
    })

  const onSelectRow : React.MouseEventHandler<HTMLDivElement>=
    (event) => {
      const index = event.currentTarget.dataset["index"] as unknown as number;
      const item = partialData![index];
      onSelect(item);
    }

    /* eslint-enable */

    return <Fragment>
        {
            (zoneList && zoneList.length > 0) ? (<div className={"w-full overflow-y-auto"}>
                    {
                        partialData?.map((zone, i) => <ZoneRow index={i} style={{}} data={{
                            zoneList: partialData!,
                            onSelect: onSelectRow
                        }} key={`zone-${zone.id}`}/>)
                    }
                </div>)
                : (
                    <EmptyState
                        title={"No hay zonas"}
                        message={"Aún no se crearon zonas para esta unidad."}
                    />
                )
        }
        {zoneList ? (
            <div className={"py-4 flex flex-col gap-4"}>
          <span className={"text-md"}>
            Mostrando {partialData?.length} elementos
          </span>
                <Pagination
                    prev
                    next
                    first
                    last
                    ellipsis
                    boundaryLinks
                    maxButtons={5}
                    size="xs"
                    layout={["total", "-", "limit", "|", "pager", "skip"]}
                    total={zoneList.length}
                    limitOptions={limits}
                    limit={limit}
                    activePage={page}
                    onChangePage={changePage}
                    onChangeLimit={changeLimit}
                />
            </div>
        ) : null}

    </Fragment>

};
