import React, {FC} from "react";
import {FaIcon} from "../../../components/fontawesome/icon";
import {faPeopleGroup} from "@fortawesome/free-solid-svg-icons";
import {Unity} from "../../../../domain/unity";

type Props = {
    unity: Unity
};

export const UnityRowOperator: FC<Props> = ({unity}) => {
    return (
        <p className={"m-0 p-0"}>
            <FaIcon icon={faPeopleGroup} className={"mr-2 text-multi"}/>
            <span className={"break-words"}>{unity.operatorCount} operador(es)</span>
        </p>
    );
};
