import React, { FC, useContext, useEffect } from "react";
import { PrimaryHeader } from "../../components/header/primary";
import { DashboardHeaderContext } from "../../../hook/header";
import { Outlet } from "react-router-dom";
import { useStyleContext } from "../../context/StyleContext";

export const UserTicketsGroupPage: FC = () => {
  const dashboardHeaderContext = useContext(DashboardHeaderContext);
  const { stylePage } = useStyleContext();
  /* eslint-disable */
  useEffect(() => {
    dashboardHeaderContext.setTheme({
      className: "bg-secondary-header",
      title: "Boletas de pago",
      dark: true,
      homeAsUpEnabled: true,
    });
  }, []);
  /* eslint-enable */

  return (
    <div className={"w-full overflow-x-hidden"} style={stylePage}>
      <PrimaryHeader
        breadcrumbItems={[
          {
            label: "Boletas de Pago",
            active: true,
          },
        ]}
        title={"Boletas de Pago"}
        description={
          <p>
            Agregue las boletas de pago en la carpeta correspondiente en formado
            PDF
            <br />
            NOTA: Las carpetas pendientes de enviar se borrarán al actualizar la
            lista.
          </p>
        }
      />
      <div className={"w-full p-3"}>
        <Outlet />
      </div>
    </div>
  );
};
