import {db} from "../db";
import {Shift} from "../../../domain/user/Shift";

export class ShiftDao {
    static async putShift(...shift: Shift[]) {
        await db.shifts.bulkPut(shift);
    }

    static async getShift(reference: string) {
        return db.shifts.get({reference});
    }

    static async getShiftById(id: number) {
        return db.shifts.where("id").equals(id).first()
    }

    static async clear() {
        await db.shifts.clear();
    }

    static async delete(reference: string) {
        await db.shifts.delete(reference);
    }

    static async update(reference: string, newData: Partial<Shift>) {
        await db.shifts.update(reference, newData);
    }

    static async getList() {
        return db.shifts.toArray()
    }
}
