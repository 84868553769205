import {FC} from "react";
import {DatePicker} from "rsuite";
import {CustomInputProps} from "../modal/FormModal";
import {DateTime} from "luxon";

export function TimePickerInput(name: string): FC<CustomInputProps<any>> {
    return ({
                values,
                updateValue
            }) => {
        const value = values[name];
        return (
            <DatePicker format="HH:mm" value={value ? DateTime.fromMillis(value).toJSDate() : null}
                        onChange={(value, _) => {
                            updateValue(name, value ? value.getTime() : null);
                        }}/>
        );
    };
}

