import { FC } from "react";
import { Loader } from "rsuite";
import { formatDateTime } from "../../../../util/date";
import { Link } from "react-router-dom";
import { AppTimestamp } from "../../../../domain/app/Timestamp";
import { DashboardDataTimestamp } from "./data-timestamp";
import { PathImageLoader } from "../../../components/imageloader/PathImageLoader";
import { UserPatrol } from "../../../../domain/patrol/Patrol";

type Props = {
  patrolDataLoading?: boolean;
  lastPatrol?: UserPatrol[];

  timestamp?: AppTimestamp;
  onRefreshClicked: () => void;
};

export const DashboardLastPatrolList: FC<Props> = ({
                                                     lastPatrol,
                                                     patrolDataLoading,
                                                     timestamp,
                                                     onRefreshClicked
                                                   }) => {
  if (patrolDataLoading === true) {
    return (
      <div className={"w-full h-full flex justify-center items-center"}>
        <Loader size={"md"} />
      </div>
    );
  }
  if (!lastPatrol || lastPatrol.length <= 0) {
    return (
      <div
        className={"w-full h-full p-2 md:p-4 flex items-center justify-center"}
      >
        <span className={"text-lg font-normal text-neutral-700"}>
          No hay registros de rondas
        </span>
      </div>
    );
  }
  return (
    <div
      className={
        "w-full h-full flex flex-col justify-between items-center relative p-4"
      }
    >
      <DashboardDataTimestamp
        position={"tr"}
        timestamp={timestamp}
        loading={patrolDataLoading}
        onRefreshButtonClicked={onRefreshClicked}
      />

      <span className={"text-lg font-semibold text-neutral-800"}>
        Registros de Rondas
      </span>
      <div
        className={"flex-1 flex flex-col w-full overflow-y-auto overflow-x-hidden divide-y divide-neutral-300 scrollbar-hide md:scrollbar-default"}
      >
        {lastPatrol.map((patrol) => (
          <div
            className={
              "w-full p-2 flex flex-row gap-2 items-center justify-start"
            }
            key={patrol.reference}
          >
            <div className={"w-20 h-20 shrink-0 overflow-hidden"}>
              <PathImageLoader path={patrol.ownerPhoto} className={"object-cover w-full h-full object-cover"}
              />
            </div>
            <div className={"flex flex-col"}>
              <span className={"text-sm font-semibold text-neutral-900"}>
                {patrol.owner?.displayName || patrol.ownerUid}
              </span>
              <span className={"text-xs font-normal text-neutral-700"}>
                {formatDateTime(new Date(patrol.timestamp))}
              </span>
            </div>
          </div>
        ))}
        <Link to={"/dashboard/patrol-group/request"}>
          <div
            className={
              "w-full py-2 flex justify-center items-center bg-neutral-100 rounded-b-lg"
            }
          >
            <span className={"text-neutral-600"}>Ver más...</span>
          </div>
        </Link>
      </div>
    </div>
  );
};
