import {GetUserLocationDto} from "../../network/location/UserLocation";

export type UserLocation = Omit<GetUserLocationDto, "timestamp"> & {
    reference: string;
    timestamp: number;
};

export function userLocationDtoAsDomain(
    userLocationDto: GetUserLocationDto,
    reference: string
): UserLocation {
    return {
        ...userLocationDto,
        reference,
        timestamp: userLocationDto.timestamp.toDate().getTime(),
    };
}
