import {FC} from "react";
import {DeleteConfirmationModal} from "../../../../components/modal/DeleteConfirmationModal";


type ShiftToDelete = {
    label: string;
    reference: string;
};

type Props = {
    shiftListToDelete: ShiftToDelete[];
    onConfirmation: () => void;
    onClose: () => void;
};

export const DeleteShiftConfirmationModal: FC<Props> = ({
                                                            shiftListToDelete,
                                                            onConfirmation,
                                                            onClose,
                                                        }) => {
    return (
        <DeleteConfirmationModal
            visible={true}
            onClose={onClose}
            entityTitle={shiftListToDelete.length > 1 ? "turnos" : "turno"}
            onConfirmation={onConfirmation}
            description={`Se eliminarán los siguientes turnos (${
                shiftListToDelete.length
            } en total): \n ${shiftListToDelete.map((it) => `* ${it.label}`).join("\n")}`}
        />
    );
};
