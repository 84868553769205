import {FC, useContext, useEffect, useState} from "react";
import {Button, Form, Modal, Schema} from "rsuite";
import {SecureContext} from "../../context/SecureContext";

type Props = {
    entityTitle?: string;
    description?: string;
    visible?: boolean;
    onClose: () => void;
    onConfirmation: () => void;
    clear?: boolean;
};

export const DeleteConfirmationModal: FC<Props> = ({
                                                       entityTitle,
                                                       description,
                                                       visible,
                                                       onClose,
                                                       onConfirmation,
                                                       clear,
                                                   }) => {
    const [inputMatch, setInputMatch] = useState(false);
    const secureContext = useContext(SecureContext)!;
    const [loadedSecret] = useState("cesp0612");

    function updateMatches(matches: boolean) {
        if (matches !== inputMatch) setInputMatch(matches);
    }

    useEffect(() => {
        if (clear === true) {
            setInputValue("");
            setInputMatch(false);
        }
    }, [clear]);

    const [inputValue, setInputValue] = useState("");
    return (
        <Modal onClose={onClose} open={visible}>
            <Modal.Header>
                <Modal.Title>Eliminar {`${entityTitle}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {description ? (
                    <p className={"text-md text-neutral-700 whitespace-pre-line"}>
                        {description}
                    </p>
                ) : null}
                <Form
                    fluid
                    model={Schema.Model({
                        input: Schema.Types.StringType().addRule((value, _) => {
                            let matches = value.toLowerCase() === loadedSecret;
                            updateMatches(matches);
                            return matches;
                        }, "Complete el campo según lo indicado."),
                    })}
                >
                    <Form.Group>
                        <Form.Control
                            name={"input"}
                            value={inputValue}
                            onChange={(value: string) => {
                                setInputValue(value);
                            }}
                            type="password"
                            autoComplete="off"
                        />
                        <Form.HelpText>
                            Es necesario que confirme ingresando la contraseña de sesión para
                            proceder con la eliminación del recurso.
                        </Form.HelpText>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={() => {
                        onClose();
                    }}
                >
                    Cancelar
                </Button>
                <Button
                    appearance={"primary"}
                    disabled={!inputMatch}
                    onClick={() => {
                        onConfirmation();
                    }}
                >
                    Confirmar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
