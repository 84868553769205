import React, { FC, useCallback } from "react";
import { EmptyState } from "../../../components/state/empty";
import { useDataPager } from "../../../../hook/pager";
import { Pagination } from "rsuite";
import { UserVehicleControl } from "../../../../domain/control/Vehicle";
import { VehicleControlRow } from "./row";

type VehicleControlListProps = {
  vehicleControlList?: UserVehicleControl[];
  onSelect: (item: UserVehicleControl) => void;
  name?: string;
};

export const VehicleControlList: FC<VehicleControlListProps> = ({
  vehicleControlList,
  onSelect,
  name,
}) => {

  /* eslint-disable */

  const { limits, changeLimit, limit, page, partialData, changePage } =
    useDataPager<UserVehicleControl>({
      id: `vehicle_list_${name}`,
      items: vehicleControlList,
    });

  const onSelectRow = useCallback<React.MouseEventHandler<HTMLDivElement>>(
    (event) => {
      if (!partialData) return;
      const index = event.currentTarget.dataset["index"] as unknown as number;
      const item = partialData[index];
      if (item) {
        onSelect(item);
      }
    },
    [partialData]
  );

  /* eslint-enable */

  return vehicleControlList && vehicleControlList.length > 0 ? ( //@ts-ignore
    <div className={"w-full h-auto"}>
      {
        partialData?.map((visit, i) =>
          <VehicleControlRow
            index={i}
            style={{}}
            key={`visit-row-${visit.reference}`}
            data={{
              userVehicleControlList: partialData,
              onSelect: onSelectRow,
            }}
          />
        )
      }
      <div className={"py-4 flex flex-col gap-4"}>
        <span className={"text-md"}>
          Mostrando {partialData?.length} elementos / Total {vehicleControlList.length}
        </span>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={["limit", "pager"]}
          total={vehicleControlList.length}
          limitOptions={limits}
          limit={limit}
          activePage={page}
          onChangePage={changePage}
          onChangeLimit={changeLimit}
        />
      </div>
    </div>
  ) : (
    <EmptyState
      title={"No hay registros de control de vehículo"}
      message={
        "No se encontraron reportes de control de vehículos para la fecha seleccionada."
      }
    />
  );
};
