import {FC} from "react";
import {getTimeMilliseconds} from "../../../../util/date";
import {toTime} from "../../../../lib/date";
import {AttendancePair} from "../../../../domain/attendance/Attendance";

type Props = {
    item: AttendancePair
}

export const AttendanceListRowEntry: FC<Props> = ({
                                                      item
                                                  }) => {
    const {
        entry: {unity, shiftId, timestamp},
    } = item
    const firstShiftMillis = unity?.firstShiftLimit
        ? getTimeMilliseconds(unity.firstShiftLimit)
        : undefined;
    const secondShiftMillis = unity?.secondShiftLimit
        ? getTimeMilliseconds(unity.secondShiftLimit)
        : undefined;

    if (shiftId === 0 && firstShiftMillis) {
        const currentEntryMillis = getTimeMilliseconds(timestamp);
        return (
            <div
                style={{
                    padding: 0,
                    margin: 0,
                }}
            >
                <div
                    className={`w-fit px-2 py-0.5 rounded h-full flex flex-wrap justify-center items-center ${
                        currentEntryMillis > firstShiftMillis
                            ? "bg-red-500 text-white"
                            : "bg-green-300"
                    }`}
                >
                    <span>{toTime(timestamp)}</span>
                </div>
            </div>
        );
    } else if (shiftId === 1 && secondShiftMillis) {
        const currentEntryMillis = getTimeMilliseconds(timestamp);
        return (
            <div
                style={{
                    padding: 0,
                    margin: 0,
                }}
            >
                <div
                    className={`w-fit px-2 py-0.5 rounded h-full flex flex-wrap justify-center items-center ${
                        currentEntryMillis > secondShiftMillis
                            ? "bg-red-500 text-white"
                            : "bg-green-300"
                    }`}
                >
                    <span>{toTime(timestamp)}</span>
                </div>
            </div>
        );
    }
    return <div>{toTime(timestamp)}</div>;
};
