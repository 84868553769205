import { FC } from "react";
import { NewsItem } from "../../../../domain/news/NewsItem";
import { IconButton, Modal } from "rsuite";
import { FaIcon } from "../../../components/fontawesome/icon";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export const NewsItemViewerModal: FC<{
  item: NewsItem;
  onClose: () => void;
}> = ({ item, onClose }) => {
  return (
    <Modal size={"lg"} onClose={onClose} open>
      <Modal.Header>
        <Modal.Title>Comunicado</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className={"break-words text-xl font-bold w-full"}>{item.title}</p>
        <hr />
        <div
          dangerouslySetInnerHTML={{
            __html: item.content,
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <IconButton
          appearance={"primary"}
          icon={<FaIcon icon={faCheck} />}
          onClick={onClose}
        >
          Listo
        </IconButton>
      </Modal.Footer>
    </Modal>
  );
};
