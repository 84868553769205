import {TableColumnRelation} from "../../ui/context/TableContext";

export type UserTableColumns = {
    role: string;
    uid: string;
    displayName: string;
    unity: string;
    lastSession: string;
    creationTime: string;
    actions: string;
};

export const UserTableSchemaBasic: TableColumnRelation<UserTableColumns> = {
    large: {
        breakpoints: ["lg", "xl", "2xl"],
        columns: [
            {
                name: "role",
                label: "Cargo",
                weight: 0.1,
            },
            {
                name: "uid",
                label: "ID",
                weight: 0.1,
            },
            {
                name: "displayName",
                label: "Usuario",
                weight: 0.3,
            },
            {
                name: "unity",
                label: "Unidad",
                weight: 0.2,
            },
            {
                name: "lastSession",
                label: "Ult. Sesión",
                weight: 0.15,
            },
            {
                name: "creationTime",
                label: "Creación",
                weight: 0.15,
            },
        ],
    },
}


export const UserTableSchema: TableColumnRelation<UserTableColumns> = {
    large: {
        breakpoints: ["lg", "xl", "2xl"],
        columns: [
            {
                name: "role",
                label: "Cargo",
                weight: 0.1,
            },
            {
                name: "uid",
                label: "ID",
                weight: 0.1,
            },
            {
                name: "displayName",
                label: "Usuario",
                weight: 0.3,
            },
            {
                name: "unity",
                label: "Unidad",
                weight: 0.2,
            },
            {
                name: "lastSession",
                label: "Ult. Sesión",
                weight: 0.15,
            },
            {
                name: "creationTime",
                label: "Creación",
                weight: 0.15,
            },
        ],
    },
}
