import { FC, useCallback } from "react";
import {
  OnClick,
  TableMultiselectHeader,
} from "../../../../components/table/header";
import { faCancel, faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";

type Props = {
  selectedItems: Array<string>;
  onDeleteClicked: OnClick;
  onCancelClicked: OnClick;
  onEditClicked: OnClick;
};

export const UsersTableMultiselectHeader: FC<Props> = ({
  onCancelClicked,
  onDeleteClicked,
  selectedItems,
  onEditClicked,
}) => {
  /* eslint-disable */
  const onCancel = useCallback(() => {
    onCancelClicked();
  }, [selectedItems]);
  const onDelete = useCallback(() => {
    onDeleteClicked();
  }, [selectedItems]);
  const onEdit = useCallback(() => {
    onEditClicked();
  }, [selectedItems]);
  /* eslint-enable */
  return (
    <TableMultiselectHeader
      selectedItems={selectedItems}
      buttons={[
        {
          icon: faTrash,
          onClick: onDelete,
          text: "Eliminar",
        },
        {
          icon: faEdit,
          onClick: onEdit,
          text: "Editar",
        },
        {
          icon: faCancel,
          onClick: onCancel,
          text: "Cancelar",
        },
      ]}
      entityName={"usuario(s) seleccionado(s)"}
    />
  );
};
