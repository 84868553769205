import {FC, Fragment} from "react";
import {Tag} from "rsuite";
import {FaIcon} from "../../../components/fontawesome/icon";
import {faCrown, faUser} from "@fortawesome/free-solid-svg-icons";
import {UserWrapper} from "../../../../domain/user/User";

type Props = {
    userWrapper: UserWrapper;
};

export const UserRowRole: FC<Props> = ({userWrapper}) => {
    if (!userWrapper) return null;
    const {user} = userWrapper;
    const {userRole} = user;
    if (userWrapper.isMainAdmin()) {
        return (
            <Tag color={"green"}>
                <FaIcon icon={faCrown}/> Administrador
            </Tag>
        );
    } else if (userRole) {
        switch (userRole.id) {
            case 0:
                return (
                    <Tag color={"green"}>
                        <FaIcon icon={faCrown}/> Administrador
                    </Tag>
                );
            case 1:
                return (
                    <Tag color={"green"}>
                        <FaIcon icon={faCrown}/> Administrador
                    </Tag>
                );
            case 2:
                return <Tag color={"red"}>Centro de Control</Tag>;
            case 3:
                return <Tag color={"red"}>Centro de Control</Tag>;
            case 4:
                return <Tag color={"yellow"}>Cliente</Tag>;
            case 5:
                return (
                    <Tag color={"blue"}>
                        <FaIcon icon={faUser}/> Jefe de grupo
                    </Tag>
                );
            case 6:
                return (
                    <Tag color={"blue"}>
                        <FaIcon icon={faUser}/> CCTV
                    </Tag>
                );
            default:
                return (
                    <Tag color={"blue"}>
                        <FaIcon icon={faUser}/> Agente
                    </Tag>
                );
        }
    } else {
        return <Fragment>Sin asignar</Fragment>;
    }
};
