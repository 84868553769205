import React, { Children, FC, ReactNode, useContext } from "react";
import { Breadcrumb, IconButton, Navbar } from "rsuite";
import { FaIcon } from "../fontawesome/icon";
import {
  faChevronRight,
  faGear,
  faHome,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Action, Fab } from "react-tiny-fab";
import { BreakPointContext } from "../../context/BreakPointContext";

export type Action = {
  label: string;
  icon: IconDefinition;
  id: string;
  onClick: () => void;

  hide?: boolean;
};

type Props = {
  breadcrumbItems: {
    label: string;
    path?: string;
    active?: boolean;
  }[];
  title: string;
  actions?: ReactNode[];
  actionsSchema?: Action[];
  stylePage?: any;
};

export const ContextHeader: FC<Props> = ({
  breadcrumbItems,
  title,
  actions,
  actionsSchema,
  stylePage,
}) => {
  const breakPoint = useContext(BreakPointContext);
  return (
    <div className={"w-full md:pb-4 md:px-3 a"} style={stylePage}>
      <Breadcrumb
        className={"p-2 md:p-5"}
        separator={<FaIcon icon={faChevronRight} />}
      >
        <Link className={"a"} to={"/dashboard"}>
          <FaIcon icon={faHome} /> Inicio
        </Link>
        {breadcrumbItems.map((bItem, i) =>
          bItem.active === true ? (
            <Breadcrumb.Item key={`bitem-${i}`} active>
              {bItem.label}
            </Breadcrumb.Item>
          ) : (
            <Link key={`bitem-${i}`} to={bItem.path || "/dashboard"}>
              {bItem.label}
            </Link>
          )
        )}
      </Breadcrumb>
      <div className={"w-full flex flex-row flex-wrap justify-between"}>
        {breakPoint.isDesktop ? (
          <h1
            className={
              "px-2 md:px-5 md:py-2 font-normal text-sm sm:text-lg md:text-xl text-black"
            }
          >
            {title}
          </h1>
        ) : null}
        {actions ? (
          <Navbar appearance="subtle" className="w-fit flex flex-row flex-wrap">
            <div className="ml-auto my-auto flex gap-2 justify-end items-center overflow-auto flex-wrap">
              {Children.toArray(actions)}
            </div>
          </Navbar>
        ) : actionsSchema ? (
          <ActionGroup schema={actionsSchema} />
        ) : null}
      </div>
    </div>
  );
};

export type ActionGroupProps = {
  schema: Action[];
};

export const ActionGroup: FC<ActionGroupProps> = ({ schema }) => {
  const breakpoint = useContext(BreakPointContext);
  const filtered = schema.filter((it) => !it.hide);
  return breakpoint.isMobile ? (
    <Fab
      icon={<FaIcon icon={faGear} />}
      alwaysShowTitle={true}
      style={{
        bottom: 0,
        right: 0,
        margin: 16,
      }}
    >
      {filtered.map((it) => (
        <Action text={it.label} key={it.id} onClick={it.onClick}>
          <FaIcon icon={it.icon} />
        </Action>
      ))}
    </Fab>
  ) : (
    <div
      className={"flex flex-row items-center justify-center flex-wrap gap-2"}
    >
      {filtered.map((it) => (
        <IconButton
          size={"sm"}
          icon={<FaIcon icon={it.icon} />}
          onClick={it.onClick}
          appearance={"primary"}
          key={it.id}
        >
          {it.label}
        </IconButton>
      ))}
    </div>
  );
};
