import {FC, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {Loader} from "rsuite";
import logo from "../../../resources/logo-pentracker.svg";
import {useAuthVerificationViewModel} from "../../../viewmodel/auth/AuthVerification";

export const AuthVerificationPage: FC<any> = () => {
    const navigate = useNavigate();
    const viewModel = useAuthVerificationViewModel();

    /*eslint-disable*/
    useEffect(() => {
        if (viewModel.emailLinkLoaded !== null) {
            if (viewModel.emailLinkLoaded) {
                void viewModel.signIn(window.location.href);
            } else {
                navigate("/auth");
            }
        }
    }, [viewModel.emailLinkLoaded]);

    useEffect(() => {
        if (viewModel.signInState && !viewModel.signInState.isLoading()) {
            if (viewModel.signInState.isSuccess()) {
                window.toast.success("Bienvenido");
                navigate("/dashboard");
            } else {
                window.toast.error(viewModel.signInState.error!.message);
            }
            viewModel.onSignInStateReceived();
        }
    }, [viewModel.signInState]);

    useEffect(() => {
        viewModel.fetchLinkLoadedState(window.location.href);
    }, []);

    /*eslint-enable*/
    return (
        <div
            className={
                "w-screen h-screen flex flex-col items-center content-center justify-center bg-neutral-100"
            }
        >
            <div
                className={
                    "w-full m-4 px-4 py-8 rounded-lg md:rounded-2xl sm:m-0 sm:w-3/4 md:p-12 md:w-full md:max-w-xl bg-white flex flex-col items-center justify-center gap-5"
                }
            >
                <div className={"p-0 m-0 text-md text-neutral-500 text-center"}>
                    <span className={"font-semibold text-lg"}>Validando Sesión</span>{" "}
                    <hr/>
                    Espere mientras se valida la sesión. La página se redireccionará
                    automáticamente.
                </div>
                <Loader size="lg"/>
            </div>
            <div className={"mt-5 flex items-center justify-center flex-col gap-2"}>
        <span className={"text-center text-neutral-400 text-xs"}>
          © 2023 Pentágono. Todos los derechos reservados.
        </span>
                <img src={logo} alt={"logo"} className={"w-28"}/>
            </div>
        </div>
    );
};
