import {deleteObject, FirebaseStorage, getDownloadURL, ref, uploadBytes, UploadMetadata,} from "firebase/storage";

export class FirebaseStorageSource {
    static storage: FirebaseStorage;

    static async uploadBytes(
        referencePath: string,
        src: Blob | Uint8Array | ArrayBuffer,
        metadata: UploadMetadata | undefined = undefined
    ): Promise<string> {
        const fileRef = ref(this.storage, referencePath);
        const uploaded = await uploadBytes(fileRef, src, metadata);
        return await getDownloadURL(uploaded.ref);
    }

    static async remove(referencePath: string) {
        try {
            await deleteObject(ref(this.storage, referencePath));
            return true;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    static async getFileUrl(referencePath: string) {
        try {
            return await getDownloadURL(ref(this.storage, referencePath));
        } catch (e) {
            return "";
        }
    }
}
