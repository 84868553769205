import React, {FC} from "react";
import {PentrackerUser} from "../../../../domain/user/User";
import {FaIcon} from "../../../components/fontawesome/icon";
import {faUser} from "@fortawesome/free-solid-svg-icons";

type Props = {
    user: PentrackerUser;
};

export const UserRowDisplayName: FC<Props> = ({user}) => {
    return (
        <p className={"m-0 p-0"}>
            <FaIcon icon={faUser} className={"mr-2 text-neutral-400"}/>
            {user.displayName ? (
                <span>{user.displayName}</span>
            ) : (
                <span className={"italic"}>No establecido</span>
            )}
        </p>
    );
};
