import {FC} from "react";
import {AttendancePair} from "../../../../../domain/attendance/Attendance";
import {Modal} from "rsuite";
import {UserAttendanceView} from "../../../attendance/components/view";

export const UnityUserAttendanceModal: FC<{
    onClose: () => void,
    attendancePair: AttendancePair
}> = ({onClose, attendancePair}) => {

    return <Modal open={true} onClose={onClose}>
        <Modal.Header>
            <Modal.Title>Asistencia</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <UserAttendanceView attendance={attendancePair}/>
        </Modal.Body>
    </Modal>
}
