import { getToken, MessagePayload, Messaging, onMessage } from "firebase/messaging";
import { httpsCallable } from "firebase/functions";
import FirebaseFunctions from "./FirebaseFunctions";

export default class FirebaseMessaging {
  static messaging: Messaging;

  static async updateWebClient(token: string) {
    const updateWebClientTokenFunction = httpsCallable(FirebaseFunctions.functions, "updateWebClientToken");
    return (await updateWebClientTokenFunction({ token })).data as boolean;
  }

  static async getFCMToken() {
    // @ts-ignore
    return await getToken(FirebaseMessaging.messaging, { vapidKey: process.env.REACT_APP_CLOUD_MESSAGING_PUBLIC_KEY });
  }

  private static channel : BroadcastChannel | undefined = new BroadcastChannel("fcm")

  static listenMessages(cb: (payload: MessagePayload) => void){
    if(!this.channel) {
      this.channel = new BroadcastChannel("fcm")
    }
    const signal = onMessage(this.messaging, (payload) => {
      cb(payload);
    });
    this.channel.onmessage = (event: any) => {
      cb(event.data)
    }
    return () => {
      signal()
      this.channel?.close()
      this.channel = undefined
    }
  }
}
