import { useState } from "react";
import { PendingTicketFile } from "../../domain/ticket/UserTicket";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../data/database/db";

export function usePendingTicketsViewModel() {
  const [pendingTickets, setPendingTickets] = useState<Array<PendingTicketFile>>([]);
  const users = useLiveQuery(() => db.users.toArray(), []);


  return ({
    pendingTickets,
    setPendingTickets,
    users
  });
}
