import {FC} from "react";
import {CustomInputProps} from "../../../../components/modal/FormModal";
import {SingleImagePickerControl} from "../../../../components/input/UploadInput";

import {IconButton} from "rsuite";
import {ImageLoader} from "../../../../components/imageloader";

import {Trash} from "@rsuite/icons";

export const EditUserUploadPhotoInput: FC<CustomInputProps<any>> = ({
                                                                        values,
                                                                        updateValue,
                                                                        disabled,
                                                                    }) => {
    return values["photoUrl"] ? (
        <div
            className={
                "w-full flex flex-col place-content-center place-items-center gap-2"
            }
        >
            <ImageLoader
                src={values["photoUrl"]}
                className={"max-w-xs object-cover"}
            />
            <IconButton
                appearance={"primary"}
                icon={<Trash/>}
                onClick={() => updateValue("photoUrl", null)}
            >
                Eliminar
            </IconButton>
        </div>
    ) : (
        <SingleImagePickerControl
            onChange={(file) => {
                updateValue("photoFileType", file);
            }}
            imageFile={values["photoFileType"]}
            disabled={disabled}
            onSelectionError={(err) => {
                console.error(err);
                window.toast.error("El archivo seleccionado no es una imagen válida.");
            }}
        />
    );
};

export const NewUserUploadPhotoInput: FC<CustomInputProps<any>> = ({
                                                                       values,
                                                                       updateValue,
                                                                       disabled,
                                                                   }) => {
    return (
        <SingleImagePickerControl
            onChange={(file) => {
                updateValue("photoFileType", file);
            }}
            imageFile={values["photoFileType"]}
            disabled={disabled}
            onSelectionError={(err) => {
                console.error(err);
                window.toast.error("El archivo seleccionado no es una imagen válida.");
            }}
        />
    );
};
