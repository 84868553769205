import { FirestoreSimpleCrudSource } from "../source/FirestoreSimpleCrudSource";
import { where } from "firebase/firestore";
import { isEmpty } from "lodash";
import { DeleteZoneDto, GetZoneDto, PostZoneDto, PutZoneDto } from "../../network/patrol/Zone";
import { Zone, zoneDtoAsDomain } from "../../domain/patrol/Zone";
import { ZoneDao } from "../database/dao/Zone";
import Fuse from "fuse.js";
import { httpsCallable } from "firebase/functions";
import FirebaseFunctions from "../source/FirebaseFunctions";
import { PutUnityDto } from "../../network/unity/Unity";

export default class ZoneRepository {
  static firestoreRepository = new FirestoreSimpleCrudSource<GetZoneDto,
    PostZoneDto,
    PutZoneDto>("");

  static async create(unityId: number, object: Omit<PostZoneDto, "unityId">): Promise<string | null> {
    const createFunction = httpsCallable(FirebaseFunctions.functions, "createZone");
    const result = (await createFunction({
      ...object, unityId
    })).data as { message: string, zones: Array<GetZoneDto> };
    if (!!result.zones) {
      const toAdd = result.zones.map(z => zoneDtoAsDomain(z, unityId));
      await ZoneDao.putZone(...toAdd);
    }
    return result.message;
  }

  static async delete(unityId: number, object: DeleteZoneDto): Promise<void> {
    const deleteFunction = httpsCallable(FirebaseFunctions.functions, "deleteZone");
    const result = (await deleteFunction({ ...object, unityId })).data as boolean;
    if (result) {
      await ZoneDao.delete(unityId, object.id);
    }
  }

  static async fetchList(unityId: number, forceRefresh: boolean = false) {
    const local = await ZoneDao.getList(unityId);
    if (isEmpty(local) || forceRefresh) {
      this.firestoreRepository.switchPath(`/unity-modules/${unityId}/list/`);
      const remote = await this.firestoreRepository.getList(null);
      if (remote) {
        const arr = remote.map((r) =>
          zoneDtoAsDomain(r.data, unityId)
        );
        await ZoneDao.clear(unityId);
        await ZoneDao.putZone(...arr);
      }
    }
  }

  static async update(
    unityId: number,
    object: PutZoneDto
  ): Promise<void> {
    console.log(object)
    const editFunction = httpsCallable(FirebaseFunctions.functions, "editZone");
    const result = (await editFunction({
      ...object, fromUnityId: unityId
    })).data as PutZoneDto["data"];
    await ZoneDao.update(object.id, result);
  }

  static async getZone(
    unityId: number,
    zoneId: number,
    refresh: boolean = false
  ) {
    const localZone = await ZoneDao.getZone(unityId, zoneId);
    if (!localZone || refresh) {
      const firestoreZone = await httpsCallable<unknown, GetZoneDto>(FirebaseFunctions.functions, "getZone")({
        id: zoneId
      })
      if (firestoreZone) {
        const zone = zoneDtoAsDomain(
          firestoreZone.data,
          unityId
        );
        await ZoneDao.putZone(zone);
        return zone;
      }
    } else return localZone;
  }

  static async searchZone(
    unityId: number,
    keyword: string
  ): Promise<Zone[] | undefined> {
    const localZones = await ZoneDao.getList(unityId);
    const options = {
      includeScore: true,
      keys: ["id", "label"]
    };
    const fuse = new Fuse(localZones, options);
    return fuse.search(keyword).map((it) => it.item);
  }
}
