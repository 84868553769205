import {GetRoleDto} from "../../network/user/Role";

export enum RoleProperties {
    reference = "reference",
    id = "id",
    label = "label",
    export = "export"
}

export type Role = {
    [RoleProperties.reference]: string;
    [RoleProperties.id]: number;
    [RoleProperties.label]: string;
};

export type NewRole = Omit<Role, RoleProperties.reference>;

export function roleDtoAsDomain(roleDto: GetRoleDto, reference: string): Role {
    return {...roleDto, reference: reference};
}

export class UserRoles {
    static mainadmin = 0
    static admin = 1
    static supervisor = 2
    static control = 3
    static client = 4
    static groupboss = 5
    static CCTV = 6
    static agent = 7
    static all = [0, 1, 2, 3, 4, 5, 6, 7]
    static manager = [0, 1, 2, 3]
    static operator = [5, 6, 7]
}
