import React, { FC } from "react";
import { ListChildComponentProps } from "react-window";
import { UserVisitControl } from "../../../../domain/control/Visit";
import { RowCell } from "../../../components/row/Cell";
import { VehicleControlRowUnity } from "./unity";
import { VehicleControlRowTimestamp } from "./timestamp";

export type VehicleControlRowProps = {
  userVehicleControlList: UserVisitControl[];
  onSelect: React.MouseEventHandler<HTMLDivElement>;
};

export const VehicleControlRow: FC<
  ListChildComponentProps<VehicleControlRowProps>
> = ({ data, index, style }) => {
  const { onSelect, userVehicleControlList } = data;
  const item = userVehicleControlList[index];
  return (
    <div style={style} className={"py-2"}>
      <div
        data-index={index}
        onClick={onSelect}
        className={
          "w-full h-full border border-neutral-300 rounded-lg p-2 flex flex-col justify-between content-between"
        }
      >
        <div className={"flex w-full flex-col gap-2"}>
          <VehicleControlRowUnity item={item} />
          <VehicleControlRowTimestamp item={item} />
          <RowCell label={"CONDUCTOR"} data={item} dataKey={"driver"} />
          <RowCell label={"EMPRESA"} data={item} dataKey={"derivation"} />
          <RowCell label={"INGRESO"} data={item} dataKey={"entry"} />
        </div>
      </div>
    </div>
  );
};
