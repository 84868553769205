import {GetNewsItemDto} from "../../network/news/NewsItemDto";

export type NewsItem = {
    reference: string;
    title: string;
    timestamp: number;
    content: string;
};

export function newsItemFromDto(
    reference: string,
    item: GetNewsItemDto
): NewsItem {
    return {
        reference,
        title: item.title,
        content: item.content,
        timestamp: item.timestamp.toMillis(),
    };
}

export function newsItemFromPartial(
    reference: string,
    partial: Partial<NewsItem>
): NewsItem {
    return {
        reference,
        title: partial.title!!,
        content: partial.content!!,
        timestamp: Date.now(),
    };
}
