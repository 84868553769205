import React, {FC, MutableRefObject, useCallback, useRef} from "react";
import {UnityRow} from "./row";
import {EmptyState} from "../../../components/state/empty";
import {Unity} from "../../../../domain/unity";
import {useDataPager} from "../../../../hook/pager";
import {Pagination} from "rsuite";

export type UnityListProps = {
    items?: Unity[];
    onClick: (user: Unity) => void;
    name?: string;
};

export type UnityListRowProps = {
    items: Unity[];
    onClick: React.MouseEventHandler<HTMLDivElement>;
};

export const UnityList: FC<UnityListProps> = ({
                                                  items,
                                                  onClick,
                                                  name,
                                              }) => {
    const actionRef = useRef<string | null>(null) as MutableRefObject<
        string | null
    >;

    /* eslint-disable */

    const {limits, changeLimit, limit, page, partialData, changePage} =
        useDataPager<Unity>({
            id: `unity_list_${name}`,
            items,
        });

  const onRowClick = useCallback<React.MouseEventHandler<HTMLDivElement>>(
    (e) => {
      if (!partialData) return;
      const index = e.currentTarget.dataset.index;
      if (index) {
        const item = partialData[index as unknown as number];
        switch (actionRef.current) {
          default:
            if (onClick) onClick(item);
            break;
        }
      }
      actionRef.current = null;
    },
    [partialData]
  );

    /* eslint-enable */

    return items && items.length > 0 ? ( //@ts-ignore
        <div className={"w-full h-auto"}>
            {partialData?.map((it, i) => (
                <UnityRow
                    index={i}
                    style={{}}
                    data={{
                        items: partialData,
                        onClick: onRowClick,
                    }}
                />
            ))}

            <div className={"py-4 flex flex-col gap-4"}>
        <span className={"text-md"}>
          Mostrando {partialData?.length} elementos / Total {items.length}
        </span>
                <Pagination
                    prev
                    next
                    first
                    last
                    ellipsis
                    boundaryLinks
                    maxButtons={5}
                    size="xs"
                    layout={["limit", "pager"]}
                    total={items.length}
                    limitOptions={limits}
                    limit={limit}
                    activePage={page}
                    onChangePage={changePage}
                    onChangeLimit={changeLimit}
                />
            </div>
        </div>
    ) : (
        <EmptyState/>
    );
};
