import {FC} from "react";
import {CellProps, Table} from "rsuite";
import {PentrackerUser} from "../../../../domain/user/User";
import {FaIcon} from "../../../components/fontawesome/icon";
import {faLock, faUnlock} from "@fortawesome/free-solid-svg-icons";
import {UserTable_CurrentUserCellStyle} from "./index";

type Props = CellProps<PentrackerUser> & {
    currentUid: string;
};
export const UserTableNameCell: FC<Props> = ({
                                                 rowData,
                                                 currentUid,
                                                 ...props
                                             }) => {
    if (!rowData) return null;
    const {displayName, disabled, uid} = rowData;
    return (
        <Table.Cell
            {...props}
            style={{
                ...props.style,
                ...(currentUid === uid ? UserTable_CurrentUserCellStyle : {}),
            }}
        >
            {displayName ? (
                <div className={"flex flex-row gap-2"}>
                    <FaIcon
                        color={disabled ? "red" : "amber"}
                        icon={disabled ? faLock : faUnlock}
                    />
                    <div
                        className={
                            disabled ? "line-through text-red-500 w-full" : "text-md w-full"
                        }
                    >
                        {displayName}
                    </div>
                </div>
            ) : (
                <div className={"text-gray-400"}>
                    <i>Sin nombre de usuario.</i>
                </div>
            )}
        </Table.Cell>
    );
};
