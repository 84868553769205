import { FC, useContext, useEffect } from "react";
import { AppLoader } from "../../../components/loading/LoadingOverlay";
import { useUserRoleViewModel } from "../../../../viewmodel/role/UserRole";
import { UserRoleTable } from "./components/table";
import { RolePaneHeader } from "./components/header";
import { FormModal } from "../../../components/modal/FormModal";
import { NewRoleInputList, NewRoleSchema } from "./modal/InputList";
import { RoleTableMultiselectHeader } from "./components/table/header";
import { DeleteRoleConfirmationModal } from "./components/delete";
import { DashboardHeaderContext } from "../../../../hook/header";
import { UserAuthContext } from "../../../context/UserContext";

import { useStyleContext } from "../../../context/StyleContext";

export const RolePaneContent: FC = (_) => {
  const { appUser } = useContext(UserAuthContext);
  const viewModel = useUserRoleViewModel(appUser);
  const { stylePage } = useStyleContext();

  /* eslint-disable */
  useEffect(() => {
    if (!!viewModel.refreshEvent) {
      void viewModel.fetchList(true);
      viewModel.onRefreshEventCompleted();
    }
  }, [viewModel.refreshEvent]);

  useEffect(() => {
    if (viewModel.postState !== null && !viewModel.postState.loading) {
      const error = viewModel.postState.error;
      if (error) {
        window.toast.error(
          error.message || "Ocurrió un error al crear el módulo."
        );
      } else {
        window.toast.success("Se creó el módulo.");
      }
      viewModel.onPostStateReceived();
      viewModel.onAddEventCompleted();
    }
  }, [viewModel.postState]);

  useEffect(() => {
    const state = viewModel.deleteState;
    if (!!state && !state.isLoading()) {
      if (state.isSuccess()) {
        window.toast.success("La eliminación se completó con éxito.");
      } else if (state.isSuccessWithWarnings()) {
        window.toast.info(state.message);
      } else {
        window.toast.error(state.error?.message);
      }
      viewModel.onDeleteStateReceived();
      viewModel.onDeleteEventCompleted();
    }
  }, [viewModel.deleteState]);

  useEffect(() => {
    if (viewModel.fetchState !== null && !viewModel.fetchState.loading) {
      const error = viewModel.fetchState.error;
      if (error) {
        window.toast.error(
          error.message || "Ocurrió un error al obtener la lista de roles"
        );
      }
      viewModel.onListStateReceived();
    }
  }, [viewModel.fetchState]);

  const headerContext = useContext(DashboardHeaderContext);

  useEffect(() => {
    void viewModel.fetchList(false);
    headerContext.setTheme({
      className: "bg-secondary-header",
      dark: true,
      title: "Cargos",
    });
  }, []);

  /* eslint-enable */

  return (
    <div className={"w-full overflow-x-hidden scrollbar-hide"}>
      <AppLoader isActive={viewModel.allLoadingObserver} />
      {viewModel.addEvent ? (
        <FormModal
          model={NewRoleSchema}
          title={"Nuevo Rol de Usuario"}
          onCancel={viewModel.onAddEventCompleted}
          onOk={viewModel.postRole}
          visible={true}
          disabled={viewModel.postState?.loading}
          inputList={NewRoleInputList}
        />
      ) : null}
      {viewModel.deleteEvent ? (
        <DeleteRoleConfirmationModal
          onClose={viewModel.onDeleteEventCompleted}
          onConfirmation={viewModel.deleteRole}
          itemsToDelete={viewModel.deleteEvent}
        />
      ) : null}
      <RolePaneHeader
        addDisabled={Boolean(viewModel.postState?.loading)}
        refreshDisabled={Boolean(viewModel.fetchState?.loading)}
        onAddClick={viewModel.requestAddEvent}
        onRefreshClick={viewModel.requestRefreshEvent}
        totalCount={viewModel.roles?.length}
        stylePage={stylePage}
      />
      <div className={"w-full bg-neutral-100 p-2 md:p-3 lg:p-5 scrollbar-hide"}>
        <RoleTableMultiselectHeader
          onCancelClicked={viewModel.clearSelection}
          onDeleteClicked={viewModel.requestDeleteEvent}
          selectedItems={viewModel.selectedItems}
        />
        <UserRoleTable
          onCreateClick={viewModel.requestAddEvent}
          loading={Boolean(viewModel.fetchState?.loading)}
          data={viewModel.roles}
          selectedItems={viewModel.selectedItems}
          onSelectAllSwitch={viewModel.switchSelectAll}
          onItemSelectSwitch={viewModel.switchItemSelection}
        />
      </div>
    </div>
  );
};
