import {TableColumnRelation} from "../../ui/context/TableContext";

export type PatrolTableColumns = {
    time: string;
    operator: string;
    unity: string;
    zone: string;
    incident: string;
    actions: string;
};

export const PatrolTableSchema: TableColumnRelation<PatrolTableColumns> =
    {
        large: {
            breakpoints: ["lg", "xl", "2xl"],
            columns: [
                {
                    name: "time",
                    label: "Hora",
                    weight: 0.1,
                },
                {
                    name: "operator",
                    label: "Operador",
                    weight: 0.3,
                },
                {
                    name: "unity",
                    label: "Unidad",
                    weight: 0.2,
                },
                {
                    name: "zone",
                    label: "Zona",
                    weight: 0.2,
                },
                {
                    name: "incident",
                    label: "Incidente",
                    weight: 0.1,
                },
                {
                    name: "actions",
                    label: "Acciones",
                    weight: 0.1,
                },
            ],
        },
    };
