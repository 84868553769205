import ReactDOM from "react-dom/client";
import "./index.less";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { Fragment } from "react";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { initializeApp } from "firebase/app";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { getMessaging } from "firebase/messaging";
import FirebaseAuth from "./data/source/FirebaseAuth";
import { inDevelopment } from "./util/environment";
import FirestoreSource from "./data/source/FirestoreSource";
import FirebaseFunctions from "./data/source/FirebaseFunctions";
import { FirebaseStorageSource } from "./data/source/FirebaseStorageSource";
import { CustomProvider } from "rsuite";
import esAr from "rsuite/locales/es_AR";
import { SecureContextProvider } from "./ui/context/SecureContext";
import { BreakPointContextProvider } from "./ui/context/BreakPointContext";
import { ScreenContextProvider } from "./ui/context/ScreenContext";
import FirebaseMessaging from "./data/source/FirebaseMessaging";
import "./stylesMulti.css";

const firebaseConfig = {
  apiKey: "AIzaSyA5-kkfo9bCdFoTibJmgc6Rg7u-JFrq-yA",
  authDomain: "pentracker-80e8a.firebaseapp.com",
  projectId: "pentracker-80e8a",
  storageBucket: "pentracker-80e8a.appspot.com",
  messagingSenderId: "588487957247",
  appId: "1:588487957247:web:a4e6e802e300c54b830aca",
  measurementId: "G-97NZ58MMEQ",
};

const root = ReactDOM.createRoot(document.getElementById("root"));

//Firebase config
// const host = "192.168.192.220";
// const host = "192.168.1.44";
const host = "127.0.0.1";
const firebaseApp = initializeApp(firebaseConfig);
const firebaseAuth = getAuth(firebaseApp);
const firebaseFirestore = getFirestore(firebaseApp);
const firebaseFunctions = getFunctions(firebaseApp);
const firebaseStorage = getStorage(firebaseApp);
const firebaseMessaging = getMessaging(firebaseApp);
if (inDevelopment()) {
  // eslint-disable-next-line no-restricted-globals
  // self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
  // initializeAppCheck(firebaseApp, {
  //     provider: new AppCheckCustomProvider({
  //         getToken: () => {
  //             return undefined;
  //         },
  //     }),
  // });
  connectFirestoreEmulator(firebaseFirestore, host, 8080);
  connectAuthEmulator(firebaseAuth, `http://${host}:9099`);
  connectFunctionsEmulator(firebaseFunctions, host, 5001);
  connectStorageEmulator(firebaseStorage, host, 9199);
} else {
  // initializeAppCheck(firebaseApp, {
  //     provider: new ReCaptchaV3Provider(
  //         "6LeoHzglAAAAAIjANp0tFnydsomPEVL59IPcdTD-"
  //     ),
  //     isTokenAutoRefreshEnabled: true,
  // });
}
FirebaseAuth.auth = firebaseAuth;
FirestoreSource.firestore = firebaseFirestore;
FirebaseFunctions.functions = firebaseFunctions;
FirebaseStorageSource.storage = firebaseStorage;
FirebaseMessaging.messaging = firebaseMessaging;
window.toast = toast;
//TODO: Add restrict mode again
root.render(
  <Fragment>
    <CustomProvider locale={esAr} theme={"light"}>
      <SecureContextProvider>
        <BreakPointContextProvider>
          <ScreenContextProvider>
            <App />
          </ScreenContextProvider>
        </BreakPointContextProvider>
      </SecureContextProvider>
    </CustomProvider>
    <ToastContainer />
  </Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
