import {FC} from "react";
import {CellProps, Table} from "rsuite";
import {toTime} from "../../../../lib/date";
import {UserPatrol} from "../../../../domain/patrol/Patrol";

export const PatrolGroupTableTimeCell: FC<CellProps<UserPatrol>> = ({
                                                                        rowData,
                                                                        ...props
                                                                    }) => {
    const {
        timestamp
    } = rowData!;

    return <Table.Cell {...props}>{toTime(timestamp)}</Table.Cell>;
};
