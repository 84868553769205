import React, { FC, useMemo } from "react";
import { ListChildComponentProps } from "react-window";
import { UserSearchListRowProps } from "./index";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FaIcon } from "../../../../../components/fontawesome/icon";

export const TicketSearchRow: FC<
  ListChildComponentProps<UserSearchListRowProps>
> = ({ index, style, data }) => {
  const { items, onClick } = data;
  const item = items!![index];
  //   const wrapper = useMemo(() => {
  //     return new UserWrapper(item);
  //   }, [item]);
  return (
    <div style={style} className={"p-2"}>
      <div
        data-index={index}
        onClick={onClick}
        className={
          "w-full h-full border border-neutral-300 rounded-2xl p-2 flex flex-col justify-center items-start gap-2"
        }
      >
        <div
          className={"flex w-full flex-row gap-3 items-center content-center"}
        >
          <FaIcon icon={faFilePdf} size={"2x"} className={`text-neutral-600`} />
          <div className={"flex flex-col"}>
            <span className={`text-lg`}>{item.name}</span>
            {item.user ? (
              <div>{item.user.displayName || item.user.uid}</div>
            ) : (
              <span className={"font-normal text-sm"}>
                No se encontró el usuario vinculado a este archivo.
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
