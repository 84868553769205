import { useContext, useState } from "react";
import { Response } from "../../domain/app/Response";
import { AppTimestamp } from "../../domain/app/Timestamp";
import { UserAuthContext } from "../../ui/context/UserContext";
import { AppManagerEvent } from "../../domain/event/ManagerEvent";
import ManagerEventRepository from "../../data/repository/ManagerEventRepository";

export function useManagerEventGroupViewModel() {
  const [fetchState, setFetchState] = useState<Response<boolean> | null>(null);
  const [managerEventList, setManagerEventList] = useState<AppManagerEvent[] | undefined>();
  const [managerEventListTimestamp, setManagerEventListTimestamp] = useState<AppTimestamp | undefined>();
  const [refreshEvent, setRefreshEvent] = useState<boolean | null>(null);
  const [selectedItem, setSelectedItem] = useState<AppManagerEvent | null>(null);
  const { appUser } = useContext(UserAuthContext);

  async function fetchManagerEventList(
    timestamp: number,
    forceRefresh: boolean = false
  ) {
    if (fetchState?.isLoading()) return;
    setFetchState(Response.loading());
    try {
      const result = await ManagerEventRepository.getList(
        forceRefresh,
        timestamp,
        appUser
      );
      setManagerEventList(result!!.data);
      setManagerEventListTimestamp(result?.timestamp);
      setFetchState(Response.success(true));
    } catch (e: any) {
      setFetchState(Response.failure(e));
    }
  }

  function onFetchStateReceived() {
    setFetchState(null);
  }

  function requestRefreshEvent() {
    setRefreshEvent(true);
  }

  function onRefreshEventCompleted() {
    setRefreshEvent(null);
  }

  function requestSelectItem(item: AppManagerEvent) {
    setSelectedItem(item);
  }

  function onSelectItemCompleted() {
    setSelectedItem(null);
  }

  return {
    fetchState,
    onFetchStateReceived,
    managerEventList,
    managerEventListTimestamp,
    fetchManagerEventList,
    refreshEvent,
    requestRefreshEvent,
    onRefreshEventCompleted,
    requestSelectItem,
    onSelectItemCompleted,
    selectedItem
  };
}
