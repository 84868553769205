import { TableColumnRelation } from "../../ui/context/TableContext";
import { VisitControl } from "../../domain/control/Visit";

export const VisitControlTableSchema: TableColumnRelation<VisitControl> = {
  large: {
    breakpoints: ["lg", "xl", "2xl"],
    columns: [
      {
        name: "unityId",
        label: "Unidad",
        weight: 0.2,
      },
      {
        name: "timestamp",
        label: "Añadido en",
        weight: 0.1,
      },
      {
        name: "name",
        label: "Nombre",
        weight: 0.2,
      },
      {
        name: "lastname",
        label: "Apellido",
        weight: 0.2,
      },
      {
        name: "dni",
        label: "Documento",
        weight: 0.2,
      },
      {
        name: "entry",
        label: "Ingreso",
        weight: 0.1,
      },
    ],
  },
};
