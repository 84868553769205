import { FC, Fragment, useEffect, useState } from "react";
import { Loader } from "rsuite";
import { createPortal } from "react-dom";

export const AppLoader: FC<{
  isActive?: boolean;
}> = ({
        isActive = false,

      }) => {
  const [root, setRoot] = useState<HTMLElement>();

  useEffect(() => {
    const _root = document.getElementById("root")!;
    setRoot(_root);
  }, []);
  return root ? createPortal(
    <Fragment>
      {
        isActive ? <div
          className={`transition-all absolute w-screen h-screen flex justify-center bg-white bg-opacity-10 top-0 left-0 opacity-100 absolute`}
          style={{
            zIndex: 9999,
          }}
        >
          <Loader center size={"lg"} />
        </div> : null
      }
    </Fragment>, root
  ) : null;
};
