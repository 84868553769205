import {FC, FormEvent, useState} from "react";
import {Button, Form, InputGroup, Schema} from "rsuite";
import {isNotBlank} from "../../../../util/validation";
import { faKey, faUserTie } from "@fortawesome/free-solid-svg-icons";
import {FaIcon} from "../../../components/fontawesome/icon";

export type LoginFormData = {
    email: string
    password: string
};

export type Props = {
    onSubmit: (data: LoginFormData) => void;
};

export const LoginForm: FC<Props> = ({onSubmit}) => {
    const [values, setValues] = useState<LoginFormData>({
        email: "", password: ""
    });

    function onFormSubmit(checkStatus: boolean, event: FormEvent) {
        event.preventDefault();
        if (checkStatus) onSubmit({...values});
    }

    return (
        <Form
            fluid
            model={Schema.Model({
                email: Schema.Types.StringType()
                    .isRequired("Ingresar email")
                  .isEmail("Ingresar email váido")
                    .addRule(isNotBlank(), "Este campo no debe estar vacío."),
                password: Schema.Types.StringType().isRequired("Ingresar contraseña")
            })}
            onChange={(data) => {
                setValues(data as LoginFormData);
            }}
            formValue={values}
            onSubmit={onFormSubmit}
        >
            <Form.Group>
                <Form.ControlLabel className={"text-neutral-200"}>Correo Electrónico</Form.ControlLabel>
                <InputGroup>
                    <InputGroup.Addon>
                        <FaIcon icon={faUserTie}/>
                    </InputGroup.Addon>
                    <Form.Control
                        name="email"
                        type={"email"}
                        placeholder={"Ingresar correo electrónico"}
                    />
                </InputGroup>
            </Form.Group>
            <Form.Group>
                <Form.ControlLabel className={"text-neutral-200"}>Contraseña</Form.ControlLabel>
                <InputGroup>
                    <InputGroup.Addon>
                        <FaIcon icon={faKey}/>
                    </InputGroup.Addon>
                    <Form.Control
                      name="password"
                      type={"password"}
                      placeholder={"Ingresar contraseña"}
                    />
                </InputGroup>
            </Form.Group>
            <Form.Group>
                <Button appearance="default" type={"submit"} className={"w-full bg-black"} size={"lg"}>
                    Acceder
                </Button>
            </Form.Group>
        </Form>
    );
};
