import {FieldValue, serverTimestamp, Timestamp} from "firebase/firestore";
import {NewsItem} from "../../domain/news/NewsItem";
import {omit} from "lodash";

export type GetNewsItemDto = {
    title: string;
    timestamp: Timestamp;
    content: string;
};

export type PostNewsItemDto = {
    title: string;
    timestamp: FieldValue;
    content: string;
};

export type PutNewsItemDto = Partial<Omit<PostNewsItemDto, "timestamp">>

export function newsItemAsPostDto(item: Partial<NewsItem>): PostNewsItemDto {
    return {
        title: item.title!!,
        content: item.content!!,
        timestamp: serverTimestamp(),
    };
}

export function newsItemAsPutDto(item: Partial<NewsItem>): PutNewsItemDto {
    return omit(item, ["reference", "timestamp"])
}


