import { FC, useContext } from "react";
import { UserIncident } from "../../../../../domain/incident/Incident";
import { BreakPointContext } from "../../../../context/BreakPointContext";
import { IncidentList } from "../../../data-display/incident-list";
import { IncidentTable } from "../../../data-display/incident-table";

type Props = {
  incidentList?: UserIncident[];
  onPreviewClick: (item: UserIncident) => void;
  onSelect: (item: UserIncident) => void;
};

export const UnityIncidentDataName = "UnityIncidentData";

export const UnityIncidentData: FC<Props> = ({
  incidentList,
  onPreviewClick,
  onSelect,
}) => {
  const screenSize = useContext(BreakPointContext);
  if (screenSize.isMobile) {
    return (
      <IncidentList
        userIncidentList={incidentList}
        onPreviewClicked={onPreviewClick}
        onSelect={onSelect}
        name={UnityIncidentDataName}
      />
    );
  } else {
    return (
      <IncidentTable
        name={UnityIncidentDataName}
        userIncidentList={incidentList}
        onPreviewClicked={onPreviewClick}
        onSelect={onSelect}
      />
    );
  }
};
