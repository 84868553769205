import {FC, useContext} from "react";
import {BreakPointContext} from "../../../../context/BreakPointContext";
import { UserIncident } from "../../../../../domain/incident/Incident";
import { IncidentList } from "../../../data-display/incident-list";
import { IncidentTable } from "../../../data-display/incident-table";

type Props = {
    items?: UserIncident[];
    onPreview: (item: UserIncident) => void;
    onClick: (user: UserIncident) => void;
};

export const IncidentGroupByRequestDataName = "IncidentGroupByRequestData"

export const IncidentGroupByRequestData: FC<Props> = ({
                                                        items,
                                                        onPreview,
                                                        onClick,
                                                    }) => {
    const screenSize = useContext(BreakPointContext);
    if (screenSize.isMobile) {
        return (
            <IncidentList
                onSelect={onClick}
                userIncidentList={items}
                onPreviewClicked={onPreview}
                name={IncidentGroupByRequestDataName}
            />
        );
    } else {
        return (
            <IncidentTable
                userIncidentList={items}
                onPreviewClicked={onPreview}
                onSelect={onClick}
                name={IncidentGroupByRequestDataName}
            />
        );
    }
};
