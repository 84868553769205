import {FC} from "react";
import {UserPatrol} from "../../../../../domain/patrol/Patrol";
import {PatrolLastEntriesList} from "./list";

type Props = {
    entries?: UserPatrol[]
}

export const PatrolOverviewLastEntries: FC<Props> = ({entries}) => {
    return <div
        className={
            "bg-white rounded shadow-sm flex flex-col py-4 w-full h-full"
        }
    >
        <div
            className={"w-full flex flex-row flex-wrap px-12 items-center"}
        >
            <p className={"m-0 p-0 text-lg font-semibold text-neutral-800"}>
                Últimos registros
            </p>
        </div>
        <div className={"w-full md:flex-1 p-2 h-56"}>
            <PatrolLastEntriesList entries={entries}/>
        </div>
    </div>
}
