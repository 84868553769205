import {UseLoadScriptOptions} from "@react-google-maps/api/dist/useLoadScript";
import { inDevelopment } from "./environment";

export const GOOGLE_MAPS_API_KEY = inDevelopment() ? "" : process.env.REACT_APP_GOOGLE_MAPS_API_KEY!
export const GoogleMapLibraries: UseLoadScriptOptions["libraries"] = ["places"];

export const GoogleMapJsLoadOptions: UseLoadScriptOptions = {
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: GoogleMapLibraries,
    version: "weekly",
    mapIds: ["54f42e9cd3f10860"],
};

export const DefaultLocation = {
    lat: -12.091838,
    lng: -77.0290964,
};
