import React, { FC, useContext, useEffect } from "react";
import { useLoaderData } from "react-router-dom";
import { ContextHeader } from "../../components/header/secondary";
import { UserPatrol } from "../../../domain/patrol/Patrol";
import { UserPatrolView } from "./components/view";
import { DashboardHeaderContext } from "../../../hook/header";

import { useStyleContext } from "../../context/StyleContext";

export const PatrolPage: FC = () => {
  const data = useLoaderData() as UserPatrol;
  /* eslint-disable */
  const dashboardHeaderContext = useContext(DashboardHeaderContext);
  useEffect(() => {
    dashboardHeaderContext.setTheme({
      className: "bg-gray-100",
      title: "Ronda",
      dark: false,
      subtitle: data.owner?.displayName,
      homeAsUpEnabled: true,
    });
  }, []);
  const {
    stylePage,
  } = useStyleContext();

  /* eslint-enable */
  return (
    <div className={"w-full h-full flex flex-col overflow-hidden px-3"}>
      <ContextHeader
        breadcrumbItems={[
          {
            label: "Rondas",
            path: "/dashboard/patrol-group",
          },
          {
            label: "...",
            active: true,
          },
        ]}
        title={"Registro de Ronda"}
        stylePage={stylePage}
      />
      <div className={"p-3 flex-1 w-full overflow-y-auto"}>
        <UserPatrolView patrol={data} />
      </div>
    </div>
  );
};
