import { useState } from "react";
import { Response } from "../domain/app/Response";
import useLocalStorageState from "use-local-storage-state";
import FirebaseMessaging from "../data/source/FirebaseMessaging";


export function useDashboardPageViewModel() {
  const [savedToken, setSavedToken] = useLocalStorageState<string | undefined>("fcm_token", {
    defaultValue: undefined
  })
  const [uploadTokenState, setUploadTokenState] = useState<Response<boolean> | null>(null)
  const [permissionsState, setPermissionState] = useState<Response<boolean> | null>(null)
  async function updateToken() {
    if(uploadTokenState?.isLoading()) return
    setUploadTokenState(Response.loading())
    try {
      const token = await FirebaseMessaging.getFCMToken();
      if(savedToken !== token) await FirebaseMessaging.updateWebClient(token)
      setSavedToken(token)
      setUploadTokenState(Response.success(true))
    } catch (e: any) {
      setUploadTokenState(Response.failure(e))
    }
  }

  function onUploadTokenStateReceived() {
    setUploadTokenState(null)
  }

  async function checkPermissions() {
    if(permissionsState?.isLoading()) return
    setPermissionState(Response.loading())
    let granted = false;
    if ("Notification" in window) {
      if (Notification.permission === "granted") {
        granted = true;
      } else {
        granted = (await Notification.requestPermission()) === "granted";
      }
      setPermissionState(Response.success(granted))
    } else {
      setPermissionState(Response.success(false))
    }
  }

  function onPermissionsStateReceived() {
    setPermissionState(null)
  }

  return ({
    uploadTokenState, permissionsState, updateToken, checkPermissions, onUploadTokenStateReceived, onPermissionsStateReceived
  })
}
