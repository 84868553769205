import {FC, HTMLAttributes, useEffect, useState} from "react";
import {getAddressFromLocation} from "../../../util/location";
import {Spinner} from "../spinner";

type Props = {
    location?: google.maps.LatLngLiteral | null
    className?: HTMLAttributes<any>["className"]
}

export const GeoLocationRender: FC<Props> = ({location, className}) => {
    const [address, setAddress] = useState("...");
    const [loading, setLoading] = useState(false)
    /* eslint-disable */
    useEffect(() => {
        if (location) {
            if (loading) return
            setLoading(true)
            getAddressFromLocation(location).then((address) => {
                setAddress(address)
                setLoading(false)
            });
        } else {
            setAddress("La ubicación es inválida - no existe.");
        }
    }, [location]);
    /* eslint-enable */
    return loading ? <div className={"flex flex-row gap-2 items-center flex-wrap break-words"}><Spinner/>
        <strong>Cargando...</strong></div> : <p className={className}>{address}</p>;
}
