import {useContext, useState} from "react";
import {Response} from "../../domain/app/Response";
import {AppTimestamp} from "../../domain/app/Timestamp";
import {UserAuthContext} from "../../ui/context/UserContext";
import { UserGoodsControl } from "../../domain/control/Goods";
import { GoodsControlRepository } from "../../data/repository/GoodsControlRepository";

export function useGoodsControlViewModel() {
    const [fetchState, setFetchState] = useState<Response<boolean> | null>(null);
    const [goodsControlList, setGoodsControlList] = useState<
        UserGoodsControl[] | undefined
    >();
    const [goodsControlTimestamp, setGoodsControlTimestamp] = useState<
        AppTimestamp | undefined
    >();
    const [refreshEvent, setRefreshEvent] = useState<boolean | null>(null);
    const {appUser} = useContext(UserAuthContext);

    async function fetchGoodsControlList(
        timestamp: number,
        forceRefresh: boolean = false
    ) {
        if (fetchState?.isLoading()) return;
        setFetchState(Response.loading());
        try {
            const result = await GoodsControlRepository.getGlobal(
                timestamp,
                forceRefresh,
                appUser
            );
            setGoodsControlList(result?.goodsControlList);
            setGoodsControlTimestamp(result?.timestamp);
            setFetchState(Response.success(true));
        } catch (e: any) {
            setFetchState(Response.failure(e));
        }
    }

    function onFetchStateReceived() {
        setFetchState(null);
    }

    function requestRefreshEvent() {
        setRefreshEvent(true);
    }

    function onRefreshEventCompleted() {
        setRefreshEvent(null);
    }

    return {
        fetchState,
        onFetchStateReceived,
        goodsControlList,
        goodsControlTimestamp,
        fetchGoodsControlList,
        refreshEvent,
        requestRefreshEvent,
        onRefreshEventCompleted,
    };
}
