import {Shift, ShiftProperties} from "../../domain/user/Shift";
import {ignoreNilAndPaths, ignorePaths} from "../../util/object";

export type PostShiftDto = {
    id: number
    label: string;
    schedule: string;
};

export type GetShiftDto = PostShiftDto;

export type PutShiftDto = {
    label?: string;
    schedule?: string;
};

export function shiftAsPostDto(shift: Shift): PostShiftDto {
    return ignorePaths(shift, ShiftProperties.reference);
}

export function shiftAsPutDto(shift: Partial<Shift>): PutShiftDto {
    return ignoreNilAndPaths(shift, ShiftProperties.reference);
}
