import { FC, useContext, useEffect } from "react";
import { AppLoader } from "../../components/loading/LoadingOverlay";
import { useUnityListViewModel } from "../../../viewmodel/unity/UnityList";
import { FormModal } from "../../components/modal/FormModal";
import { Unity } from "../../../domain/unity";
import { useNavigate } from "react-router-dom";
import { PrimaryHeader } from "../../components/header/primary";
import { UnityData } from "./components/data";
import { DashboardHeaderContext } from "../../../hook/header";
import { faAdd, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { UserAuthContext } from "../../context/UserContext";
import { NewUnityInputList, UnityInputSchema } from "./modal/InputList";
import { useStyleContext } from "../../context/StyleContext";

const UnityPanel: FC = () => {
  const unityViewModel = useUnityListViewModel();
  const { appUser } = useContext(UserAuthContext);
  const navigate = useNavigate();
  const {
    fetchListState,
    createState,
    createEvent,
    createUnity,
    onCreateStateReceived,
    onFetchStateReceived,
    onCreateEventCompleted,
    requestCreateEvent,
    requestRefreshEvent,
    unityList,
    refreshEvent,
    fetchUnityList,
    onRefreshEventCompleted,
  } = unityViewModel;

  const {
    stylePage,
  } = useStyleContext();

  /*eslint-disable*/
  useEffect(() => {
    if (createState !== null && !createState.loading) {
      if (createState.isSuccess()) {
        window.toast.success("Se creó la unidad.");
      } else {
        window.toast.error(createState!.error!.message);
      }
      onCreateStateReceived();
      onCreateEventCompleted();
    }
  }, [createState]);

  useEffect(() => {
    if (fetchListState !== null && !fetchListState.loading) {
      if (fetchListState.isFailed()) {
        window.toast.error(fetchListState!.error!.message);
      }
      onFetchStateReceived();
    }
  }, [fetchListState]);

  useEffect(() => {
    if (refreshEvent) {
      void fetchUnityList(true);
      onRefreshEventCompleted();
    }
  }, [refreshEvent]);

  const headerContext = useContext(DashboardHeaderContext);

  useEffect(() => {
    headerContext.setTheme({
      className: "bg-secondary-header",
      dark: true,
      title: "Unidades",
    });
    void fetchUnityList();
  }, []);

  const showLoadingOverlay =
    (fetchListState && fetchListState.loading) ||
    (createState && createState.loading);
  /*eslint-enable*/

  function onUnitySelected(unity: Unity) {
    navigate(`/dashboard/unity/${unity.id}/main`);
  }

  return (
    <div
      className={
        "w-full overflow-y-auto overflow-x-hidden scrollbar-hide md:scrollbar-default"
      }
      style={stylePage}
    >
      <AppLoader isActive={showLoadingOverlay!} />
      {!!createEvent ? (
        <FormModal
          title={"Nueva Unidad"}
          inputList={NewUnityInputList}
          visible={true}
          onOk={createUnity}
          onCancel={onCreateEventCompleted}
          disabled={!!createState?.loading}
          okButtonLoading={createState?.loading}
          model={UnityInputSchema}
        />
      ) : null}
      <PrimaryHeader
        breadcrumbItems={[
          {
            label: "Unidades",
            active: true,
          },
        ]}
        title={"Unidades"}
        description={`${unityList?.length} unidad(es)`}
        actionsSchema={[
          {
            id: "add",
            icon: faAdd,
            label: "Nuevo",
            onClick: requestCreateEvent,
            hide: appUser.isClient() || !appUser.isAdmin(),
          },
          {
            id: "refresh",
            icon: faRefresh,
            label: "Actualizar",
            onClick: requestRefreshEvent,
          },
        ]}
      />
      <div className={"w-full p-2 md:p-3 lg:p-5"}>
        <UnityData items={unityList} onClick={onUnitySelected} />
      </div>
    </div>
  );
};

export default UnityPanel;
