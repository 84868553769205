import { FC, useCallback, useEffect } from "react";
import { useUnitySubmoduleListViewModel } from "../../../../viewmodel/unity/UnityZone";
import { AppLoader } from "../../../components/loading/LoadingOverlay";
import { UnityZonePaneHeader } from "./components/header";
import { FormModal } from "../../../components/modal/FormModal";
import { EditableFormModal } from "../../../components/modal/EditableFormModal";
import { PutZone, Zone } from "../../../../domain/patrol/Zone";
import { NewZoneInputList, NewZoneInputSchema } from "./modal/NewZone";
import { EditZoneInputList, EditZoneInputSchema } from "./modal/EditZone";
import { UnityZoneData } from "./components/data";
import { UnityZoneModal } from "./components/modal";
import { UnityZoneTableMultiselectHeader } from "./components/table/header";
import { DeleteZoneInputList, DeleteZoneInputSchema } from "./modal/DeleteZone";

export const UnityZonePage: FC = () => {
  const {
    fetchListState,
    createState,
    deleteState,
    editState,
    onFetchStateReceived,
    onCreateStateReceived,
    onCreateEventCompleted,
    onDeleteStateReceived,
    onDeleteEventCompleted,
    onEditStateReceived,
    onEditEventCompleted,
    zoneList,
    requestCreateEvent,
    requestRefreshEvent,
    requestEditZone,
    editZone,
    deleteZone,
    createZone,
    createEvent,
    editEvent,
    deleteEvent,
    requestDeleteEvent,
    fetchZoneList,
    downloadEvent,
    onDownloadEventCompleted,
    requestDownloadEvent,
    selectedZonesReferences,
    switchSelectAll,
    switchZoneSelected,
    refreshEvent,
    onRefreshEventCompleted,
    downloadState,
    downloadZone,
    onDownloadStateReceived,
    selectedItem,
    requestSelectItem,
    onSelectItemCompleted,
    clearSelection,
    initialUnity
  } = useUnitySubmoduleListViewModel();

  const loading =
    (!!fetchListState && fetchListState.loading) ||
    (!!createState && createState.loading) ||
    (!!deleteState && deleteState.loading) ||
    (!!editState && editState.loading) ||
    (!!downloadState && downloadState.loading);

  /* eslint-disable */
  useEffect(() => {
    if (!!fetchListState && !fetchListState.loading) {
      if (fetchListState.isFailed()) {
        window.toast.error("Ocurrió un error al cargar la lista de zonas.");
        console.log(fetchListState.error);
      }
      onFetchStateReceived();
    }
  }, [fetchListState]);

  useEffect(() => {
    if (!!createState && !createState.loading) {
      if (createState.isSuccess()) {
        console.log(createState.data);
        window.toast.success("Zona creada correctamente.");
      } else {
        console.log(createState.error);
        window.toast.error(
          createState.error ? createState.error.message : "Error al crear zona."
        );
        console.error(createState.error);
      }
      onCreateStateReceived();
      onCreateEventCompleted();
    }
  }, [createState]);

  useEffect(() => {
    if (!!editState && !editState.loading) {
      if (editState.isSuccess()) {
        window.toast.success("Zona editada correctamente.");
      } else {
        window.toast.error("Ocurrió un error al editar la zona.");
        console.error(editState.error);
      }

      onEditStateReceived();
      onEditEventCompleted();
    }
  }, [editState]);

  useEffect(() => {
    if (!!deleteState && !deleteState.loading) {
      if (deleteState.isSuccess()) window.toast.success("Zona eliminada.");
      else {
        window.toast.error("Ocurrió un error al eliminar la zona.");
        console.error(deleteState.error);
      }
      onDeleteStateReceived();
      onDeleteEventCompleted();
    }
  }, [deleteState]);

  useEffect(() => {
    void fetchZoneList();
  }, []);

  useEffect(() => {
    if (refreshEvent === true) {
      void fetchZoneList(true);
      onRefreshEventCompleted();
    }
  }, [refreshEvent]);

  useEffect(() => {
    if (downloadEvent) {
      void downloadZone();
    }
  }, [downloadEvent]);

  useEffect(() => {
    if (!!downloadState && !downloadState.isLoading()) {
      if (downloadState.isSuccess()) {
        window.toast.success("Los QR fueron añadidos a la cola de descarga.");
      } else {
        console.log(downloadState.error);
        window.toast.error("Ocurrió un error.");
      }

      onDownloadEventCompleted();
      onDownloadStateReceived();
    }
  }, [downloadState]);

  const onTableDownloadAction = useCallback((item: Zone) => {
    requestDownloadEvent([item.id]);
  }, []);

  const onTableDeleteAction = useCallback((item: Zone) => {
    requestDeleteEvent([item.id]);
  }, []);

  const onEditZoneAction = useCallback((zone: Zone) => {
    void requestEditZone([zone.id]);
  }, []);

  const onTableRowClick = (item: Zone) => {
    requestSelectItem(item);
  }

  /* eslint-enable */
  return (
    <div

      className={"w-full overflow-x-hidden"}
    >
      <AppLoader isActive={loading} />
      {!!selectedItem ? (
        <UnityZoneModal
          zone={selectedItem}
          onEditClicked={onEditZoneAction}
          onDeleteClicked={requestDeleteEvent}
          onDownloadClicked={requestDownloadEvent}
          onClose={onSelectItemCompleted}
        />
      ) : null}
      {createEvent ? (
        <FormModal
          title={"Nueva Zona"}
          visible={true}
          inputList={NewZoneInputList}
          onCancel={onCreateEventCompleted}
          onOk={createZone}
          model={NewZoneInputSchema}
          disabled={createState?.loading}
        />
      ) : null}
      {editEvent ? (
        <EditableFormModal
          initialValues={editEvent}
          title={"Editar Zona"}
          visible={true}
          inputList={EditZoneInputList}
          onCancel={onEditEventCompleted}
          onOk={editZone}
          model={EditZoneInputSchema}
          disabled={editState?.loading}
          okButtonLoading={editState?.loading}
        />
      ) : null}
      {deleteEvent ? (
        <FormModal
          title={"Eliminar Zona"}
          visible={true}
          inputList={DeleteZoneInputList}
          onCancel={onDeleteEventCompleted}
          onOk={deleteZone}
          model={DeleteZoneInputSchema}
          disabled={deleteState?.loading}
        />
      ) : null}

      <div className={"p-3"}>
        <UnityZonePaneHeader
          onAddClick={requestCreateEvent}
          onRefreshClick={requestRefreshEvent}
          onDeleteClick={requestDeleteEvent}
          onDownloadClick={requestDownloadEvent}
        />
      </div>
      <UnityZoneTableMultiselectHeader
        onDownloadClicked={requestDownloadEvent}
        onCancelClicked={clearSelection}
        onDeleteClicked={requestDeleteEvent}
        onEditClicked={requestEditZone}
        selectedZones={selectedZonesReferences}
      />
      <UnityZoneData
        unity={initialUnity}
        items={zoneList}
        checkedZonesReferences={selectedZonesReferences}
        onCheckedChange={switchZoneSelected}
        onDeleteButtonClick={onTableDeleteAction}
        onDownloadClick={onTableDownloadAction}
        onEditButtonClick={onEditZoneAction}
        onSwitchCheckAll={switchSelectAll}
        onRowClick={onTableRowClick}
      />
    </div>
  );
};
