import {FC} from "react";
import {CellProps, Table} from "rsuite";
import {Link} from "react-router-dom";
import {UserPatrol} from "../../../../domain/patrol/Patrol";

export const PatrolGroupTableUnityCell: FC<CellProps<UserPatrol>> = ({
                                                                         rowData,
                                                                         ...props
                                                                     }) => {
    const {unity, unityId} = rowData!;
    return (
        <Table.Cell {...props}>
            <Link to={`/dashboard/unity/${unityId}`}>
                {unity ? unity.label : unityId}
            </Link>
        </Table.Cell>
    );
};
