import React, { FC } from "react";
import { ListChildComponentProps } from "react-window";
import { faEdit, faMessage, faTrash } from "@fortawesome/free-solid-svg-icons";
import { NewsItem } from "../../../../domain/news/NewsItem";
import { FaIcon } from "../../../components/fontawesome/icon";
import { toRelativeCalendar } from "../../../../lib/date";
import { ButtonToolbar, IconButton } from "rsuite";
import { useStyleContext } from "../../../context/StyleContext";

export type NewsContentListRowProps = {
  items: NewsItem[];
  onSelect: React.MouseEventHandler<HTMLDivElement>;
  onEdit: React.MouseEventHandler<HTMLElement>;
  onDelete: React.MouseEventHandler<HTMLElement>;
};

export const NewsContentListRow: FC<
  ListChildComponentProps<NewsContentListRowProps>
> = ({ data, index, style }) => {
  const { onSelect, items, onDelete, onEdit } = data;
  const item = items[index];
  const { primaryColors } = useStyleContext();

  return (
    <div style={style} className={"py-2 cursor-pointer"}>
      <div
        id={"row"}
        data-index={index}
        onClick={onSelect}
        className={
          "w-full h-full border border-neutral-300 rounded-lg p-2 flex flex-row flex-wrap"
        }
      >
        <div
          className={
            "flex w-full flex-row gap-3 items-start content-center flex-1"
          }
        >
          <FaIcon
            icon={faMessage}
            size={"3x"}
            className={`text-white p-2 rounded ${primaryColors.colorPrimary}`}
          />
          <div className={"flex-1 flex flex-col"}>
            <span className={`text-lg font-semibold`}>{item.title}</span>
            <span className={"text-xs"}>
              {toRelativeCalendar(item.timestamp, true)?.toUpperCase()}
            </span>
            <div
              className={"line-clamp-2"}
              dangerouslySetInnerHTML={{
                __html: item.content,
              }}
            />
          </div>
        </div>

        <ButtonToolbar>
          <IconButton
            id={"delete"}
            icon={<FaIcon icon={faTrash} />}
            appearance={"primary"}
            data-index={index}
            onClick={onDelete}
          />
          <IconButton
            id={"edit"}
            icon={<FaIcon icon={faEdit} />}
            appearance={"primary"}
            data-index={index}
            onClick={onEdit}
          />
        </ButtonToolbar>
      </div>
    </div>
  );
};
