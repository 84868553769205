import React, { FC, useContext, useEffect } from "react";
import { PrimaryHeader } from "../../components/header/primary";
import { DashboardHeaderContext } from "../../../hook/header";
import { ByRequestVisitControlGroup } from "./request";
import { useStyleContext } from "../../context/StyleContext";

export const VisitControlGroupPage: FC = () => {
  const dashboardHeaderContext = useContext(DashboardHeaderContext);
  const {
    stylePage,
  } = useStyleContext();

  /* eslint-disable */
  useEffect(() => {
    dashboardHeaderContext.setTheme({
      className: "bg-secondary-header",
      title: "Control de Visitas",
      dark: true,
    });
  }, []);
  /* eslint-enable */

  return (
    <div className={"w-full overflow-x-hidden"} style={stylePage}>
      <PrimaryHeader
        breadcrumbItems={[
          {
            label: "Control de Visitas",
            active: true,
          },
        ]}
        title={"Control de Visitas"}
      />
      <div className={"w-full p-3"}>
        <ByRequestVisitControlGroup />
      </div>
    </div>
  );
};
