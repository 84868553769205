import {FC} from "react";
import {FaIcon} from "../../../components/fontawesome/icon";
import {faCheckToSlot, faXmarkCircle,} from "@fortawesome/free-solid-svg-icons";
import {UserPatrol} from "../../../../domain/patrol/Patrol";

type Props = {
    item: UserPatrol;
};

export const PatrolRowIncident: FC<Props> = ({item}) => {
    const {incident} = item!!;
    return !!incident ? (
        <div className={"flex flex-row flex-wrap items-center"}>
            <FaIcon className={"mr-2"} icon={faCheckToSlot} color={"green"}/> Se
            registró un incidente.
        </div>
    ) : (
        <div>
            <FaIcon className={"mr-2"} icon={faXmarkCircle} color={"red"}/> Ningún
            incidente.
        </div>
    );
};
