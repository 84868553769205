import {FC, Fragment} from "react";
import {CustomInputProps} from "../modal/FormModal";
import JoditEditor from "jodit-react";
import {Message} from "rsuite";

export const QuillInput: FC<CustomInputProps<any>> = ({
                                                          values,
                                                          updateValue,
                                                          disabled,
                                                          name,
                                                          errorWrapper,
                                                      }) => {
    const error =
        errorWrapper.formError && errorWrapper.formError[name]
            ? errorWrapper.formError[name]
            : undefined;
    return (
        <Fragment>
            <JoditEditor
                className={
                    disabled
                        ? "opacity-50 cursor-not-allowed pointer-events-none"
                        : undefined
                }
                value={values[name]}
                onBlur={(newContent) => updateValue(name, newContent)}
            />
            {error ? <Message showIcon type={"error"}>{error}</Message> : null}
        </Fragment>
    );
};
