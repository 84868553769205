import { InputDataList } from "../../../components/modal/FormModal";
import { Schema } from "rsuite";
import { isNotBlank } from "../../../../util/validation";
import { TimePickerInput } from "../../../components/input/TimePicker";
import { PostUnityDto } from "../../../../network/unity/Unity";
import { DeleteUnity, PutUnity } from "../../../../domain/unity";
import { ReasonInputSelector } from "../../../components/input/ReasonSelector";

export const UnityInputSchema = Schema.Model({
  label: Schema.Types.StringType()
    .isRequired("Es necesario completar este campo.")
    .addRule(isNotBlank(3), "Es nombre debe contener al menos 3 caracteres."),
  reason: Schema.Types.StringType()
    .isRequired("Es necesario completar este campo.")
    .addRule(isNotBlank(10), "Es nombre debe contener al menos 10 caracteres."),
});

export const DeleteUnityInputSchema = Schema.Model({
  reason: Schema.Types.StringType()
    .isRequired("Es necesario completar este campo.")
    .addRule(isNotBlank(10), "Es nombre debe contener al menos 10 caracteres."),
});

export const NewUnityInputList: InputDataList<PostUnityDto> = [
  {
    name: "label",
    label: "Nombre",
    type: "text",
    placeholder: "Nombre",
    defaultValue: undefined,
  },
  {
    name: "description",
    label: "Descripción",
    type: "textarea",
    defaultValue: undefined,
  },
  {
    name: "firstShiftLimit",
    label: "Turno mañana / Límite de entrada",
    type: "custom",
    provide: TimePickerInput("firstShiftLimit"),
    defaultValue: undefined,
  },
  {
    name: "secondShiftLimit",
    label: "Turno noche / Límite de entrada",
    type: "custom",
    provide: TimePickerInput("secondShiftLimit"),
    defaultValue: undefined,
  },
  {
    name: "reason",
    label: "Motivo de cambio",
    type: "custom",
    help: "Detalle el motivo de la eliminación",
    defaultValue: undefined,
    provide: (props) => (
      <ReasonInputSelector
        {...(props as any)}
        options={["Nuevo cliente", "Adición de cliente existente"]}
      />
    ),
  },
  {
    name: "id",
    label: "ID",
    hidden: true,
    defaultValue: null,
    type: "custom",
    provide: ()=>null
  },
];

export const EditUnityInputList: InputDataList<PutUnity> = [
  {
    name: "label",
    label: "Nombre",
    type: "text",
    placeholder: "Nombre",
    defaultValue: undefined,
  },
  {
    name: "description",
    label: "Descripción",
    type: "textarea",
    defaultValue: undefined,
  },
  {
    name: "firstShiftLimit",
    label: "Turno mañana / Límite de entrada",
    type: "custom",
    provide: TimePickerInput("firstShiftLimit"),
    defaultValue: undefined,
  },
  {
    name: "secondShiftLimit",
    label: "Turno noche / Límite de entrada",
    type: "custom",
    provide: TimePickerInput("secondShiftLimit"),
    defaultValue: undefined,
  },
  {
    name: "reason",
    label: "Motivo de cambio",
    type: "custom",
    help: "Detalle el motivo de la eliminación",
    defaultValue: undefined,
    provide: (props) => (
      <ReasonInputSelector
        {...(props as any)}
        options={["Nuevo cliente", "Adición de cliente existente"]}
      />
    ),
  },
];

export const DeleteUnityInputList: InputDataList<DeleteUnity> = [
  {
    name: "reason",
    label: "Motivo de cambio",
    type: "custom",
    help: "Detalle el motivo de la eliminación",
    defaultValue: undefined,
    provide: (props) => (
      <ReasonInputSelector
        {...(props as any)}
        options={["Nuevo cliente", "Adición de cliente existente"]}
      />
    ),
  },
];
