import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useState,
} from "react";
import FirebaseAuth from "../../data/source/FirebaseAuth";
import { useLoaderData } from "react-router-dom";
import { UserWrapper } from "../../domain/user/User";
import { db } from "../../data/database/db";

export type AuthContextValue = {
  appUser: UserWrapper;
  signOut?: () => void;
  requestSignOut?: () => void;
  revokeSignOutRequest?: () => void;
  signOutFlag?: boolean;
};

export const UserAuthContext = createContext<AuthContextValue>(null!);

export const UserAuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const data = useLoaderData() as AuthContextValue;
  const [signOutFlag, setSignOutFlag] = useState<boolean>(false);
  const requestSignOut = useCallback(() => {
    setSignOutFlag(true);
  }, []);

  const revokeSignOutRequest = useCallback(() => {
    setSignOutFlag(false);
  }, []);
  return (
    <UserAuthContext.Provider
      value={{
        appUser: data.appUser,
        signOut: async () => {
          await FirebaseAuth.signOut();
          await db.delete();
        },
        requestSignOut,
        revokeSignOutRequest,
        signOutFlag,
      }}
    >
      {children}
    </UserAuthContext.Provider>
  );
};
