import {PentrackerUser} from "../../domain/user/User";
import {useContext, useState} from "react";
import {Response} from "../../domain/app/Response";
import {AppTimestamp} from "../../domain/app/Timestamp";
import {UserIncident} from "../../domain/incident/Incident";
import {IncidentRepository} from "../../data/repository/IncidentRepository";
import {UserAuthContext} from "../../ui/context/UserContext";

export function useUserIncidentViewModel(user: PentrackerUser) {
    const [fetchState, setFetchState] = useState<Response<boolean> | null>(null);
    const [userIncidentList, setUserIncidentList] = useState<UserIncident[] | undefined>();
    const [userIncidentTimestamp, setUserIncidentTimestamp] = useState<AppTimestamp | undefined>()
    const [refreshEvent, setRefreshEvent] = useState<boolean | null>(null);
    const [selectedPreviewItem, setSelectedPreviewItem] = useState<UserIncident | null>(null)
    const {appUser} = useContext(UserAuthContext)

    async function fetchUserIncidentList(
        timestamp: number,
        forceRefresh: boolean = false
    ) {
        if (fetchState?.isLoading()) return;
        setFetchState(Response.loading());
        try {
            const result = await IncidentRepository.getUserList(
                timestamp,
                user.uid,
                forceRefresh,
                appUser
            );
            setUserIncidentList(result?.incidentList);
            setUserIncidentTimestamp(result?.timestamp)
            setFetchState(Response.success(true));
        } catch (e: any) {
            setFetchState(Response.failure(e));
        }
    }

    function onFetchStateReceived() {
        setFetchState(null);
    }

    function requestRefreshEvent() {
        setRefreshEvent(true);
    }

    function onRefreshEventCompleted() {
        setRefreshEvent(null);
    }

    function selectItemToPreview(item: UserIncident) {
        setSelectedPreviewItem(item)
    }

    function onPreviewItemCompleted() {
        setSelectedPreviewItem(null)
    }

    return {
        fetchState,
        onFetchStateReceived,
        userIncidentList,
        userIncidentTimestamp,
        fetchUserIncidentList,
        refreshEvent,
        requestRefreshEvent,
        onRefreshEventCompleted,
        selectItemToPreview,
        onPreviewItemCompleted,
        selectedPreviewItem
    };
}
