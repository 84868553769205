import {FC, useEffect} from "react";
import {faCircleCheck, faUser} from "@fortawesome/free-solid-svg-icons";
import {FaIcon} from "../../components/fontawesome/icon";
import {IconButton} from "rsuite";
import {useNavigate} from "react-router-dom";
import logo from "../../../resources/logo-pentracker.svg";
import secureStorage from "react-secure-storage";

export const EmailVerifiedPage: FC<any> = () => {
    const navigate = useNavigate();

    useEffect(() => {
        secureStorage.removeItem("verification-email")
    }, [])

    return (
        <div
            className={
                "w-screen h-screen flex flex-col items-center content-center justify-center bg-neutral-100"
            }
        >
            <div
                className={
                    "w-full m-4 px-4 py-8 rounded-lg md:rounded-2xl sm:m-0 sm:w-3/4 md:p-12 md:w-full md:max-w-xl bg-white flex flex-col items-center justify-center gap-5"
                }
            >
                <div className={"p-0 m-0 text-md text-neutral-500 text-center"}>
          <span className={"font-semibold text-lg"}>
            Cuenta verificada
          </span>{" "}
                    <hr/>
                    Tu cuenta ha sido verificada, ya puedes iniciar sesión.
                </div>
                <div className={"text-app"}>
                    <FaIcon icon={faCircleCheck} size={"10x"}/>
                </div>
                <IconButton
                    appearance={"ghost"}
                    onClick={() => {
                        navigate("/auth");
                    }}
                    icon={<FaIcon icon={faUser}/>}
                >
                    Iniciar Sesión
                </IconButton>
            </div>
            <div className={"mt-5 flex items-center justify-center flex-col gap-2"}>
        <span className={"text-center text-neutral-400 text-xs"}>
          © 2023 Pentágono. Todos los derechos reservados.
        </span>
                <img src={logo} alt={"logo"} className={"w-28"}/>
            </div>
        </div>
    );
};
