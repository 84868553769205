import React, {FC} from "react";
import {Zone} from "../../../../../../domain/patrol/Zone";

type Props = {
    item: Zone;
};

export const ZoneRowId: FC<Props> = ({item}) => {
    const {id} = item;
    return (
        <div className={"m-0 p-0 flex flex-row flex-wrap gap-2"}>
            <span className={"text-neutral-400 font-semibold"}>ID</span>
            <span>{id}</span>
        </div>
    );
};
