import {PentrackerUser} from "../../domain/user/User";
import {useContext, useState} from "react";
import {Response} from "../../domain/app/Response";
import {AppTimestamp} from "../../domain/app/Timestamp";
import {UserAuthContext} from "../../ui/context/UserContext";
import { UserVehicleControl } from "../../domain/control/Vehicle";
import { VehicleControlRepository } from "../../data/repository/VehicleControlRepository";

export function useUserVehicleControlViewModel(user: PentrackerUser) {
    const [fetchState, setFetchState] = useState<Response<boolean> | null>(null);
    const [vehicleControlList, setVehicleControlList] = useState<UserVehicleControl[] | undefined>();
    const [userVehicleControlTimestamp, setUserVehicleControlTimestamp] = useState<AppTimestamp | undefined>()
    const [refreshEvent, setRefreshEvent] = useState<boolean | null>(null);
    const {appUser} = useContext(UserAuthContext)

    async function fetchUserVehicleControlList(
        timestamp: number,
        forceRefresh: boolean = false
    ) {
        if (fetchState?.isLoading()) return;
        setFetchState(Response.loading());
        try {
            const result = await VehicleControlRepository.getUserList(
                timestamp,
                user.uid,
                forceRefresh,
                appUser
            );
            setVehicleControlList(result?.userVehicleControlList);
            setUserVehicleControlTimestamp(result?.timestamp)
            setFetchState(Response.success(true));
        } catch (e: any) {
            setFetchState(Response.failure(e));
        }
    }

    function onFetchStateReceived() {
        setFetchState(null);
    }

    function requestRefreshEvent() {
        setRefreshEvent(true);
    }

    function onRefreshEventCompleted() {
        setRefreshEvent(null);
    }

    return {
        fetchState,
        onFetchStateReceived,
        vehicleControlList,
        userVehicleControlTimestamp,
        fetchUserVehicleControlList,
        refreshEvent,
        requestRefreshEvent,
        onRefreshEventCompleted,
    };
}
