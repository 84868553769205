import {FC, useEffect, useRef, useState} from "react";
import landing1 from "../../../../resources/landing/1.webp";
import landing2 from "../../../../resources/landing/2.webp";
import landing3 from "../../../../resources/landing/3.webp";
import landing4 from "../../../../resources/landing/4.jpg";
import landing5 from "../../../../resources/landing/5.webp";
import {AnimatePresence, motion} from "framer-motion";

export const AuthAnimatedBackground: FC = () => {
    const intervalRef = useRef<NodeJS.Timeout | undefined>();
    const landingImages = [landing1, landing2, landing3, landing4, landing5];
    const [currentLandingImg, setCurrentLandingImg] = useState(landing1);

    /*eslint-disable*/
    useEffect(() => {
        intervalRef.current = setInterval(() => {
            setCurrentLandingImg(
                (prev) =>
                    landingImages[
                    (landingImages.indexOf(prev) + 1) % landingImages.length
                        ]
            );
        }, 5000);

        return () => {
            clearInterval(intervalRef.current);
        };
    }, []);

    return <AnimatePresence>
        <motion.img
            className={"object-cover h-full w-full z-30 absolute inset-0"}
            key={currentLandingImg}
            src={currentLandingImg}
            initial={{opacity: 0, scale: 0.95}}
            animate={{opacity: 1, scale: 1}}
            exit={{opacity: 0}}
            transition={{
                opacity: {
                    duration: 2,
                },
                scale: {
                    duration: 2
                }
            }}
        />
    </AnimatePresence>
}
