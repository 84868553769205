import { useAuthViewModel } from "../../../viewmodel/auth/AuthPage";
import {
  FC,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { AppLoader } from "../../components/loading/LoadingOverlay";
import { LoginForm, LoginFormData } from "./components/loginform";
import { Notification } from "rsuite";
import { ReactComponent as PentagonoLogo } from "../../../resources/logo_nocolor.svg";
import { ReactComponent as PentrackerLogo } from "../../../resources/logos/logo-pentracker2.svg";
import secureStorage from "react-secure-storage";
import { obfuscateEmail } from "../../../util/email";
import { FaIcon } from "../../components/fontawesome/icon";
import { faEnvelopeCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { BreakPointContext } from "../../context/BreakPointContext";
import { AuthAnimatedBackground } from "./components/background";
import { AuthPageBanner } from "./components/banner";

const AuthPage: FC<any> = (_) => {
  const {
    onAuthStateReceived,
    fetchCredentials,
    fetchState,
    login,
    loginState,
    onLoginStateReceived,
  } = useAuthViewModel();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [step, setStep] = useState(0);
  const [credentials, setCredentials] = useState<LoginFormData | null>();
  const screenSize = useContext(BreakPointContext);

  const startTimeout = useCallback(() => {
    if (!!timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      window.location.reload();
    }, 3000);
  }, [timeoutRef]);

  function onFormSubmit(_credentials: LoginFormData) {
    void fetchCredentials(_credentials.email);
    setCredentials(_credentials);
  }

  /*eslint-disable*/

  useEffect(() => {
    if (fetchState && !fetchState.loading) {
      if (fetchState?.isSuccess()) {
        const result = fetchState!.data!;
        if (result.verified) {
          void login(credentials!!);
        } else {
          secureStorage.setItem("verification-email", result.email!);
          setStep(2);
        }
      } else {
        window.toast.error(
          fetchState?.error ? fetchState.error.message : "Ha ocurrido un error."
        );
        if (step !== 0) setStep(0);
      }
      onAuthStateReceived();
    }
  }, [fetchState]);

  useEffect(() => {
    if (loginState && !loginState.loading) {
      if (loginState?.isSuccess()) {
        setStep(1);
        startTimeout();
      } else {
        window.toast.error(
          loginState?.error ? loginState.error.message : "Ha ocurrido un error."
        );
        if (step !== 0) setStep(0);
      }
      onLoginStateReceived();
    }
  }, [loginState]);

  /*eslint-enable*/

  return (
    <div className={"w-full h-full lg:grid lg:grid-cols-2 overflow-x-hidden"}>
      <AppLoader isActive={fetchState?.loading || loginState?.loading} />
      <div
        className={"absolute w-full h-full left-0 right-0 top-0 bottom-0 z-0"}
      >
        <AuthAnimatedBackground />
      </div>
      <div>
        <AuthPageBanner />
      </div>
      <div
        className={
          "w-full h-full flex relative bg-black bg-opacity-30 justify-center items-center overflow-hidden"
        }
      >
        <div
          className={
            "absolute w-full h-full bg-black bg-opacity-10 backdrop-blur-sm"
          }
        />
        <PentrackerLogo
          className={
            "w-44 h-fit absolute left-0 top-0 mt-8 ml-8 md:mt-12 md:ml-12 lg:ml-24 fill-white"
          }
        />
        <div
          className={
            "z-50 w-full h-fit px-4 sm:px-12 lg:px-24 overflow-x-hidden break-all flex flex-col items-center justify-center"
          }
        >
          {(() => {
            switch (step) {
              case 0:
                return (
                  <Fragment>
                    <p
                      className={
                        "m-0 p-0 text-center font-semibold text-2xl lg:text-4xl text-multi"
                      }
                    >
                      Bienvenido
                    </p>

                    <p
                      className={
                        "m-0 p-0 md:text-lg font-normal text-neutral-100 text-center"
                      }
                    >
                      Inicie sesión
                    </p>
                    <hr className={"border-white w-full"} />

                    <div className={"w-full"}>
                      <LoginForm onSubmit={onFormSubmit} />
                    </div>
                  </Fragment>
                );
              case 2:
                return (
                  <Fragment>
                    <p
                      className={
                        "m-0 p-0 text-center font-semibold text-2xl lg:text-4xl text-multi"
                      }
                    >
                      Cuenta pendiente de Verificación
                    </p>
                    <hr />
                    <div className={"text-white text-center"}>
                      <FaIcon icon={faEnvelopeCircleCheck} size={"10x"} />
                    </div>
                    <p
                      className={
                        "text-center text-neutral-200 w-full text-md font-normal"
                      }
                    >
                      Es necesario que verifique su cuenta para acceder a la
                      administración de Pentracker. Un link de verificación fue
                      enviado a su correo:
                    </p>

                    <p
                      className={
                        "text-center text-neutral-200 font-bold text-xl"
                      }
                    >
                      {obfuscateEmail(
                        (secureStorage.getItem(
                          "verification-email"
                        ) as string) || "*****@****.**"
                      )}
                    </p>

                    <Notification className={"my-4"} closable>
                      Verifique otras carpetas como <strong>Spam</strong> en
                      caso no encuentre el correo.
                    </Notification>
                  </Fragment>
                );
              case 1:
                return (
                  <Fragment>
                    <p
                      className={
                        "m-0 p-0 text-center font-semibold text-2xl lg:text-4xl text-white"
                      }
                    >
                      Iniciar Sesión
                    </p>
                    <hr />
                    <div className={"text-white text-center"}>
                      <FaIcon icon={faEnvelopeCircleCheck} size={"10x"} />
                    </div>
                    <p
                      className={
                        "text-center text-neutral-200 text-md font-normal"
                      }
                    >
                      Bienvenido
                    </p>

                    <p
                      className={
                        "text-center text-neutral-200 text-md font-normal"
                      }
                    >
                      En unos instantes será redireccionado al dashboard
                    </p>
                  </Fragment>
                );
            }
          })()}

          {screenSize.isMobile ? (
            <PentagonoLogo
              className={
                "absolute w-48 left-0 right-0 bottom-0 mb-8 h-fit mx-auto fill-white"
              }
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default AuthPage;
