import { useContext, useEffect, useState } from "react";
import UnityRepository from "../../data/repository/UnityRepository";
import { Unity } from "../../domain/unity";
import { Response } from "../../domain/app/Response";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../data/database/db";
import { UserAuthContext } from "../../ui/context/UserContext";
import { LocalErrorLogRepository } from "../../data/repository/LocalErrorLogRepository";
import { PostUnityDto } from "../../network/unity/Unity";

export function useUnityListViewModel() {
  const unityListQuery = useLiveQuery(() => db.unities.toArray());
  const [createEvent, setCreateEvent] = useState<boolean | null>(null);
  const [refreshEvent, setRefreshEvent] = useState<boolean | null>(null);
  const [fetchListState, setFetchListState] =
    useState<Response<boolean> | null>(null);
  const [createState, setCreateState] = useState<Response<boolean> | null>(
    null
  );
  const [unityList, setUnityList] = useState<Unity[] | undefined>();
  const { appUser } = useContext(UserAuthContext);
  /*eslint-disable*/
  useEffect(() => {
    void fetchUnityList();
  }, [unityListQuery]);

  /*eslint-enable*/

  async function fetchUnityList(refreshFromNetwork: boolean = false) {
    if (fetchListState?.loading) return;
    setFetchListState(Response.loading());
    try {
      setUnityList(await UnityRepository.getList(refreshFromNetwork, appUser));
      setFetchListState(Response.success(true));
    } catch (e: any) {
      const label = "Error al obtener la lista de unidades.";
      await LocalErrorLogRepository.registerError(label, e);
      setFetchListState(Response.failure(new Error(label)));
    }
  }

  function onFetchStateReceived() {
    setFetchListState(null);
  }

  function requestCreateEvent() {
    setCreateEvent(true);
  }

  function onCreateEventCompleted() {
    setCreateEvent(null);
  }

  async function createUnity(unity: PostUnityDto) {
    if (createState?.loading) return;
    setCreateState(Response.loading());
    try {
      await UnityRepository.create(unity);
      setCreateState(Response.success(true));
    } catch (e: any) {
      const label = `Error al crear unidad con nombre: '${unity.label}, id: '${unity.id}'`;
      console.log(e);
      await LocalErrorLogRepository.registerError(label, e);
      setCreateState(Response.failure(new Error(label)));
    }
  }

  function onCreateStateReceived() {
    setCreateState(null);
  }

  function requestRefreshEvent() {
    setRefreshEvent(true);
  }

  function onRefreshEventCompleted() {
    setRefreshEvent(null);
  }

  return {
    unityList,
    createEvent,
    refreshEvent,
    fetchListState,
    createState,
    fetchUnityList,
    onFetchStateReceived,
    requestCreateEvent,
    onCreateEventCompleted,
    createUnity,
    onCreateStateReceived,
    requestRefreshEvent,
    setRefreshEvent,
    onRefreshEventCompleted,
  };
}
