import {FC} from "react";
import {UserAttendance} from "../../../../../domain/attendance/Attendance";
import {AutoSizer} from "react-virtualized";
import {FixedSizeList} from "react-window";
import {LastEntriesListRow} from "./row";
import {EmptyState} from "../../../../components/state/empty";

type Props = {
    entries?: UserAttendance[];
};

export const LastEntriesList: FC<Props> = ({entries}) => {
    return !!entries && entries.length > 0 ? (
        //@ts-ignore
        <AutoSizer>
            {({width, height}) => (
                <FixedSizeList
                    itemSize={85}
                    height={height}
                    itemCount={entries.length}
                    width={width}
                    itemData={{entries}}
                >
                    {LastEntriesListRow}
                </FixedSizeList>
            )}
        </AutoSizer>
    ) : (
        <EmptyState/>
    );
};
