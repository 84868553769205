import {FC} from "react";
import {AttendancePair} from "../../../../domain/attendance/Attendance";
import {FaIcon} from "../../../components/fontawesome/icon";
import {faCheckToSlot, faXmarkCircle,} from "@fortawesome/free-solid-svg-icons";

type Props = {
    item: AttendancePair;
};

export const AttendanceListRowObservation: FC<Props> = ({item}) => {
    const {
        entry: {observation},
    } = item!!;
    return !!observation ? (
        <div className={"flex flex-row flex-wrap items-center"}>
            <FaIcon className={"mr-2"} icon={faCheckToSlot} color={"green"}/> Se
            registró una observación
        </div>
    ) : (
        <div>
            <FaIcon className={"mr-2"} icon={faXmarkCircle} color={"red"}/> Sin
            observaciones
        </div>
    );
};
