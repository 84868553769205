import { useContext, useState } from "react";
import { Response } from "../../domain/app/Response";
import { AttendancePair } from "../../domain/attendance/Attendance";
import { AttendanceRepository } from "../../data/repository/AttendanceRepository";
import { AppTimestamp } from "../../domain/app/Timestamp";
import { UserAuthContext } from "../../ui/context/UserContext";

export function useByRequestAttendanceViewModel() {
  const [fetchState, setFetchState] = useState<Response<boolean> | null>(null);
  const [userAttendanceList, setUserAttendanceList] = useState<
    AttendancePair[] | undefined
  >();
  const [userAttendanceTimestamp, setUserAttendanceTimestamp] = useState<
    AppTimestamp | undefined
  >();
  const [refreshEvent, setRefreshEvent] = useState<boolean | null>(null);
  const { appUser } = useContext(UserAuthContext);

  async function fetchAttendance(
    timestamp: number,
    forceRefresh: boolean = false
  ) {
    if (fetchState?.isLoading()) return;
    setFetchState(Response.loading());
    try {
      console.log("appUser!.user!.clientUnity", appUser!.user!.clientUnity);

      const result = await AttendanceRepository.getGlobalList(
        timestamp,
        forceRefresh,
        appUser
      );

      setUserAttendanceList(result?.pairs);
      setUserAttendanceTimestamp(result?.timestamp);
      setFetchState(Response.success(true));
    } catch (e: any) {
      setFetchState(Response.failure(e));
    }
  }

  function onFetchStateReceived() {
    setFetchState(null);
  }

  function requestRefreshEvent() {
    setRefreshEvent(true);
  }

  function onRefreshEventCompleted() {
    setRefreshEvent(null);
  }

  return {
    fetchState,
    onFetchStateReceived,
    userAttendanceList,
    userAttendanceTimestamp,
    fetchAttendance,
    refreshEvent,
    requestRefreshEvent,
    onRefreshEventCompleted,
  };
}
