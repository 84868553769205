import React, { FC } from "react";
import { ListChildComponentProps } from "react-window";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
import { TicketFileType } from "../../../../../../domain/ticket/UserTicket";
import { FaIcon } from "../../../../../components/fontawesome/icon";

export type TicketContainerListRowProps = {
  userTicketContainerList: TicketFileType[];
  onSelect: React.MouseEventHandler<HTMLDivElement>;
};

export const TicketContainerListRow: FC<
  ListChildComponentProps<TicketContainerListRowProps>
> = ({ data, index, style }) => {
  const { onSelect, userTicketContainerList } = data;
  const item = userTicketContainerList[index];
  return (
    <div style={style} className={"py-2 cursor-pointer"}>
      <div
        data-index={index}
        onClick={onSelect}
        className={
          "w-full h-full border border-neutral-300 rounded-lg p-2 flex flex-col justify-between content-between"
        }
      >
        <div
          className={
            "flex w-full flex-row gap-3 items-center content-center"
          }
        >
          <FaIcon
            icon={faFolder}
            size={"2x"}
            className={`${
              item.temp === 1 ? "text-green-400" : "text-neutral-600"
            }`}
          />{" "}
          <div className={"flex flex-col gap-1"}>
            <span className={`${
              item.temp === 1 ? "text-sm" : "text-lg"
            }`}>{item.name}</span>
            {
              item.temp === 1 ? <span className={"text-white bg-green-400 rounded p-1"}>Pendiente de publicación</span> : null
            }
          </div>
        </div>
      </div>
    </div>
  );
};
