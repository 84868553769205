import { FC } from "react";
import { Modal } from "rsuite";
import { AppManagerEvent } from "../../../../../domain/event/ManagerEvent";
import { formatDateTime } from "../../../../../util/date";
import { JsonView, darkStyles, defaultStyles } from "react-json-view-lite";
import "react-json-view-lite/dist/index.css";

export const ManagerEventModal: FC<{
  onClose: () => void,
  item: AppManagerEvent
}> = ({ onClose, item }) => {
  let extra = item.extra;
  let isJson = false;
  try {
    extra = JSON.parse(extra!);
    isJson = true;
  } catch (e) {
    isJson = false;
  }
  return <Modal open={true} onClose={onClose}>
    <Modal.Header>
      <Modal.Title>Evento de Administrador</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className={"flex flex-col"}>
        <p className={"gap-2 flex flex-row text-sm"}><span className={"font-semibold"}>* </span> {item.title}</p>
        <p className={"gap-2 flex flex-row text-sm"}><span
          className={"font-semibold"}>Usuario</span> {item.user?.displayName}</p>
        <p className={"gap-2 flex flex-row text-sm"}><span className={"font-semibold"}>Motivo</span> {item.reason}</p>
        <p className={"gap-2 flex flex-row text-sm"}><span
          className={"font-semibold"}>Realizado el</span> {formatDateTime(item.date)}</p>
        <p className={"gap-2 flex flex-row text-sm"}><span className={"font-semibold"}>Mensaje</span> {item.message}</p>
        {
          !!extra ? (isJson ? <JsonView data={extra} shouldInitiallyExpand={(level) => true} style={defaultStyles} />
              :
              <p className={"gap-2 flex flex-row text-sm"}><span className={"font-semibold"}>Extra</span> {item.extra}
              </p>
          ) : null
        }
      </div>
    </Modal.Body>
  </Modal>;
};
