import {FC} from "react";
import {ByUnityAttendance} from "../../../../../viewmodel/attendance-group/OverviewAttendanceGroup";

type Props = {
    unityAttendanceList: ByUnityAttendance[]
}

export const AttendanceOverviewByUnity: FC<Props> = ({unityAttendanceList}) => {
    return <div className={"w-full h-fit bg-white rounded shadow-sm flex flex-col py-4"}>
        <div className={"px-4 md:px-12"}>
            <span className={"text-lg font-semibold text-neutral-800"}>
              Hoy en cada unidad
            </span>
        </div>
        <div
            className={
                "flex flex-col divide-y divide-neutral-200 overflow-y-scroll"
            }
        >
            {unityAttendanceList.map((attendance) => {
                return (
                    <div
                        key={`attendance-${attendance.name}`}
                        className={
                            "px-4 md:px-12 py-2 flex flex-col"
                        }
                    >
                  <span className={"text-neutral-800 font-semibold text-md"}>
                    {attendance.name}
                  </span>
                        <span className={"text-md"}>
                    {attendance.total} asistente(s)
                  </span>
                    </div>
                );
            })}
        </div>
    </div>
}
