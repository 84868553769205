import { GetUnityDto, PutUnityDto } from "../../network/unity/Unity";
import { PentrackerUser } from "../user/User";

export type Unity = {
  id: number;
  label: string;
  description: string;
  firstShiftLimit?: number | null
  secondShiftLimit?: number | null
  createdAt: number
  operatorCount?: number
  client?: Array<PentrackerUser>
};
export type AppUnity = {
  id: number;
  label: string;
  description: string;
  firstShiftLimit?: number | null
  secondShiftLimit?: number | null
  createdAt?: number
};

export type PutUnity = PutUnityDto["data"] & {
  reason: string
}

export type DeleteUnity = {
  reason: string
}

export function unityDtoAsDomain(
  unityDto: GetUnityDto
): Unity {
  return ({
    ...unityDto,
    createdAt: unityDto.createdAt ? (typeof unityDto.createdAt === "number" ? unityDto.createdAt : unityDto.createdAt.toMillis()) : Date.now()
  });
}
