import {filter, find, indexOf} from "lodash";

export function replaceInArray<T>(array: Array<T>, oldItem: T, newItem: T) {
    const newArray = [...array];
    newArray.splice(indexOf(array, oldItem), 1, newItem);
    return newArray;
}

export function addToArray<T>(
    array: Array<T>,
    item: T,
    atFirst: boolean = false
) {
    return atFirst ? [item, ...array] : [...array, item];
}

export function removeInArray<T>(array: Array<T>, item: T) {
    const newArray = [...array];
    return filter(newArray, it => it !== item);
}

export function findInArray<T>(
    array: Array<T>,
    path: keyof T,
    valueToSearch: any
): T | undefined {
    return find(array, (item) => item[path] === valueToSearch);
}
