export class Response<T> {
    data: DataType<T>;
    loading: boolean;
    error: ErrorType;
    failed?: boolean
    success?: boolean
    successWithWarnings?: boolean
    message?: string

    private constructor(data: DataType<T>, loading: boolean, error: ErrorType, failed: boolean, success: boolean, successWithWarnings: boolean, warningMessage: string | undefined = undefined) {
        this.data = data;
        this.loading = loading;
        this.error = error;
        this.failed = failed
        this.success = success
        this.successWithWarnings = successWithWarnings
        this.message = warningMessage
    }

    static loading<T>(): Response<T> {
        return new Response<T>(null, true, null, false, false, false);
    }

    static success<T>(data: T): Response<T> {
        return new Response<T>(data, false, null, false, true, false);
    }

    static failure<T>(error: Error): Response<T> {
        return new Response<T>(null, false, error, true, false, false);
    }

    static successWithWarning<T>(message: string): Response<T> {
        return new Response<T>(null, false, null, false, false, true, message)
    }

    static existsAndIsLoading(response?: Response<any> | null) {
        return !!response && response.isLoading();
    }

    static isSuccess(response: Response<any> | null) {
        return !!response && !response.isLoading() && response.isSuccess()
    }

    isLoading(): boolean {
        return this.loading;
    }

    isIdle(): Boolean {
        return !this.loading;
    }

    isSuccess() {
        return this.success
    }

    isSuccessWithWarnings() {
        return this.successWithWarnings
    }

    isFailed() {
        return this.failed
    }
}

export default class ResponseBuilder {
    static loading<T>(): Response<T> {
        return Response.loading();
    }

    static success<T>(data: T): Response<T> {
        return Response.success(data);
    }

    static failure<T>(error: Error): Response<T> {
        return Response.failure(error);
    }

    static isLoading<T>(response: Response<T> | null): boolean {
        if (!response) return false;
        return response?.isLoading();
    }

    static existsAndIsIdle<T>(response: Response<T> | null): Boolean {
        if (!response) return false;
        return !response.isLoading();
    }

    static isSuccess<T>(response: Response<T> | null) {
        if (response === null) return false;
        return response.isSuccess();
    }

    static isFailed<T>(response: Response<T>) {
        return response.isFailed();
    }
}

type DataType<T> = T | null;
type ErrorType = Error | null;
