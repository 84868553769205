import {GetIncidentDto} from "../../network/incident/Incident";
import {Zone} from "../patrol/Zone";
import {Unity} from "../unity";
import {PentrackerUser} from "../user/User";

export type Incident = Omit<GetIncidentDto, "timestamp"> & {
    reference: string
    timestamp: number
}

export type UserIncident = Incident & {
    zone?: Zone;
    unity?: Unity;
    owner?: PentrackerUser
}


export function incidentDtoAsDomain(incidentDto: GetIncidentDto, reference: string): Incident {
    return {
        ...incidentDto,
        reference,
        timestamp: incidentDto.timestamp.toDate().getTime()
    }
}

export function incidentAsUserIncident(incident: Incident, unity ?: Unity, zone ?: Zone, owner ?: PentrackerUser): UserIncident {
    return {
        ...incident,
        unity,
        zone,
        owner
    }
}
