import {FC} from "react";
import {CellProps, Table} from "rsuite";
import {PentrackerUser} from "../../../../domain/user/User";
import {FaIcon} from "../../../components/fontawesome/icon";
import {faCrown, faInfoCircle, faUser, faUserSecret, faUserTie,} from "@fortawesome/free-solid-svg-icons";
import {UserTable_CurrentUserCellStyle} from "./index";

type Props = CellProps<PentrackerUser> & {
    currentUid?: string;
};
export const UserTableRoleCell: FC<Props> = ({
                                                 rowData,
                                                 currentUid,
                                                 ...props
                                             }) => {
    if (!rowData) return null;
    const {userRole, uid} = rowData;
    if (userRole) {
        switch (userRole.id) {
            case 0:
                return (
                    <Table.Cell
                        {...props}
                        style={{
                            ...props.style,
                            ...(currentUid === uid ? UserTable_CurrentUserCellStyle : {}),
                        }}
                    >
            <span className={"text-green-700"}>
              <FaIcon icon={faCrown}/> Administrador
            </span>
                    </Table.Cell>
                );
            case 1:
                return (
                    <Table.Cell
                        {...props}
                        style={{
                            ...props.style,
                            ...(currentUid === uid ? UserTable_CurrentUserCellStyle : {}),
                        }}
                    >
            <span className={"text-green-700"}>
              <FaIcon icon={faCrown}/> Administrador
            </span>
                    </Table.Cell>
                );
            case 2:
                return (
                    <Table.Cell
                        {...props}
                        style={{
                            ...props.style,
                            ...(currentUid === uid ? UserTable_CurrentUserCellStyle : {}),
                        }}
                    >
            <span className={"text-purple-700"}>
              <FaIcon icon={faUserSecret}/> Centro de Control
            </span>
                    </Table.Cell>
                );
            case 3:
                return (
                    <Table.Cell
                        {...props}
                        style={{
                            ...props.style,
                            ...(currentUid === uid ? UserTable_CurrentUserCellStyle : {}),
                        }}
                    >
            <span className={"text-red-700"}>
              <FaIcon icon={faUserSecret}/> Centro de Control
            </span>
                    </Table.Cell>
                );
            case 4:
                return (
                    <Table.Cell
                        {...props}
                        style={{
                            ...props.style,
                            ...(currentUid === uid ? UserTable_CurrentUserCellStyle : {}),
                        }}
                    >
            <span className={"text-amber-700"}>
              <FaIcon icon={faUserTie}/> Cliente
            </span>
                    </Table.Cell>
                );
            case 5:
                return (
                    <Table.Cell
                        {...props}
                        style={{
                            ...props.style,
                            ...(currentUid === uid ? UserTable_CurrentUserCellStyle : {}),
                        }}
                    >
            <span className={"text-blue-700"}>
              <FaIcon icon={faUser}/> Jefe ge Grupo
            </span>
                    </Table.Cell>
                );
            case 6:
                return (
                    <Table.Cell
                        {...props}
                        style={{
                            ...props.style,
                            ...(currentUid === uid ? UserTable_CurrentUserCellStyle : {}),
                        }}
                    >
            <span className={"text-sky-700"}>
              <FaIcon icon={faUser}/> CCTV
            </span>
                    </Table.Cell>
                );
            default:
                return (
                    <Table.Cell
                        {...props}
                        style={{
                            ...props.style,
                            ...(currentUid === uid ? UserTable_CurrentUserCellStyle : {}),
                        }}
                    >
            <span className={"text-cyan-700"}>
              <FaIcon icon={faUser}/> Agente
            </span>
                    </Table.Cell>
                );
        }
    } else {
        return (
            <Table.Cell
                {...props}
                style={{
                    ...props.style,
                    ...(currentUid === uid ? UserTable_CurrentUserCellStyle : {}),
                }}
            >
        <span className={"text-red-700"}>
          <FaIcon icon={faInfoCircle}/> No asignado
        </span>
            </Table.Cell>
        );
    }
};
