import {db} from "../db";
import {VehicleControl} from "../../../domain/control/Vehicle";

export class VehicleControlDao {
    static async putVehicleControl(...vehicleControl: VehicleControl[]) {
        return db.vehicle_control.bulkPut(vehicleControl);
    }

    static async getAllUnityVehicleControl(unityId: number, start: number, end: number) {
        return db.vehicle_control
            .where(["unityId+timestamp"])
            .between([unityId, start], [unityId, end], true, true)
            .reverse()
            .sortBy("timestamp");
    }

    static async deleteAllUnityVehicleControl(
        unityId: number,
        start: number,
        end: number
    ) {
        return db.vehicle_control
            .where(["unityId+timestamp"])
            .between([unityId, start], [unityId, end], true, true)
            .delete();
    }

    static async getUserVehicleControlList(ownerUid: string, start: number, end: number) {
        return db.vehicle_control
            .where(["ownerUid+timestamp"])
            .between([ownerUid, start], [ownerUid, end])
            .reverse()
            .sortBy("timestamp");
    }

    static async deleteAllUserVehicleControl(
        ownerUid: string,
        start: number,
        end: number
    ) {
        return db.vehicle_control
            .where(["ownerUid+timestamp"])
            .between([ownerUid, start], [ownerUid, end])
            .delete();
    }

    static async getAllVehicleControl(start: number, end: number) {
        return db.vehicle_control
            .where("timestamp")
            .between(start, end, true, true)
            .reverse()
            .sortBy("timestamp");
    }

    static async deleteAllVehicleControl(
        start: number,
        end: number
    ) {
        return db.vehicle_control
            .where("timestamp")
            .between(start, end, true, true)
            .delete();
    }

    static async getVehicleControl(reference: string) {
        return db.vehicle_control.get(reference)
    }
}
