import { FC } from "react";
import { FaIcon } from "../../../components/fontawesome/icon";
import {
  faBuilding,
  faCalendar,
  faPerson,
} from "@fortawesome/free-solid-svg-icons";
import { toRelativeCalendar } from "../../../../lib/date";
import { Link } from "react-router-dom";
import { UserVisitControl } from "../../../../domain/control/Visit";

export const VisitControlView: FC<{
  visitControl: UserVisitControl;
}> = ({ visitControl }) => {
  const {
    timestamp,
    unity,
    unityId,
    owner,
    ownerUid,
    approvedBy,
    dni,
    departure,
    entry,
    derivation,
    lastname,
    name,
    observation,
    phoneNumber,
    subject,
  } = visitControl;

  return (
    <div
      className={
        "w-full h-full overflow-auto flex flex-row flex-wrap gap-x-4 py-4"
      }
    >
      <p className={"px-2 m-0 text-neutral-900 text-lg uppercase grow"}>
        Usuario: {owner?.displayName || ownerUid}
      </p>
      <div className={"w-full flex flex-col p-2 rounded items-start gap-2"}>
        <div className={"flex flex-col flex-wrap w-fit gap-y-4"}>
          <div className={"flex flex-col gap-2"}>
            <p className={"text-neutral-400 text-md uppercase grow"}>GENERAL</p>
            <div
              className={
                "w-full flex flex-col flex-wrap gap-x-6 gap-y-2"
              }
            >
              <div>
                <FaIcon icon={faPerson} className={"mr-2 text-neutral-400"} />
                <Link to={`/dashboard/users/${ownerUid}`}>
                  {owner ? owner.displayName : ownerUid}
                </Link>
              </div>

              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <FaIcon icon={faCalendar} className={"text-neutral-400"} />
                <span className={"font-semibold uppercase"}>Registrado el</span>
                {toRelativeCalendar(timestamp, true)}
              </div>
              <div>
                <FaIcon icon={faBuilding} className={"mr-2 text-neutral-400"} />
                <Link to={`/dashboard/unity/${unityId}`}>
                  {unity ? unity.label : unityId}
                </Link>
              </div>
              <div className={"flex flex-row gap-2"}>
                <span className={"font-bold"}>Nombre de Visita</span> {name || "No especificado"}
              </div>
              <div className={"flex flex-row gap-2"}>
                <span className={"font-bold"}>Apellido de Visita</span> {lastname || "No especificado"}
              </div>
              <div className={"flex flex-row gap-2"}>
                <span className={"font-bold"}>Ingreso</span> {entry || "No especificado"}
              </div>
              <div className={"flex flex-row gap-2"}>
                <span className={"font-bold"}>Salida</span> {departure || "No especificado"}
              </div>
              <div className={"flex flex-row gap-2"}>
                <span className={"font-bold"}>DNI</span> {dni || "No especificado"}
              </div>
              <div className={"flex flex-row gap-2"}>
                <span className={"font-bold"}>Empresa (Procedencia)</span> {derivation || "No especificado"}
              </div>
              <div className={"flex flex-row gap-2"}>
                <span className={"font-bold"}>Contacto</span> {phoneNumber || "No especificado"}
              </div>
            </div>
          </div>

          <div className={"flex flex-col gap-y-2 justify-start items-start"}>
            <p className={"text-neutral-400 text-md uppercase grow"}>
              APROBADO POR
            </p>
            {approvedBy || "No especificado."}
          </div>

          <div className={"flex flex-col gap-y-2 justify-start items-start"}>
            <p className={"text-neutral-400 text-md uppercase grow"}>
              MOTIVO DE VISITA
            </p>
            {subject || "No especificado."}
          </div>

          <div className={"flex flex-col gap-y-2 justify-start items-start"}>
            <p className={"text-neutral-400 text-md uppercase grow"}>
              OBSERVACIONES
            </p>
            {observation || "Sin observaciones"}
          </div>
        </div>
      </div>
    </div>
  );
};
