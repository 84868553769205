import { useState } from "react";
import { Response } from "../../domain/app/Response";
import { TicketRepository } from "../../data/repository/TicketRepository";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../data/database/db";

export function useUserTicketListViewModel() {
  const [fetchState, setFetchState] = useState<Response<boolean> | null>(null);
  const [addState, setAddState] = useState<Response<boolean> | null>(null);
  const folderList = useLiveQuery(
    () => db.ticket_container.toArray(),
    []
  );
  const [refreshEvent, setRefreshEvent] = useState<boolean | null>(null);
  const [addEvent, setAddEvent] = useState<boolean | null>(null);

  async function fetchUserTicketFolderList(forceRefresh: boolean = false) {
    if (fetchState?.isLoading()) return;
    setFetchState(Response.loading());
    try {
      await TicketRepository.fetchContainerList(forceRefresh);
      setFetchState(Response.success(true));
    } catch (e: any) {
      setFetchState(Response.failure(e));
    }
  }

  function onFetchStateReceived() {
    setFetchState(null);
  }

  function requestRefreshEvent() {
    setRefreshEvent(true);
  }

  function onRefreshEventCompleted() {
    setRefreshEvent(null);
  }

  function requestAddEvent() {
    setAddEvent(true);
  }

  function onAddEventCompleted() {
    setAddEvent(null);
  }

  async function addNewFolder({ month, year }: { [k: string]: string }) {
    try{
      await TicketRepository.addContainer(month, year);
      setAddState(Response.success(true))
    } catch (e: any) {
      setAddState(Response.failure(e))
    }
  }

  function onAddStateReceived() {
    setAddState(null)
  }

  return {
    fetchState,
    onFetchStateReceived,
    folderList,
    fetchUserTicketFolderList,
    refreshEvent,
    requestRefreshEvent,
    onRefreshEventCompleted,
    requestAddEvent,
    onAddEventCompleted,
    addEvent,
    addNewFolder,
    addState,
    onAddStateReceived
  };
}
