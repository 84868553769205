import React, {FC, useContext} from "react";
import {Tag} from "rsuite";
import {UserAvatar} from "../../../components/avatar/useravatar";
import {FaIcon} from "../../../components/fontawesome/icon";
import {faChildReaching, faLock, faUnlock} from "@fortawesome/free-solid-svg-icons";
import {toRelativeCalendar} from "../../../../lib/date";
import {Link, useOutletContext} from "react-router-dom";
import {UserPageOutletContext} from "../index";
import {UserAuthContext} from "../../../context/UserContext";

export const UserInfoTab: FC = () => {
    const [user] = useOutletContext<UserPageOutletContext>()
    const { appUser} = useContext(UserAuthContext)
    return (
        <div
            className={
                "w-full h-full overflow-auto flex flex-col gap-2 justify-start items-start"
            }
        >
            {user.uid === appUser.uid() ? (
                <Tag color={"green"}>
                    <FaIcon icon={faChildReaching}/> Este eres tú
                </Tag>
            ) : null}
            <div className={"gap-4 flex flex-row flex-wrap items-center break-words"}>
                <UserAvatar user={user!!}/>
                {user.displayName ? (
                    <span className={"text-lg uppercase"}>{user.displayName}</span>
                ) : (
                    <span className={"italic"}>"No se ha asignado un nombre de usuario."</span>
                )}
            </div>
            {user.disabled ? (
                <Tag color={"red"}>
                    <FaIcon icon={faLock}/> Esta cuenta se encuentra suspendida
                </Tag>
            ) : (
                <Tag color={"cyan"}>
                    <FaIcon icon={faUnlock}/> Esta cuenta se encuentra activa
                </Tag>
            )}
            <div className={"gap-2 flex flex-col"}>
                <p className={"font-normal text-md uppercase text-neutral-400"}>
                    Creado
                </p>
                {toRelativeCalendar(new Date(user.creationTime!!), true)}
            </div>
            <div className={"gap-2 flex flex-col"}>
                <p className={"font-normal text-md uppercase text-neutral-400"}>
                    ÚLT. SESIÓN
                </p>
                {toRelativeCalendar(new Date(user.lastSignInTime!!), true)}
            </div>
            <div className={"gap-2 flex flex-col"}>
                <p className={"font-normal text-md uppercase text-neutral-400"}>
                    Cargo
                </p>
                {user.userRole ? (
                    user.userRole.label
                ) : (
                    <span className={"italic"}>No fue asignado</span>
                )}
            </div>
            <div className={"gap-2 flex flex-col"}>
                <p className={"font-normal text-md uppercase text-neutral-400"}>
                    NOMBRE DE USUARIO
                </p>
                {user.displayName ? (
                    user.displayName
                ) : (
                    <span className={"italic"}>No fue asignado</span>
                )}
            </div>
            <div className={"gap-2 flex flex-col"}>
                <p className={"font-normal text-md uppercase text-neutral-400"}>
                    Email
                </p>
                {user.email ? (
                    user.email
                ) : (
                    <span className={"italic"}>No fue asignado</span>
                )}
            </div>
            <div className={"gap-2 flex flex-col"}>
                <p className={"font-normal text-md uppercase text-neutral-400"}>
                    Nro. Teléfono
                </p>
                {user.phoneNumber ? (
                    user.phoneNumber
                ) : (
                    <span className={"italic"}>No fue asignado</span>
                )}
            </div>
            {appUser?.isClient() === true ? (
                <div className={"gap-2 flex flex-col"}>
                    <p className={"font-normal text-md uppercase text-neutral-400"}>
                        Unidades asignadas al cliente
                    </p>
                    {user.clientUnityArray ? (
                        <div className={"flex flex-row flex-wrap gap-2"}>
                            {user.clientUnityArray.map((unity) => (
                                <Link to={`/dashboard/unity/${unity.id}`}>{unity.label}</Link>
                            ))}
                        </div>
                    ) : (
                        <span className={"italic"}>
              No se asignaron unidades al cliente
            </span>
                    )}
                </div>
            ) : appUser?.isOperator() === true ? (
                <div className={"gap-2 flex flex-col"}>
                    <p className={"font-normal text-md uppercase text-neutral-400"}>
                        Unidad
                    </p>
                    {user.unity ? (
                        <Link to={`/dashboard/unity/${user.unity.id}`}>
                            {user.unity.label}
                        </Link>
                    ) : (
                        <span className={"italic"}>No fue asignado</span>
                    )}
                </div>
            ) : null}
            <div className={"gap-2 flex flex-col"}>
                <p className={"font-normal text-md uppercase text-neutral-400"}>
                    Turno
                </p>
                {user.shift ? (
                    user.shift.label
                ) : (
                    <span className={"italic"}>No fue asignado</span>
                )}
            </div>
            <div className={"gap-2 flex flex-col"}>
                <p className={"font-normal text-md uppercase text-neutral-400"}>
                    Etiqueta
                </p>
                {user.accountType ? (
                    user.accountType.label
                ) : (
                    <span className={"italic"}>No fue asignado</span>
                )}
            </div>
        </div>
    );
};
