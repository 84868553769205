import { FC, useContext, useEffect } from "react";
import useAccountTypeViewModel from "../../../../viewmodel/type/AccountType";
import { AppLoader } from "../../../components/loading/LoadingOverlay";
import { AccountTypeHeader } from "./header/Header";
import { AccountTypeTable } from "./list/Table";
import ResponseBuilder from "../../../../domain/app/Response";
import { AccountTypeInputList, AccountTypeModel } from "./modal/InputList";
import { FormModal } from "../../../components/modal/FormModal";
import { DeleteConfirmationModal } from "../../../components/modal/DeleteConfirmationModal";
import { DashboardHeaderContext } from "../../../../hook/header";
import { useStyleContext } from "../../../context/StyleContext";

export const AccountTypePage: FC = (_) => {
  const viewModel = useAccountTypeViewModel();
  const headerContext = useContext(DashboardHeaderContext);
  const { stylePage } = useStyleContext();

  /*eslint-disable*/
  useEffect(() => {
    if (ResponseBuilder.existsAndIsIdle(viewModel.fetchState)) {
      const error = viewModel.fetchState?.error;
      if (error) {
        window.toast.error(
          error.message
            ? error.message
            : "Ocurrió un error al obtener la lista de tipos de cuenta. "
        );
        console.error(error);
      }
      viewModel.onFetchListStateReceived();
    }
  }, [viewModel.fetchState]);

  useEffect(() => {
    if (ResponseBuilder.existsAndIsIdle(viewModel.createState)) {
      const error = viewModel.createState?.error;
      if (error) {
        window.toast.error(
          error.message
            ? error.message
            : "Ocurrió un error al crear el tipo de operador."
        );
        console.error(error);
      } else {
        window.toast.success("Item creado correctamente.");
      }
      viewModel.onAddEventCompleted();
      viewModel.onCreateStateReceived();
    }
  }, [viewModel.createState]);

  useEffect(() => {
    if (viewModel.refreshEvent) {
      void viewModel.fetchList(true);
      viewModel.onRefreshEventCompleted();
    }
  }, [viewModel.refreshEvent]);

  useEffect(() => {
    void viewModel.fetchList();
    headerContext.setTheme({
      className: "bg-secondary-header",
      dark: true,
      title: "Etiquetas",
    });
  }, []);

  useEffect(() => {
    if (ResponseBuilder.existsAndIsIdle(viewModel.deleteState)) {
      const error = viewModel.deleteState?.error;
      if (error) {
        window.toast.error("Ocurrió un error durante la eliminación. " + error);
      } else {
        window.toast.success("Eliminado correctamente.");
      }
      viewModel.onDeleteEventCompleted();
      viewModel.onDeleteStateReceived();
    }
  }, [viewModel.deleteState]);
  /*eslint-enable*/
  return (
    <div
      className={"w-full overflow-x-hidden scrollbar-hide"}
      style={stylePage}
    >
      <AppLoader isActive={viewModel.allLoadingObserver} />
      <AccountTypeHeader
        onAddClicked={viewModel.requestAddEvent}
        onRefreshClicked={viewModel.requestRefreshEvent}
      />
      {viewModel.addEvent ? (
        <FormModal
          model={AccountTypeModel}
          title={"Nuevo Tipo de Operador"}
          visible={true}
          onCancel={viewModel.onAddEventCompleted}
          inputList={AccountTypeInputList}
          onOk={viewModel.createAccountType}
          disabled={viewModel.createState?.loading}
        />
      ) : null}
      {viewModel.deleteEvent ? (
        <DeleteConfirmationModal
          onClose={viewModel.onDeleteEventCompleted}
          onConfirmation={viewModel.deleteAccountType}
          entityTitle={"Tipo de Operador"}
          visible={true}
        />
      ) : null}
      <div className={"w-full bg-neutral-100 p-2 md:p-3 lg:p-5 scrollbar-hide"}>
        <AccountTypeTable
          data={viewModel.types}
          onDelete={viewModel.requestDeleteEvent}
          deleteDisabled={Boolean(viewModel.deleteState?.loading)}
          loading={Boolean(viewModel.fetchState?.loading)}
        />
      </div>
    </div>
  );
};
