import {
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Response } from "../../domain/app/Response";
import { AttendanceRepository } from "../../data/repository/AttendanceRepository";
import useSessionStorageState from "use-session-storage-state";
import { Attendance, AttendancePair } from "../../domain/attendance/Attendance";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../data/database/db";
import { DateTime } from "luxon";
import { UserAuthContext } from "../../ui/context/UserContext";
import { isEmpty } from "lodash";
import { AttendanceStoreDao } from "../../data/database/dao/Attendance";

export function useLiveAttendanceGroupViewModel() {
  const [selectedItem, setSelectedItem] = useState<AttendancePair | null>(null);
  const { appUser } = useContext(UserAuthContext);
  // const query = useLiveQuery(async () => {
  //   const [entries, departures] = await Promise.all([
  //     db.entries
  //       .where("timestamp")
  //       .aboveOrEqual(
  //         DateTime.now().setZone("America/Lima").startOf("day").toMillis()
  //       )
  //       .toArray(),
  //     db.departures
  //       .where("timestamp")
  //       .aboveOrEqual(
  //         DateTime.now().setZone("America/Lima").startOf("day").toMillis()
  //       )
  //       .toArray(),
  //   ]);

  //   return {
  //     entries: entries,
  //     departures: departures,
  //   };
  // }, []);

  const [queryComplete, setQueryComplete] = useState(false);
  // const [prevResult, setPrevResult] = useState<string[]>([]);
  const [prevResult, setPrevResult] = useState<Attendance[]>([]);

  const query = useLiveQuery(
    async () =>
      await db.entries
        .where("timestamp")
        .aboveOrEqual(
          DateTime.now().setZone("America/Lima").startOf("day").toMillis()
        )
        .toArray(),
    []
  );

  const departures = useLiveQuery(async () => {
    const results = await db.departures
      .where("timestamp")
      .aboveOrEqual(
        DateTime.now().setZone("America/Lima").startOf("day").toMillis()
      )
      .toArray();
    return results;
  }, []);

  const [list, setList] = useSessionStorageState<AttendancePair[]>(
    "live_attendance_group_1",
    {
      defaultValue: [],
    }
  );

  const [connectionState, setConnectionState] =
    useState<Response<boolean> | null>(null);

  const connectionReference = useRef<any>(null) as MutableRefObject<
    Awaited<ReturnType<typeof AttendanceRepository.getLive>>
  >;

  useEffect(() => {
    return () => {
      if (connectionReference.current) connectionReference.current();
      void AttendanceRepository.stopLive();
    };
  }, []);

  const onError = useCallback((error: any) => {
    setConnectionState(Response.failure(error));
  }, []);

  /* eslint-disable */
  const injectEntries = useCallback(
    async (entries: Attendance[]) => {
      if (entries.length > 0) {
        // : Promise<AttendancePair>
        const tasks = entries.map(async (entry): Promise<AttendancePair> => {
          try {
            // const entryAttendance = await AttendanceRepository.injectAttendance(
            //   entry
            // );
            const attendance = await AttendanceRepository.getLiveList(
              entry,
              appUser
            );

            return attendance;
            // return {
            //   entry: entryAttendance,
            // };
          } catch (e: any) {
            return e;
            //   {
            //     entry,
            //   };
          }
        });
        const injectedTasksDone = (await Promise.all(
          tasks
        )) as AttendancePair[];
        setList([...injectedTasksDone, ...list]);
      }
    },
    [list]
  );

  const injectDepartures = useCallback(
    async (entries: Attendance[]) => {
      if (entries.length > 0) {
        // : Promise<AttendancePair>
        const tasks = entries.map(async (entry): Promise<AttendancePair> => {
          try {
            // const entryAttendance = await AttendanceRepository.injectAttendance(
            //   entry
            // );
            const attendance = await AttendanceRepository.getLiveList(
              entry,
              appUser
            );

            return attendance;
            // return {
            //   entry: entryAttendance,
            // };
          } catch (e: any) {
            return e;
            //   {
            //     entry,
            //   };
          }
        });
        const injectedTasksDone = (await Promise.all(
          tasks
        )) as AttendancePair[];
        setList([...injectedTasksDone]);
      }
    },
    [list]
  );

  useEffect(() => {
    if (query) {
      if (
        prevResult.length > 0 &&
        JSON.stringify(prevResult) === JSON.stringify(query)
      ) {
        setQueryComplete(true);
      } else {
        setPrevResult((prevResult) => {
          return JSON.stringify(prevResult) !== JSON.stringify(query)
            ? query
            : prevResult.reverse();
        });
        setQueryComplete(false);
      }
      // const queryEntries = query.entries;
      // const queryDepartures = query.departures;

      const currentReferences = list.map((it) => it.entry.reference);

      const differentReferences = query
        .filter((it) => !currentReferences.includes(it.reference))
        .reverse();

      void injectEntries(differentReferences);
    }
  }, [query]);

  useEffect(() => {
    if (departures) {
      void injectDepartures(prevResult);
    }
  }, [departures]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      // Aquí puedes ejecutar cualquier acción que desees antes de la recarga de la página
      console.log("La página se está actualizando...");
      // Por ejemplo, podrías guardar datos en el localStorage antes de la recarga
      // localStorage.setItem("lastPageUpdate", new Date().toISOString());
    };

    // Agregar el listener para el evento beforeunload
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Limpiar el listener cuando el componente se desmonta
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  /* eslint-enable */

  async function connect() {
    if (connectionState?.isLoading()) return;
    setConnectionState(Response.loading());
    try {
      connectionReference.current = await AttendanceRepository.getLive(
        onError,
        appUser
      );
      connectionReference.current =
        await AttendanceRepository.getLiveDepartures(onError, appUser);
      setConnectionState(Response.success(true));
    } catch (e: any) {
      setConnectionState(Response.failure(e));
    }
  }

  function onConnectionStateReceived() {
    setConnectionState(null);
  }

  function requestSelectItem(item: AttendancePair) {
    setSelectedItem(item);
  }

  function onSelectItemCompleted() {
    setSelectedItem(null);
  }

  return {
    connectionState,
    onConnectionStateReceived,
    connect,
    list,
    requestSelectItem,
    onSelectItemCompleted,
    selectedItem,
  };
}
