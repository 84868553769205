import React, { FC, useContext, useEffect } from "react";
import {useLoaderData} from "react-router-dom";
import {ContextHeader} from "../../components/header/secondary";
import { DashboardHeaderContext } from "../../../hook/header";
import { VehicleControlView } from "./components/view";
import { UserVehicleControl } from "../../../domain/control/Vehicle";

export const VehicleControlPage: FC<any> = () => {
    const data = useLoaderData() as UserVehicleControl;
  /* eslint-disable */
  const dashboardHeaderContext = useContext(DashboardHeaderContext);
  useEffect(() => {
    dashboardHeaderContext.setTheme({
      className: "bg-gray-100",
      title: "Control Vehicular",
      dark: false,
      subtitle: data.owner?.displayName,
      homeAsUpEnabled: true
    });
  }, []);
  /* eslint-enable */
    return (
        <div className={"w-full h-full flex flex-col overflow-hidden px-3"}>
            <ContextHeader
                breadcrumbItems={[
                    {
                        label: "Control Vehicular",
                        path: "/dashboard/vehicle-control-group",
                    },
                    {
                        label: "...",
                        active: true,
                    },
                ]}
                title={"Registro de Control Vehicular"}
            />
            <div className={"p-3 flex-1 w-full overflow-y-auto"}>
                <VehicleControlView vehicleControl={data}/>
            </div>
        </div>
    );
};
