import {db} from "../db";
import {VisitControl} from "../../../domain/control/Visit";

export class VisitControlDao {
    static async putVisitControl(...visitControl: VisitControl[]) {
        return db.visit_control.bulkPut(visitControl);
    }

    static async getAllUnityVisitControl(unityId: number, start: number, end: number) {
        return db.visit_control
            .where(["unityId+timestamp"])
            .between([unityId, start], [unityId, end], true, true)
            .reverse()
            .sortBy("timestamp");
    }

    static async deleteAllUnityVisitControl(
        unityId: number,
        start: number,
        end: number
    ) {
        return db.visit_control
            .where(["unityId+timestamp"])
            .between([unityId, start], [unityId, end], true, true)
            .delete();
    }

    static async getUserVisitControlList(ownerUid: string, start: number, end: number) {
        return db.visit_control
            .where(["ownerUid+timestamp"])
            .between([ownerUid, start], [ownerUid, end])
            .reverse()
            .sortBy("timestamp");
    }

    static async deleteAllUserVisitControl(
        ownerUid: string,
        start: number,
        end: number
    ) {
        return db.visit_control
            .where(["ownerUid+timestamp"])
            .between([ownerUid, start], [ownerUid, end])
            .delete();
    }

    static async getAllVisitControl(start: number, end: number) {
        return db.visit_control
            .where("timestamp")
            .between(start, end, true, true)
            .reverse()
            .sortBy("timestamp");
    }

    static async deleteAllVisitControl(
        start: number,
        end: number
    ) {
        return db.visit_control
            .where("timestamp")
            .between(start, end, true, true)
            .delete();
    }

    static async getVisitControl(reference: string) {
        return db.visit_control.get(reference)
    }
}
