import {FC, useCallback, useEffect, useState} from "react";
import {useUserPatrolViewModel} from "../../../../viewmodel/user/UserPatrol";
import {formatDate} from "../../../../util/date";
import {DatePicker, IconButton, Loader} from "rsuite";
import {FaIcon} from "../../../components/fontawesome/icon";
import {faRefresh} from "@fortawesome/free-solid-svg-icons";
import {toRelativeCalendar} from "../../../../lib/date";
import {PentrackerUser} from "../../../../domain/user/User";
import {UserPatrolData} from "./components/data";
import {useNavigate, useSearchParams} from "react-router-dom";
import {DateTime} from "luxon";
import {useQueryNavigate} from "../../../../hook/navigate";
import {UserPatrol} from "../../../../domain/patrol/Patrol";
import {UserPatrolPreviewModal} from "./components/modal";
import {toInteger} from "lodash";

type Props = {
    user: PentrackerUser;
};

export const UserPatrolTabContent: FC<Props> = ({user}) => {
    const navigate = useNavigate();
    const queryNavigate = useQueryNavigate();
    const [params] = useSearchParams();
    const startingTimestamp = params.get("timestamp");
    const [selectedTime, setSelectedTime] = useState(
        !!startingTimestamp
            ? DateTime.fromMillis(toInteger(startingTimestamp)).toJSDate()
            : new Date()
    );
    const {
        fetchState,
        fetchUserPatrolList,
        onFetchStateReceived,
        userPatrolList,
        userPatrolTimestamp,
        refreshEvent,
        requestRefreshEvent,
        onRefreshEventCompleted,
        selectedItem,
        selectPatrolItem,
        onItemSelectCompleted,
    } = useUserPatrolViewModel(user);

    /* eslint-disable */
    useEffect(() => {
        void fetchUserPatrolList(selectedTime.getTime());
    }, [selectedTime]);

    useEffect(() => {
        if (fetchState && !fetchState.isLoading()) {
            if (fetchState.isFailed()) {
                window.toast.error(
                    "Ocurrió un error al obtener la lista de rondas del usuario."
                );
                console.log(fetchState?.error);
            }
            onFetchStateReceived();
        }
    }, [fetchState]);

    useEffect(() => {
        if (refreshEvent) {
            void fetchUserPatrolList(selectedTime.getTime(), true);
            onRefreshEventCompleted();
        }
    }, [refreshEvent]);

    const onDataItemSelect = useCallback((item: UserPatrol) => {
        navigate(
            `/dashboard/patrol?reference=${encodeURIComponent(item.reference)}`
        );
    }, []);

    const onDataItemPreviewSelected = useCallback((item: UserPatrol) => {
        selectPatrolItem(item);
    }, []);
    /* eslint-enable */
    return (
        <div className={"relative w-full overflow-x-hidden overflow-y-auto"}>
            {selectedItem ? (
                <UserPatrolPreviewModal
                    item={selectedItem}
                    onClose={onItemSelectCompleted}
                />
            ) : null}
            <div
                className={
                    "flex-none w-full flex flex-row flex-wrap gap-2 p-2 border-b-2 border-neutral-100 overflow-x-hidden items-center justify-between"
                }
            >
        <span className={"text-lg font-normal text-neutral-600 uppercase"}>
          {formatDate(selectedTime)}
        </span>
                <div className={"flex flex-row flex-wrap items-center gap-2"}>
                    <div className={"flex flex-row flex-wrap gap-2 items-center z-0"}>
                        <span>Seleccionar fecha</span>
                        <DatePicker
                            size={"sm"}
                            appearance={"default"}
                            value={selectedTime}
                            onChange={(newDate) => {
                                if (
                                    !!newDate &&
                                    newDate?.getTime() !== selectedTime.getTime()
                                ) {
                                    setSelectedTime(newDate);
                                    queryNavigate({
                                        timestamp: newDate.getTime(),
                                    });
                                }
                            }}
                        />
                    </div>
                    <IconButton
                        onClick={requestRefreshEvent}
                        icon={<FaIcon icon={faRefresh}/>}
                        size={"sm"}
                        appearance={"ghost"}
                    >
                        Actualizar
                    </IconButton>
                </div>
            </div>
            {fetchState?.isLoading() ? (
                <Loader center backdrop size={"sm"} content={"Cargando..."}/>
            ) : (
                <div
                    className={
                        "w-full"
                    }
                >
                    <div className={"w-full"}>
                        {userPatrolTimestamp ? (
                            <div className={"w-full flex-none p-2"}>
                                <p>
                    <span className={"text-neutral-400 uppercase"}>
                      Última actualización:{" "}
                    </span>
                                    {toRelativeCalendar(userPatrolTimestamp.timestamp, true)}
                                </p>
                            </div>
                        ) : null}
                        <div className={"w-full"}>
                            <UserPatrolData
                                userPatrolList={userPatrolList}
                                onSelect={onDataItemSelect}
                                onPreviewClicked={onDataItemPreviewSelected}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
