import { GetZoneDto, PutZoneDto } from "../../network/patrol/Zone";

export type Zone = {
  id: number;
  label: string;
  description: string;
  createdAt?: number;
  unityId: number
};

export function zoneDtoAsDomain(
  zoneDto: GetZoneDto,
  unityId: number
): Zone {
  return {
    ...zoneDto,
    unityId,
    createdAt: zoneDto.createdAt ? (typeof zoneDto.createdAt === "number" ? zoneDto.createdAt : zoneDto.createdAt.toMillis()) : Date.now()
  };
}


export type PutZone = PutZoneDto["data"] & {
  toUnityId?: number
  reason: string
}

export type DeleteZone = {
  reason: string
}
