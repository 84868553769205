import { Loader, Schema, SelectPicker } from "rsuite";
import { isNotBlank } from "../../../../../util/validation";
import { CustomInputProps, InputDataList } from "../../../../components/modal/FormModal";
import { PutZone, Zone } from "../../../../../domain/patrol/Zone";
import { ReasonInputSelector } from "../../../../components/input/ReasonSelector";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../../../../data/database/db";
import { FC } from "react";

export const EditZoneInputSchema = Schema.Model<Partial<PutZone>>(
  {
    label: Schema.Types.StringType().isRequired("Debe completar este campo").addRule(isNotBlank(2), "Se necesitan al menos 2 caracteres."),
    description: Schema.Types.StringType("Escriba una descripción válida."),
    reason: Schema.Types.StringType().isRequired(
      "Debe especificar el motivo."
    ).addRule(isNotBlank(10), "El motivo de la edición debe tener al menos 10 letras."),
    toUnityId: Schema.Types.NumberType()
  }
);

const UnitySelector: FC<CustomInputProps<PutZone>> = ({ values, updateValue, name }) => {
  const unities = useLiveQuery(() => db.unities.toArray());
  return unities ? <SelectPicker data={unities?.map(u => ({ label: u.label, value: u.id }))} value={values[name]}
                                 onSelect={(v) => {
                                   updateValue(name, v);
                                 }} /> : <Loader size={"xs"} />;
};
export const EditZoneInputList: InputDataList<PutZone> = [
  {
    name: "label",
    label: "Nombre",
    type: "text",
    defaultValue: undefined
  },
  {
    name: "description",
    label: "Descripción",
    type: "textarea",
    defaultValue: undefined
  },
  {
    name: "reason",
    label: "Motivo de cambio",
    type: "custom",
    help: "Detalle el motivo de la eliminación",
    defaultValue: undefined,
    provide: (props) => <ReasonInputSelector {...props as any}
                                             options={["Nuevo cliente", "Adición de cliente existente"]} />
  },
  {
    name: "toUnityId",
    label: "Mover a Unidad",
    type: "custom",
    controlId: "id",
    defaultValue: undefined,
    provide: UnitySelector
  }
];

