import {FC, useContext} from "react";
import {BreakPointContext} from "../../../../context/BreakPointContext";
import {Zone} from "../../../../../domain/patrol/Zone";
import {ZoneList} from "./list";
import {UnityZoneTable} from "./table";
import {Unity} from "../../../../../domain/unity";

type OnActionClick = (item: Zone) => void;

type Props = {
    unity: Unity;
    items: Zone[] | undefined;
    checkedZonesReferences: number[];
    onEditButtonClick: OnActionClick;
    onDeleteButtonClick: OnActionClick;
    onDownloadClick: OnActionClick;
    onRowClick: OnActionClick;
    onCheckedChange: (reference: number, checked: boolean) => void;
    onSwitchCheckAll: (value: any, checked: boolean) => void;
};

export const UnityZoneData: FC<Props> = ({
                                             items,
                                             checkedZonesReferences,
                                             onEditButtonClick,
                                             onDeleteButtonClick,
                                             onDownloadClick,
                                             onRowClick,
                                             onCheckedChange,
                                             onSwitchCheckAll,
                                             unity,
                                         }) => {
    const screenSize = useContext(BreakPointContext);
    if (screenSize.isMobile) {
        return <ZoneList onSelect={onRowClick} zoneList={items} unity={unity}/>;
    } else {
        return (
            <UnityZoneTable
                unity={unity}
                items={items}
                checkedZonesReferences={checkedZonesReferences}
                onEditButtonClick={onEditButtonClick}
                onDeleteButtonClick={onDeleteButtonClick}
                onDownloadClick={onDownloadClick}
                onRowClick={onRowClick}
                onCheckedChange={onCheckedChange}
                onSwitchCheckAll={onSwitchCheckAll}
            />
        );
    }
};
