import React, { FC, createContext, PropsWithChildren, useContext } from "react";
import { CSSProperties, ReactNode } from "react";

type CustomCSSProperties = CSSProperties & { [key: string]: string | number };

interface SpecificStyles {
  tableHeaderStyle: CustomCSSProperties;
  tableHeaderStyleSecondary: CustomCSSProperties;
}

interface GenericStyles {
  [key: string]: CustomCSSProperties;
}

// const StyleContext = createContext<StyleContextType>({
//   tableHeaderStyle: "",
// });

type StyleContextType = SpecificStyles & GenericStyles;

const StyleContext = createContext<StyleContextType>({
  tableHeaderStyle: {},
  tableHeaderStyleSecondary: {},
  style: {},
});

export const useStyleContext = () => useContext(StyleContext);

interface StyleProviderProps {
  children: ReactNode;
  stylePage?: any;
  primaryColors?: any;
}

export const StyleProvider: FC<StyleProviderProps> = ({
  children,
  stylePage,
  primaryColors,
}) => {
  const defaultStyles: StyleContextType = {
    tableHeaderStyle: {
      backgroundColor: primaryColors.colorPrimary,
      color: primaryColors.colorTextTable,
      fontWeight: 550,
      textTransform: "uppercase",
    },
    tableHeaderStyleSecondary: {
      backgroundColor: primaryColors.colorPrimary,
      color: primaryColors.colorTextTable,
    },
    stylePage: { ...stylePage },
    primaryColors: { ...primaryColors },
  };

  return (
    <StyleContext.Provider value={defaultStyles}>
      {children}
    </StyleContext.Provider>
  );
};
