import React, { FC, useContext, useEffect } from "react";
import { ByRequestIncidentGroup } from "./request";
import { PrimaryHeader } from "../../components/header/primary";
import { DashboardHeaderContext } from "../../../hook/header";
import { useStyleContext } from "../../context/StyleContext";

export const UserIncidentGroupPage: FC = () => {
  const dashboardHeaderContext = useContext(DashboardHeaderContext);

  const {
    stylePage,
  } = useStyleContext();

  /* eslint-disable */
  useEffect(() => {
    dashboardHeaderContext.setTheme({
      className: "bg-primary-header",
      title: "Incidencias",
      dark: true,
    });
  }, []);
  /* eslint-enable */

  return (
    <div className={"w-full overflow-x-hidden"} style={stylePage}>
      <PrimaryHeader
        breadcrumbItems={[
          {
            label: "Incidencias",
            active: true,
          },
        ]}
        title={"Incidencias"}
      />
      <div className={"w-full p-3"}>
        <ByRequestIncidentGroup />
      </div>
    </div>
  );
};
