import React, { Children, FC, ReactNode, useContext, useEffect } from "react";
import { Breadcrumb, Navbar } from "rsuite";
import { FaIcon } from "../fontawesome/icon";
import { faChevronRight, faHome } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Action, ActionGroup } from "./secondary";
import { DashboardHeaderContext } from "../../../hook/header";

type Props = {
  breadcrumbItems: {
    label: string;
    path?: string;
    active?: boolean;
  }[];
  title: string;
  description?: ReactNode;
  actions?: ReactNode[];
  actionsSchema?: Action[];
};

export const PrimaryHeader: FC<Props> = ({
  breadcrumbItems,
  title,
  actions,
  description,
  actionsSchema,
}) => {
  const appBarContext = useContext(DashboardHeaderContext);
  useEffect(() => {
    appBarContext.setTheme({
      dark: true,
      homeAsUpEnabled: true,
      title: "Notificaciones",
      className: "bg-primary-header",
    });
  }, []);
  // ``
  return (
    <div className={`w-full pb-4 px-3 ${appBarContext.theme.className}`}>
      <Breadcrumb
        className={"p-5"}
        separator={<FaIcon icon={faChevronRight} />}
      >
        <Link className={"a"} to={"/dashboard"}>
          <FaIcon icon={faHome} /> Inicio
        </Link>
        {breadcrumbItems.map((bItem, i) =>
          bItem.active === true ? (
            <Breadcrumb.Item key={`bitem-${i}`} active className={"text-multi"}>
              {bItem.label}
            </Breadcrumb.Item>
          ) : (
            <Link key={`bitem-${i}`} to={bItem.path || "/dashboard"}>
              {bItem.label}
            </Link>
          )
        )}
      </Breadcrumb>
      <div className={"w-full flex flex-row flex-wrap justify-between"}>
        <div className={"flex flex-col px-5 py-2 "}>
          <h1 className={"font-normal text-lg sm:text-xl text-multi"}>
            {title}
          </h1>
          {description ? (
            <div className={"text-multi"}>{description}</div>
          ) : null}
        </div>
        {actions ? (
          <Navbar appearance="subtle" className="w-fit flex flex-row flex-wrap">
            <div className="ml-auto my-auto flex gap-2 justify-end items-center overflow-auto flex-wrap">
              {Children.toArray(actions)}
            </div>
          </Navbar>
        ) : actionsSchema ? (
          <ActionGroup schema={actionsSchema} />
        ) : null}
      </div>
    </div>
  );
};
