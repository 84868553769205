import React, { FC, useCallback } from "react";
import { EmptyState } from "../../../components/state/empty";
import { useDataPager } from "../../../../hook/pager";
import { Pagination } from "rsuite";
import { UserVisitControl } from "../../../../domain/control/Visit";
import { VisitControlRow } from "./row";

type VisitControlListProps = {
  visitControlList?: UserVisitControl[];
  onSelect: (item: UserVisitControl) => void;
  name?: string;
};

export const VisitControlList: FC<VisitControlListProps> = ({
  visitControlList,
  onSelect,
  name,
}) => {

  /* eslint-disable */

  const { limits, changeLimit, limit, page, partialData, changePage } =
    useDataPager<UserVisitControl>({
      id: `visit_list_${name}`,
      items: visitControlList,
    });

  const onSelectRow = useCallback<React.MouseEventHandler<HTMLDivElement>>(
    (event) => {
      if (!partialData) return;
      const index = event.currentTarget.dataset["index"] as unknown as number;
      const item = partialData[index];
      if (item) {
        onSelect(item);
      }
    },
    [partialData]
  );

  /* eslint-enable */

  return visitControlList && visitControlList.length > 0 ? ( //@ts-ignore
    <div className={"w-full h-auto"}>
      {
        partialData?.map((visit, i) =>
          <VisitControlRow
            index={i}
            style={{}}
            key={`visit-row-${visit.reference}`}
            data={{
              userVisitControlList: partialData,
              onSelect: onSelectRow,
            }}
          />
        )
      }
      <div className={"py-4 flex flex-col gap-4"}>
        <span className={"text-md"}>
          Mostrando {partialData?.length} elementos / Total {visitControlList.length}
        </span>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={["limit", "pager"]}
          total={visitControlList.length}
          limitOptions={limits}
          limit={limit}
          activePage={page}
          onChangePage={changePage}
          onChangeLimit={changeLimit}
        />
      </div>
    </div>
  ) : (
    <EmptyState
      title={"No hay registros de control de visitas"}
      message={
        "No se encontraron registros de control de visitas para la fecha seleccionada."
      }
    />
  );
};
