import {FC} from "react";
import {toTime} from "../../../../lib/date";
import {CellProps, Table} from "rsuite";
import { UserVisitControl } from "../../../../domain/control/Visit";

export const VisitControlTableTimestampCell: FC<CellProps<UserVisitControl>> = ({
                                                                            rowData,
                                                                            ...props
                                                                        }) => {
    if (!rowData) {
        return null
    }
    const {timestamp} = rowData;
    return (
        <Table.Cell {...props}>
            <span>{toTime(timestamp)}</span>
        </Table.Cell>
    );
};
