import React, { FC, useContext, useEffect, useRef } from "react";
import { useDashboardHomeViewModel } from "../../../viewmodel/home/DashboardHome";
import { DashboardHomeHeader } from "./components/header";
import { DashboardUserPieCard } from "./components/user-pie-card";
import { DashboardLatestUsersList } from "./components/user-list";
import { BreakPointContext } from "../../context/BreakPointContext";
import { switchBreakpoint } from "../../../util/breakpoint";
import GridLayout, { Layout } from "react-grid-layout";
import { DashboardUnityPieCard } from "./components/unity-pie-card";
import { DashboardItemCount } from "./components/item-count";
import { DashboardLastAttendanceList } from "./components/attendance-list";
import { DashboardLastPatrolList } from "./components/patrol-list";
import { DashboardLastIncidentList } from "./components/incident-list";
import { DashboardErrorList } from "./components/log-list";
import { DashboardHeaderContext } from "../../../hook/header";
import {
  DashboardAttendanceDataItem_Latest,
  DashboardAttendanceDataItem_Today,
  DashboardAttendanceDataItem_Yesterday,
  DashboardErrorLogsItem,
  DashboardEventListItem,
  DashboardIncidentDataItem_Latest,
  DashboardIncidentDataItem_Today,
  DashboardIncidentDataItem_Yesterday,
  DashboardMainLayoutLarge_3,
  DashboardMainLayoutMedium_2,
  DashboardMainLayoutSmall_1,
  DashboardMainLayoutXLarge_4,
  DashboardPatrolDataItem_Latest,
  DashboardPatrolDataItem_Today,
  DashboardPatrolDataItem_Yesterday,
  DashboardUnityDataItem_Count,
  DashboardUnityDataItem_Pie,
  DashboardUserDataItem_Count,
  DashboardUserDataItem_Latest,
  DashboardUserDataItem_Pie,
} from "./layout/grid";
import { useElementWidth } from "../../../hook/width";
import { useStyleContext } from "../../context/StyleContext";

export const DashboardHomePane: FC = () => {
  const {
    tableHeaderStyle,
    tableHeaderStyleSecondary,
    stylePage,
    primaryColors,
  } = useStyleContext();

  const {
    userData,
    userDataState,
    userDataFetchTimestamp,
    fetchUsersData,
    unityData,
    unityDataState,
    unityDataFetchTimestamp,
    fetchUnityData,
    attendanceData,
    attendanceDataState,
    attendanceDataFetchTimestamp,
    fetchAttendanceData,
    patrolData,
    patrolDataState,
    patrolDataFetchTimestamp,
    fetchPatrolData,
    incidentData,
    incidentDataState,
    incidentDataFetchTimestamp,
    fetchIncidentData,
    latestPentrackerErrors,
    fetchInitialData,
  } = useDashboardHomeViewModel();
  const containerRef = useRef<HTMLDivElement>(null);
  const width = useElementWidth(containerRef);

  const headerContext = useContext(DashboardHeaderContext);

  useEffect(() => {
    headerContext.setTheme({
      className: "bg-secondary-header",
      dark: true,
      title: "Descripción General",
    });
    void fetchUsersData(true);
    void fetchUnityData(true);
    void fetchPatrolData(true);
    void fetchUnityData(true);
    void fetchIncidentData(true);
  }, []);

  const breakpoint = useContext(BreakPointContext);
  let columns = switchBreakpoint<number>(
    { bp: breakpoint.breakpoint },
    [["xs", "sm", "md"], 1],
    [["lg"], 2],
    [["xl"], 3],
    [["2xl"], 4]
  );

  let layout = switchBreakpoint<Layout[]>(
    { bp: breakpoint.breakpoint },
    [["xs", "sm", "md"], DashboardMainLayoutSmall_1],
    [["lg"], DashboardMainLayoutMedium_2],
    [["xl"], DashboardMainLayoutLarge_3],
    [["2xl"], DashboardMainLayoutXLarge_4]
  );
  useEffect(() => {
    void fetchInitialData();
  }, []);

  return (
    <div
      className={"w-full relative md:px-4 xl:px-20 z-30 bg-neutral-100"}
      style={stylePage}
    >
      <div
        className={
          "absolute w-full h-96 bg-secondary-header top-0 left-0 right-0 z-10"
        }
      />

      <DashboardHomeHeader
        userCount={userData?.count}
        userDataLoading={userDataState?.isLoading()}
        unityCount={unityData?.count}
        unityDataLoading={unityDataState?.isLoading()}
        attendanceCount={attendanceData?.todayActiveUsers}
        attendanceDataLoading={attendanceDataState?.isLoading()}
        patrolCount={patrolData?.todayPatrolCount}
        patrolDataLoading={patrolDataState?.isLoading()}
        incidentCount={incidentData?.todayIncidentCount}
        incidentDataLoading={incidentDataState?.isLoading()}
        errorCount={latestPentrackerErrors?.length}
      />

      <div className={"w-full py-2 md:py-6 z-50 relative"} ref={containerRef}>
        {/*@ts-ignore*/}

        <GridLayout
          className="layout"
          layout={layout}
          cols={columns}
          rowHeight={30}
          autoSize
          width={width}
        >
          <div
            className={
              "rounded-lg md:rounded-2xl shadow bg-white w-full overflow-hidden"
            }
            key={DashboardUserDataItem_Count}
          >
            <DashboardItemCount
              timestamp={userDataFetchTimestamp}
              onRefreshButtonClicked={fetchUsersData}
              count={userData?.count}
              loading={userDataState?.isLoading()}
              title={"Usuarios registrados"}
              suffix={"usuario(s)"}
            />
          </div>
          <div
            className={
              "rounded-lg md:rounded-2xl shadow bg-white w-full overflow-hidden"
            }
            key={DashboardUserDataItem_Pie}
          >
            <DashboardUserPieCard
              timestamp={userDataFetchTimestamp}
              onRefreshButtonClicked={fetchUsersData}
              data={userData?.countByRole}
              userDataLoading={userDataState?.isLoading()}
            />
          </div>
          <div
            className={
              "rounded-lg md:rounded-2xl shadow bg-white w-full overflow-hidden"
            }
            key={DashboardUserDataItem_Latest}
          >
            <DashboardLatestUsersList
              lastUsers={userData?.lastUsers}
              userDataLoading={userDataState?.isLoading()}
              timestamp={userDataFetchTimestamp}
              onRefreshClicked={fetchUsersData}
            />
          </div>
          <div
            className={
              "rounded-lg md:rounded-2xl shadow bg-white w-full overflow-hidden"
            }
            key={DashboardUnityDataItem_Count}
          >
            <DashboardItemCount
              count={unityData?.count}
              loading={unityDataState?.isLoading()}
              onRefreshButtonClicked={fetchUnityData}
              timestamp={unityDataFetchTimestamp}
              title={"Unidades registradas"}
              suffix={"unidad(es)"}
            />
          </div>
          <div
            className={
              "rounded-lg md:rounded-2xl shadow bg-white w-full overflow-hidden"
            }
            key={DashboardUnityDataItem_Pie}
          >
            <DashboardUnityPieCard
              data={unityData?.countByUsers}
              onRefreshButtonClicked={fetchUnityData}
              timestamp={unityDataFetchTimestamp}
              unityDataLoading={unityDataState?.isLoading()}
            />
          </div>
          <div
            className={
              "rounded-lg md:rounded-2xl shadow bg-white w-full overflow-hidden"
            }
            key={DashboardAttendanceDataItem_Today}
          >
            <DashboardItemCount
              count={attendanceData?.todayActiveUsers}
              loading={attendanceDataState?.isLoading()}
              onRefreshButtonClicked={fetchAttendanceData}
              timestamp={attendanceDataFetchTimestamp}
              title={"Usuarios activos (hoy)"}
              suffix={"usuario(s)"}
            />
          </div>

          <div
            className={
              "rounded-lg md:rounded-2xl shadow bg-white w-full overflow-hidden"
            }
            key={DashboardAttendanceDataItem_Yesterday}
          >
            <DashboardItemCount
              count={attendanceData?.yesterdayActiveUsers}
              loading={attendanceDataState?.isLoading()}
              onRefreshButtonClicked={fetchAttendanceData}
              timestamp={attendanceDataFetchTimestamp}
              title={"Usuarios activos (ayer)"}
              suffix={"usuario(s)"}
            />
          </div>

          <div
            className={
              "rounded-lg md:rounded-2xl shadow bg-white w-full overflow-hidden"
            }
            key={DashboardAttendanceDataItem_Latest}
          >
            <DashboardLastAttendanceList
              lastAttendance={attendanceData?.lastUserEntries}
              timestamp={attendanceDataFetchTimestamp}
              attendanceDataLoading={attendanceDataState?.isLoading()}
              onRefreshClicked={fetchAttendanceData}
            />
          </div>

          <div
            className={
              "rounded-lg md:rounded-2xl shadow bg-white w-full overflow-hidden"
            }
            key={DashboardPatrolDataItem_Today}
          >
            <DashboardItemCount
              count={patrolData?.todayPatrolCount}
              loading={patrolDataState?.isLoading()}
              onRefreshButtonClicked={fetchPatrolData}
              timestamp={patrolDataFetchTimestamp}
              title={"Rondas (hoy)"}
              suffix={"registro(s)"}
            />
          </div>

          <div
            className={
              "rounded-lg md:rounded-2xl shadow bg-white w-full overflow-hidden"
            }
            key={DashboardPatrolDataItem_Yesterday}
          >
            <DashboardItemCount
              count={patrolData?.yesterdayPatrolCount}
              loading={patrolDataState?.isLoading()}
              onRefreshButtonClicked={fetchPatrolData}
              timestamp={patrolDataFetchTimestamp}
              title={"Rondas (ayer)"}
              suffix={"ronda(s)"}
            />
          </div>
          <div
            className={
              "rounded-lg md:rounded-2xl shadow bg-white w-full overflow-hidden"
            }
            key={DashboardPatrolDataItem_Latest}
          >
            <DashboardLastPatrolList
              onRefreshClicked={fetchPatrolData}
              timestamp={patrolDataFetchTimestamp}
              lastPatrol={patrolData?.lastUserPatrol}
              patrolDataLoading={patrolDataState?.isLoading()}
            />
          </div>
          <div
            className={
              "rounded-lg md:rounded-2xl shadow bg-white w-full overflow-hidden"
            }
            key={DashboardIncidentDataItem_Today}
          >
            <DashboardItemCount
              count={incidentData?.todayIncidentCount}
              loading={incidentDataState?.isLoading()}
              onRefreshButtonClicked={fetchIncidentData}
              timestamp={incidentDataFetchTimestamp}
              title={"Incidencias (hoy)"}
              suffix={"incidencia(s)"}
            />
          </div>

          <div
            className={
              "rounded-lg md:rounded-2xl shadow bg-white w-full overflow-hidden"
            }
            key={DashboardIncidentDataItem_Yesterday}
          >
            <DashboardItemCount
              count={incidentData?.yesterdayIncidentCount}
              loading={incidentDataState?.isLoading()}
              onRefreshButtonClicked={fetchIncidentData}
              timestamp={incidentDataFetchTimestamp}
              title={"Incidencias (ayer)"}
              suffix={"incidencia(s)"}
            />
          </div>
          <div
            className={
              "rounded-lg md:rounded-2xl shadow bg-white w-full overflow-hidden"
            }
            key={DashboardIncidentDataItem_Latest}
          >
            <DashboardLastIncidentList
              onRefreshClicked={fetchIncidentData}
              timestamp={incidentDataFetchTimestamp}
              incidentDataLoading={incidentDataState?.isLoading()}
              lastIncident={incidentData?.lastUserIncident}
            />
          </div>
          <div
            className={
              "rounded-lg md:rounded-2xl shadow bg-white w-full overflow-hidden"
            }
            key={DashboardErrorLogsItem}
          >
            <DashboardErrorList data={latestPentrackerErrors} />
          </div>
          <div
            className={"rounded-2xl border border-neutral-300 bg-white w-full"}
            key={DashboardEventListItem}
          ></div>
        </GridLayout>
      
      </div>
    </div>
  );
};
