import {Unity} from "../unity";
import {PentrackerUser} from "../user/User";
import {GetGoodsControlDto} from "../../network/control/Goods";

export type GoodsControl = Omit<GetGoodsControlDto, "timestamp"> & {
    reference : string
    timestamp: number
}

export type UserGoodsControl = GoodsControl & {
    unity?: Unity;
    owner?: PentrackerUser
}

export function goodsControlDtoAsGoodsControl(goodsControlDto: GetGoodsControlDto, reference: string): GoodsControl {
    return {
        ...goodsControlDto,
        reference,
        timestamp: goodsControlDto.timestamp.toDate().getTime()
    }
}

export function goodsControlAsUserGoodsControl(goodsControl: GoodsControl, unity ?: Unity, owner ?: PentrackerUser): UserGoodsControl {
    return {
        ...goodsControl,
        unity,
        owner
    }
}
