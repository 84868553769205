import {PentrackerUser} from "../../../../domain/user/User";
import React, {FC} from "react";
import {FaIcon} from "../../../components/fontawesome/icon";
import {faIdCard} from "@fortawesome/free-solid-svg-icons";

type Props = {
    user: PentrackerUser;
};

export const UserRowUid: FC<Props> = ({user}) => {
    return (
        <p className={"m-0 p-0"}>
            <FaIcon icon={faIdCard} className={"mr-2 text-neutral-400"}/>
            <span className={"break-words"}>{user.uid}</span>
        </p>
    );
};
