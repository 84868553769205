import { FC, useContext } from "react";
import { BreakPointContext } from "../../../../context/BreakPointContext";
import { UserPatrol } from "../../../../../domain/patrol/Patrol";
import { PatrolList } from "../../../data-display/patrol-list";
import { PatrolTable } from "../../../data-display/patrol-table";

type Props = {
  userPatrolList?: UserPatrol[];
  onSelect: (item: UserPatrol) => void;
  onPreviewClicked: (item: UserPatrol) => void;
};

export const UserPatrolDataName = "UserPatrolData";

export const UserPatrolData: FC<Props> = ({
  userPatrolList,
  onPreviewClicked,
  onSelect,
}) => {
  const breakpoint = useContext(BreakPointContext);
  if (breakpoint.isMobile) {
    return (
      <PatrolList
        name={UserPatrolDataName}
        onSelect={onSelect}
        onPreviewClicked={onPreviewClicked}
        patrolList={userPatrolList}
      />
    );
  } else {
    return (
      <PatrolTable
        name={UserPatrolDataName}
        userPatrolList={userPatrolList}
        onPreviewClicked={onPreviewClicked}
        onSelect={onSelect}
      />
    );
  }
};
