import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";

type Value = {
  width: number;
  height: number;
};
export const ScreenContext = createContext<Value>({
  width: window?.innerWidth || 0,
  height: window?.innerHeight || 0,
});

export const ScreenContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [width, setWidth] = useState<number>(window?.innerWidth || 0);
  const [height, setHeight] = useState<number>(window?.innerHeight || 0);
  const listener = useCallback(() => {
    const { innerWidth, innerHeight } = window;
    if (width !== innerWidth) setWidth(innerWidth);
    if (height !== innerHeight) setHeight(innerHeight);
  }, []);
  useEffect(() => {
    window.addEventListener("resize", listener);
    listener();
    return () => {
      window.removeEventListener("resize", listener);
    };
  }, []);

  return (
    <ScreenContext.Provider
      value={{
        width,
        height,
      }}
    >
      {children}
    </ScreenContext.Provider>
  );
};
