import {isSignInWithEmailLink, signInWithEmailLink} from "firebase/auth";
import {useState} from "react";
import {Response} from "../../domain/app/Response";
import FirebaseAuth from "../../data/source/FirebaseAuth";
import secureStorage from "react-secure-storage";

export function useAuthVerificationViewModel() {
    const [emailLinkLoaded, setEmailLinkLoaded] = useState<boolean | null>(null);

    const [signInState, setSignInState] = useState<Response<boolean> | null>(
        null
    );

    const fetchLinkLoadedState = (link: string) => {
        setEmailLinkLoaded(isSignInWithEmailLink(FirebaseAuth.auth, link));
    };
    const signIn = async (link: string) => {
        setSignInState(Response.loading());
        const email = secureStorage.getItem("user-email") as string | undefined;
        if (!email) {
            setSignInState(
                Response.failure(
                    new Error("La sesión no es válida. Cierre esta ventana.")
                )
            );
            return;
        }
        try {
            await signInWithEmailLink(FirebaseAuth.auth, email, link);
            secureStorage.removeItem("user-email");
            setSignInState(Response.success(true));
        } catch (e) {
            setSignInState(
                Response.failure(
                    new Error("La sesión no es válida. Cierre esta ventana.")
                )
            );
        }
    };

    const onSignInStateReceived = () => {
        setSignInState(null)
    }

    return {
        emailLinkLoaded,
        signInState,
        fetchLinkLoadedState,
        signIn,
        onSignInStateReceived
    };
}
