import {FC} from "react";
import {FaIcon} from "../../../../components/fontawesome/icon";
import {IconDefinition} from "@fortawesome/free-solid-svg-icons";
import {AttendancePeriodicCount} from "../../../../../viewmodel/attendance-group/OverviewAttendanceGroup";

type Props = {
    icon: IconDefinition;
    count: AttendancePeriodicCount;
};

export const AttendanceOverviewPeriodicCount: FC<Props> = ({icon, count}) => {
    return (
        <div className={"w-full p-4 flex flex-row items-center gap-2"}>
            <div className={"p-3 bg-app rounded-full"}>
                <FaIcon icon={icon} color={"white"} size={"2x"}/>
            </div>
            <div className={"flex flex-col justify-center"}>
        <span className={"text-lg font-semibold text-neutral-800"}>
          {count.label}
        </span>
                <span className={"text-md text-neutral-700"}>
          {count.total} asistente(s)
        </span>
            </div>
        </div>
    );
};
