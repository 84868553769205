import {FC, useEffect, useState} from "react";
import {useUserAttendanceViewModel} from "../../../../viewmodel/user/UserAttendance";
import {formatDate} from "../../../../util/date";
import {DatePicker, IconButton, Loader} from "rsuite";
import {FaIcon} from "../../../components/fontawesome/icon";
import {faCalendarXmark, faRefresh} from "@fortawesome/free-solid-svg-icons";
import {PentrackerUser} from "../../../../domain/user/User";
import {useSearchParams} from "react-router-dom";
import {DateTime} from "luxon";
import {toInteger} from "lodash";
import {EmptyState} from "../../../components/state/empty";
import {UserAttendanceView} from "../../attendance/components/view";

type Props = {
    user: PentrackerUser;
};

export const UserAttendanceTabContent: FC<Props> = ({user}) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const timestamp = searchParams?.get("timestamp")
    const [selectedTime, setSelectedTime] = useState(timestamp ? DateTime.fromMillis(toInteger(timestamp)).toJSDate() : new Date());
    const {
        fetchState,
        fetchUserAttendance,
        onFetchStateReceived,
        userAttendance,
        refreshEvent,
        requestRefreshEvent,
        onRefreshEventCompleted
    } = useUserAttendanceViewModel(user);

    /* eslint-disable */
    useEffect(() => {
        void fetchUserAttendance(selectedTime.getTime());
        setSearchParams({
            timestamp: `${selectedTime.getTime()}`
        })
    }, [selectedTime]);

    useEffect(() => {
        if (fetchState && !fetchState.isLoading()) {
            if (fetchState.isFailed()) {
                window.toast.error(
                    "Ocurrió un error al obtener la asistencia del usuario."
                );
                console.log(fetchState?.error);
            }
            onFetchStateReceived();
        }
    }, [fetchState]);

    useEffect(() => {
        if (refreshEvent) {
            void fetchUserAttendance(selectedTime.getTime(), true);
            onRefreshEventCompleted();
        }
    }, [refreshEvent]);
    /* eslint-enable */

    return (
        <div className={"relative w-full h-full overflow-hidden flex flex-col"}>
            <div
                className={
                    "flex-none w-full flex flex-row flex-wrap gap-2 p-2 border-b-2 border-neutral-100 overflow-x-hidden items-center justify-between"
                }
            >
        <span className={"text-lg font-normal text-neutral-600 uppercase"}>
          {formatDate(selectedTime)}
        </span>
                <div className={"flex flex-row flex-wrap items-center gap-2"}>
                    <div className={"flex flex-row flex-wrap gap-2 items-center z-0"}>
                        <span>Seleccionar fecha</span>
                        <DatePicker
                            size={"sm"}
                            appearance={"default"}
                            value={selectedTime}
                            onChange={(newDate) => {
                                if (!!newDate && newDate?.getTime() !== selectedTime.getTime())
                                    setSelectedTime(newDate);
                            }}
                        />
                    </div>
                    <IconButton
                        size={"sm"}
                        appearance={"ghost"}
                        onClick={requestRefreshEvent}
                        icon={<FaIcon icon={faRefresh}/>}
                    >
                        Actualizar
                    </IconButton>
                </div>
            </div>
            {fetchState?.isLoading() ? (
                <Loader center backdrop size={"sm"} content={"Cargando..."}/>
            ) : (
                <div
                    className={
                        "flex-1 w-full h-full flex overflow-hidden"
                    }
                >
                    {userAttendance ? (
                        <UserAttendanceView attendance={userAttendance}/>
                    ) : (
                        <EmptyState icon={faCalendarXmark} title={"Sin registro de asistencia"}
                                    message={"El usuario no registró su asistencia este día."}/>
                    )}
                </div>
            )}
        </div>
    );
};
