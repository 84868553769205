import { FC } from "react";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import { Icon } from "@rsuite/icons";

type Props = {
  icon: IconDefinition;
  color?: any;
} & FontAwesomeIconProps;

export const FaIcon: FC<Props> = ({ icon, ...props }) => {
  // @ts-ignore
  return <Icon as={FontAwesomeIcon} icon={icon} {...props} />;
};
