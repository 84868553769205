import { keysIn, orderBy } from "lodash";
import {
  MutableRefObject,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { TableInstance } from "rsuite/Table";

interface Relation {
  [key: string]: number;
}

type SizeRelation = {
  [minSize: number]: Relation;
};

export function useTableWidth(
  ref:
    | MutableRefObject<TableInstance<any, any>>
    | RefObject<TableInstance<any, any>>,
  sizeRelation: SizeRelation,
  defaultWidth: number = 0
) {
  const [width, setWidth] = useState(defaultWidth);
  const [currentRelation, setCurrentRelation] = useState<Relation>({});
  const timeoutReference = useRef<NodeJS.Timeout>();

  const updateWidth = useCallback(
    function () {
      if (!ref.current) return;
      const element = (ref.current as any).body as HTMLDivElement;
      if (!element || element.clientWidth === width) return;
      setWidth(element.clientWidth);
    },
    [ref, width]
  );

  // const observer = new ResizeObserver(() => {
  //   clearTimeout(timeoutReference.current);
  //   timeoutReference.current = setTimeout(
  //     updateWidth,
  //     timeoutReference.current ? 500 : 0
  //   );
  // });

  const observer = new ResizeObserver((entries) => {
    try {
      clearTimeout(timeoutReference.current);
      timeoutReference.current = setTimeout(updateWidth, 500);
    } catch (error) {
      console.error("ResizeObserver error:", error);
    }
  });

  /* eslint-disable */
  useEffect(() => {
    const keys = orderBy(
      keysIn(sizeRelation).map((key) => Number.parseInt(key)),
      undefined,
      ["desc"]
    );
    let currentRelation: Relation | undefined = undefined;
    keys.every((size) => {
      if (width >= size) {
        currentRelation = sizeRelation[size];
        return false;
      }
      return true;
    });
    if (!currentRelation) currentRelation = sizeRelation[keys[keys.length - 1]];
    keysIn(currentRelation).forEach((key) => {
      currentRelation![key] = currentRelation![key] * width - 1;
    });
    setCurrentRelation(currentRelation);
  }, [width]);

  useEffect(() => {
    
    // const observeTarget = ref.current;
    // if (!observeTarget) return;
    // const debouncedCallback = debounce((entry) => {
    //   setElementSize({
    //     width: entry.contentRect.width,
    //     height: entry.contentRect.height,
    //   });
    //   if (callback && typeof callback === "function") {
    //     callback(entry.contentRect);
    //   }
    // }, debounceTime);

    const currentRef = ref.current;
    if (!currentRef) return;
    const currentDiv = (currentRef as any).body as HTMLDivElement;
    setWidth(currentDiv.clientWidth);
    observer.observe(currentDiv);
    return () => {
      observer.unobserve(currentDiv);
      observer.disconnect();
    };
  }, [ref]);
  /* eslint-enable */
  return currentRelation;
}

export function useTableSize(
  ref:
    | MutableRefObject<TableInstance<any, any>>
    | RefObject<TableInstance<any, any>>
) {
  const [width, setWidth] = useState(0);
  const timeoutReference = useRef<NodeJS.Timeout>();

  const updateWidth = useCallback(
    function () {
      if (!ref.current) return;
      const element = (ref.current as any).body as HTMLDivElement;
      if (!element || element.clientWidth === width) return;
      setWidth(element.clientWidth);
    },
    [ref, width]
  );

  const calculateWeight = useCallback(
    (weigth: number) => {
      return weigth * width;
    },
    [width]
  );

  const sizeToWeight = useCallback(
    (size: number) => {
      return size / width;
    },
    [width]
  );

  // const observer = new ResizeObserver(() => {
  //   clearTimeout(timeoutReference.current);
  //   timeoutReference.current = setTimeout(
  //     updateWidth,
  //     timeoutReference.current ? 500 : 0
  //   );
  // });

  const observer = new ResizeObserver((entries) => {
    try {
      clearTimeout(timeoutReference.current);
      timeoutReference.current = setTimeout(updateWidth, 500);
    } catch (error) {
      console.error("ResizeObserver error:", error);
    }
  });

  /* eslint-disable */

  useEffect(() => {
    const currentRef = ref.current;
    if (!currentRef) return;
    const currentDiv = (currentRef as any).body as HTMLDivElement;
    setWidth(currentDiv.clientWidth);

    observer.observe(currentDiv);
    return () => {
      observer.unobserve(currentDiv);
      observer.disconnect();
    };
  }, [ref]);
  /* eslint-enable */
  return {
    width,
    calculateWeight,
    sizeToWeight,
  };
}

export function useElementWidth(
  ref: MutableRefObject<HTMLDivElement> | RefObject<HTMLDivElement>
) {
  const [width, setWidth] = useState(ref.current?.clientWidth || 0);

  useEffect(() => {
    const current = ref.current;
    if (current && current.clientWidth !== width) setWidth(current.clientWidth);
  }, [ref.current]);

  return width;
}
