import { FC } from "react";
import { ButtonGroup, IconButton, Navbar } from "rsuite";
import { Plus, Reload } from "@rsuite/icons";
import { PrimaryHeader } from "../../../components/header/primary";

type Props = {
  onAdd: () => void;
  onRefresh: () => void;
  addDisabled: boolean;
  refreshDisabled: boolean;
  stylePage: any;
};

export const NewsPageHeader: FC<Props> = ({
  onAdd,
  onRefresh,
  addDisabled,
  refreshDisabled,
  stylePage,
}) => {
  return (
    <div style={stylePage}>
      <PrimaryHeader
        breadcrumbItems={[
          {
            label: "Comunicados",
            active: true,
          },
        ]}
        title={"Comunicados"}
        description={
          "Publique comunicados que se visualizarán a través de Pentracker"
        }
        actions={[
          <IconButton
            appearance={"primary"}
            onClick={onAdd}
            disabled={addDisabled}
            icon={<Plus />}
          >
            Nuevo
          </IconButton>,
          <IconButton
            appearance={"primary"}
            onClick={onRefresh}
            disabled={refreshDisabled}
            icon={<Reload />}
          >
            Actualizar
          </IconButton>,
        ]}
      />
    </div>
  );
};
