import React, { FC } from "react";
import { ListChildComponentProps } from "react-window";
import { UserVisitControl } from "../../../../domain/control/Visit";
import { VisitControlRowUnity } from "./unity";
import { VisitControlRowTimestamp } from "./timestamp";
import { RowCell } from "../../../components/row/Cell";

export type VisitControlRowProps = {
  userVisitControlList: UserVisitControl[];
  onSelect: React.MouseEventHandler<HTMLDivElement>;
};

export const VisitControlRow: FC<
  ListChildComponentProps<VisitControlRowProps>
> = ({ data, index, style }) => {
  const { onSelect, userVisitControlList } = data;
  const item = userVisitControlList[index];
  return (
    <div style={style} className={"py-2"}>
      <div
        data-index={index}
        onClick={onSelect}
        className={
          "w-full h-full border border-neutral-300 rounded-lg p-2 flex flex-col justify-between content-between"
        }
      >
        <div className={"flex w-full flex-col gap-2"}>
          <VisitControlRowUnity item={item} />
          <VisitControlRowTimestamp item={item} />
          <RowCell label={"NOMBRE"} data={item} dataKey={"name"} />
          <RowCell label={"APELLIDO"} data={item} dataKey={"lastname"} />
          <RowCell label={"DNI"} data={item} dataKey={"dni"} />
          <RowCell label={"INGRESO"} data={item} dataKey={"entry"} />
        </div>
      </div>
    </div>
  );
};
