import { FC, useContext } from "react";
import { BreakPointContext } from "../../../../context/BreakPointContext";
import { UserGoodsControl } from "../../../../../domain/control/Goods";
import { GoodsControlList } from "../../../data-display/goods-control-list";
import { GoodsControlTable } from "../../../data-display/goods-control-table";

type Props = {
  goodsControlList?: UserGoodsControl[];
  onSelect: (item: UserGoodsControl) => void;
};

export const UnityGoodsControlDataName = "UnityGoodsControlData";

export const UnityGoodsControlData: FC<Props> = ({
  goodsControlList,
  onSelect,
}) => {
  const screenSize = useContext(BreakPointContext);
  if (screenSize.isMobile) {
    return (
      <GoodsControlList
        name={UnityGoodsControlDataName}
        onSelect={onSelect}
        goodsControlList={goodsControlList}
      />
    );
  } else {
    return (
      <GoodsControlTable
        name={UnityGoodsControlDataName}
        goodsControlList={goodsControlList}
        onSelect={onSelect}
      />
    );
  }
};
