import React, {FC} from "react";
import {DashboardHeaderPill} from "./pill";
import {FaIcon} from "../../../../components/fontawesome/icon";
import {faBugs, faBuilding, faClipboardUser, faSync, faUserGroup, faWarning,} from "@fortawesome/free-solid-svg-icons";
import {Loader} from "rsuite";

type Props = {
    userCount?: number | null;
    userDataLoading?: boolean;
    unityCount?: number | null;
    unityDataLoading?: boolean;
    attendanceCount?: number;
    attendanceDataLoading?: boolean;
    patrolCount?: number;
    patrolDataLoading?: boolean;
    incidentCount?: number;
    incidentDataLoading?: boolean;
    errorCount?: number;
};

export const DashboardHomeHeader: FC<Props> = ({
                                                   userCount,
                                                   userDataLoading,
                                                   unityCount,
                                                   unityDataLoading,
                                                   attendanceCount,
                                                   attendanceDataLoading,
                                                   patrolCount,
                                                   patrolDataLoading,
                                                   incidentCount,
                                                   incidentDataLoading,
                                                   errorCount,
                                               }) => {
    return (
        <div
            className={
                "w-full h-fit flex flex-col justify-center items-start px-4 pt-4 gap-6 z-50"
            }
        >
      <span className={"w-full text-multi font-semibold text-xl z-50 text-center md:text-start"}>
        Descripción General
      </span>

            <div className={"w-full flex flex-row justify-center md:justify-start flex-wrap gap-2 z-50"}>
                {userDataLoading === true ? (
                    <Loader size={"sm"}/>
                ) : (
                    <DashboardHeaderPill
                        icon={<FaIcon icon={faUserGroup}/>}
                        label={`${userCount} usuarios`}
                    />
                )}

                {unityDataLoading === true ? (
                    <Loader size={"sm"}/>
                ) : (
                    <DashboardHeaderPill
                        icon={<FaIcon icon={faBuilding}/>}
                        label={`${unityCount} unidades`}
                    />
                )}

                {attendanceDataLoading === true ? (
                    <Loader size={"sm"}/>
                ) : (
                    <DashboardHeaderPill
                        icon={<FaIcon icon={faClipboardUser}/>}
                        label={`${attendanceCount} operadores hoy`}
                    />
                )}

                {patrolDataLoading === true ? (
                    <Loader size={"sm"}/>
                ) : (
                    <DashboardHeaderPill
                        icon={<FaIcon icon={faSync}/>}
                        label={`${patrolCount} rondas registradas hoy`}
                    />
                )}

                {incidentDataLoading === true ? (
                    <Loader size={"sm"}/>
                ) : (
                    <DashboardHeaderPill
                        icon={<FaIcon icon={faWarning}/>}
                        label={`${incidentCount} incidentes reportados hoy`}
                    />
                )}

                <DashboardHeaderPill
                    icon={<FaIcon icon={faBugs}/>}
                    label={`${errorCount} errores reportados`}
                />
            </div>
        </div>
    );
};
