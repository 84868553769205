import { useState} from "react";
import {Response} from "../../domain/app/Response";
import {AppTimestamp} from "../../domain/app/Timestamp";
import { UserVehicleControl } from "../../domain/control/Vehicle";
import { VehicleControlRepository } from "../../data/repository/VehicleControlRepository";
import { Unity } from "../../domain/unity";

export function useUnityVehicleControlViewModel(unity: Unity) {
    const [fetchState, setFetchState] = useState<Response<boolean> | null>(null);
    const [vehicleControlList, setVehicleControlList] = useState<UserVehicleControl[] | undefined>();
    const [unityVehicleControlTimestamp, setUnityVehicleControlTimestamp] = useState<AppTimestamp | undefined>()
    const [refreshEvent, setRefreshEvent] = useState<boolean | null>(null);

    async function fetchUnityVehicleControlList(
        timestamp: number,
        forceRefresh: boolean = false
    ) {
        if (fetchState?.isLoading()) return;
        setFetchState(Response.loading());
        try {
            const result = await VehicleControlRepository.getList(
                timestamp,
                unity.id,
                forceRefresh
            );
            setVehicleControlList(result?.vehicleControlList);
            setUnityVehicleControlTimestamp(result?.timestamp)
            setFetchState(Response.success(true));
        } catch (e: any) {
            setFetchState(Response.failure(e));
        }
    }

    function onFetchStateReceived() {
        setFetchState(null);
    }

    function requestRefreshEvent() {
        setRefreshEvent(true);
    }

    function onRefreshEventCompleted() {
        setRefreshEvent(null);
    }

    return {
        fetchState,
        onFetchStateReceived,
        vehicleControlList,
        unityVehicleControlTimestamp,
        fetchUnityVehicleControlList,
        refreshEvent,
        requestRefreshEvent,
        onRefreshEventCompleted,
    };
}
