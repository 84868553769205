import { FC } from "react";
import { IconButton } from "rsuite";
import { Plus, Reload } from "@rsuite/icons";
import { PrimaryHeader } from "../../../../components/header/primary";

export const RolePaneHeader: FC<{
  addDisabled?: boolean;
  onAddClick: () => void;
  refreshDisabled?: boolean;
  onRefreshClick: () => void;
  totalCount?: number;
  stylePage?: any;
}> = ({
  addDisabled,
  onAddClick,
  refreshDisabled,
  onRefreshClick,
  totalCount,
  stylePage,
}) => {
  return (
    <div style={stylePage}>
      <PrimaryHeader
        description={`${totalCount} en total`}
        breadcrumbItems={[
          {
            active: true,
            label: "Cargos de Usuario",
          },
        ]}
        title={"Cargos de Usuario"}
        actions={[
          <IconButton
            appearance={"primary"}
            size={"sm"}
            icon={<Plus />}
            disabled={addDisabled}
            onClick={onAddClick}
          >
            Nuevo
          </IconButton>,
          <IconButton
            appearance={"primary"}
            size={"sm"}
            disabled={refreshDisabled}
            onClick={onRefreshClick}
            icon={<Reload />}
          >
            Actualizar
          </IconButton>,
        ]}
      />
    </div>
  );
};
