import {FC} from "react";
import {UserIncident} from "../../../../../domain/incident/Incident";
import {Modal} from "rsuite";
import {UserIncidentView} from "../../../incident/components/view";

type Props = {
    incident: UserIncident
    onClose: () => void
}

export const UserIncidentPreviewModal: FC<Props> = ({incident, onClose}) => {

    return <Modal open={true} onClose={onClose}>
        <Modal.Header>
            <Modal.Title>Vista Previa</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <UserIncidentView incident={incident}/>
        </Modal.Body>
    </Modal>
}
