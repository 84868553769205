import {TableColumnRelation} from "../../ui/context/TableContext";

export type ZoneTableColumns = {
    selection: string;
    id: string;
    name: string;
    actions: string;
};

export const ZoneTableSchema: TableColumnRelation<ZoneTableColumns> = {
    large: {
        breakpoints: ["lg", "xl", "2xl"],
        columns: [
            {
                name: "selection",
                label: "",
                weight: 0.1
            },
            {
                name: "id",
                label: "ID",
                weight: 0.3
            },
            {
                name: "name",
                label: "Nombre",
                weight: 0.6
            },
        ]
    }
};
