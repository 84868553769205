import { FC, MouseEventHandler } from "react";
import { AppManagerEvent } from "../../../../../domain/event/ManagerEvent";
import { EmptyState } from "../../../../components/state/empty";
import { formatDateTime } from "../../../../../util/date";
import { Link } from "react-router-dom";
import { Timeline } from "rsuite";

type Props = {
  items?: AppManagerEvent[];
  onClick: (item: AppManagerEvent) => void;
};

export const ManagerEventListData: FC<Props> = ({
                                                  items,
                                                  onClick
                                                }) => {
  const onClickListener: MouseEventHandler<HTMLDivElement> = (e) => {
    const index = e.currentTarget.dataset["index"];
    onClick(items![Number.parseInt(index!)]);
  };

  return (items && items.length > 0) ? <Timeline isItemActive={Timeline.ACTIVE_FIRST}>

    {
      items!.map((it, index) => <Timeline.Item key={it.reference}
                                               onClick={onClickListener}
                                               data-index={index}
                                               className={"flex flex-col gap-2"}>
        <p className={"text-sm text-black"}>
          El {formatDateTime(it.date)}
        </p>
        <p>{it.title}</p>
        <span>{it.message}</span>
        <div className={"flex flex-row flex-wrap truncate gap-2"}>
          <span>Realizado por</span> <Link to={`/dashboard/users/${it.uid}/main`}>
          {it.user?.displayName}
        </Link>
        </div>

      </Timeline.Item>)
    }
  </Timeline> : <div className={"w-full flex justify-center"}>
    <EmptyState />
  </div>;
};
