import React, { FC } from "react";
import { ListChildComponentProps } from "react-window";
import { IconButton } from "rsuite";
import { FaIcon } from "../../../components/fontawesome/icon";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { AttendancePair } from "../../../../domain/attendance/Attendance";
import { AttendanceListRowEntry } from "./entry";
import { AttendanceListRowDeparture } from "./departure";
import { AttendanceListRowOperator } from "./operator";
import { AttendanceListRowObservation } from "./observation";

export type AttendanceListRowProps = {
  attendanceList: AttendancePair[];
  onPreviewClicked: React.MouseEventHandler<HTMLElement>;
  onSelect: React.MouseEventHandler<HTMLDivElement>;
};

export const AttendanceRow: FC<
  ListChildComponentProps<AttendanceListRowProps>
> = ({ data, index, style }) => {
  const { onPreviewClicked, onSelect, attendanceList } = data;
  const item = attendanceList[index];
  return (
    <div style={style} className={"pb-2"}>
      <div
        data-index={index}
        onClick={onSelect}
        className={
          "w-full h-full bg-white shadow rounded-lg p-2 flex flex-col justify-between content-between"
        }
      >
        <div className={"flex w-full flex-col gap-2"}>
          <AttendanceListRowEntry item={item} />
          <AttendanceListRowDeparture item={item} />
          <AttendanceListRowOperator item={item} />
          <AttendanceListRowObservation item={item} />
        </div>
        <IconButton
          appearance={"primary"}
          icon={<FaIcon icon={faEye} />}
          onClick={onPreviewClicked}
        >
          Vista Previa
        </IconButton>
      </div>
    </div>
  );
};
