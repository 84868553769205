import React, { FC, Fragment, useCallback, useEffect, useState } from "react";
import { formatDate } from "../../../../util/date";
import { DatePicker, IconButton, Loader } from "rsuite";
import { FaIcon } from "../../../components/fontawesome/icon";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { toRelativeCalendar } from "../../../../lib/date";
import { Unity } from "../../../../domain/unity";
import { useUnityIncidentViewModel } from "../../../../viewmodel/unity/UnityIncident";
import { UnityIncidentData } from "./components/data";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DateTime } from "luxon";
import { toNumber } from "lodash";
import { useQueryNavigate } from "../../../../hook/navigate";
import { UserIncident } from "../../../../domain/incident/Incident";
import { UnityIncidentModal } from "./components/modal";

type Props = {
  unity: Unity;
};

export const UnityIncidentTabContent: FC<Props> = ({ unity }) => {
  const [searchParams] = useSearchParams();
  const initialTimestamp = searchParams.get("timestamp");
  const [selectedTime, setSelectedTime] = useState(
    !!initialTimestamp
      ? DateTime.fromMillis(toNumber(initialTimestamp)).toJSDate()
      : new Date()
  );
  const queryNavigate = useQueryNavigate();
  const navigate = useNavigate();
  const {
    fetchState,
    fetchUserIncidentList,
    onFetchStateReceived,
    userIncidentList,
    userIncidentTimestamp,
    refreshEvent,
    requestRefreshEvent,
    onRefreshEventCompleted,
    selectedItem,
    requestSelectItem,
    onSelectItemCompleted,
  } = useUnityIncidentViewModel(unity);

  /* eslint-disable */
  useEffect(() => {
    void fetchUserIncidentList(selectedTime.getTime());
  }, [selectedTime]);

  useEffect(() => {
    if (fetchState && !fetchState.isLoading()) {
      if (fetchState.isFailed()) {
        window.toast.error(
          "Ocurrió un error al obtener la lista de incidencias del usuario."
        );
        console.log(fetchState?.error);
      }
      onFetchStateReceived();
    }
  }, [fetchState]);

  useEffect(() => {
    if (refreshEvent) {
      void fetchUserIncidentList(selectedTime.getTime(), true);
      onRefreshEventCompleted();
    }
  }, [refreshEvent]);

  const onPreview = useCallback((item: UserIncident) => {
    requestSelectItem(item);
  }, []);

  const onSelect = useCallback((item: UserIncident) => {
    navigate(
      `/dashboard/incident?reference=${encodeURIComponent(item.reference)}`
    );
  }, []);
  /* eslint-enable */
  return (
    <div className={"relative w-full overflow-x-hidden"}>
      {selectedItem ? (
        <UnityIncidentModal
          incident={selectedItem}
          onClose={onSelectItemCompleted}
        />
      ) : null}
      <div
        className={
          "flex-none w-full flex flex-row flex-wrap gap-2 p-2 border-b-2 border-neutral-100 overflow-x-hidden items-center justify-between"
        }
      >
        <span className={"text-lg font-normal text-neutral-600 uppercase"}>
          {formatDate(selectedTime)}
        </span>
        <div className={"flex flex-row flex-wrap items-center gap-2"}>
          <div className={"flex flex-row flex-wrap gap-2 items-center z-0"}>
            <span>Seleccionar fecha</span>
            <DatePicker
              value={selectedTime}
              onChange={(newDate) => {
                if (
                  !!newDate &&
                  newDate?.getTime() !== selectedTime.getTime()
                ) {
                  setSelectedTime(newDate);
                  queryNavigate({
                    timestamp: newDate.getTime(),
                  });
                }
              }}
            />
          </div>
          <IconButton
            appearance={"ghost"}
            size={"sm"}
            onClick={requestRefreshEvent}
            icon={<FaIcon icon={faRefresh} />}
          >
            Actualizar
          </IconButton>
        </div>
      </div>
      {fetchState?.isLoading() ? (
        <Loader center backdrop size={"sm"} content={"Cargando..."} />
      ) : (
        <Fragment>
          {userIncidentTimestamp ? (
            <div className={"w-full flex-none p-2"}>
              <p>
                <span className={"text-neutral-400 uppercase"}>
                  Última actualización:{" "}
                </span>
                {toRelativeCalendar(userIncidentTimestamp.timestamp, true)}
              </p>
            </div>
          ) : null}
          <UnityIncidentData
            incidentList={userIncidentList}
            onPreviewClick={onPreview}
            onSelect={onSelect}
          />
        </Fragment>
      )}
    </div>
  );
};
