import { InputDataList } from "../../../../components/modal/FormModal";
import { Input, InputNumber, Schema, Toggle } from "rsuite";
import { isNotBlank } from "../../../../../util/validation";
import { PostZoneDto } from "../../../../../network/patrol/Zone";
import { ReasonInputSelector } from "../../../../components/input/ReasonSelector";

export const NewZoneInputSchema = Schema.Model<Partial<PostZoneDto>>(
  {
    name: Schema.Types.StringType().isRequired(
      "La zona debe tener un nombre válido."
    ).addRule(isNotBlank(4), "La zona debe tener un nombre válido. de al menos 4 letras."),
    description: Schema.Types.StringType(),
    ending: Schema.Types.NumberType(),
    starting: Schema.Types.NumberType(),
    multiple: Schema.Types.BooleanType(),
    reason: Schema.Types.StringType().isRequired(
      "Debe especificar el motivo."
    ).addRule(isNotBlank(10), "El motivo de la edición debe tener al menos 10 letras.")
  }
);

export const NewZoneInputList: InputDataList<PostZoneDto> = [
  {
    name: "multiple",
    label: "Creación Múltiple",
    type: "custom",
    defaultValue: false,
    provide: ({ values, updateValue }) => {
      return (
        <Toggle
          onChange={(v) => updateValue("multiple", v)}
          checked={values["multiple"] === true}
        />
      );
    }
  },
  {
    name: "name",
    label: "Nombre",
    type: "text",
    help: "En caso de crear varios, se asignará como prefijo a cada nombre de zona.",
    defaultValue: undefined
  },
  {
    name: "description",
    type: "custom",
    provide: ({ values, updateValue }) => {
      const multipleCreation = values["multiple"] === true;
      return multipleCreation ? null : (
        <div className={"flex flex-col gap-0.5"}>
          <span>Descripción (Opcional)</span>
          <Input
            value={values["description"]}
            onChange={(newValue) => {
              updateValue("description", newValue);
            }}
          />
        </div>
      );
    },
    defaultValue: undefined
  },
  {
    name: "starting",
    type: "custom",
    defaultValue: undefined,
    provide: ({ values, updateValue }) => {
      const multipleCreation = values["multiple"] === true;
      return multipleCreation ? (
        <div className={"flex flex-col gap-0.5"}>
          <span>Inicio de Rango</span>
          <div className={"flex flex-row gap-2 items-center justify-start"}>
            <InputNumber
              style={{
                maxWidth: 100
              }}
              value={values["starting"]}
              onChange={(newValue) => {
                const toNumber = Number.parseInt(String(newValue));
                updateValue("starting", toNumber);
              }}
            />
            <span>hasta</span>
            <InputNumber
              style={{
                maxWidth: 100
              }}
              value={values["ending"]}
              onChange={(newValue) => {
                const toNumber = Number.parseInt(String(newValue));
                updateValue("ending", toNumber);
              }}
            />
          </div>
        </div>
      ) : null;
    }
  },
  {
    name: "reason",
    label: "Motivo de cambio",
    type: "custom",
    help: "Detalle el motivo de la eliminación",
    defaultValue: undefined,
    provide: (props) => <ReasonInputSelector {...props as any}
                                             options={["Nuevo cliente", "Adición de cliente existente"]} />
  }
];
