import { useCallback, useEffect, useState } from "react";
import { keysIn } from "lodash";

export function useBreakpoints(): AvailableTailwindSize["sizes"] {
  const [matchSize, setMatchSize] =
    useState<AvailableTailwindSize["sizes"]>("xs");

  /* eslint-disable */
  const windowCallback = useCallback(function () {
    const currentWidth = window.innerWidth;
    for (let key of keysIn(TailwindScreens)) {
      if (
        currentWidth >= TailwindScreens[key as AvailableTailwindSize["sizes"]]
      ) {
        setMatchSize(key as AvailableTailwindSize["sizes"]);
        break;
      }
    }
  }, []);

  useEffect(() => {
    windowCallback();
    window.addEventListener("resize", windowCallback);
    return () => {
      window.removeEventListener("resize", windowCallback);
    };
  }, []);
  /* eslint-enable */
  return matchSize;
}

export const TailwindScreens: {
  [key in AvailableTailwindSize["sizes"]]: number;
} = {
  "2xl": 1536,
  xl: 1280,
  lg: 1024,
  md: 768,
  sm: 640,
  xs: 0,
};

export class AvailableTailwindSize {
  static all: AvailableTailwindSize["sizes"][] = [
    "xs",
    "sm",
    "md",
    "lg",
    "xl",
    "2xl",
  ];
  static mobile: AvailableTailwindSize["sizes"][] = ["xs", "sm", "md"];
  sizes!: "xs" | "sm" | "md" | "lg" | "xl" | "2xl";
}
