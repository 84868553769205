import { useState } from "react";
import { Response } from "../../domain/app/Response";
import { PentrackerUser } from "../../domain/user/User";
import { TicketRepository } from "../../data/repository/TicketRepository";
import UserRepository from "../../data/repository/UserRepository";
import {
  AppTicketFileType,
  PendingTicketFile,
  TicketFileType,
} from "../../domain/ticket/UserTicket";
import { LocalErrorLogRepository } from "../../data/repository/LocalErrorLogRepository";

export function useUserTicketContentViewModel(container: TicketFileType) {
  const [fetchState, setFetchState] = useState<Response<boolean> | null>(null);
  const [addState, setAddState] = useState<Response<{
    uploaded: Array<PendingTicketFile>;
    messages: string[];
  }> | null>(null);
  const [searchState, setSearchState] = useState<Response<boolean> | null>(
    null
  );
  const [list, setList] = useState<AppTicketFileType[] | undefined>();
  const [refreshEvent, setRefreshEvent] = useState<boolean | null>(null);
  const [addEvent, setAddEvent] = useState<boolean | null>(null);

  const [searchEvent, setSearchEvent] = useState<boolean | null>(null);
  // const [searchResults, setSearchResults] = useState<
  //   AppTicketFileType[] | undefined
  // >();
  const [searchResults, setSearchResults] = useState<AppTicketFileType[]>();
  async function fetchContentList(forceRefresh: boolean = false) {
    if (fetchState?.isLoading()) return;
    setFetchState(Response.loading());
    try {
      const list = await TicketRepository.fetchContainerContentList(
        container.path,
        forceRefresh
      );
      setList(list);
      setFetchState(Response.success(true));
    } catch (e: any) {
      setFetchState(Response.failure(e));
    }
  }

  function onFetchStateReceived() {
    setFetchState(null);
  }

  function requestRefreshEvent() {
    setRefreshEvent(true);
  }

  function onRefreshEventCompleted() {
    setRefreshEvent(null);
  }

  function requestAddEvent() {
    setAddEvent(true);
  }

  function onAddEventCompleted() {
    setAddEvent(null);
  }

  function requestSearchEvent() {
    setSearchEvent(true);
  }

  function onSearchEventCompleted() {
    setSearchEvent(null);
  }

  async function postTickets(tickets: Array<PendingTicketFile>) {
    setAddState(Response.loading());
    try {
      const result = await TicketRepository.postTickets(
        container.name,
        tickets
      );
      setAddState(Response.success(result));
    } catch (e: any) {
      setAddState(Response.failure(e));
    }
  }

  async function searchTicket(keyword: string) {
    setSearchState(Response.loading());
    try {
      // search keyword "user"
      const resultUser = await UserRepository.searchUser(keyword);
      // match ticket
      const resultTicket = await TicketRepository.matchTicket(
        container.path,
        resultUser
      );
      setSearchResults(resultTicket);
      setSearchState(Response.success(true));
    } catch (e: any) {
      const label = `Error al buscar usuario: '${keyword}'`;
      await LocalErrorLogRepository.registerError(label, e);
      setSearchState(Response.failure(new Error(label)));
    }
  }

  function onAddStateReceived() {
    setAddState(null);
  }

  return {
    fetchState,
    onFetchStateReceived,
    list,
    fetchContentList,
    refreshEvent,
    requestRefreshEvent,
    onRefreshEventCompleted,
    requestAddEvent,
    onAddEventCompleted,
    addEvent,
    searchState,
    searchTicket,
    searchResults,
    requestSearchEvent,
    onSearchEventCompleted,
    searchEvent,
    postTickets,
    addState,
    onAddStateReceived,
  };
}
