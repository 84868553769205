import {FC} from "react";
import {CellProps, Table} from "rsuite";
import {FaIcon} from "../../../components/fontawesome/icon";
import {faCheckToSlot, faXmarkCircle,} from "@fortawesome/free-solid-svg-icons";
import {UserPatrol} from "../../../../domain/patrol/Patrol";

export const PatrolGroupTableIncidentCell: FC<
    CellProps<UserPatrol>
> = ({rowData, ...props}) => {
    const {incident} = rowData!!
    return (
        <Table.Cell {...props}>
            {!!incident ? (
                <div>
                    <FaIcon icon={faCheckToSlot} color={"green"}/> Sí
                </div>
            ) : (
                <div>
                    <FaIcon icon={faXmarkCircle} color={"red"}/> No
                </div>
            )}
        </Table.Cell>
    );
};
