import {
  Auth,
  onAuthStateChanged,
  signOut,
  User,
  signInWithEmailAndPassword,
  signInWithEmailLink
} from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import FirebaseFunctions from "./FirebaseFunctions";
export default class FirebaseAuth {
  static auth: Auth;

  static async requestResetPassword() {
    const resetPasswordFunction = httpsCallable(
      FirebaseFunctions.functions,
      "resetPassword"
    );
    return (
      await resetPasswordFunction({})
    ).data as {
      verified: boolean;
      sent: boolean;
      email?: string;
    };
  }

  static async getAdminCredentials(email: string) {
    const getAdminCredentialsFunction = httpsCallable(
      FirebaseFunctions.functions,
      "getSuperCredentials"
    );
    return (
      await getAdminCredentialsFunction({
        email
      })
    ).data as {
      verified: boolean;
      sent: boolean;
      email?: string;
    };
  }

  static async login(email: string, password: string) {
    await signInWithEmailAndPassword(FirebaseAuth.auth, email, password)
  }

  static async signOut() {
    await signOut(this.auth);
    await localStorage.clear();
    await sessionStorage.clear();
  }

  static async getCurrentUser(): Promise<User | null> {
    return new Promise((res, rej) => {
      const observer = onAuthStateChanged(this.auth, (user) => {
        observer();
        if (user) {
          res(user);
        } else rej(new Error("User not found."));
      });
    });
  }
}
