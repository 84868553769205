import {FC} from "react";
import {IconButton} from "rsuite";
import {Plus, Reload} from "@rsuite/icons";
import {PrimaryHeader} from "../../../../components/header/primary";

export const AccountTypeHeader: FC<{
    onAddClicked: () => void;
    onRefreshClicked: () => void;
    addDisabled?: boolean;
    refreshDisabled?: boolean;
}> = ({onAddClicked, onRefreshClicked, addDisabled, refreshDisabled}) => {
    return (
        <PrimaryHeader
            breadcrumbItems={[
                {
                    label: "Etiquetas",
                    active: true,
                },
            ]}
            title={"Etiquetas de Operador"}
            actions={[
                <IconButton
                    icon={<Plus/>}
                    size={"sm"}
                    appearance={"primary"}
                    disabled={addDisabled}
                    onClick={onAddClicked}
                >
                    Nuevo
                </IconButton>,
                <IconButton
                    size={"sm"}
                    icon={<Reload/>}
                    appearance={"primary"}
                    disabled={refreshDisabled}
                    onClick={onRefreshClicked}
                >
                    Actualizar
                </IconButton>,
            ]}
        />
    );
};
