import {DateTime, DateTimeUnit} from "luxon";

export const toRelativeCalendar = (date: Date | number, showTime?: boolean) => {
    let dateTime =
        typeof date === "number"
            ? DateTime.fromMillis(date)
            : DateTime.fromJSDate(date);
    const relativeCalendar = dateTime.toRelativeCalendar({
        locale: "es",
    });
    return showTime ? `${relativeCalendar}, ${toTime(date)}` : relativeCalendar;
};

export const toTime = (date: Date | number) => {
    let dateTime =
        typeof date === "number"
            ? DateTime.fromMillis(date)
            : DateTime.fromJSDate(date);
    return dateTime.toFormat("h:mm a");
};

export const getDateStartTimestamp = (date: Date | number): number => {
    let dateTime =
        typeof date === "number"
            ? DateTime.fromMillis(date)
            : DateTime.fromJSDate(date);
    return dateTime.startOf("day").toMillis();
};

export function getCalendar(
    fromDate: Date,
    fromStartOf: DateTimeUnit,
    toEndOf: DateTimeUnit
): Date[] {
    const dateTime = DateTime.fromJSDate(fromDate);
    const copy = dateTime.startOf(fromStartOf).toJSDate();
    copy.setUTCHours(0);
    copy.setUTCMinutes(0);
    copy.setUTCSeconds(0);
    copy.setUTCMilliseconds(0);
    const copyDateTime = DateTime.fromJSDate(copy);

    const lastDate = dateTime.endOf(toEndOf).toJSDate();
    lastDate.setUTCHours(0);
    lastDate.setUTCMinutes(0);
    lastDate.setUTCSeconds(0);
    lastDate.setUTCMilliseconds(0);
    const lastDateTime = DateTime.fromJSDate(lastDate);
    const diff = lastDateTime.diff(copyDateTime, ["days"]);
    if (diff.days <= 0) {
        return [copy];
    } else {
        const result = [];
        let count = 0;
        while (count <= diff.days) {
            const currentDateTime = copyDateTime.plus({
                day: count,
            });
            const currentDate = currentDateTime.toJSDate();
            currentDate.setUTCHours(0);
            currentDate.setUTCMinutes(0);
            currentDate.setUTCSeconds(0);
            currentDate.setUTCMilliseconds(0);
            result.push(currentDate);
            count += 1;
        }
        return result;
    }
}
