import { listAll, ref, StorageReference } from "firebase/storage";
import { FirebaseStorageSource } from "../source/FirebaseStorageSource";
import { isEmpty, upperCase } from "lodash";
import {
  AppTicketFileType,
  PendingTicketFile,
  TicketFileType,
  UserTicketMonths,
} from "../../domain/ticket/UserTicket";
import { TicketFileDao } from "../database/dao/TicketFile";
import { getFileTypeMetadata } from "../../util/file";
import { LocalErrorLogRepository } from "./LocalErrorLogRepository";
import { UserStoreDao } from "../database/dao/User";
import Fuse from "fuse.js";
import { PentrackerUser } from "../../domain/user/User";

export function sortTicketFilesByStringDate(
  a: TicketFileType,
  b: TicketFileType
) {
  const [aMonth, aYear] = a.name.split("-");
  const [bMonth, bYear] = b.name.split("-");

  if (aYear < bYear) {
    return 1;
  } else if (aYear > bYear) {
    return -1;
  } else {
    return UserTicketMonths.indexOf(bMonth) - UserTicketMonths.indexOf(aMonth);
  }
}

export class TicketRepository {
  private static ticketsRef: StorageReference | undefined;

  private static getRef() {
    if (!this.ticketsRef) {
      this.ticketsRef = ref(FirebaseStorageSource.storage, "tickets/");
    }
    return this.ticketsRef;
  }

  static async fetchContainerList(
    forceRefresh: boolean = false
  ): Promise<void> {
    let local = await TicketFileDao.getAllContainer();
    if (isEmpty(local) || forceRefresh) {
      const remote = await listAll(this.getRef());
      await TicketFileDao.deleteAllContainers();
      await TicketFileDao.addContainer(
        ...remote.prefixes.map((it) => ({
          name: it.name,
          path: it.fullPath,
          temp: 0,
        }))
      );
    }
  }

  static async fetchContainerContentList(
    folderPath: string,
    forceRefresh: boolean = false
  ): Promise<Array<AppTicketFileType> | undefined> {
    let local = await TicketFileDao.getAllFiles(folderPath);
    let users = await UserStoreDao.getList();
    if (isEmpty(local) || forceRefresh) {
      await TicketFileDao.deleteAllFiles(folderPath);
      const remote = await listAll(
        ref(FirebaseStorageSource.storage, folderPath)
      );
      if (remote) {
        const container = await TicketFileDao.getContainer(folderPath);
        if (container && container.temp === 1) {
          await TicketFileDao.deleteContainer(container.path);
          await TicketFileDao.addContainer({
            ...container,
            temp: 0,
          });
        }
      }
      await TicketFileDao.addFile(
        ...remote.items.map((it) => ({
          name: it.name,
          path: it.fullPath,
          temp: 0,
        }))
      );
      local = await TicketFileDao.getAllFiles(folderPath);
    }
    const searchFn = new Fuse(users, {
      keys: ["uid"],
      includeScore: false,
      threshold: 0,
      distance: 0,
      minMatchCharLength: 1,
    });
    return local.map((it) => {
      const userId = it.name.split(".pdf")[0];
      const user = searchFn.search(userId);
      return {
        ...it,
        user: user[0]?.item,
      };
    });
  }

  static async addContainer(
    month: (typeof UserTicketMonths)[number],
    year: string | number
  ) {
    if (!month || !year) throw new Error("Solicitud inválida.");
    if (!UserTicketMonths.includes(month)) throw new Error("Mes inválido");
    const fullYear = `${year}`;
    if (!RegExp(/^(\d{4})$/).test(fullYear)) throw new Error("Año inválido");
    const folder = `${upperCase(month)}-${year}`;
    const local = await this.getContainer(folder);
    if (local) {
      throw new Error("Esta carpeta ya existe");
    } else {
      await TicketFileDao.addContainer({
        name: folder,
        path: `tickets/${folder}`,
        temp: 1,
      });
    }
  }

  static async getContainer(name: string) {
    return TicketFileDao.getContainerByName(name);
  }

  static async postTickets(
    containerName: string,
    tickets: Array<PendingTicketFile>
  ) {
    const messages: string[] = [];
    const results: PendingTicketFile[] = [];
    const tasks = tickets.map(async (ticket) => {
      const filePath = `tickets/${containerName}/${ticket.user!!.uid}.pdf`;
      try {
        await FirebaseStorageSource.uploadBytes(
          filePath,
          ticket.file,
          getFileTypeMetadata(ticket.file)
        );
        results.push(ticket);
      } catch (e: any) {
        await LocalErrorLogRepository.registerError(
          `Publicar boleta ${filePath}`,
          e
        );
        messages.push(
          `Ocurrió un error al añadir la boleta de pago en ${
            ticket.user!!.uid
          }, ruta: ${filePath}`
        );
      }
    });
    await Promise.all(tasks);
    return {
      messages,
      uploaded: results,
    };
  }

  static async matchTicket(
    folderPath: string,
    searchList: PentrackerUser[] | undefined
  ): Promise<Array<AppTicketFileType> | undefined> {

    let local = await TicketFileDao.getAllFiles(folderPath);

    if (searchList !== undefined) {
      const searchFn = new Fuse(searchList, {
        keys: ["uid"],
        includeScore: false,
        threshold: 0,
        distance: 0,
        minMatchCharLength: 1,
      });

      return local
        .map((it) => {
          const userId = it.name.split(".pdf")[0];
          const user = searchFn.search(userId);

          if (user.length > 0) {
            return {
              ...it,
              user: user[0]?.item,
            };
          }
          return null;
        })
        .filter((it) => it !== null) as Array<AppTicketFileType>;
    }
  }
}
