import { FC } from "react";
import { AttendancePair } from "../../../../domain/attendance/Attendance";
import { Tag } from "rsuite";
import { FaIcon } from "../../../components/fontawesome/icon";
import {
  faBriefcaseClock,
  faBuilding,
  faCalendar,
  faInfoCircle,
  faLocationCrosshairs,
  faMapLocation,
  faUserGear,
} from "@fortawesome/free-solid-svg-icons";
import { toRelativeCalendar } from "../../../../lib/date";
import { Link } from "react-router-dom";
import { GeocodeRevealButton } from "../../../components/geolocation/button";
import { PathImageLoader } from "../../../components/imageloader/PathImageLoader";

export const UserAttendanceView: FC<{
  attendance: AttendancePair;
}> = ({ attendance }) => {
  const { entry, departure } = attendance;

  console.log("entry", entry);

  return (
    <div
      className={
        "w-full h-full overflow-auto flex flex-row flex-wrap gap-4 py-4"
      }
    >
      <p className={"px-2 m-0 text-neutral-900 text-lg uppercase grow"}>
        Usuario: {entry.owner?.displayName || entry.ownerUid}
      </p>
      <div className={"w-full flex flex-col p-2 rounded items-start gap-2"}>
        <Tag color={"green"}>Ingreso</Tag>
        <div className={"flex flex-col flex-wrap w-fit gap-4"}>
          <div className={"flex flex-col gap-2"}>
            <p className={"text-neutral-400 text-md uppercase grow"}>GENERAL</p>
            <div
              className={
                "w-full flex flex-row flex-wrap gap-x-6 gap-y-2 items-center"
              }
            >
              <span>
                <FaIcon icon={faCalendar} className={"mr-2 text-neutral-400"} />
                {toRelativeCalendar(entry.timestamp, true)}
              </span>
              <div>
                <FaIcon icon={faBuilding} className={"mr-2 text-neutral-400"} />
                <Link to={`/dashboard/unity/${entry.unity!!.id}`}>
                  {entry.unity!!.label}
                </Link>
              </div>
              <p className={"m-0 p-0"}>
                <FaIcon icon={faUserGear} className={"mr-2 text-neutral-400"} />
                {entry.accountType ? (
                  <span>{entry.accountType.label}</span>
                ) : (
                  <span className={"italic"}>Desconocido</span>
                )}
              </p>
              <p className={"m-0 p-0"}>
                <FaIcon
                  icon={faBriefcaseClock}
                  className={"mr-2 text-neutral-400"}
                />
                {entry.shift ? (
                  <span>{entry.shift.label}</span>
                ) : (
                  <span className={"italic"}>Desconocido</span>
                )}
              </p>
            </div>
          </div>
          <div className={"flex flex-col gap-2"}>
            <p className={"text-neutral-400 text-md uppercase grow"}>
              UBICACIÓN
            </p>
            <div className={"flex flex-row flex-wrap gap-x-6 gap-y-2"}>
              <p className={"p-0 m-0"}>
                <FaIcon
                  icon={faLocationCrosshairs}
                  className={"mr-2 text-neutral-400"}
                />
                {`${entry.location._lat}, ${entry.location._long}`}
              </p>
              {entry.location ? (
                <p className={"p-0 m-0"}>
                  <FaIcon
                    icon={faMapLocation}
                    className={"mr-2 text-neutral-400"}
                  />
                  <GeocodeRevealButton
                    location={{
                      lat: entry.location._lat,
                      lng: entry.location._long,
                    }}
                  />
                </p>
              ) : null}
            </div>
          </div>

          <div className={"flex flex-col gap-2 justify-start items-start"}>
            <p className={"text-neutral-400 text-md uppercase grow"}>
              FOTO DE INGRESO
            </p>
            <PathImageLoader
              path={entry.ownerPhoto}
              className={"object-cover aspect-square rounded-2xl max-h-96"}
            />
          </div>
          <div className={"flex flex-col gap-2"}>
            <p className={"text-neutral-400 text-md uppercase grow"}>
              OBSERVACIÓN
            </p>
            {entry.observation ? (
              <div className={"flex flex-row flex-wrap gap-6"}>
                <span>
                  <FaIcon
                    icon={faInfoCircle}
                    className={"mr-2 text-neutral-400"}
                  />
                  {entry.observation.details}
                </span>
                {entry.observation.media ? (
                  <div className={"w-full overflow-x-auto flex flex-row gap-2"}>
                    {entry.observation.media.map((mediaItem, i) => (
                      <PathImageLoader
                        key={`pitem-${i}`}
                        path={mediaItem}
                        className={
                          "object-cover aspect-square rounded-2xl max-h-96"
                        }
                      />
                    ))}
                  </div>
                ) : null}
              </div>
            ) : (
              <span className={"italic"}>No registró ninguna observación.</span>
            )}
          </div>
        </div>
      </div>

      <div className={"w-full flex flex-col p-2 rounded items-start gap-2"}>
        <Tag color={"green"}>Salida</Tag>
        {departure ? (
          <div className={"flex flex-col flex-wrap w-fit gap-4"}>
            <div className={"flex flex-col gap-2"}>
              <p className={"text-neutral-400 text-md uppercase grow"}>
                GENERAL
              </p>
              <div className={"flex flex-row flex-wrap gap-x-6 gap-y-2"}>
                <span>
                  <FaIcon
                    icon={faCalendar}
                    className={"mr-2 text-neutral-400"}
                  />
                  {toRelativeCalendar(departure.timestamp, true)}
                </span>
                <div>
                  <FaIcon
                    icon={faBuilding}
                    className={"mr-2 text-neutral-400"}
                  />
                  <Link to={`/dashboard/unity/${departure.unity!!.id}`}>
                    {departure.unity!!.label}
                  </Link>
                </div>
                <p className={"m-0 p-0"}>
                  <FaIcon
                    icon={faUserGear}
                    className={"mr-2 text-neutral-400"}
                  />
                  {departure.accountType ? (
                    <span>{departure.accountType.label}</span>
                  ) : (
                    <span className={"italic"}>Desconocido</span>
                  )}
                </p>
                <p className={"m-0 p-0"}>
                  <FaIcon
                    icon={faBriefcaseClock}
                    className={"mr-2 text-neutral-400"}
                  />
                  {departure.shift ? (
                    <span>{departure.shift.label}</span>
                  ) : (
                    <span className={"italic"}>Desconocido</span>
                  )}
                </p>
              </div>
            </div>
            <div className={"flex flex-col gap-2"}>
              <p className={"text-neutral-400 text-md uppercase grow"}>
                UBICACIÓN
              </p>
              <div
                className={
                  "flex flex-row flex-wrap gap-x-6 gap-y-2 items-center"
                }
              >
                <p className={"p-0 m-0"}>
                  <FaIcon
                    icon={faLocationCrosshairs}
                    className={"mr-2 text-neutral-400"}
                  />
                  {`${departure.location._lat}, ${departure.location._long}`}
                </p>
                {departure.location ? (
                  <p className={"p-0 m-0"}>
                    <FaIcon
                      icon={faMapLocation}
                      className={"mr-2 text-neutral-400"}
                    />
                    <GeocodeRevealButton
                      location={{
                        lat: departure.location._lat,
                        lng: departure.location._long,
                      }}
                    />
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        ) : (
          <p className={"italic"}>El usuario aún no registró su salida.</p>
        )}
      </div>
    </div>
  );
};
