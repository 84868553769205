import { FC, useContext, useEffect } from "react";
import { Loader, SelectPicker } from "rsuite";
import { toNumber } from "lodash";
import { useUserRoleViewModel } from "../../../../../../viewmodel/role/UserRole";
import { CustomInputProps } from "../../../../../components/modal/FormModal";
import { NewUser } from "../../../../../../domain/user/User";
import { UserAuthContext } from "../../../../../context/UserContext";

const Component: FC<CustomInputProps<NewUser>> = ({
  name,
  updateValue,
  values,
}) => {
  const { appUser } = useContext(UserAuthContext);
  const viewModel = useUserRoleViewModel(appUser);

  /* eslint-disable */
  useEffect(() => {
    void viewModel.fetchList();
  }, []);

  useEffect(() => {
    if (viewModel.fetchState && !viewModel.fetchState.loading) {
      if (viewModel.fetchState.isFailed()) {
        window.toast.error(
          "Ocurrió un error al obtener la lista de cargos de usuario."
        );
      }
    }
  }, [viewModel.fetchState]);

  /* eslint-enable */
  return (
    <div className={"flex flex-col gap-1"}>
      <p>Cargo del Usuario</p>
      {viewModel.fetchState?.loading ? (
        <Loader content={"Cargando..."} />
      ) : (
        <SelectPicker
          block
          data={viewModel.allowedRoles || []}
          onSelect={(selection) => {
            updateValue(name, toNumber(selection));
          }}
          onClean={() => {
            updateValue(name, null);
          }}
          labelKey={"label"}
          valueKey={"id"}
          value={values[name]}
          cleanable
        />
      )}
    </div>
  );
};

export const NewUserRoleSelectInput: FC<CustomInputProps<NewUser>> = (
  props
) => {
  return <Component {...props} />;
};
