import { InputDataList } from "../../../../components/modal/FormModal";
import { CharacterLock, Email, Phone, UserInfo } from "@rsuite/icons";
import { EditUserUploadPhotoInput } from "../components/uploadphotoinput";
import { Toggle } from "rsuite";
import { NewUser } from "../../../../../domain/user/User";
import { NewUserRoleSelectInput } from "./components/roleselect";
import { NewUserShiftSelectInput } from "./components/shiftselect";
import { NewUserAccountTypeSelectInput } from "./components/typeselect";
import { NewUserUnitySelectInput } from "./components/operatorunityselect";
import { NewUserUnityParentSelectInput } from "./components/clientUnityParentSelect";
import { NewUserClientUnitySelectInput } from "./components/clientunityselect";
import { ReasonInputSelector } from "../../../../components/input/ReasonSelector";

export const EditUserInputDataList: InputDataList<NewUser> = [
  {
    label: "ID (Solo lectura)",
    name: "uid",
    type: "custom",
    provide: ({ values }) => {
      return <p className={"text-gray-400 text-sm"}>{values["uid"]}</p>;
    },
    defaultValue: ""
  }
  ,
  {
    label: "Apellidos y Nombres",
    name: "displayName",
    type: "text",
    addon: <UserInfo />,
    defaultValue: ""
  },
  {
    label: "Nro. Teléfono / Designado",
    name: "phoneNumber",
    type: "text",
    addon: <Phone />,
    defaultValue: ""
  },
  {
    label: "Correo Electrónico",
    name: "email",
    type: "text",
    addon: <Email />,
    defaultValue: ""
  },
  {
    label: "Contraseña",
    name: "password",
    type: "text",
    help:
      "Al rellenar este campo, se cambiará la contraseña del usuario.",
    addon: <CharacterLock />,
    defaultValue: ""
  },
  {
    name: "roleId",
    type: "custom",
    provide: NewUserRoleSelectInput,
    defaultValue: null
  },
  {
    name: "shiftId",
    type: "custom",
    defaultValue: null,
    provide: NewUserShiftSelectInput
  },
  {
    name: "accountTypeId",
    type: "custom",
    defaultValue: null,
    provide: NewUserAccountTypeSelectInput
  },
  {
    name: "unityId",
    type: "custom",
    provide: NewUserUnitySelectInput,
    defaultValue: null
  },
  {
    name: "unityIdParent",
    type: "custom",
    provide: NewUserUnityParentSelectInput,
    defaultValue: null
  },
  {
    label: "Foto de perfil",
    name: "photoFileType",
    type: "custom",
    provide: EditUserUploadPhotoInput,
    defaultValue: null
  },
  {
    name: "clientUnity",
    type: "custom",
    defaultValue: undefined,
    provide: NewUserClientUnitySelectInput
  },
  {
    label: "Bloquear",
    name: "disabled",
    type: "custom",
    defaultValue: false,
    provide: ({ values, updateValue }) => {
      return (
        <Toggle
          checked={values["disabled"]}
          onChange={(checked) => {
            updateValue("disabled", checked);
          }}
          checkedChildren="Suspender"
          unCheckedChildren="No suspender"
        />
      );
    }
  },
  {
    name: "reason",
    label: "Motivo de cambio",
    type: "custom",
    help: "Detalle el motivo de la eliminación",
    defaultValue: undefined,
    provide: (props) => <ReasonInputSelector {...props as any}
                                             options={["Nuevo cliente", "Adición de cliente existente"]} />
  }
];

export const EditProfileInputDataList: InputDataList<NewUser> = [
  {
    label: "ID (Solo lectura)",
    name: "uid",
    type: "custom",
    provide: ({ values }) => {
      return <p className={"text-gray-400 text-sm"}>{values["uid"]}</p>;
    },
    defaultValue: ""
  }
  ,
  {
    label: "Apellidos y Nombres",
    name: "displayName",
    type: "text",
    addon: <UserInfo />,
    defaultValue: ""
  },
  {
    label: "Foto de perfil",
    name: "photoFileType",
    type: "custom",
    provide: EditUserUploadPhotoInput,
    defaultValue: null
  },
  {
    name: "reason",
    label: "Motivo de cambio",
    type: "custom",
    help: "Detalle el motivo de la eliminación",
    defaultValue: undefined,
    provide: (props) => <ReasonInputSelector {...props as any}
                                             options={["Nuevo cliente", "Adición de cliente existente"]} />
  }
];


export const EditOperatorProfileInputDataList: InputDataList<NewUser> = [
  {
    label: "ID (Solo lectura)",
    name: "uid",
    type: "custom",
    provide: ({ values }) => {
      return <p className={"text-gray-400 text-sm"}>{values["uid"]}</p>;
    },
    defaultValue: ""
  }
  ,
  {
    label: "Apellidos y Nombres",
    name: "displayName",
    type: "custom",
    provide: ({ values }) => {
      return <p className={"text-gray-400 text-sm"}>{values["displayName"]}</p>;
    },
    defaultValue: ""
  },
  {
    name: "shiftId",
    type: "custom",
    defaultValue: null,
    provide: NewUserShiftSelectInput
  },
  {
    name: "accountTypeId",
    type: "custom",
    defaultValue: null,
    provide: NewUserAccountTypeSelectInput
  },
  {
    name: "unityId",
    type: "custom",
    provide: NewUserUnitySelectInput,
    defaultValue: null
  },
  {
    label: "Bloquear",
    name: "disabled",
    type: "custom",
    defaultValue: false,
    provide: ({ values, updateValue }) => {
      return (
        <Toggle
          checked={values["disabled"]}
          onChange={(checked) => {
            updateValue("disabled", checked);
          }}
          checkedChildren="Suspender"
          unCheckedChildren="No suspender"
        />
      );
    }
  },
  {
    name: "reason",
    label: "Motivo de cambio",
    type: "custom",
    help: "Detalle el motivo de la eliminación",
    defaultValue: undefined,
    provide: (props) => <ReasonInputSelector {...props as any}
                                             options={["Nuevo cliente", "Adición de cliente existente"]} />
  }
];
