import {PentrackerUser} from "../../domain/user/User";
import {useContext, useState} from "react";
import {Response} from "../../domain/app/Response";
import {PatrolRepository} from "../../data/repository/PatrolRepository";
import {UserPatrol} from "../../domain/patrol/Patrol";
import {AppTimestamp} from "../../domain/app/Timestamp";
import {UserAuthContext} from "../../ui/context/UserContext";

export function useUserPatrolViewModel(user: PentrackerUser) {
    const [fetchState, setFetchState] = useState<Response<boolean> | null>(null);
    const [userPatrolList, setUserPatrolList] = useState<
        UserPatrol[] | undefined
    >();
    const [userPatrolTimestamp, setUserPatrolTimestamp] = useState<
        AppTimestamp | undefined
    >();
    const [refreshEvent, setRefreshEvent] = useState<boolean | null>(null);
    const [selectedItem, setSelectedItem] = useState<UserPatrol | null>(null);
    const {appUser} = useContext(UserAuthContext);

    async function fetchUserPatrolList(
        timestamp: number,
        forceRefresh: boolean = false
    ) {
        if (fetchState?.isLoading()) return;
        setFetchState(Response.loading());
        try {
            const result = await PatrolRepository.getUserList(
                timestamp,
                user.uid,
                forceRefresh,
                appUser
            );
            setUserPatrolList(result?.patrolList);
            setUserPatrolTimestamp(result?.timestamp);
            setFetchState(Response.success(true));
        } catch (e: any) {
            setFetchState(Response.failure(e));
        }
    }

    function onFetchStateReceived() {
        setFetchState(null);
    }

    function requestRefreshEvent() {
        setRefreshEvent(true);
    }

    function onRefreshEventCompleted() {
        setRefreshEvent(null);
    }

    function selectPatrolItem(item: UserPatrol) {
        setSelectedItem(item);
    }

    function onItemSelectCompleted() {
        setSelectedItem(null);
    }

    return {
        fetchState,
        onFetchStateReceived,
        userPatrolList,
        userPatrolTimestamp,
        fetchUserPatrolList,
        refreshEvent,
        requestRefreshEvent,
        onRefreshEventCompleted,
        selectedItem,
        selectPatrolItem,
        onItemSelectCompleted,
    };
}
