import {FirestoreSimpleCrudSource} from "../source/FirestoreSimpleCrudSource";
import {AccountType, FirestoreAccountType} from "../../domain/account-type/AccountType";
import {where} from "firebase/firestore";
import {AccountTypeDao} from "../database/dao/AccountType";
import {isEmpty, toNumber} from "lodash";

export class AccountTypeRepository {
    static firestoreRepository = new FirestoreSimpleCrudSource<FirestoreAccountType,
        FirestoreAccountType,
        FirestoreAccountType>("account_type");

    static async fetchList(forced: boolean = false) {
        const localList = await AccountTypeDao.getList();
        if (isEmpty(localList) || forced) {
            const list = await this.firestoreRepository.getList(null);
            await AccountTypeDao.clear();
            await AccountTypeDao.putAccountType(...list.map(
                (it) => new AccountType(it.reference, it.data.id, it.data.label)
            ));
        }
    }

    static async getList(forced: boolean = false) {
        const localList = await AccountTypeDao.getList();
        if (isEmpty(localList) || forced) {
            const list = await this.firestoreRepository.getList(null);
            await AccountTypeDao.clear();
            await AccountTypeDao.putAccountType(...list.map(
                (it) => new AccountType(it.reference, it.data.id, it.data.label)
            ));
            return AccountTypeDao.getList();
        } else {
            return localList;
        }
    }

    static async createAccountType(accountType: FirestoreAccountType) {
        if (!accountType.id) accountType.id = Date.now();
        else accountType.id = toNumber(accountType.id);
        const newAccountType = await this.firestoreRepository.create(
            accountType,
            accountType.id,
        );
        await AccountTypeDao.putAccountType(new AccountType(
            newAccountType.path,
            accountType.id,
            accountType.label
        ));
    }

    static async deleteAccountType(reference: string) {
        await this.firestoreRepository.delete(reference);
        await AccountTypeDao.delete(reference);
    }

    static async fetchAccountType(id: number, forced: boolean) {
        const localAccountType = await AccountTypeDao.getAccountTypeById(id);
        if (!localAccountType || forced) {
            const accountType = await this.firestoreRepository.getByQuery([
                where("id", "==", id)
            ]);
            if (accountType) await AccountTypeDao.putAccountType(new AccountType(
                accountType.reference,
                accountType.data.id,
                accountType.data.label
            ));
        }
    }

    static async getAccountType(id: number, forced: boolean = false) {
        const localAccountType = await AccountTypeDao.getAccountTypeById(id);
        if (!localAccountType || forced) {
            const accountType = await this.firestoreRepository.getByQuery([
                where("id", "==", id)
            ]);
            if (accountType) {
                await AccountTypeDao.putAccountType(new AccountType(
                    accountType.reference,
                    accountType.data.id,
                    accountType.data.label
                ));
                return AccountTypeDao.getAccountTypeById(id);
            }
        } else {
            return localAccountType;
        }
    }
}
