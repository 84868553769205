import {FC, useState} from "react";
import {GeoLocationRender} from "./index";
import {Button} from "rsuite";

type Props = {
    location: google.maps.LatLngLiteral;
};

export const GeocodeRevealButton: FC<Props> = ({location}) => {
    const [reveal, setReveal] = useState(false);

    return reveal ? (
        <GeoLocationRender className={"inline p-0 m-0"} location={location}/>
    ) : (
        <Button
            style={{padding: 0, margin: 0}}
            appearance={"link"}
            className={"p-0 m-0"}
            onClick={() => setReveal(true)}
        >
            Click para ver dirección
        </Button>
    );
};
