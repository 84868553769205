import {InputDataList} from "../../../../components/modal/FormModal";
import {Tag} from "@rsuite/icons";
import {Schema} from "rsuite";

export const NewRoleSchema = Schema.Model({
    label: Schema.Types.StringType().isRequired(
        "Es necesario colocar un título para el rol de usuario."
    ),
});

export const NewRoleInputList: InputDataList = [
    {
        label: "ID (Opcional)",
        name: "id",
        type: "number",
        addon: <Tag/>,
    },
    {
        label: "Título",
        name: "label",
        type: "text",
        addon: <Tag/>,
    },
];
