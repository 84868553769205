import React, {FC} from "react";
import {ListChildComponentProps} from "react-window";
import {UnityListRowProps} from "./index";
import {UnityRowName} from "./name";
import {UnityRowClient} from "./client";
import {UnityRowOperator} from "./operator";

export const UnityRow: FC<ListChildComponentProps<UnityListRowProps>> = ({
                                                                             index,
                                                                             style,
                                                                             data,
                                                                         }) => {
    const {items, onClick} = data;
    const item = items[index];
    return (
        <div style={style} className={"pb-2"}>
            <div
                data-index={index}
                onClick={onClick}
                className={
                    "w-full h-full bg-white shadow rounded-lg p-2 flex flex-col justify-between gap-2"
                }
            >
                <div className={"w-full flex flex-col gap-2"}>
                    <UnityRowName unity={item}/>
                    <UnityRowClient unity={item}/>
                    <UnityRowOperator unity={item}/>
                </div>
            </div>
        </div>
    );
};
