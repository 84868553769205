import {useContext, useState} from "react";
import {UserDto} from "../../network/user/User";
import {db} from "../../data/database/db";
import {UserRoles} from "../../domain/user/Role";
import {DateTime} from "luxon";
import {UserPatrol} from "../../domain/patrol/Patrol";
import {useLiveQuery} from "dexie-react-hooks";
import {UserIncident} from "../../domain/incident/Incident";
import UserRepository from "../../data/repository/UserRepository";
import {AppTimestampDao} from "../../data/database/dao/AppTimestamp";
import {Response} from "../../domain/app/Response";
import {LocalErrorLogRepository} from "../../data/repository/LocalErrorLogRepository";
import UnityRepository from "../../data/repository/UnityRepository";
import {AttendanceRepository} from "../../data/repository/AttendanceRepository";
import {isNil, orderBy} from "lodash";
import {AttendancePair} from "../../domain/attendance/Attendance";
import {PatrolRepository} from "../../data/repository/PatrolRepository";
import {IncidentRepository} from "../../data/repository/IncidentRepository";
import * as stc from "string-to-color";
import {UserAuthContext} from "../../ui/context/UserContext";

export type RoleCount = {
    name: string;
    count: number;
    fill?: string;
};

export type UnityOperatorCount = {
    name: string;
    count: number;

    fill?: string;
};

type UserData = {
    count: number;
    lastUsers: UserDto[];
    countByRole: RoleCount[];
};

type UnityData = {
    count: number;
    countByUsers: UnityOperatorCount[];
};

type AttendanceData = {
    todayActiveUsers: number;
    yesterdayActiveUsers: number;
    lastUserEntries: AttendancePair[];
};

type PatrolData = {
    todayPatrolCount: number;
    yesterdayPatrolCount: number;
    lastUserPatrol: UserPatrol[];
};

type IncidentData = {
    todayIncidentCount: number;
    yesterdayIncidentCount: number;
    lastUserIncident: UserIncident[];
};

const UsersDataFetchTimestampKey = "users_data_fetch_timestamp";

const UnityDataFetchTimestampKey = "unity_data_fetch_timestamp";

const AttendanceDataFetchTimestampKey = "attendance_data_fetch_timestamp";

const PatrolDataFetchTimestampKey = "patrol_data_fetch_timestamp";

const IncidentDataFetchTimestampKey = "incident_data_fetch_timestamp";

export function useDashboardHomeViewModel() {
    const [userData, setUserData] = useState<UserData | null>(null);
    const userDataFetchTimestamp = useLiveQuery(() =>
        db.timestamps.get({key: UsersDataFetchTimestampKey})
    );
    const [userDataState, setUserDataState] =
        useState<Response<boolean> | null>();
    const [unityData, setUnityData] = useState<UnityData | null>(null);
    const unityDataFetchTimestamp = useLiveQuery(
        () => db.timestamps.get({key: UnityDataFetchTimestampKey}),
        []
    );
    const [unityDataState, setUnityDataState] =
        useState<Response<boolean> | null>(null);
    const [attendanceData, setAttendanceData] = useState<AttendanceData | null>(
        null
    );
    const attendanceDataFetchTimestamp = useLiveQuery(
        () => db.timestamps.get({key: AttendanceDataFetchTimestampKey}),
        []
    );
    const [attendanceDataState, setAttendanceDataState] =
        useState<Response<boolean> | null>(null);
    const [patrolData, setPatrolData] = useState<PatrolData | null>(null);
    const patrolDataFetchTimestamp = useLiveQuery(
        () => db.timestamps.get({key: PatrolDataFetchTimestampKey}),
        []
    );
    const [patrolDataState, setPatrolDataState] =
        useState<Response<boolean> | null>(null);
    const [incidentData, setIncidentData] = useState<IncidentData | null>(null);
    const incidentDataFetchTimestamp = useLiveQuery(
        () => db.timestamps.get({key: IncidentDataFetchTimestampKey}),
        []
    );
    const [incidentDataState, setIncidentDataState] =
        useState<Response<boolean> | null>(null);
    const latestPentrackerErrors = useLiveQuery(
        () => db.local_error_log.orderBy("timestamp").limit(10).toArray(),
        []
    );
    const {appUser} = useContext(UserAuthContext);

    async function fetchUsersData(download: boolean = false) {
        if (userDataState?.isLoading()) {
            return;
        }
        setUserDataState(Response.loading());
        try {
            await UserRepository.getUserList(
                {
                    order: "asc",
                    orderBy: "displayName",
                },
                download
            );
            if (download) {
                await AppTimestampDao.putTimestamp({
                    key: UsersDataFetchTimestampKey,
                    timestamp: Date.now(),
                });
            }
            const usersCount = await db.users.count();
            const lastUsers = await db.users
                .orderBy("creationTime")
                .limit(5)
                .toArray();
            const userByCountResult: RoleCount[] = [];
            const operatorsCount = await db.users
                .where("roleId")
                .anyOf(UserRoles.operator)
                .count();
            userByCountResult.push({
                name: "Agentes",
                count: operatorsCount,
                fill: "#60a5fa",
            });
            const clientCount = await db.users
                .where("roleId")
                .equals(UserRoles.client)
                .count();
            userByCountResult.push({
                name: "Clientes",
                count: clientCount,
                fill: "#fbbf24",
            });
            const managerCount = await db.users
                .where("roleId")
                .anyOf(UserRoles.manager)
                .count();
            userByCountResult.push({
                name: "Administradores",
                count: managerCount,
                fill: "#4ade80",
            });
            const otherUsersCount = await db.users
                .filter((user) => isNil(user.roleId))
                .count();
            userByCountResult.push({
                name: "Otros",
                count: otherUsersCount,
                fill: "#f87171",
            });
            setUserData({
                count: usersCount,
                lastUsers,
                countByRole: userByCountResult,
            });
            setUserDataState(Response.success(true));
        } catch (e: any) {
            console.log(e);
            const label = "Error al actualizar los datos de usuario.";
            setUserDataState(Response.failure(new Error(label)));
            await LocalErrorLogRepository.registerError(label, e);
        }
    }

    async function fetchUnityData(download: boolean = false) {
        if (unityDataState?.isLoading()) {
            return;
        }
        setUnityDataState(Response.loading());
        try {
            await UnityRepository.getList(download, appUser);
            if (download) {
                await UserRepository.getUserList(
                    {
                        order: "asc",
                        orderBy: "displayName",
                    },
                    true
                );
                await AppTimestampDao.putTimestamp({
                    key: UnityDataFetchTimestampKey,
                    timestamp: Date.now(),
                });
            }
            const unities = await db.unities.toArray();
            const unityByUsersCountResult: UnityOperatorCount[] = [];
            const tasks = unities.map(async (it) => {
                const userCount = await db.users.where("unityId").equals(it.id).count();
                unityByUsersCountResult.push({
                    name: it.label,
                    count: userCount,
                    fill: stc.default(it.label),
                });
            });
            await Promise.all(tasks);
            setUnityData({
                count: unities.length,
                countByUsers: unityByUsersCountResult,
            });
            setUnityDataState(Response.success(true));
        } catch (e: any) {
            const label = "Error al actualizar los datos de las unidades.";
            setUnityDataState(Response.failure(new Error(label)));
            await LocalErrorLogRepository.registerError(label, e);
        }
    }

    async function fetchAttendanceData(download: boolean = false) {
        if (attendanceDataState?.isLoading()) {
            return;
        }
        setAttendanceDataState(Response.loading());
        const today = DateTime.now().setZone("America/Lima");
        const yesterday = today.minus({day: 1});
        try {
            const todayAttendance = await AttendanceRepository.getGlobalList(
                today.toMillis(),
                download,
                appUser
            );
            const yesterdayAttendance = await AttendanceRepository.getGlobalList(
                yesterday.toMillis(),
                download,
                appUser
            );
            if (download) {
                await AttendanceRepository.getGlobalList(
                    today.toMillis(),
                    true,
                    appUser
                );
                await AttendanceRepository.getGlobalList(
                    yesterday.toMillis(),
                    true,
                    appUser
                );
                await AppTimestampDao.putTimestamp({
                    key: AttendanceDataFetchTimestampKey,
                    timestamp: Date.now(),
                });
            }

            const lastEntries = todayAttendance?.pairs
                ? orderBy(todayAttendance.pairs, "timestamp", "desc").slice(0, 10)
                : [];
            setAttendanceData({
                todayActiveUsers: todayAttendance?.pairs.length || 0,
                yesterdayActiveUsers: yesterdayAttendance?.pairs.length || 0,
                lastUserEntries: lastEntries,
            });
            setAttendanceDataState(Response.success(true));
        } catch (e: any) {
            const label = "Error al actualizar los datos de asistencia.";
            setAttendanceDataState(Response.failure(new Error(label)));
            await LocalErrorLogRepository.registerError(label, e);
        }
    }

    async function fetchPatrolData(download: boolean = false) {
        if (patrolDataState?.isLoading()) {
            return;
        }
        setPatrolDataState(Response.loading());
        const today = DateTime.now().setZone("America/Lima");
        const yesterday = today.minus({day: 1});
        try {
            const todayPatrol = await PatrolRepository.getGlobal(
                today.toMillis(),
                download,
                appUser
            );
            const yesterdayPatrol = await PatrolRepository.getGlobal(
                yesterday.toMillis(),
                download,
                appUser
            );
            if (download) {
                await PatrolRepository.getGlobal(today.toMillis(), true, appUser);
                await PatrolRepository.getGlobal(yesterday.toMillis(), true, appUser);
                await AppTimestampDao.putTimestamp({
                    key: PatrolDataFetchTimestampKey,
                    timestamp: Date.now(),
                });
            }

            const lastPatrolList = todayPatrol?.patrolList
                ? orderBy(todayPatrol.patrolList, "timestamp", "desc").slice(0, 10)
                : [];
            setPatrolData({
                lastUserPatrol: lastPatrolList,
                yesterdayPatrolCount: yesterdayPatrol?.patrolList.length || 0,
                todayPatrolCount: todayPatrol?.patrolList.length || 0,
            });
            setPatrolDataState(Response.success(true));
        } catch (e: any) {
            const label = "Error al actualizar los datos de rondas.";
            setPatrolDataState(Response.failure(new Error(label)));
            await LocalErrorLogRepository.registerError(label, e);
        }
    }

    async function fetchIncidentData(download: boolean = false) {
        if (incidentDataState?.isLoading()) {
            return;
        }
        setIncidentDataState(Response.loading());
        const today = DateTime.now().setZone("America/Lima");
        const yesterday = today.minus({day: 1});
        try {
            const todayIncident = await IncidentRepository.getGlobal(
                today.toMillis(),
                download, appUser
            );
            const yesterdayIncident = await IncidentRepository.getGlobal(
                yesterday.toMillis(),
                download, appUser
            );
            if (download) {
                await IncidentRepository.getGlobal(today.toMillis(), true, appUser);
                await IncidentRepository.getGlobal(yesterday.toMillis(), true, appUser);
                await AppTimestampDao.putTimestamp({
                    key: IncidentDataFetchTimestampKey,
                    timestamp: Date.now(),
                });
            }

            const lastIncident = todayIncident?.incidentList
                ? orderBy(todayIncident.incidentList, "timestamp", "desc").slice(0, 10)
                : [];
            setIncidentData({
                lastUserIncident: lastIncident,
                todayIncidentCount: todayIncident?.incidentList.length || 0,
                yesterdayIncidentCount: yesterdayIncident?.incidentList.length || 0,
            });
            setIncidentDataState(Response.success(true));
        } catch (e: any) {
            const label = "Error al actualizar los datos de incidencias.";
            setIncidentDataState(Response.failure(new Error(label)));
            await LocalErrorLogRepository.registerError(label, e);
        }
    }

    async function fetchInitialData() {
        await fetchUsersData();
        await fetchUnityData();
        await fetchAttendanceData();
        await fetchPatrolData();
        await fetchIncidentData();
    }

    const loading =
        userDataState?.isLoading() ||
        unityDataState?.isLoading() ||
        attendanceDataState?.isLoading() ||
        patrolDataState?.isLoading() ||
        incidentDataState?.isLoading();

    return {
        userData,
        userDataState,
        userDataFetchTimestamp,
        fetchUsersData,
        unityData,
        unityDataState,
        unityDataFetchTimestamp,
        fetchUnityData,
        attendanceData,
        attendanceDataState,
        attendanceDataFetchTimestamp,
        fetchAttendanceData,
        patrolData,
        patrolDataState,
        patrolDataFetchTimestamp,
        fetchPatrolData,
        incidentData,
        incidentDataState,
        incidentDataFetchTimestamp,
        fetchIncidentData,
        latestPentrackerErrors,
        fetchInitialData,
        loading,
    };
}
