import {db} from "../db";
import {GoodsControl} from "../../../domain/control/Goods";

export class GoodsControlDao {
    static async putGoodsControl(...goodsControl: GoodsControl[]) {
        return db.goods_control.bulkPut(goodsControl);
    }

    static async getAllUnityGoodsControl(unityId: number, start: number, end: number) {
        return db.goods_control
            .where(["unityId+timestamp"])
            .between([unityId, start], [unityId, end], true, true)
            .reverse()
            .sortBy("timestamp");
    }

    static async deleteAllUnityGoodsControl(
        unityId: number,
        start: number,
        end: number
    ) {
        return db.goods_control
            .where(["unityId+timestamp"])
            .between([unityId, start], [unityId, end], true, true)
            .delete();
    }

    static async getUserGoodsControlList(ownerUid: string, start: number, end: number) {
        return db.goods_control
            .where(["ownerUid+timestamp"])
            .between([ownerUid, start], [ownerUid, end])
            .reverse()
            .sortBy("timestamp");
    }

    static async deleteAllUserGoodsControl(
        ownerUid: string,
        start: number,
        end: number
    ) {
        return db.goods_control
            .where(["ownerUid+timestamp"])
            .between([ownerUid, start], [ownerUid, end])
            .delete();
    }

    static async getAllGoodsControl(start: number, end: number) {
        return db.goods_control
            .where("timestamp")
            .between(start, end, true, true)
            .reverse()
            .sortBy("timestamp");
    }

    static async deleteAllGoodsControl(
        start: number,
        end: number
    ) {
        return db.goods_control
            .where("timestamp")
            .between(start, end, true, true)
            .delete();
    }

    static async getGoodsControl(reference: string) {
        return db.goods_control.get(reference)
    }
}
