import {InputDataList} from "../../../../components/modal/FormModal";
import {Schema} from "rsuite";
import {isNotBlank} from "../../../../../util/validation";

export const AccountTypeInputList: InputDataList = [
    {
        type: "number",
        label: "ID (Opcional)",
        name: "id",
        help: "No se admiten IDs duplicados",
    },
    {
        type: "text",
        label: "Nombre",
        name: "label",
    },
];

export const AccountTypeModel = Schema.Model({
    id: Schema.Types.NumberType(),
    label: Schema.Types.StringType().isRequired("Es necesario completar este campo.").addRule(isNotBlank(3), "Este campo debe contener al menos 3 caracteres.")
})
