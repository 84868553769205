import React, {FC} from "react";
import {Unity} from "../../../../domain/unity";
import {Link, useOutletContext} from "react-router-dom";
import {toTime} from "../../../../lib/date";

export const UnityMainPane: FC = () => {
    const unity = useOutletContext() as Unity;
    return (
        <div
            className={
                "w-full h-full overflow-auto flex flex-col gap-2 justify-start items-start"
            }
        >
            <div className={"gap-4 flex flex-row flex-wrap items-center break-words"}>
                <span className={"text-lg uppercase"}>{unity.label}</span>
            </div>
            <div className={"gap-2 flex flex-col"}>
                <p className={"font-normal text-md uppercase text-neutral-400"}>Id</p>
                {unity.id}
            </div>
            <div className={"gap-2 flex flex-col"}>{unity.description}</div>
            <div className={"gap-2 flex flex-col"}>
                <p className={"font-normal text-md uppercase text-neutral-400"}>
                    Límite de Entrada por la mañana
                </p>
                {unity.firstShiftLimit ? (
                    toTime(unity.firstShiftLimit)
                ) : (
                    <span className={"italic"}>No se estableció</span>
                )}
            </div>
            <div className={"gap-2 flex flex-col"}>
                <p className={"font-normal text-md uppercase text-neutral-400"}>
                    Límite de Entrada por la noche
                </p>
                {unity.secondShiftLimit ? (
                    toTime(unity.secondShiftLimit)
                ) : (
                    <span className={"italic"}>No se estableció</span>
                )}
            </div>
            <div className={"gap-2 flex flex-col"}>
                <p className={"font-normal text-md uppercase text-neutral-400"}>
                    Operadores
                </p>
                {unity.operatorCount}
            </div>
            <div className={"gap-2 flex flex-col"}>
                <p className={"font-normal text-md uppercase text-neutral-400"}>
                    Clientes
                </p>
                {unity.client ? (
                    <div className={"flex flex-row flex-wrap gap-2"}>
                        {unity.client.map((user) => (
                            <Link
                                key={`client-${user.uid}`}
                                to={`/dashboard/users/${user.uid}`}
                            >
                                {user.displayName}
                            </Link>
                        ))}
                    </div>
                ) : (
                    <span className={"italic"}>No hay clientes asignados</span>
                )}
            </div>
        </div>
    );
};
