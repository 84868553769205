import { FC, forwardRef, useState } from "react";
import { Button, Form, Input, InputProps, Schema } from "rsuite";
import { UserNotificationFormData } from "../../../../domain/user/UserNotification";


type Props = {
  onSubmit: (data: UserNotificationFormData) => void
}
const TextArea = forwardRef<any, InputProps>((props, ref) => <Input {...props} as="textarea" ref={ref} />);
export const UserNotificationForm: FC<Props> = ({ onSubmit }) => {
  const [formValue, setFormValue] = useState<UserNotificationFormData>({
    title: "", body: "", fullBody: ""
  });
  return <Form fluid model={Schema.Model({
    title: Schema.Types.StringType().isRequired("Es necesario especificar el título."),
    fullBody: Schema.Types.StringType().isRequired("Es necesario especificar el cuerpo.")
  })} formValue={formValue} onChange={setFormValue as any} onSubmit={(checkStatus, event) => {
    event.preventDefault();
    if (checkStatus) {
      onSubmit(formValue);
    }
  }
  }>
    <Form.Group controlId="title">
      <Form.ControlLabel>Título</Form.ControlLabel>
      <Form.Control name="title" accepter={TextArea} required />
    </Form.Group>
    <Form.Group controlId="fullBody">
      <Form.ControlLabel>Cuerpo</Form.ControlLabel>
      <Form.Control name="fullBody" accepter={TextArea} required />
      <Form.HelpText>Se mostrará al expandir la notificación</Form.HelpText>
    </Form.Group>
    <Form.Group controlId="body">
      <Form.ControlLabel>Resúmen</Form.ControlLabel>
      <Form.Control name="body" accepter={TextArea} />
      <Form.HelpText>Igual a (Cuerpo) si se deja en blanco</Form.HelpText>
    </Form.Group>
    <Form.Group>
      <Button type={"submit"} appearance={"primary"}>Enviar</Button>
    </Form.Group>

  </Form>;
};
