import {Unity} from "../unity";
import {PentrackerUser} from "../user/User";
import {GetVehicleControlDto} from "../../network/control/Vehicle";

export type VehicleControl = Omit<GetVehicleControlDto, "timestamp"> & {
    reference : string
    timestamp: number
}

export type UserVehicleControl = VehicleControl & {
    unity?: Unity;
    owner?: PentrackerUser
}

export function vehicleControlDtoAsVehicleControl(vehicleDto: GetVehicleControlDto, reference: string): VehicleControl {
    return {
        ...vehicleDto,
        reference,
        timestamp: vehicleDto.timestamp.toDate().getTime()
    }
}

export function vehicleControlAsUserVehicleControl(vehicle: VehicleControl, unity ?: Unity, owner ?: PentrackerUser): UserVehicleControl {
    return {
        ...vehicle,
        unity,
        owner
    }
}
