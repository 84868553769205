import { FC, useContext } from "react";
import { UserPatrol } from "../../../../../domain/patrol/Patrol";
import { BreakPointContext } from "../../../../context/BreakPointContext";
import { PatrolList } from "../../../data-display/patrol-list";
import { PatrolTable } from "../../../data-display/patrol-table";

type Props = {
  patrolList?: UserPatrol[];
  onSelect: (item: UserPatrol) => void;
  onPreviewClick: (item: UserPatrol) => void;
};

export const UnityPatrolDataName = "UnityPatrolData";

export const UnityPatrolData: FC<Props> = ({
  patrolList,
  onPreviewClick,
  onSelect,
}) => {
  const screenSize = useContext(BreakPointContext);
  if (screenSize.isMobile) {
    return (
      <PatrolList
        name={UnityPatrolDataName}
        onSelect={onSelect}
        patrolList={patrolList}
        onPreviewClicked={onPreviewClick}
      />
    );
  } else {
    return (
      <PatrolTable
        name={UnityPatrolDataName}
        userPatrolList={patrolList}
        onPreviewClicked={onPreviewClick}
        onSelect={onSelect}
      />
    );
  }
};
