import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
} from "react";
import useLocalStorageState from "use-local-storage-state";
import { AvailableTailwindSize } from "../../hook/tailwind";
import { BreakPointContext } from "./BreakPointContext";

export type TableColumn<T> = {
  name: keyof T;
  label: string;
  weight: number;
};

export type TableColumnRelation<T> = {
  [sizeIdentifier: string]: {
    columns: TableColumn<T>[];
    breakpoints: AvailableTailwindSize["sizes"][];
  };
};

export type TablePreferencesRelation = {
  [tableName: string]: {
    [sizeIdentifier: string]: TableColumn<any>[];
  };
};

export type TableColumnContextValue = {
  schema: {
    [tableName: string]: {
      relation: TableColumnRelation<any>;
      version: number;
    };
  };
  addSchema: <T>(
    tableName: string,
    version: number,
    relation: TableColumnRelation<T>
  ) => void;
  preferences: TablePreferencesRelation;
  getTablePreferences: <T>(tableName: string) => TableColumn<T>[];
  updateTableEnabledColumns: <T>(
    tableName: string,
    enabledColumns: (keyof T)[]
  ) => void;
  updateTableColumnWeight: <T>(
    tableName: string,
    columnName: keyof T,
    newWeight: number
  ) => void;
  resetTablePreferences: (tableName: string) => void;
};

const tableSchemaToTablePreferences = <T,>(
  schema: TableColumnRelation<T>
): { [sizeIdentifier: string]: TableColumn<any>[] } => {
  const keys = Object.keys(schema) as string[];
  let preferencesResult: { [sizeIdentifier: string]: TableColumn<any>[] } = {};
  for (let key of keys) {
    preferencesResult[key] = schema[key].columns;
  }
  return preferencesResult;
};

export const TableColumnContext = createContext<TableColumnContextValue>({
  schema: {},
  addSchema: () => {},
  preferences: {},
  updateTableColumnWeight: () => {},
  updateTableEnabledColumns: () => {},
  getTablePreferences: () => ({} as any),
  resetTablePreferences: () => {},
});

export const TableColumnProvider: FC<PropsWithChildren> = ({ children }) => {
  const currentBreakpoint = useContext(BreakPointContext);

  const [localTableSchemaList, setLocalTableSchemaList] = useLocalStorageState<
    TableColumnContextValue["schema"]
  >("TableSchemaList", {
    defaultValue: {},
  });

  const [localTablePreferenceList, setLocalTablePreferenceList] =
    useLocalStorageState<TablePreferencesRelation>("TablePreferencesList", {
      defaultValue: {},
    });

  const addSchema = useCallback(
    function <T>(
      name: string,
      version: number,
      relation: TableColumnRelation<T>
    ) {
      const existingTableSchema = localTableSchemaList[name];
      if (!existingTableSchema || existingTableSchema.version < version) {
        const oldSchema = { ...localTableSchemaList };
        const newValueSchema = { ...oldSchema, [name]: { relation, version } };
        setLocalTableSchemaList(newValueSchema);
        const oldPreferences = { ...localTablePreferenceList };
        const newPreferencesValue = {
          ...oldPreferences,
          [name]: tableSchemaToTablePreferences(relation),
        };
        setLocalTablePreferenceList(newPreferencesValue);
      }
    },
    [
      localTableSchemaList,
      setLocalTableSchemaList,
      localTablePreferenceList,
      setLocalTablePreferenceList,
    ]
  );

  const getIdentifier = useCallback(
    (tableName: string) => {
      const currentTable = localTableSchemaList[tableName];
      let currentIdentifier: string = "";
      let identifiers = Object.keys(currentTable.relation) as string[];
      for (let identifier of identifiers) {
        if (
          currentTable.relation[identifier].breakpoints.includes(
            currentBreakpoint.breakpoint
          )
        ) {
          currentIdentifier = identifier;
          break;
        }
      }
      return currentIdentifier;
    },
    [currentBreakpoint, localTableSchemaList]
  );

  const updateTableEnabledColumns = useCallback(
    <T,>(tableName: string, enabledColumns: (keyof T)[]) => {
      const currentTable = localTableSchemaList[tableName];
      if (currentTable) {
        const currentIdentifier: string = getIdentifier(tableName);
        const newColumnsInPreferences = (
          currentTable.relation[currentIdentifier].columns as TableColumn<T>[]
        ).filter((it) => {
          return enabledColumns.includes(it.name);
        });
        const weigthSum = newColumnsInPreferences.reduce(
          (accumulator, object) => {
            return accumulator + object.weight;
          },
          0.0
        );
        const moreWeightForEach =
          (1.0 - weigthSum) / newColumnsInPreferences.length;
        newColumnsInPreferences.forEach((it) => {
          it.weight = it.weight + moreWeightForEach;
        });

        const newTablePreference = {
          ...localTablePreferenceList,
          [tableName]: {
            ...localTablePreferenceList[tableName],
            [currentIdentifier]: newColumnsInPreferences,
          },
        };
        setLocalTablePreferenceList(newTablePreference);
      }
    },
    [
      getIdentifier,
      localTablePreferenceList,
      localTableSchemaList,
      setLocalTablePreferenceList,
    ]
  );

  const updateTableColumnWeight = useCallback(
    <T,>(tableName: string, columnName: keyof T, newWeight: number) => {
      const currentTable = localTableSchemaList[tableName];
      const currentTablePreference = localTablePreferenceList[tableName];
      if (currentTable) {
        let currentIdentifier: string = getIdentifier(tableName);
        const newTablePreference = {
          ...localTablePreferenceList,
          [tableName]: {
            ...localTablePreferenceList[tableName],
            [currentIdentifier]: (
              currentTablePreference[currentIdentifier] as TableColumn<T>[]
            ).map((it) =>
              it.name === columnName
                ? {
                    ...it,
                    weight: newWeight,
                  }
                : it
            ),
          },
        };
        setLocalTablePreferenceList(newTablePreference);
      }
    },
    [
      getIdentifier,
      localTablePreferenceList,
      localTableSchemaList,
      setLocalTablePreferenceList,
    ]
  );

  const resetPreferences = (tableName: string) => {
    const existingTableSchema = localTableSchemaList[tableName];
    const newPreferences = tableSchemaToTablePreferences(
      existingTableSchema.relation
    );
    const preferencesKeys = Object.keys(newPreferences) as string[];
    for (let key of preferencesKeys) {
      const currentPreferenceColumns = newPreferences[key];
      const weightSum = currentPreferenceColumns.reduce(
        (acumulator, object) => {
          return acumulator + object.weight;
        },
        0.0
      );
      if (weightSum > 1) {
        const exceedingSpace =
          (weightSum - 1.0) / currentPreferenceColumns.length;
        currentPreferenceColumns.forEach((it) => {
          it.weight = it.weight - exceedingSpace;
        });
      } else {
        const availableSpace =
          (1.0 - weightSum) / currentPreferenceColumns.length;
        currentPreferenceColumns.forEach((it) => {
          it.weight = it.weight + availableSpace;
        });
      }
    }
    if (existingTableSchema) {
      const newPreferencesValue = {
        ...localTablePreferenceList,
        [tableName]: newPreferences,
      };
      setLocalTablePreferenceList(newPreferencesValue);
    }
  };

  const getPreferences = (tableName: string) => {
    const identifier = getIdentifier(tableName);
    return { ...localTablePreferenceList }[tableName][identifier];
  };

  return (
    <TableColumnContext.Provider
      value={{
        schema: localTableSchemaList,
        addSchema,
        preferences: localTablePreferenceList,
        updateTableEnabledColumns,
        resetTablePreferences: resetPreferences,
        getTablePreferences: getPreferences,
        updateTableColumnWeight,
      }}
    >
      {children}
    </TableColumnContext.Provider>
  );
};
