import { FC, ReactNode } from "react";
import { PentrackerUser } from "../../../domain/user/User";
import { ImageLoader } from "../imageloader";
import { User } from "firebase/auth";

export const UserAvatar: FC<{
  user?: PentrackerUser | User;
  size?: number;
  src?: {
    src?: string | null;
    notfound: string;
  };
  errorElement?: ReactNode;
}> = ({ user, size = 60, src, errorElement }) => {
  const withUser = !!user;
  return (
    <div
      className={`my-auto rounded-full overflow-hidden text-white`}
      style={{
        width: size,
        height: size,
      }}
    >
      <ImageLoader
        showPreview={false}
        className={"object-cover w-full h-full"}
        src={withUser && user.photoURL ? user.photoURL : src ? src.src : ""}
        errorComp={() => {
          return (
            <div
              className={
                "w-full h-full flex items-center justify-center color-user-avatar font-light"
              }
              style={{
                fontSize: size - 20,
              }}
            >
              {errorElement
                ? errorElement
                : withUser
                ? user.displayName && user.displayName.length > 0
                  ? user.displayName[0]
                  : user.email!![0]
                : src && src.notfound
                ? src.notfound[0]
                : "U"}
            </div>
          );
        }}
      />
    </div>
  );
};
