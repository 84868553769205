import {FC} from "react";
import {UserAttendance} from "../../../../../domain/attendance/Attendance";
import {ListChildComponentProps} from "react-window";
import {UserAvatar} from "../../../../components/avatar/useravatar";
import {toRelativeCalendar} from "../../../../../lib/date";

type Props = {
    entries: UserAttendance[];
};

export const LastEntriesListRow: FC<ListChildComponentProps<Props>> = ({
                                                                           data,
                                                                           index,
                                                                           style,
                                                                       }) => {
    const {entries} = data;
    const item = entries[index];
    return (
        <div style={style} className={"py-2"}>
            <div className={"flex flex-row gap-2 items-center"}>
                <UserAvatar user={item.owner}/>
                <div className={"flex flex-col"}>
                    <div className={"flex flex-row gap-2 items-center"}>
            <span
                className={"uppercase text-neutral-800 font-semibold text-md"}
            >
              Operador
            </span>
                        <span className={"text-md"}>{item.owner?.displayName}</span>
                    </div>
                    <div className={"flex flex-row gap-2 items-center"}>
            <span
                className={"uppercase text-neutral-800 font-semibold text-md"}
            >
              Unidad
            </span>
                        <span className={"text-md"}>{item.unity?.label}</span>
                    </div>
                    <div className={"flex flex-row gap-2 items-center"}>
            <span className={"text-sm text-neutral-600"}>
              {toRelativeCalendar(item.timestamp, true)}
            </span>
                    </div>
                </div>
            </div>
        </div>
    );
};
