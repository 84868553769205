import { db } from "../db";
import { TicketFileType } from "../../../domain/ticket/UserTicket";

export class TicketFileDao {
  static async getAllContainer() : Promise<TicketFileType[]> {
    return db.ticket_container.toArray()
  }

  static async getAllFiles(containerPath: string) : Promise<TicketFileType[]> {
    return db.ticket_file.where("path").startsWith(containerPath).toArray()
  }

  static async deleteContainer(path: string) {
    return db.ticket_container.delete(path)
  }

  static async deleteAllContainers(){
    return db.ticket_container.clear()
  }

  static async deleteAllFiles(containerPath: string) {
    return db.ticket_file.where("path").startsWith(containerPath).delete()
  }

  static async addContainer(...container: Array<TicketFileType>) {
    return db.ticket_container.bulkAdd(container)
  }

  static async addFile(...file: Array<TicketFileType>) {
    return db.ticket_file.bulkAdd(file)
  }

  static async getContainer(path: string) {
    return db.ticket_container.get(path)
  }

  static async getContainerByName(name: string) {
    return db.ticket_container.where("name").equalsIgnoreCase(name).first()
  }
}
