import { FC } from "react";
import { formatDateTime } from "../../../../util/date";
import { Link } from "react-router-dom";
import { LocalErrorLog } from "../../../../domain/app/LocalErrorLog";
import { FaIcon } from "../../../components/fontawesome/icon";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

type Props = {
  data?: LocalErrorLog[];
};

export const DashboardErrorList: FC<Props> = ({ data }) => {
  if (!data || data.length <= 0) {
    return (
      <div
        className={
          "w-full h-full p-2 md:p-4 flex items-center justify-center gap-2"
        }
      >
        <span className={"text-lg font-normal text-neutral-700"}>
          No se encontraron errores
        </span>
        <FaIcon icon={faCheck} color={"green"} size={"4x"} />
      </div>
    );
  }
  return (
    <div
      className={
        "w-full h-full flex flex-col justify-between items-center relative p-4"
      }
    >
      <span className={"text-lg font-semibold text-neutral-800"}>Errores</span>
      <span className={"text-sm text-multi"}>{data.length} error(es)</span>
      <div
        className={
          "flex-1 flex flex-col w-full overflow-y-auto overflow-x-hidden divide-y divide-neutral-300 scrollbar-hide md:scrollbar-default"
        }
      >
        {data.map((error) => (
          <div
            className={
              "w-full p-2 flex flex-row gap-2 items-center justify-start"
            }
            key={error.id}
          >
            <div className={"flex flex-col"}>
              <span className={"text-sm font-normal text-neutral-900"}>
                {error.label}
              </span>
              <span className={"text-xs font-normal text-neutral-700"}>
                {formatDateTime(new Date(error.timestamp))}
              </span>
            </div>
          </div>
        ))}
        <Link to={"/dashboard/error_log"}>
          <div
            className={
              "w-full py-2 flex justify-center items-center bg-neutral-100 rounded-b-lg"
            }
          >
            <span className={"text-neutral-600"}>Ver más...</span>
          </div>
        </Link>
      </div>
    </div>
  );
};
