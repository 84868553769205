import {
    AppUnity,
    Unity,
    unityDtoAsDomain,
} from "../../domain/unity";
import {
    DeleteUnityDto,
    GetUnityDto,
    PostUnityDto,
    PutUnityDto,
} from "../../network/unity/Unity";
import {FirestoreSimpleCrudSource} from "../source/FirestoreSimpleCrudSource";
import {UnityStoreDao} from "../database/dao/Unity";
import {isEmpty} from "lodash";
import {UserStoreDao} from "../database/dao/User";
import {where} from "firebase/firestore";
import {UserWrapper} from "../../domain/user/User";
import {DataWithReference} from "../source/FirestoreSource";
import {httpsCallable} from "firebase/functions"
import FirebaseFunctions from "../source/FirebaseFunctions";

export default class UnityRepository {
    static firestoreRepository = new FirestoreSimpleCrudSource<
        GetUnityDto,
        PostUnityDto,
        PutUnityDto
    >("unity");

    static async getList(
        forced: boolean = false,
        appUser?: UserWrapper
    ): Promise<Unity[] | undefined> {
        let localList = await UnityStoreDao.getList();
        if (isEmpty(localList) || forced || localList.length === 1) {
            await UnityStoreDao.clear();
            let remoteList: DataWithReference<GetUnityDto>[] | undefined;

            if (appUser?.isClient()) {
                if (appUser!.user.clientUnity) {
                    remoteList = await this.firestoreRepository.getList([
                        where("id", "in", appUser!.user.clientUnity),
                    ]);
                }
            } else {
                remoteList = await this.firestoreRepository.getList();
            }
            if (remoteList) {
                const list = remoteList.map((data) =>
                    unityDtoAsDomain(data.data)
                );
                await UnityStoreDao.putUnity(...list);
            }
            localList = await UnityStoreDao.getList();
        }
        if (!!localList) {
            return Promise.resolve(
                Promise.all(
                    localList.map(async (lUnity) => await this.injectUnity(lUnity))
                )
            );
        }
    }

    static async create(object: PostUnityDto) {
        const createFunction = httpsCallable(FirebaseFunctions.functions, "createUnity")
        const dto = (await createFunction(object)).data as GetUnityDto
        await UnityStoreDao.putUnity(unityDtoAsDomain(dto));
    }

    static async delete(object: DeleteUnityDto): Promise<void> {
        const deleteFunction = httpsCallable(FirebaseFunctions.functions, "deleteUnity")
        const result = (await deleteFunction(object)).data as boolean
        if (result) await UnityStoreDao.deleteById(object.id)
    }

    static async getUnity(unityId: number, refresh: boolean = false) {
        return await this.getUnityBy(unityId, refresh);
    }

    static async update(object: PutUnityDto) {
        const updateFunction = httpsCallable(FirebaseFunctions.functions, "editUnity")
        const result = (await updateFunction(object)).data as PutUnityDto["data"]
        await UnityStoreDao.updateById(object.id, result);
    }

    private static async getUnityBy(
        by: number,
        refresh: boolean = false
    ) : Promise<Unity| undefined>{
        let localUnity = await UnityStoreDao.getUnityById(by)
        if (!localUnity || refresh) {
            const firestoreUnity = await this.firestoreRepository.getByReference(
                `${this.firestoreRepository.path}/${by}`,
                refresh
            );
            if (firestoreUnity) {
                const unity = unityDtoAsDomain(
                    firestoreUnity.data,
                );
                await UnityStoreDao.putUnity(unity);
                return await this.injectUnity(unity);
            }
        } else {
            if (localUnity) {
                return await this.injectUnity(localUnity);
            }
        }
    }

    private static async injectUnity(unity: Unity): Promise<Unity> {
        const userCount = await UserStoreDao.getUserCountForUnity(unity.id);
        const clientList = await UserStoreDao.getClientsForUnity(unity.id);
        unity.operatorCount = userCount;
        unity.client = clientList;
        return unity;
    }
}
