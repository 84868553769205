import {db} from "../db";
import {Patrol} from "../../../domain/patrol/Patrol";

export class PatrolDao {
    static async putPatrol(...patrol: Patrol[]) {
        return db.patrol.bulkPut(patrol);
    }

    static async getAllUnityPatrol(unityId: number, start: number, end: number) {
        return db.patrol
            .where(["unityId+timestamp"])
            .between([unityId, start], [unityId, end], true, true)
            .reverse()
            .sortBy("timestamp");
    }

    static async getAllPatrol(start: number, end: number) {
        return db.patrol
            .where("timestamp")
            .between(start, end, true, true)
            .reverse()
            .sortBy("timestamp");
    }

    static async deleteAllUnityPatrol(
        unityId: number,
        start: number,
        end: number
    ) {
        return db.patrol
            .where(["unityId+timestamp"])
            .between([unityId, start], [unityId, end], true, true)
            .delete();
    }

    static async deleteAllPatrol(start: number, end: number) {
        return db.patrol
            .where("timestamp")
            .between(start, end, true, true)
            .delete();
    }

    static async getUserPatrolList(ownerUid: string, start: number, end: number) {
        return db.patrol
            .where(["ownerUid+timestamp"])
            .between([ownerUid, start], [ownerUid, end])
            .reverse()
            .sortBy("timestamp");
    }

    static async getLastPatrolAboveTimestamp(preferredTimestamp: number) {
        return db.patrol.where("timestamp").aboveOrEqual(preferredTimestamp).last();
    }

    static async deleteAllUserPatrol(
        ownerUid: string,
        start: number,
        end: number
    ) {
        return db.patrol
            .where(["ownerUid+timestamp"])
            .between([ownerUid, start], [ownerUid, end])
            .delete();
    }

    static async getPatrol(reference: string) {
        return db.patrol.get(reference);
    }
}
