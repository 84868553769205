import { FC, useState } from "react";
import { isEmpty, isEqual } from "lodash";
import { copyDifferentProperties, copySchema } from "../../../util/object";
import { FormModal, FormModalProps } from "./FormModal";

export type EditableFormModalProps = Omit<FormModalProps, "onOk"> & {
  onOk: (data: any, modified: boolean) => void;
};

export const EditableFormModal: FC<
  Omit<Omit<EditableFormModalProps, "okButtonDisabled">, "onValuesChanged">
> = (props) => {
  const [okButtonDisabled, setOkButtonDisabled] = useState(true);
  return (
    <FormModal
      onValuesChanged={(values) => {
        let initialParsedValues = copySchema(values, props.initialValues);
        let changed = copyDifferentProperties(initialParsedValues, values);
        setOkButtonDisabled(isEmpty(changed));
      }}
      okButtonDisabled={okButtonDisabled}
      {...props}
      onOk={async (values: any) => {
        let initialParsedValues = copySchema(values, props.initialValues);
        let changed = copyDifferentProperties(
          initialParsedValues,
          values
        ) as any;
        if (props.onOk)
          props.onOk(changed, !isEqual(initialParsedValues, values));
      }}
    />
  );
};
