import {db} from "../db";
import {Incident} from "../../../domain/incident/Incident";

export class IncidentDao {
    static async putIncident(...incident: Incident[]) {
        return db.incident.bulkPut(incident);
    }

    static async getAllUnityIncident(unityId: number, start: number, end: number) {
        return db.incident
            .where(["unityId+timestamp"])
            .between([unityId, start], [unityId, end], true, true)
            .reverse()
            .sortBy("timestamp");
    }

    static async deleteAllUnityIncident(
        unityId: number,
        start: number,
        end: number
    ) {
        return db.incident
            .where(["unityId+timestamp"])
            .between([unityId, start], [unityId, end], true, true)
            .delete();
    }

    static async getUserIncidentList(ownerUid: string, start: number, end: number) {
        return db.incident
            .where(["ownerUid+timestamp"])
            .between([ownerUid, start], [ownerUid, end])
            .reverse()
            .sortBy("timestamp");
    }

    static async deleteAllUserIncident(
        ownerUid: string,
        start: number,
        end: number
    ) {
        return db.incident
            .where(["ownerUid+timestamp"])
            .between([ownerUid, start], [ownerUid, end])
            .delete();
    }

    static async getAllIncident(start: number, end: number) {
        return db.incident
            .where("timestamp")
            .between(start, end, true, true)
            .reverse()
            .sortBy("timestamp");
    }

    static async deleteAllIncident(
        start: number,
        end: number
    ) {
        return db.incident
            .where("timestamp")
            .between(start, end, true, true)
            .delete();
    }

    static async getIncident(reference: string) {
        return db.incident.get(reference)
    }
}
