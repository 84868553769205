import {PentrackerUser} from "../../../../domain/user/User";
import React, {FC} from "react";
import {FaIcon} from "../../../components/fontawesome/icon";
import {faBuilding} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

type Props = {
    user: PentrackerUser;
};
export const UserRowUnity: FC<Props> = ({user}) => {
    return user.unityId ? (
        <p className={"m-0 p-0"}>
            <FaIcon icon={faBuilding} className={"mr-2 text-neutral-400"}/>
            <Link to={`/dashboard/unity/${user.unityId}`}>
                {user.unity ? user.unity.label : user.unityId}
            </Link>
        </p>
    ) : null;
};
