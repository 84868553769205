import { FC, useContext } from "react";
import { BreakPointContext } from "../../../../context/BreakPointContext";
import { AttendancePair } from "../../../../../domain/attendance/Attendance";
import { AttendanceList } from "../../../data-display/attendance-list";
import { AttendanceTable } from "../../../data-display/attendance-table";

type Props = {
  items?: AttendancePair[];
  onPreview: (item: AttendancePair) => void;
  onClick: (user: AttendancePair) => void;
};

export const UnityAttendanceDataName = "UnityAttendanceData";
export const UnityAttendanceData: FC<Props> = ({
  items,
  onPreview,
  onClick,
}) => {
  const screenSize = useContext(BreakPointContext);
  if (screenSize.isMobile) {
    return (
      <AttendanceList
        onSelect={onClick}
        attendanceList={items}
        onPreviewClicked={onPreview}
        name={UnityAttendanceDataName}
      />
    );
  } else {
    return (
      <AttendanceTable
        name={UnityAttendanceDataName}
        userAttendanceList={items}
        onPreviewClicked={onPreview}
        onSelect={onClick}
      />
    );
  }
};
