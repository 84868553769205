import { FC, forwardRef, ReactNode, useEffect, useRef, useState } from "react";
import {
  Button,
  Form,
  FormGroupProps,
  FormInstance,
  IconButton,
  Input,
  InputGroup,
  InputNumber,
  InputProps,
  Modal,
  SelectPicker,
  SelectPickerProps,
} from "rsuite";
import { Send } from "@rsuite/icons";
import { isEmpty, keysIn } from "lodash";

type BasicTypeWithStringProperties = { [key: string]: any };

export type FormModalProps = {
  clear?: boolean | null | undefined;
  title: string;
  onOk?: (values: any, clear?: () => void) => void;
  onCancel?: () => void;
  inputList: InputDataList<any>;
  disabled?: boolean;
  visible?: boolean;
  initialValues?: any | undefined;
  onValuesChanged?: (values: any) => void;
  okButtonDisabled?: boolean;
  okButtonLoading?: boolean;
  model?: any;
  layout?: "horizontal" | "vertical" | "inline";
};

export enum FormModalInputTypes {
  number = "number",
  text = "text",
  textarea = "textarea",
  password = "password",
  select = "select",
  checkbox = "checkbox",
  custom = "custom",
}

interface InputData<Type extends BasicTypeWithStringProperties>
  extends FormGroupProps {
  type?: `${FormModalInputTypes}`;
  name: Extract<keyof Type, string>;
  label?: string;
  help?: string;
  hidden?: boolean;
  defaultValue?: any;
}

export interface NumberInputData<Type extends BasicTypeWithStringProperties>
  extends InputData<Type> {
  type: "number";
  min?: number;
  max?: number;
  maxLength?: number;
  placeholder?: string;
  addon?: ReactNode;
  defaultValue?: number;
}

export type InputDataList<
  Type extends BasicTypeWithStringProperties = BasicTypeWithStringProperties
> = (
  | NumberInputData<Type>
  | TextInputData<Type>
  | PasswordInputData<Type>
  | TextAreaInputData<Type>
  | SelectInputData<Type>
  | CheckBoxInputData<Type>
  | CustomInputData<Type>
)[];

export interface TextInputData<T extends BasicTypeWithStringProperties>
  extends InputData<T> {
  addon?: ReactNode;
  type: "text" | undefined;
  placeholder?: string;
  defaultValue?: string;
}

export interface PasswordInputData<Type extends BasicTypeWithStringProperties>
  extends InputData<Type> {
  type: "password";
  placeholder?: string;
  defaultValue?: string;
}

export interface TextAreaInputData<Type extends BasicTypeWithStringProperties>
  extends InputData<Type> {
  type: "textarea";
  placeholder?: string;
  rows?: number;
  defaultValue?: string;
}

export interface SelectInputData<Type extends BasicTypeWithStringProperties>
  extends InputData<Type> {
  type: "select";
  options: SelectPickerProps<any>["data"];
  placeholder?: string;
  cleanable?: boolean;
  block?: boolean;
  defaultValue?: any;
}

export interface CheckBoxInputData<Type extends BasicTypeWithStringProperties>
  extends InputData<Type> {
  type: "checkbox";
  defaultValue?: boolean;
}

export interface CustomInputData<Type extends BasicTypeWithStringProperties>
  extends InputData<Type> {
  type: "custom";
  provide: FC<CustomInputProps<Type>>;
  defaultValue?: any;
}

type Values<Type extends BasicTypeWithStringProperties> = {
  [key in keyof Type]: any;
};

export type CustomInputProps<Type extends BasicTypeWithStringProperties> = {
  values: Values<Type>;
  initialValues?: FormModalValues;
  updateValue: (key: Extract<keyof Type, string>, newValue: any) => void;
  disabled: boolean;
  name: Extract<keyof Type, string>;
  errorWrapper: ErrorWrapper;
};

type FormModalValues = {
  [key: string]: any;
};

type ErrorWrapper = {
  hasError?: boolean;
  formError?: {
    [key: string]: string;
  };
};

function getDefaults(inputList: InputDataList) {
  const result: { [key: string]: any } = {};
  inputList.forEach((i) => {
    // @ts-ignore
    result[i.name] = keysIn(i).includes("defaultValue") ? i.defaultValue : null;
  });
  return result;
}

export const FormModal: FC<FormModalProps> = ({
  title,
  onOk,
  onCancel,
  inputList,
  disabled,
  visible,
  initialValues,
  clear,
  onValuesChanged,
  okButtonDisabled,
  okButtonLoading,
  model,
  layout,
}) => {
  const formRef = useRef<FormInstance>(null);
  const ghostButtonRef = useRef<HTMLButtonElement>(null);
  const [values, setValues] = useState<FormModalValues>(getDefaults(inputList));
  const [errorWrapper, setErrorWrapper] = useState<ErrorWrapper>({});
  /* eslint-disable */
  useEffect(() => {
    if (!!initialValues) {
      setValues(initialValues);
      if (onValuesChanged) onValuesChanged(initialValues);
    }
  }, [initialValues]);
  useEffect(() => {
    if (!!clear && clear) {
      resetFields();
      if (onValuesChanged) onValuesChanged({});
    }
  }, [clear]);

  /* eslint-enable */
  function resetFields() {
    const defaults = getDefaults(inputList);
    setValues(defaults);
    if (onValuesChanged) onValuesChanged(defaults);
  }

  function updateValue(key: string, value: any) {
    setValues((oldValues) => ({ ...oldValues, [key]: value }));
  }

  /* eslint-disable */
  useEffect(() => {
    console.log("values", values);
    if (onValuesChanged) onValuesChanged(values);
  }, [values]);
  /* eslint-enable */

  // @ts-ignore
  return (
    <Modal
      size="lg"
      overflow
      open={visible}
      onClose={!!onCancel ? onCancel : undefined}
      title={title}
    >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          ref={formRef}
          model={model as any}
          fluid
          formValue={values}
          onChange={setValues}
          onError={(err) => {
            setErrorWrapper({
              hasError: !isEmpty(err),
              formError: err,
            });
          }}
          layout={layout}
          checkTrigger={"change"}
          onSubmit={(checkState, event) => {
            event.preventDefault();
            if (onOk && checkState) {
              onOk(values, resetFields);
            }
          }}
        >
          {inputList?.map((inputData, i) => (
            <RenderInput
              inputData={inputData}
              values={values}
              // values={{}}
              initialValues={initialValues}
              updateValue={updateValue}
              disabled={disabled ? disabled : false}
              key={`input-${String(inputData.name)}-${i}`}
              errorWrapper={errorWrapper}
            />
          ))}
          <button
            ref={ghostButtonRef}
            type={"submit"}
            style={{
              display: "none",
            }}
          ></button>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={!!onCancel ? onCancel : undefined}>Cancelar</Button>
        <IconButton
          loading={okButtonLoading}
          disabled={okButtonDisabled}
          appearance="primary"
          icon={<Send />}
          onClick={() => {
            ghostButtonRef.current?.click();
          }}
        >
          Enviar
        </IconButton>
      </Modal.Footer>
    </Modal>
  );
};

const RenderInput: FC<{
  inputData: InputData<BasicTypeWithStringProperties>;
  values: FormModalValues;
  updateValue: (key: string, arg: any) => void;
  disabled: boolean;
  initialValues?: FormModalValues;
  errorWrapper: ErrorWrapper;
}> = ({
  inputData,
  values,
  updateValue,
  disabled,
  initialValues,
  errorWrapper,
}) => {
  const { type, name, label, help } = inputData;
  let Control: ReactNode = null;
  switch (type) {
    case "custom":
      const Renderer: FC<CustomInputProps<BasicTypeWithStringProperties>> = (
        inputData as CustomInputData<BasicTypeWithStringProperties>
      ).provide;
      Control = (
        <Renderer
          values={values as any}
          updateValue={updateValue}
          disabled={disabled}
          name={name}
          initialValues={initialValues}
          errorWrapper={errorWrapper}
        />
      );
      break;
    case "text":
      let data1 = inputData as TextInputData<BasicTypeWithStringProperties>;
      let Input = (
        <Form.Control
          name={name}
          placeholder={data1.placeholder}
          disabled={disabled}
          value={values[name]}
          onChange={(value) => {
            updateValue(name, value);
          }}
        />
      );
      Control = data1.addon ? (
        <InputGroup>
          <InputGroup.Addon>{data1.addon}</InputGroup.Addon>
          {Input}
        </InputGroup>
      ) : (
        Input
      );
      break;
    case "number":
      let data2 = inputData as NumberInputData<BasicTypeWithStringProperties>;
      let NumberInput = (
        <Form.Control
          name={name}
          accepter={InputNumber}
          min={data2.min}
          max={data2.max}
          maxLength={data2.maxLength}
          placeholder={data2.placeholder}
          disabled={disabled}
          value={values[name]}
          onChange={(value: any) => {
            updateValue(name, value);
          }}
        />
      );
      Control = data2.addon ? (
        <InputGroup>
          <InputGroup.Addon>{data2.addon}</InputGroup.Addon>
          {NumberInput}
        </InputGroup>
      ) : (
        NumberInput
      );
      break;
    case "textarea":
      let data3 = inputData as TextAreaInputData<BasicTypeWithStringProperties>;
      Control = (
        <Form.Control
          name={name}
          accepter={Textarea}
          {...{ rows: data3.rows }}
          disabled={disabled}
          value={values[name]}
          onChange={(value: any) => {
            updateValue(name, value);
          }}
        />
      );
      break;
    case "password":
      let data4 = inputData as PasswordInputData<BasicTypeWithStringProperties>;
      Control = (
        <Form.Control
          name={name}
          placeholder={data4.placeholder}
          type={"password"}
          autoComplete={"off"}
          disabled={disabled}
          value={values[name]}
          onChange={(value: any) => {
            updateValue(name, value);
          }}
        />
      );
      break;
    case "select":
      let data5 = inputData as SelectInputData<BasicTypeWithStringProperties>;
      Control = (
        <Form.Control
          name={name}
          accepter={SelectPicker}
          placeholder={data5.placeholder}
          {...{
            data: data5.options,
            cleanable: data5.cleanable,
            block: data5.block,
          }}
          disabled={disabled}
          value={values[name]}
          onChange={(value: any) => {
            updateValue(name, value);
          }}
        />
      );
      break;
  }
  return (
    <Form.Group controlId={`${name}-1`}>
      {label ? <Form.ControlLabel>{label}</Form.ControlLabel> : null}
      {Control}
      {help ? <Form.HelpText>{help}</Form.HelpText> : null}
    </Form.Group>
  );
};

const Textarea = forwardRef((props: InputProps, ref) => (
  <Input {...props} as="textarea" ref={ref as any} />
));
