import {FC, MouseEventHandler} from "react";
import {ListChildComponentProps} from "react-window";
import {Shift} from "../../../../../../domain/user/Shift";
import {IconButton} from "rsuite";
import {FaIcon} from "../../../../../components/fontawesome/icon";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";

type ChildComponentProps = {
    items: Shift[];
    onClick: MouseEventHandler<HTMLDivElement>;
    onDeleteClick: MouseEventHandler<HTMLElement>;
    onEditClick: MouseEventHandler<HTMLElement>;
};

export const ShiftListRowItem: FC<
    ListChildComponentProps<ChildComponentProps>
> = ({index, data, style}) => {
    const {items, onClick, onDeleteClick, onEditClick} = data;
    if (!items) return null;
    const item = items[index];
    if (!item) return null;
    return (
        <div style={style} className={"pb-2"}>
            <div
                data-index={index}
                onClick={onClick}
                className={
                    "w-full bg-white h-full shadow rounded-lg p-2 flex flex-col justify-between gap-2"
                }
            >
                <div className={"flex flex-row gap-x-2 flex-wrap"}>
                    <span className={"uppercase font-semibold"}>ETIQUETA</span>
                    <span>{item.label}</span>
                </div>
                <div className={"flex flex-wrap flex-row gap-x-2"}>
                    <span className={"uppercase font-semibold"}>DESCRIPCIÓN</span>
                    <span>{item.schedule}</span>
                </div>
                <div className={"flex flex-row w-full flex-wrap gap-2"}>
                    <IconButton
                        icon={<FaIcon icon={faEdit}/>}
                        className={"grow"}
                        appearance={"primary"}
                        onClick={onEditClick}
                    >
                        Editar
                    </IconButton>
                    <IconButton
                        onClick={onDeleteClick}
                        icon={<FaIcon icon={faTrash}/>}
                        className={"grow"}
                        appearance={"primary"}
                    >
                        Eliminar
                    </IconButton>
                </div>
            </div>
        </div>
    );
};
