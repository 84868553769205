import {FC} from "react";
import {UserPatrol} from "../../../../../domain/patrol/Patrol";
import {Modal} from "rsuite";
import {UserPatrolView} from "../../../patrol/components/view";

type Props = {
    item: UserPatrol;
    onClose: () => void;
};

export const UserPatrolPreviewModal: FC<Props> = ({item, onClose}) => {
    return (
        <Modal open onClose={onClose}>
            <Modal.Header>
                <Modal.Title>Vista Previa</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <UserPatrolView patrol={item}/>
            </Modal.Body>
        </Modal>
    );
};
