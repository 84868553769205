import React, {FC} from "react";
import {UserPatrol} from "../../../../domain/patrol/Patrol";
import {faUser} from "@fortawesome/free-solid-svg-icons"
import {FaIcon} from "../../../components/fontawesome/icon";

type Props = {
    item: UserPatrol;
};

export const PatrolRowOperator: FC<Props> = ({item}) => {
    const {owner, ownerUid} = item;
    return (
        <div className={"m-0 p-0 flex flex-row items-center flex-wrap gap-2"}>
            <FaIcon icon={faUser} className={"mr-2"}/>
            <span>{owner?.displayName ? owner.displayName : ownerUid}</span>
        </div>
    );
};
