import {UserIncident} from "../../../../domain/incident/Incident";
import React, {FC} from "react";

type Props = {
    item: UserIncident;
};

export const IncidentRowDate: FC<Props> = ({item}) => {
    const {date} = item;
    return (
        <div className={"m-0 p-0 flex flex-row flex-wrap gap-2"}>
            <span className={"text-neutral-400 font-semibold"}>DÍA</span>
            <span>{date}</span>
        </div>
    );
};
