import React, { FC, Fragment, useCallback, useContext, useRef } from "react";
import { Pagination, Table } from "rsuite";
import { useTableSize } from "../../../../hook/width";
import { TableInstance } from "rsuite/Table";
import { TableColumnContext } from "../../../context/TableContext";
import { faCalendarXmark } from "@fortawesome/free-solid-svg-icons";
import { EmptyState } from "../../../components/state/empty";
import { useDataPager } from "../../../../hook/pager";
import {
  GoodsControl,
  UserGoodsControl,
} from "../../../../domain/control/Goods";
import { GoodsControlTableTimestampCell } from "./timestamp";
import { GoodsControlTableUnityCell } from "./unity";

import { useStyleContext } from "../../../context/StyleContext";

type Props = {
  goodsControlList?: UserGoodsControl[];
  name: string;
  onSelect: (item: UserGoodsControl) => void;
};

export const GoodsControlTable: FC<Props> = ({
  goodsControlList,
  name,
  onSelect,
}) => {
  const tableRef = useRef<TableInstance<any, any>>(null);
  const tableSize = useTableSize(tableRef);
  const tableContext = useContext(TableColumnContext);
  const tablePreferences = tableContext.getTablePreferences<GoodsControl>(name);

  const {
    tableHeaderStyle,
    tableHeaderStyleSecondary,
    stylePage,
    primaryColors,
  } = useStyleContext();

  const styleContext = useStyleContext();
  /* eslint-disable */

  const { partialData, changePage, page, changeLimit, limits, limit } =
    useDataPager({
      id: `goods_table_${name}`,
      items: goodsControlList,
    });

  const onRowSelect = useCallback(
    (item: UserGoodsControl) => {
      onSelect(item);
    },
    [partialData]
  );

  /* eslint-enable */

  return (
    <Fragment>
      <Table
        className={"rounded md:rounded-xl"}
        autoHeight
        data={partialData}
        ref={tableRef}
        renderEmpty={() => (
          <EmptyState
            icon={faCalendarXmark}
            title={"No hay registros de control de bienes"}
            message={
              "No se encontraron reportes de control de bienes para la fecha seleccionada."
            }
          />
        )}
        onRowClick={onRowSelect as any}
        cellBordered
      >
        {tablePreferences.map((column) => {
          switch (column.name) {
            case "timestamp":
              return (
                <Table.Column
                  verticalAlign={"middle"}
                  align={"center"}
                  key={`column-${column.name}`}
                  resizable
                  width={tableSize.calculateWeight(column.weight)}
                  onResize={(columnWidth) => {
                    if (columnWidth) {
                      tableContext.updateTableColumnWeight<GoodsControl>(
                        name,
                        column.name,
                        tableSize.sizeToWeight(columnWidth)
                      );
                    }
                  }}
                >
                  <Table.HeaderCell style={tableHeaderStyle}>
                    {column.label}
                  </Table.HeaderCell>
                  <GoodsControlTableTimestampCell />
                </Table.Column>
              );
            case "unityId":
              return (
                <Table.Column
                  verticalAlign={"middle"}
                  align={"center"}
                  key={`column-${column.name}`}
                  resizable
                  width={tableSize.calculateWeight(column.weight)}
                  onResize={(columnWidth) => {
                    if (columnWidth) {
                      tableContext.updateTableColumnWeight<GoodsControl>(
                        name,
                        column.name,
                        tableSize.sizeToWeight(columnWidth)
                      );
                    }
                  }}
                >
                  <Table.HeaderCell style={tableHeaderStyle}>
                    {column.label}
                  </Table.HeaderCell>
                  <GoodsControlTableUnityCell />
                </Table.Column>
              );
            case "provider":
              return (
                <Table.Column
                  verticalAlign={"middle"}
                  align={"center"}
                  key={`column-${column.name}`}
                  resizable
                  width={tableSize.calculateWeight(column.weight)}
                  onResize={(columnWidth) => {
                    if (columnWidth) {
                      tableContext.updateTableColumnWeight<GoodsControl>(
                        name,
                        column.name,
                        tableSize.sizeToWeight(columnWidth)
                      );
                    }
                  }}
                >
                  <Table.HeaderCell style={tableHeaderStyle}>
                    {column.label}
                  </Table.HeaderCell>
                  <Table.Cell dataKey={"provider"} />
                </Table.Column>
              );
            case "document":
              return (
                <Table.Column
                  verticalAlign={"middle"}
                  align={"center"}
                  key={`column-${column.name}`}
                  resizable
                  width={tableSize.calculateWeight(column.weight)}
                  onResize={(columnWidth) => {
                    if (columnWidth) {
                      tableContext.updateTableColumnWeight<GoodsControl>(
                        name,
                        column.name,
                        tableSize.sizeToWeight(columnWidth)
                      );
                    }
                  }}
                >
                  <Table.HeaderCell style={tableHeaderStyle}>
                    {column.label}
                  </Table.HeaderCell>
                  <Table.Cell dataKey={"document"} />
                </Table.Column>
              );
            default:
              return (
                <Table.Column
                  align={"center"}
                  verticalAlign={"middle"}
                  key={`column-${column.name}`}
                  resizable
                  width={tableSize.calculateWeight(column.weight)}
                  onResize={(columnWidth) => {
                    if (columnWidth) {
                      tableContext.updateTableColumnWeight<GoodsControl>(
                        name,
                        column.name,
                        tableSize.sizeToWeight(columnWidth)
                      );
                    }
                  }}
                >
                  <Table.HeaderCell style={tableHeaderStyle}>
                    {column.label}
                  </Table.HeaderCell>
                  <Table.Cell dataKey={"entry"} />
                </Table.Column>
              );
          }
        })}
      </Table>

      {goodsControlList ? (
        <div className={"py-4 flex flex-col gap-4"}>
          <span className={"text-md"}>
            Mostrando {partialData?.length} elementos / Total{" "}
            {goodsControlList.length}
          </span>
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="xs"
            layout={["limit", "|", "pager", "skip"]}
            total={goodsControlList.length}
            limitOptions={limits}
            limit={limit}
            activePage={page}
            onChangePage={changePage}
            onChangeLimit={changeLimit}
          />
        </div>
      ) : null}
    </Fragment>
  );
};
