import {FC} from "react";
import {CellProps, Table} from "rsuite";
import {UserIncident} from "../../../../domain/incident/Incident";


export const IncidentTableZoneCell: FC<CellProps<UserIncident>> = ({rowData, ...props}) => {
    if (!rowData) return null
    const {zone, submoduleId} = rowData
    return <Table.Cell {...props}>
        {
            submoduleId ? (
                <span>{zone ? zone.label : submoduleId}</span>
            ) : (
                <span className={"italic"}>No especificado</span>
            )
        }
    </Table.Cell>
}
