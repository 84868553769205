import { DateTime, DateTimeUnit } from "luxon";
import { db } from "../../data/database/db";
import { useContext, useState } from "react";
import { toInteger } from "lodash";
import { AttendanceRepository } from "../../data/repository/AttendanceRepository";
import { UserAttendance } from "../../domain/attendance/Attendance";
import UnityRepository from "../../data/repository/UnityRepository";
import { getCalendar } from "../../lib/date";
import { UserAuthContext } from "../../ui/context/UserContext";

export type DailyAttendance = {
  date: Date;
  formattedDate: string;
  total: number | undefined;
};

export type PeriodicAttendanceWrapper = {
  total: number;
  data: DailyAttendance[];
};

export type ByUnityAttendance = {
  name: string;
  total: number;
};

export type AttendancePeriodicCount = {
  label: string;
  total: number;
};

export function useOverviewAttendanceGroupViewModel() {
  const [lastWeekAttendance, setLastWeekAttendance] =
    useState<PeriodicAttendanceWrapper>();
  const [selectedPeriodAttendance, setSelectedPeriodAttendance] =
    useState<PeriodicAttendanceWrapper>();

  const [byUnityAttendance, setByUnityAttendance] = useState<
    ByUnityAttendance[]
  >([]);

  const [todayEntries, setTodayEntries] = useState<UserAttendance[]>([]);

  const [attendancePeriodicCount, setAttendancePeriodicCount] = useState<
    AttendancePeriodicCount[]
  >([]);

  const { appUser } = useContext(UserAuthContext);

  async function fetchAttendancePeriodicCount() {
    const result: AttendancePeriodicCount[] = [];
    const today = DateTime.now().setZone("America/Lima").startOf("day");
    const todayCount = await getPeriodAttendance(
      today.toJSDate(),
      "day",
      "day"
    );
    const yesterdayCount = await getPeriodAttendance(
      today.minus({ day: 1 }).toJSDate(),
      "day",
      "day"
    );
    const lastWeekCount = await getPeriodAttendance(
      today.toJSDate(),
      "week",
      "week"
    );
    const lastMonthCount = await getPeriodAttendance(
      today.toJSDate(),
      "month",
      "month"
    );
    result.push({
      label: "Hoy",
      total: todayCount.total,
    });
    result.push({
      label: "Ayer",
      total: yesterdayCount.total,
    });
    result.push({
      label: "Última semana",
      total: lastWeekCount.total,
    });
    result.push({
      label: "Último mes",
      total: lastMonthCount.total,
    });
    setAttendancePeriodicCount(result);
  }

  async function fetchLastWeekAttendance() {
    const thisWeekAttendance = await getPeriodAttendance(
      new Date(),
      "week",
      "week"
    );
    setLastWeekAttendance(thisWeekAttendance);
  }

  async function fetchPeriodAttendance(date: Date) {
    const periodAttendance = await getPeriodAttendance(date, "month", "month");
    setSelectedPeriodAttendance(periodAttendance);
  }

  async function fetchLastEntries() {
    const today = DateTime.now()
      .setZone("America/Lima")
      .startOf("day")
      .toJSDate();
    const entries = await db.entries
      .where("timestamp")
      .aboveOrEqual(today.getTime())
      .reverse()
      .toArray();
    const task = await Promise.all(
      entries.map(async (entry) => {
        return await AttendanceRepository.injectAttendance(entry);
      })
    );
    setTodayEntries(task);
  }

  async function getPeriodAttendance(
    date: Date,
    fromStartOf: DateTimeUnit,
    toEndOf: DateTimeUnit
  ): Promise<PeriodicAttendanceWrapper> {
    const calendar = getCalendar(date, fromStartOf, toEndOf);
    const result = [];
    let totalCount: number = 0;
    for (const date of calendar) {
      const dateTime = DateTime.fromJSDate(date);
      const start = dateTime.startOf("day").toMillis();
      const end = dateTime.endOf("day").toMillis();
      const count = await db.entries
        .where("timestamp")
        .between(start, end, true, true)
        .count();
      totalCount += count;
      result.push({
        date,
        total: toInteger(count),
        formattedDate: dateTime.toFormat("dd MMM"),
      });
    }
    return {
      total: totalCount,
      data: result,
    };
  }

  async function fetchByUnityAttendance() {
    const today = DateTime.now()
      .setZone("America/Lima")
      .startOf("day")
      .toJSDate();
    const unityList = await UnityRepository.getList(false, appUser);
    const entries = await db.entries
      .where("timestamp")
      .aboveOrEqual(today.getTime())
      .toArray();
    const result: Array<ByUnityAttendance> = [];
    if (!!unityList) {
      for (let unity of unityList) {
        const count = entries.filter((it) => it.unityId === unity.id).length;
        result.push({
          name: unity.label,
          total: count,
        });
      }
      setByUnityAttendance(result);
    }
  }

  return {
    fetchAttendancePeriodicCount,
    attendancePeriodicCount,
    fetchLastWeekAttendance,
    fetchPeriodAttendance,
    selectedPeriodAttendance,
    lastWeekAttendance,
    fetchLastEntries,
    todayEntries,
    byUnityAttendance,
    fetchByUnityAttendance,
  };
}
