import {CSSProperties} from "react";

export const TableHeaderStyle: CSSProperties = {
    backgroundColor: "#382c22",
    color: "#FAFAFA",
};

export const TableHeaderStyleSecondary: CSSProperties = {
    color: "#212121",
    fontWeight: 550,
    textTransform: "uppercase"
};
