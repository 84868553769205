import {TypeAttributes} from "rsuite/cjs/@types/common";
import {useContext} from "react";
import {BreakPointContext} from "../ui/context/BreakPointContext";

type Config = {
    max?: TypeAttributes.Size;
    min?: TypeAttributes.Size;
};

export function useComponentSize(config?: Config): TypeAttributes.Size {
    const breakpoint = useContext(BreakPointContext);
    const ranking: { [key in TypeAttributes.Size]: number } = {
        xs: 0,
        sm: 1,
        md: 2,
        lg: 3,
    };
    let result: TypeAttributes.Size;
    switch (breakpoint.breakpoint) {
        case "xs":
            result = "xs";
            break;
        case "md":
            result = "md";
            break;
        case "sm":
            result = "sm";
            break;
        default:
            result = "lg";
            break;
    }
    if (!!config?.min) {
        if (ranking[result] < ranking[config.min]) return config.min;
    }
    if (!!config?.max) {
        if (ranking[result] > ranking[config.max]) return config.max;
    }
    return result;
}
