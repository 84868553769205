import {FC, useEffect, useRef, useState} from "react";
import {DatePicker} from "rsuite";
import {Bar, BarChart, Brush, CartesianGrid, Legend, ReferenceLine, Tooltip, XAxis, YAxis} from "recharts";
import {PeriodicPatrolWrapper} from "../../../../../viewmodel/patrol-group/OverviewPatrolGroup";

type Props = {
    periodicPatrol?: PeriodicPatrolWrapper
    onDateChanged: (date: Date) => void
}

export const PatrolOverviewPeriodicPatrol: FC<Props> = ({periodicPatrol, onDateChanged}) => {
    const [selectedPeriod, setSelectedPeriod] = useState<Date>(new Date());
    const mainGraphicParentRef = useRef<HTMLDivElement>(null);
    const [mainGraphicSize, setMainGraphicSize] = useState<{
        width: number;
        height: number;
    }>({
        width: 300,
        height: 300,
    });


    /*eslint-disable*/
    useEffect(() => {
        const div = mainGraphicParentRef.current;
        if (div) {
            setMainGraphicSize({
                width: div.clientWidth - 40,
                height: div.clientHeight,
            });
        }
    }, [mainGraphicParentRef.current]);

    useEffect(() => {
        onDateChanged(selectedPeriod)
    }, [selectedPeriod]);
    /*eslint-enable */
    return <div
        className={
            "w-full h-full flex flex-col overflow-x-hidden bg-white rounded shadow-sm py-4"
        }
    >
        <div
            className={
                "w-full flex flex-row flex-wrap px-4 md:px-12 items-center justify-center md:justify-between gap-2"
            }
        >
            <p className={"m-0 p-0 text-lg font-semibold text-neutral-800"}>
                Estado de Rondas
            </p>
            <DatePicker
                format="yyyy-MM"
                placeholder={"Año / Mes"}
                ranges={[]}
                value={selectedPeriod}
                onChange={(newVal) => {
                    if (!!newVal) setSelectedPeriod(newVal);
                }}
                style={{width: 200}}
            />
        </div>
        <div className={"w-full h-fit flex-1"} ref={mainGraphicParentRef}>
            <BarChart
                width={mainGraphicSize.width}
                height={mainGraphicSize.height}
                data={periodicPatrol?.data}
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="formattedDate"/>
                <YAxis/>
                <Tooltip/>
                <Legend
                    verticalAlign="top"
                    wrapperStyle={{lineHeight: "40px"}}
                />
                <ReferenceLine y={0} stroke="#000"/>
                <Brush dataKey="formattedDate" height={30} stroke="#C4191C"/>
                <Bar dataKey="total" fill="#C4191C"/>
            </BarChart>
        </div>
    </div>
}
