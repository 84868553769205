import {createContext, FC, PropsWithChildren} from "react";
import secureLocalStorage from "react-secure-storage";

type SecureContextType = {
    getMainSecret: () => string | undefined;
    updateMainSecret: (newSecret: string) => boolean;
};

export const SecureContext = createContext<SecureContextType | undefined>(
    undefined
);

export const SecureContextProvider: FC<PropsWithChildren> = ({children}) => {
    const updateMainSecret: SecureContextType["updateMainSecret"] = function (
        newSecret
    ) {
        secureLocalStorage.setItem("secret", newSecret);
        return true;
    };

    const getMainSecret = () =>
        secureLocalStorage.getItem("secret") as string | undefined;

    return (
        <SecureContext.Provider
            value={{
                updateMainSecret,
                getMainSecret,
            }}
        >
            {children}
        </SecureContext.Provider>
    );
};
