import {FC, useEffect} from "react";
import {CustomInputProps} from "../../../../../components/modal/FormModal";
import {NewUser} from "../../../../../../domain/user/User";
import {useUnityListViewModel} from "../../../../../../viewmodel/unity/UnityList";
import {Loader, SelectPicker} from "rsuite";
import {toNumber} from "lodash";
import {UserRoles} from "../../../../../../domain/user/Role";

const Component: FC<CustomInputProps<NewUser>> = ({
                                                      name,
                                                      updateValue,
                                                      values,
                                                  }) => {
    const viewModel = useUnityListViewModel();

    /* eslint-disable */
    useEffect(() => {
        void viewModel.fetchUnityList();
    }, []);

    useEffect(() => {
        if (viewModel.fetchListState && !viewModel.fetchListState.loading) {
            if (viewModel.fetchListState.isFailed()) {
                window.toast.error(
                    "Ocurrió un error al obtener la lista de unidades para el operador."
                );
            }
        }
    }, [viewModel.fetchListState]);
    /*eslint-enable */
    return (
        <div className={"flex flex-col gap-1"}>
            <p>Unidad de Operador</p>
            {viewModel.fetchListState?.loading ? (
                <Loader content={"Cargando..."}/>
            ) : (
                <SelectPicker
                    block
                    data={viewModel.unityList || []}
                    onSelect={(selection) => {
                        updateValue(name, toNumber(selection));
                    }}
                    onClean={() => {
                        updateValue(name, null);
                    }}
                    labelKey={"label"}
                    valueKey={"id"}
                    value={values[name]}
                    cleanable
                />
            )}
        </div>
    );
};

export const NewUserUnitySelectInput: FC<CustomInputProps<NewUser>> = (
    props
) => {
    if (
        [UserRoles.groupboss, UserRoles.CCTV, UserRoles.agent].includes(
            props.values.roleId
        )
    ) {
        return <Component {...props} />;
    }
    return null;
};
