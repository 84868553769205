import { FC, useCallback, useContext, useEffect } from "react";
import { IconButton, Loader } from "rsuite";
import { FaIcon } from "../../../components/fontawesome/icon";
import { faAdd, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { TicketContainerList } from "./components/container-list";
import { TicketFileType } from "../../../../domain/ticket/UserTicket";
import { NewContainerFolderModal } from "./components/modal";
import { sortTicketFilesByStringDate } from "../../../../data/repository/TicketRepository";
import { useUserTicketListViewModel } from "../../../../viewmodel/tickets/TicketsContainerList";

export const UserTicketsGroupContainerFolderList: FC<any> = () => {
  const {
    fetchState,
    fetchUserTicketFolderList,
    onFetchStateReceived,
    folderList,
    refreshEvent,
    requestRefreshEvent,
    onRefreshEventCompleted,
    addEvent,
    requestAddEvent,
    onAddEventCompleted,
    addNewFolder,
    addState,
    onAddStateReceived,
  } = useUserTicketListViewModel();
  const navigate = useNavigate();


  /* eslint-disable */

  useEffect(() => {
    if (fetchState && !fetchState.isLoading()) {
      if (fetchState.isFailed()) {
        window.toast.error(
          "Ocurrió un error al obtener la lista de carpetas de boletas."
        );
        console.log(fetchState?.error);
      }
      onFetchStateReceived();
    }
  }, [fetchState]);
  useEffect(() => {
    if (addState && !addState.isLoading()) {
      if (addState.isFailed()) {
        window.toast.error(addState.error?.message);
        console.log(addState?.error);
      }
      onAddStateReceived();
      onAddEventCompleted();
    }
  }, [addState]);

  useEffect(() => {
    void fetchUserTicketFolderList();
  }, []);

  useEffect(() => {
    if (refreshEvent) {
      void fetchUserTicketFolderList(true);
      void onRefreshEventCompleted();
    }
  }, [refreshEvent]);

  const onListItemSelected = useCallback((item: TicketFileType) => {
    navigate(`/dashboard/tickets/${encodeURIComponent(item.name)}`);
  }, []);

  /* eslint-enable */
  return (
    <div className={"w-full h-full flex flex-col gap-2 overflow-hidden p-3"}>
      {addEvent ? (
        <NewContainerFolderModal
          onOk={addNewFolder}
          onCancel={onAddEventCompleted}
        />
      ) : null}
      <div className={"flex flex-row justify-end"}>
        <div className={"flex flex-row flex-wrap items-center gap-2"}>
          <IconButton
            appearance={"primary"}
            size={"sm"}
            onClick={requestRefreshEvent}
            icon={<FaIcon icon={faRefresh} />}
            disabled={fetchState?.isLoading()}
          >
            Actualizar
          </IconButton>

          <IconButton
            appearance={"primary"}
            size={"sm"}
            onClick={requestAddEvent}
            icon={<FaIcon icon={faAdd} />}
            disabled={fetchState?.isLoading()}
          >
            Añadir
          </IconButton>
        </div>
      </div>
      {fetchState?.isLoading() ? (
        <Loader center backdrop size={"sm"} content={"Cargando..."} />
      ) : (
        <div
          className={
            "flex-1 w-full h-full flex justify-center sm:items-center overflow-hidden"
          }
        >
          <div className={"w-full h-full flex flex-col"}>
            <div className={"w-full flex-1 overflow-x-hidden"}>
              <TicketContainerList
                items={
                  folderList
                    ? folderList.sort(sortTicketFilesByStringDate)
                    : undefined
                }
                onClick={onListItemSelected}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
