import { FC, useCallback } from "react";
import { faCancel, faTrash } from "@fortawesome/free-solid-svg-icons";
import { TableMultiselectHeader } from "../../../../../components/table/header";

type OnClick = () => void;

type Props = {
  selectedItems: Array<string>;
  onDeleteClicked: OnClick;
  onCancelClicked: OnClick;
};

export const RoleTableMultiselectHeader: FC<Props> = ({
  selectedItems,
  onCancelClicked,
  onDeleteClicked,
}) => {
  /* eslint-disable */
  const onCancel = useCallback(() => {
    onCancelClicked();
  }, [selectedItems]);
  const onDelete = useCallback(() => {
    onDeleteClicked();
  }, [selectedItems]);
  /* eslint-enable */
  return (
    <TableMultiselectHeader
      selectedItems={selectedItems}
      buttons={[
        {
          icon: faTrash,
          onClick: onDelete,
          text: "Eliminar",
        },
        {
          icon: faCancel,
          onClick: onCancel,
          text: "Cancelar",
        },
      ]}
      entityName={"cargo"}
    />
  );
};
