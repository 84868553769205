import React, { FC, useContext, useEffect } from "react";
import { useUserViewModel } from "../../../viewmodel/user/User";
import { Outlet, useNavigate } from "react-router-dom";
import { DeleteConfirmationModal } from "../../components/modal/DeleteConfirmationModal";
import {
  PentrackerUser,
  pentrackerUserAsNewUserData,
} from "../../../domain/user/User";
import { UserPageMainHeader } from "./components/Header";
import { UserPageNavigator } from "./components/Navigator";
import { EditUserModal } from "../userlist/main/modal/EditUserModal";
import { AppLoader } from "../../components/loading/LoadingOverlay";
import { DashboardHeaderContext } from "../../../hook/header";
import { FormModal } from "../../components/modal/FormModal";
import {
  DeleteUserFormSchema,
  DeleteUserInputList,
} from "../userlist/main/modal/InputList";
import { useStyleContext } from "../../context/StyleContext";

export type UserPageOutletContext = [PentrackerUser];

export const UserPage: FC = () => {
  const navigate = useNavigate();
  const dashboardHeaderContext = useContext(DashboardHeaderContext);
  const {
    editEvent,
    requestEditEvent,
    onEditStateReceived,
    onEditEventCompleted,
    editState,
    editUser,
    deleteEvent,
    deleteState,
    deleteUser,
    requestDeleteEvent,
    onDeleteStateReceived,
    onDeleteEventCompleted,
    user,
    fetchUser,
    fetchState,
    requestRefreshEvent,
    onRefreshEventCompleted,
    refreshEvent,
    onFetchStateReceived,
  } = useUserViewModel();
  const {
    stylePage,
  } = useStyleContext();
  /* eslint-disable */
  useEffect(() => {
    if (editState && !editState.loading) {
      const error = editState.error;
      if (error) {
        window.toast.error(
          error.message || "Ocurrió un error al editar el usuario."
        );
        console.error(error);
      } else {
        window.toast.success("Usuario editado correctamente.");
      }
      onEditStateReceived();
      onEditEventCompleted();
    }
  }, [editState]);

  useEffect(() => {
    if (fetchState && !fetchState.loading) {
      const error = fetchState.error;
      if (error) {
        window.toast.error(
          error.message ||
            "Ocurrió un error al actualizar la información del usuario."
        );
        console.error(error);
      }
      onFetchStateReceived();
      onRefreshEventCompleted();
    }
  }, [fetchState]);

  useEffect(() => {
    if (deleteState && !deleteState.loading) {
      const error = deleteState.error;
      if (error) {
        window.toast.error(
          error.message || "Ocurrió un error al eliminar el usuario."
        );
        console.error(error);
      } else {
        window.toast.success("Usuario eliminado correctamente.");
      }
      onDeleteStateReceived();
      onDeleteEventCompleted();
      navigate(-1);
    }
  }, [deleteState]);

  useEffect(() => {
    if (refreshEvent === true) {
      console.log("refresh resquest");
      void fetchUser();
      onRefreshEventCompleted();
    }
  }, [refreshEvent]);

  useEffect(() => {
    dashboardHeaderContext.setTheme({
      className: "bg-neutral-100",
      subtitle: `${user.displayName || ""}`,
      title: "Usuario",
      homeAsUpEnabled: true,
    });
  }, []);

  /*eslint-enable*/

  return (
    <div
      className={"w-full overflow-y-auto overflow-x-hidden"}
      style={stylePage}
    >
      <AppLoader
        isActive={
          fetchState?.isLoading() ||
          editState?.isLoading() ||
          deleteState?.isLoading()
        }
      />
      <UserPageMainHeader
        openUser={user}
        onEditClick={requestEditEvent}
        onDeleteClick={requestDeleteEvent}
        onRefreshClick={requestRefreshEvent}
        stylePage={stylePage}
      />
      <div
        className={
          "w-full flex flex-col lg:flex-row border-t-2 border-neutral-200"
        }
      >
        <div className={"w-full md:w-fit p-2 md:p-3 lg:py-5"}>
          <div
            className={
              "lg:bg-white lg:rounded-xl lg:overflow-hidden lg:border lg:border-neutral-300"
            }
          >
            <UserPageNavigator openingUser={user} />
          </div>
        </div>
        <div className={"w-full overflow-y-auto p-2 md:p-3 lg:py-3 lg:px-5"}>
          <Outlet context={[user]} />
        </div>
      </div>

      {deleteEvent ? (
        <FormModal
          visible={true}
          onCancel={onDeleteEventCompleted}
          title={"Eliminar Usuario"}
          onOk={deleteUser}
          inputList={DeleteUserInputList}
          model={DeleteUserFormSchema}
        />
      ) : null}

      {editEvent ? (
        <EditUserModal
          onOk={editUser}
          onCancel={onEditEventCompleted}
          initialValues={pentrackerUserAsNewUserData(user)}
        />
      ) : null}
    </div>
  );
};
