import React, {FC} from "react";
import {UserIncident} from "../../../../domain/incident/Incident";
import {ListChildComponentProps} from "react-window";
import {IconButton} from "rsuite";
import {FaIcon} from "../../../components/fontawesome/icon";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {IncidentRowTimestamp} from "./timestamp";
import {IncidentRowTime} from "./time";
import {IncidentRowDate} from "./date";
import {IncidentRowType} from "./type";
import {IncidentRowUnity} from "./unity";
import {IncidentRowZone} from "./zone";

export type IncidentRowProps = {
    userIncidentList: UserIncident[];
    onPreviewClicked: React.MouseEventHandler<HTMLElement>;
    onSelect: React.MouseEventHandler<HTMLDivElement>;
};

export const IncidentRow: FC<ListChildComponentProps<IncidentRowProps>> = ({
                                                                               data,
                                                                               index,
                                                                               style,
                                                                           }) => {
    const {onPreviewClicked, onSelect, userIncidentList} = data;
    const item = userIncidentList[index];
    return (
        <div style={style} className={"py-2"}>
            <div
                data-index={index}
                onClick={onSelect}
                className={
                    "w-full h-full border border-neutral-300 rounded-lg p-2 flex flex-col justify-between content-between"
                }
            >
                <div className={"flex w-full flex-col gap-2"}>
                    <IncidentRowTimestamp item={item}/>
                    <IncidentRowDate item={item}/>
                    <IncidentRowTime item={item}/>
                    <IncidentRowType item={item}/>
                    <IncidentRowUnity item={item}/>
                    <IncidentRowZone item={item}/>
                </div>
                <IconButton appearance={"primary"} icon={<FaIcon icon={faEye}/>} onClick={onPreviewClicked}>
                    Vista Previa
                </IconButton>
            </div>
        </div>
    );
};
