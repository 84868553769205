import {useState} from "react";
import {Response} from "../../domain/app/Response";
import {PentrackerUser} from "../../domain/user/User";
import {AppTimestamp} from "../../domain/app/Timestamp";
import {UserLocation} from "../../domain/location/UserLocation";
import {UserLocationRepository} from "../../data/repository/UserLocationRepository";

export function useUserLocationViewModel(user: PentrackerUser) {
    const [fetchState, setFetchState] = useState<Response<boolean> | null>(null);
    const [userLocationList, setUserLocationList] = useState<UserLocation[] | undefined>();
    const [userLocationTimestamp, setUserLocationTimestamp] = useState<AppTimestamp | undefined>()
    const [refreshEvent, setRefreshEvent] = useState<boolean | null>(null);

    async function fetchUserLocationList(
        timestamp: number,
        forceRefresh: boolean = false
    ) {
        if (fetchState?.isLoading()) return;
        setFetchState(Response.loading());
        try {
            const result = await UserLocationRepository.getUserList(
                timestamp,
                user.uid,
                forceRefresh
            );
            setUserLocationList(result?.locationList);
            setUserLocationTimestamp(result?.timestamp)
            setFetchState(Response.success(true));
        } catch (e: any) {
            setFetchState(Response.failure(e));
        }
    }

    function onFetchStateReceived() {
        setFetchState(null);
    }

    function requestRefreshEvent() {
        setRefreshEvent(true);
    }

    function onRefreshEventCompleted() {
        setRefreshEvent(null);
    }

    return {
        fetchState,
        onFetchStateReceived,
        userLocationList,
        userLocationTimestamp,
        fetchUserLocationList,
        refreshEvent,
        requestRefreshEvent,
        onRefreshEventCompleted,
    };
}
