import {AccountType, FirestoreAccountType,} from "../../domain/account-type/AccountType";
import {useState} from "react";
import ResponseBuilder, {Response} from "../../domain/app/Response";
import {AccountTypeRepository} from "../../data/repository/AccountTypeRepository";
import {useLiveQuery} from "dexie-react-hooks";
import {db} from "../../data/database/db";

export default function useAccountTypeViewModel() {
    const types = useLiveQuery(() => db.account_types.toArray())
    const [addEvent, setAddEvent] = useState<Boolean | null>(null);
    const [refreshEvent, setRefreshEvent] = useState<Boolean | null>(null);
    const [deleteEvent, setDeleteEvent] = useState<AccountType | null>(null);
    const [fetchState, setFetchState] = useState<Response<boolean> | null>(null);
    const [createState, setCreateState] = useState<Response<boolean> | null>(
        null
    );
    const [deleteState, setDeleteState] = useState<Response<boolean> | null>(
        null
    );

    async function fetchList(forceRefresh: boolean = false) {
        if (fetchState?.loading) return
        setFetchState(ResponseBuilder.loading());
        try {
            await AccountTypeRepository.fetchList(forceRefresh)
            setFetchState(ResponseBuilder.success(true));
        } catch (err: any) {
            setFetchState(ResponseBuilder.failure(err));
        }
    }

    function onFetchListStateReceived() {
        setFetchState(null);
    }

    function requestAddEvent() {
        setAddEvent(true);
    }

    function onAddEventCompleted() {
        setAddEvent(null);
    }

    function requestRefreshEvent() {
        setRefreshEvent(true);
    }

    function onRefreshEventCompleted() {
        setRefreshEvent(null);
    }

    const allLoadingObserver =
        (!!fetchState && fetchState.loading) ||
        (!!createState && createState.loading) ||
        (!!deleteState && deleteState.loading);

    async function createAccountType(accountType: FirestoreAccountType) {
        setCreateState(ResponseBuilder.loading());
        try {
            await AccountTypeRepository.createAccountType(
                accountType
            );
            setCreateState(ResponseBuilder.success(true));
        } catch (e: any) {
            setCreateState(ResponseBuilder.failure(e));
        }
    }

    function onCreateStateReceived() {
        setCreateState(null);
    }

    function requestDeleteEvent(arg0: AccountType) {
        setDeleteEvent(arg0);
    }

    function onDeleteEventCompleted() {
        setDeleteEvent(null);
    }

    async function deleteAccountType() {
        setDeleteState(ResponseBuilder.loading());

        if (deleteEvent == null) return setDeleteState(ResponseBuilder.failure(new Error("No se ha seleccionado ningún elemento para eliminar.")))

        try {
            await AccountTypeRepository.deleteAccountType(deleteEvent.reference)
            setDeleteState(ResponseBuilder.success(true));
        } catch (e: any) {
            setDeleteState(ResponseBuilder.failure(e));
        }
    }

    function onDeleteStateReceived() {
        setDeleteState(null);
    }

    return {
        types,
        fetchState,
        fetchList,
        onFetchListStateReceived,
        addEvent,
        refreshEvent,
        requestAddEvent,
        onAddEventCompleted,
        requestRefreshEvent,
        onRefreshEventCompleted,
        createState,
        createAccountType,
        onCreateStateReceived,
        allLoadingObserver,
        requestDeleteEvent,
        onDeleteEventCompleted,
        deleteAccountType,
        onDeleteStateReceived,
        deleteEvent,
        deleteState
    };
}
