import { FC, useContext } from "react";
import { BreakPointContext } from "../../../../context/BreakPointContext";
import { UserIncident } from "../../../../../domain/incident/Incident";
import { IncidentList } from "../../../data-display/incident-list";
import { IncidentTable } from "../../../data-display/incident-table";

type Props = {
  userIncidentList?: UserIncident[];
  onPreviewClicked: (item: UserIncident) => void;
  onSelect: (item: UserIncident) => void;
};

export const UserIncidentDataName = "UserIncidentData";

export const UserIncidentData: FC<Props> = ({
  userIncidentList,
  onPreviewClicked,
  onSelect,
}) => {
  const screenSize = useContext(BreakPointContext);
  if (screenSize.isMobile) {
    return (
      <IncidentList
        name={UserIncidentDataName}
        onSelect={onSelect}
        onPreviewClicked={onPreviewClicked}
        userIncidentList={userIncidentList}
      />
    );
  } else {
    return (
      <IncidentTable
        name={UserIncidentDataName}
        userIncidentList={userIncidentList}
        onPreviewClicked={onPreviewClicked}
        onSelect={onSelect}
      />
    );
  }
};
