import {NewShift, Shift, shiftDtoAsDomain} from "../../domain/user/Shift";
import {GetShiftDto, PostShiftDto, PutShiftDto, shiftAsPutDto,} from "../../network/user/Shift";
import {FirestoreSimpleCrudSource} from "../source/FirestoreSimpleCrudSource";
import {ShiftDao} from "../database/dao/Shift";
import {isEmpty, toNumber} from "lodash";
import {where} from "firebase/firestore";

export default class ShiftRepository {
    static firestoreRepository = new FirestoreSimpleCrudSource<
        GetShiftDto,
        PostShiftDto,
        PutShiftDto
    >("shift");

    static async create(object: NewShift) {
        if (!object.id) object.id = Date.now();
        else object.id = toNumber(object.id);
        const reference = await this.firestoreRepository.create(object, object.id);
        await ShiftDao.putShift({
            ...object,
            reference: reference.path,
        });
    }

    static async delete(reference: string): Promise<void> {
        await this.firestoreRepository.delete(reference);
        await ShiftDao.delete(reference);
    }

    static async fetchList(forced: boolean = false) {
        const localList = await ShiftDao.getList();
        if (isEmpty(localList) || forced) {
            const firestoreList = await this.firestoreRepository.getList(
                null
            );
            await ShiftDao.clear();
            await ShiftDao.putShift(
                ...firestoreList.map((shiftDto) =>
                    shiftDtoAsDomain(shiftDto.data, shiftDto.reference)
                )
            );
        }
    }

    static async getList(forced: boolean = false): Promise<Shift[]> {
        const localList = await ShiftDao.getList();
        if (isEmpty(localList) || forced) {
            const firestoreList = await this.firestoreRepository.getList(
                null
            );
            await ShiftDao.clear();
            await ShiftDao.putShift(
                ...firestoreList.map((shiftDto) =>
                    shiftDtoAsDomain(shiftDto.data, shiftDto.reference)
                )
            );
            return ShiftDao.getList();
        } else {
            return localList;
        }
    }

    static async update(shift: Shift, newData: Partial<Shift>) {
        await this.firestoreRepository.update(
            shift.reference,
            shiftAsPutDto(newData)
        );
        await ShiftDao.update(shift.reference, {...shift, ...newData});
    }

    static async getShift(id: number, forced: boolean = false) {
        const localShift = await ShiftDao.getShiftById(id);
        if (!localShift || forced) {
            const firestoreShift = await this.firestoreRepository.getByQuery([
                where("id", "==", id),
            ]);
            if (firestoreShift) {
                await ShiftDao.putShift(
                    shiftDtoAsDomain(firestoreShift.data, firestoreShift.reference)
                );
                return ShiftDao.getShiftById(id);
            }
        } else {
            return localShift;
        }
    }

    static async fetchShift(id: number, forced: boolean = false) {
        const localShift = await ShiftDao.getShiftById(id);
        if (!localShift || forced) {
            const firestoreShift = await this.firestoreRepository.getByQuery([
                where("id", "==", id),
            ]);
            if (firestoreShift) {
                await ShiftDao.putShift(
                    shiftDtoAsDomain(firestoreShift.data, firestoreShift.reference)
                );
            }
        }
    }
}
