import {Firestore} from "firebase/firestore";

export default class FirestoreSource {
    static firestore: Firestore;
}


export type DataWithReference<T> = {
    data: T
    reference: string
}
