import { useState } from "react";
import { Response } from "../../domain/app/Response";
import FirebaseAuth from "../../data/source/FirebaseAuth";
import { LoginFormData } from "../../ui/page/auth/components/loginform";

export function useAuthViewModel() {
  const [fetchState, setFetchState] = useState<Response<
    Awaited<ReturnType<typeof FirebaseAuth.getAdminCredentials>>
  > | null>(null);
  const [loginState, setLoginState] = useState<Response<boolean> | null>(null);
  async function fetchCredentials(uid: string) {
    if (fetchState?.loading) return;
    setFetchState(Response.loading());
    try {
      const result = await FirebaseAuth.getAdminCredentials(uid);
      setFetchState(Response.success(result));
    } catch (e: any) {
      setFetchState(Response.failure(e));
    }
  }

  async function login(credentials: LoginFormData) {
    if (loginState?.loading) return;
    setLoginState(Response.loading());
    try {
      await FirebaseAuth.login(credentials.email, credentials.password);
      setLoginState(Response.success(true));
    } catch (e: any) {
      setLoginState(
        Response.failure(new Error("Los datos ingresados son incorrectos"))
      );
    }
  }

  function onAuthStateReceived() {
    setFetchState(null);
  }

  function onLoginStateReceived() {
    setLoginState(null);
  }

  return {
    fetchState,
    onAuthStateReceived,
    fetchCredentials,
    login,
    loginState,
    onLoginStateReceived,
  };
}
