import { FC, useCallback, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLivePatrolGroupViewModel } from "../../../../viewmodel/patrol-group/LivePatrolGroup";
import { UserPatrol } from "../../../../domain/patrol/Patrol";
import { PatrolGroupLiveData } from "./components/data";
import { LivePatrolModal } from "./components/modal";
import { DashboardHeaderContext } from "../../../../hook/header";

export const LivePatrolGroup: FC<any> = () => {
  const {
    connect,
    connectionState,
    onConnectionStateReceived,
    list,
    onSelectItemCompleted,
    requestSelectItem,
    selectedItem,
  } = useLivePatrolGroupViewModel();

  const navigate = useNavigate();
  const dashboardHeaderContext = useContext(DashboardHeaderContext);

  /* eslint-disable */
  useEffect(() => {
    void connect();
    dashboardHeaderContext.setTheme({
      className: "bg-secondary-header",
      title: "Rondas",
      dark: true,
      subtitle: "Ver en tiempo real",
    });
  }, []);

  const onTableAttendanceSelected = useCallback((item: UserPatrol) => {
    navigate(
      `/dashboard/patrol?reference=${encodeURIComponent(item.reference)}`
    );
  }, []);

  const onTablePreviewActionClicked = useCallback((item: UserPatrol) => {
    requestSelectItem(item);
  }, []);

  useEffect(() => {
    if (!!connectionState && !connectionState.isLoading()) {
      if (connectionState.isFailed()) {
      }
      onConnectionStateReceived();
    }
  }, [connectionState]);

  /* eslint-enable */

  return (
    <div className={"w-full overflow-x-hidden"}>
      {!!selectedItem ? (
        <LivePatrolModal
          onClose={onSelectItemCompleted}
          patrol={selectedItem}
        />
      ) : null}
      <PatrolGroupLiveData
        items={list}
        onClick={onTableAttendanceSelected}
        onPreview={onTablePreviewActionClicked}
      />
    </div>
  );
};
