import { UploadMetadata } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { RefObject } from "react";
import { renderToString } from "react-dom/server";

export function getUniqueFileName(file: File) {
  let parts = file.name.split(".");
  let ext: string = parts[parts.length - 1];
  return !!ext ? `${uuidv4()}.${ext}` : uuidv4();
}

export function getFileTypeMetadata(file: File): UploadMetadata | undefined {
  return {
    contentType: file.type,
  };
}

export function getFileType(file: File) {
  return file.type;
}

export const getBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export function humanFileSize(bytes: number) {
  const units = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let i = 0;
  while (bytes >= 1024) {
    bytes /= 1024;
    ++i;
  }
  return `${bytes.toFixed(2)} ${units[i]}`;
}

export const previewPdfFile = async (
  options: {
    fileUrl: string;
    title?: string;
  },
  ref?: RefObject<HTMLDivElement>
) => {
  const { fileUrl, title } = options;
  const targetElement = ref?.current;
  if (targetElement) {
    const existingChild = targetElement.getElementsByTagName("iframe");
    if (existingChild.length <= 0) {
      const iframe = document.createElement("iframe");
      iframe.src = fileUrl;
      iframe.classList.add("w-full");
      iframe.classList.add("h-full");
      targetElement.appendChild(iframe);
    }
  } else {
    window.open(fileUrl);
  }
};
