import {GetPatrolDto} from "../../network/patrol/Patrol";
import {Unity} from "../unity";
import {Zone} from "./Zone";
import {PentrackerUser} from "../user/User";

export type Patrol = Omit<GetPatrolDto, "timestamp"> & {
    reference: string
    timestamp: number
}

export type UserPatrol = Omit<GetPatrolDto, "timestamp"> & {
    reference: string
    unity?: Unity
    zone?: Zone
    owner?: PentrackerUser
    timestamp: number
}

export const patrolDtoAsDomain = (patrolDto: GetPatrolDto, reference: string): Patrol => {
    return {
        ...patrolDto,
        reference,
        timestamp: patrolDto.timestamp.toDate().getTime()
    }
}

export const patrolAsUserPatrol = (patrol: Patrol, unity ?: Unity, zone ?: Zone, owner ?: PentrackerUser): UserPatrol => {
    return {
        ...patrol,
        unity,
        zone,
        owner
    }
}
