import React, { FC, MutableRefObject, useCallback, useRef } from "react";
import { TicketContainerListRow } from "./row";
import { Pagination } from "rsuite";
import { EmptyState } from "../../../../../components/state/empty";
import { TicketFileType } from "../../../../../../domain/ticket/UserTicket";
import { useDataPager } from "../../../../../../hook/pager";

type TicketContainerListProps = {
  items ?: TicketFileType[]
  onClick: (item: TicketFileType) => void
};

export const TicketContainerList: FC<TicketContainerListProps> = ({
  items, onClick
}) => {

  /* eslint-disable */

  const { limits, changeLimit, limit, page, partialData, changePage } =
    useDataPager<TicketFileType>({
      id: `ticket_container_list`,
      items,
    });


  const onSelectRow = useCallback<React.MouseEventHandler<HTMLDivElement>>(
    (event) => {
      if (!partialData) return;
      const index = event.currentTarget.dataset["index"] as unknown as number;
      const item = partialData[index];
      if (item) {
        onClick(item);
      }
    },
    [partialData]
  );

  /* eslint-enable */

  return items && items.length > 0 ? ( //@ts-ignore
    <div className={"w-full h-auto"}>
      {
        partialData?.map((inc, i) =>
          <TicketContainerListRow
            index={i}
            style={{}}
            key={`incident-row-${inc.path}`}
            data={{
              userTicketContainerList: partialData,
              onSelect: onSelectRow,
            }}
          />
        )
      }
      <div className={"py-4 flex flex-col gap-4"}>
        <span className={"text-md"}>
          Mostrando {partialData?.length} elementos
        </span>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={["limit", "pager"]}
          total={items.length}
          limitOptions={limits}
          limit={limit}
          activePage={page}
          onChangePage={changePage}
          onChangeLimit={changeLimit}
        />
      </div>
    </div>
  ) : (
    <EmptyState
      title={"Sin elementos"}
      message={
        "Pulsa sobre Nuevo para agregar una carpeta"
      }
    />
  );
};
