import {Unity} from "../../../domain/unity";
import {db} from "../db";

export class UnityStoreDao {
    static async putUnity(...unity: Unity[]) {
        await db.unities.bulkPut(unity);
    }

    static async getUnityById(id: number) {
        return db.unities.where("id").equals(id).first()
    }

    static async clear() {
        await db.unities.clear();
    }

    static async deleteById(id: number) {
        return db.unities.where("id").equals(id).delete()
    }

    static async updateById(id: number, newData: Partial<Unity>) {
        await db.unities.update(id, newData);
    }

    static async getList() {
        return db.unities.toArray()
    }
}
