import { useEffect, useState } from "react";
import UnityRepository from "../../data/repository/UnityRepository";
import { Response } from "../../domain/app/Response";
import { AppUnity, PutUnity, Unity } from "../../domain/unity";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../data/database/db";
import { useLoaderData } from "react-router-dom";
import { ignorePaths } from "../../util/object";

export function useUnityViewModel() {
  const loadedUnity = useLoaderData() as Unity;
  const [unity, setUnity] = useState<AppUnity | undefined>(loadedUnity);
  const [fetchState, setFetchState] = useState<Response<boolean> | null>(null);
  const [deleteEvent, setDeleteEvent] = useState<boolean | null>(null);
  const [editEvent, setEditEvent] = useState<AppUnity | null>(null);
  const [deleteState, setDeleteState] = useState<Response<boolean> | null>(
    null
  );
  const [editState, setEditState] = useState<Response<boolean> | null>(null);
  const [refreshEvent, setRefreshEvent] = useState<boolean | null>(null);

  const unityObserver = useLiveQuery(() =>
    db.unities.where("id").equals(loadedUnity.id).first()
  );

  /*eslint-disable*/
  useEffect(() => {
    void fetchUnity();
  }, [unityObserver]);

  /*eslint-enable*/
  async function fetchUnity(refresh: boolean = false) {
    if (fetchState?.loading) return;
    setFetchState(Response.loading());
    try {
      const unity = await UnityRepository.getUnity(loadedUnity.id, refresh);
      setFetchState(Response.success(true));
      setUnity(unity);
    } catch (e: any) {
      setFetchState(Response.failure(e));
      setUnity(undefined);
    }
  }

  function onFetchStateReceived() {
    setFetchState(null);
  }

  function requestRefreshEvent() {
    setRefreshEvent(true);
  }

  function onRefreshEventCompleted() {
    setRefreshEvent(null);
  }

  function requestEditEvent() {
    setEditEvent(unity!!);
  }

  function onEditEventCompleted() {
    setEditEvent(null);
  }

  async function editUnity(newData: PutUnity) {
    if (editState?.loading) return;
    setEditState(Response.loading());
    try {
      if (!unity) {
        setEditState(Response.failure(new Error("La unidad no es válida.")));
        return;
      }
      await UnityRepository.update({
        id: unity.id,
        reason: newData.reason,
        data: {
          ...ignorePaths(newData, "reason")
        }
      });
      setEditState(Response.success(true));
    } catch (e: any) {
      setEditState(Response.failure(e));
    }
  }

  function onEditStateReceived() {
    setEditState(null);
  }

  function requestDeleteEvent() {
    setDeleteEvent(true);
  }

  function onDeleteEventCompleted() {
    setDeleteEvent(null);
  }

  async function deleteUnity({ reason }: { reason: string }) {
    if (deleteState?.loading) return;
    try {
      if (!unity) {
        setDeleteState(Response.failure(new Error("La unidad no es válida.")));
        return;
      }
      await UnityRepository.delete({
        id: unity.id,
        reason
      });
      setDeleteState(Response.success(true));
    } catch (e: any) {
      setDeleteState(Response.failure(e));
    }
  }

  function onDeleteStateReceived() {
    setDeleteState(null);
  }

  return {
    unity,
    fetchState,
    deleteEvent,
    editEvent,
    deleteState,
    editState,
    refreshEvent,
    fetchUnity,
    onFetchStateReceived,
    requestRefreshEvent,
    onRefreshEventCompleted,
    requestEditEvent,
    onEditEventCompleted,
    editUnity,
    onEditStateReceived,
    requestDeleteEvent,
    onDeleteEventCompleted,
    deleteUnity,
    onDeleteStateReceived
  };
}
