import { Schema } from "rsuite";
import { isNotBlank } from "../../../../../util/validation";
import { InputDataList } from "../../../../components/modal/FormModal";
import { ReasonInputSelector } from "../../../../components/input/ReasonSelector";
import { DeleteUser } from "../../../../../domain/user";

export const DeleteUserFormSchema = Schema.Model({
  reason: Schema.Types.StringType().isRequired("Es necesario completar este campo.").addRule(isNotBlank(10), "Es nombre debe contener al menos 10 caracteres.")
});

export const DeleteUserInputList: InputDataList<DeleteUser> = [
  {
    name: "reason",
    label: "Motivo de cambio",
    type: "custom",
    help: "Detalle el motivo de la eliminación",
    defaultValue: undefined,
    provide: (props) => <ReasonInputSelector {...props as any}
                                             options={["Nuevo cliente", "Adición de cliente existente"]} />
  }
];
