// @ts-ignore
import withImageLoader from "react-image-loader-hoc";
import {FC, Fragment, HTMLProps, useState} from "react";
//@ts-ignore
import Lightbox from "react-awesome-lightbox";

export type ImageLoaderProps = Omit<HTMLProps<HTMLImageElement>, "src"> & {
    src: string | undefined | null;
    errorComp?: FC;
    loaderComp?: FC;
    showPreview?: boolean
};

const Image = (props: ImageLoaderProps) => (
    // @ts-ignore
    <img alt="" {...props} loading="lazy"/>
);
const ImageWithLoader = withImageLoader(Image);

export function ImageLoader({ showPreview = true, ...props }: ImageLoaderProps) {
    const [previewVisible, setPreviewVisible] = useState(false);
    return (
        <Fragment>
            {showPreview && previewVisible ? (
                <Lightbox
                    image={props.src}
                    title={props.alt}
                    onClose={() => {
                        setPreviewVisible(false);
                    }}
                />
            ) : null}
            <ImageWithLoader
                {...props}
                fadeIn={false}
                onClick={() => {
                    setPreviewVisible((p) => !p);
                }}
            />
        </Fragment>
    );
}
