import {httpsCallable} from "firebase/functions";
import FirebaseFunctions from "./FirebaseFunctions";
import {NewUserDto, UserDto, UserListQueryRequest,} from "../../network/user/User";
import { DeleteUser } from "../../domain/user";
import { UserNotification } from "../../domain/user/UserNotification";

export class FirebaseUser {

    async sendNotification(data: UserNotification) {
        const sendNotificationFunction = httpsCallable(FirebaseFunctions.functions, "sendNotification")
        await sendNotificationFunction(data)
    }
    async createUser(newUser: NewUserDto): Promise<UserDto | undefined> {
        const createUserFunction = httpsCallable(
            FirebaseFunctions.functions,
            "createUser"
        );
        let result = await createUserFunction(newUser);
        return result.data as UserDto;
    }

    async editUser(
        uid: string,
        reason: string,
        newUser: Partial<NewUserDto>
    ): Promise<UserDto | undefined> {
        const editUserFunction = httpsCallable(
            FirebaseFunctions.functions,
            "editUser"
        );
        const result = await editUserFunction({
            uid,
            data: newUser,
            reason
        });
        return result.data as UserDto;
    }

    async deleteUser(data: DeleteUser) {
        const deleteUserFunction = httpsCallable(
            FirebaseFunctions.functions,
            "deleteUser"
        );
        return await (
            await deleteUserFunction(data)
        ).data;
    }

    async getUsers({
                       order,
                       orderBy,
                   }: UserListQueryRequest): Promise<UserDto[] | undefined> {
        const getUserList = httpsCallable(FirebaseFunctions.functions, "getUsers");
        const result = await getUserList({
            order,
            orderBy,
        });
        return result.data as UserDto[];
    }

    async searchUser(
        keyword: string | null
    ): Promise<UserDto[] | undefined> {
        const searchFunction = httpsCallable(
            FirebaseFunctions.functions,
            "searchUser"
        );
        const result = await searchFunction({
            keyword,
        });
        return result.data as UserDto[];
    }

    async getUser(uid: string) {
        const getUserFunction = httpsCallable(
            FirebaseFunctions.functions,
            "getUser"
        );
        const result = await getUserFunction({uid});
        return result.data as UserDto;
    }
}
