import { FC } from "react";
import { ReactComponent as Logo } from "../../../../resources/logo_nocolor.svg";
import { FaIcon } from "../../../components/fontawesome/icon";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

export type DashboardFooterProps = {
  stylePage?: any;
};

export const DashboardFooter: FC<DashboardFooterProps> = ({ stylePage }) => {
  return (
    <div
      className={"w-full flex flex-col break-all bg-secondary-footer"}
      style={stylePage}
    >
      <hr className={"border-any-app border-4 my-0"} />
      <div className={"w-full"}>
        <div className={"w-full grid grid-cols-1 md:grid-cols-2 gap-y-4"}>
          <div
            className={
              "flex flex-col gap-4 px-3 py-4 md:px-8 md:py-4 lg:px-16 lg:py-8 shadow"
            }
          >
            <div className={"w-1/2 sm:w-48 md:w-64 m-auto md:m-0 fill-white"}>
              <Logo className={"w-full h-fit fill-white"} />
            </div>
            <p className={"text-neutral-400 text-sm text-justify"}>
              Pentágono Seguridad Industrial es una empresa peruana, con más de
              6 años de experiencia en el mercado, ofreciendo servicios de
              seguridad a nivel nacional. Brindamos asesoramiento acorde a las
              necesidades y buscamos en conjunto la mejor solución que se adapte
              a tu empresa.
            </p>
            <div className={"flex flex-col"}>
              <a
                className={"text-white hover:text-white hover:text-opacity-50"}
                href={"https://pentagonosegur.pe/"}
                rel={"_noreferrer"}
                target={"_blank"}
              >
                <FaIcon icon={faArrowRight} /> Ir a Pentágono Seguridad
              </a>
            </div>
          </div>
          <div
            className={
              "flex flex-col gap-4 px-3 py-4 md:px-8 md:py-4 lg:px-16 lg:py-8 shadow"
            }
          >
            <span
              className={"text-sm md:text-lg font-semibold text-neutral-300"}
            >
              Contactar con area de Sistemas
            </span>
            <a
              className={"text-white hover:text-white hover:text-opacity-50"}
              href={"https://wa.me/51986576864"}
              rel={"_noreferrer"}
              target={"_blank"}
            >
              <FaIcon icon={faArrowRight} /> Whatsapp
            </a>
            <a
              className={"text-white hover:text-white hover:text-opacity-50"}
              href={"tel:51986576864"}
              rel={"_noreferrer"}
              target={"_blank"}
            >
              <FaIcon icon={faArrowRight} /> Teléfono
            </a>
            <a
              className={"text-white hover:text-white hover:text-opacity-50"}
              href={
                "mailto:sistemas@pentagonosegur.com.pe?subject=Solicitud%20desde%20Centro%20de%20Control%20-%20Pentracker"
              }
              rel={"_noreferrer"}
              target={"_blank"}
            >
              <FaIcon icon={faArrowRight} /> Correo Electrónico
            </a>
          </div>
        </div>
      </div>

      <div
        className={
          "p-4 flex items-center justify-center text-neutral-200 text-center bg-black bg-opacity-20 text-xs font-light"
        }
      >
        © 2023 Pentágono. Todos los derechos reservados.
      </div>
    </div>
  );
};
