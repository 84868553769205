import { FC } from "react";
import { OnClick } from "../../../../components/table/header";
import { FormModal } from "../../../../components/modal/FormModal";
import { UserTicketMonths } from "../../../../../domain/ticket/UserTicket";
import { range } from "lodash";
import { Schema } from "rsuite";

type Props = {
  onOk: (values: { month: string; year: string }) => void;
  onCancel: OnClick;
};

export const NewContainerFolderModal: FC<Props> = ({ onCancel, onOk }) => {
  return (
    <FormModal
      title={"Nueva Carpeta"}
      layout={"inline"}
      inputList={[
        {
          label: "Mes",
          type: "select",
          options: UserTicketMonths.map((month) => ({
            label: month,
            value: month,
          })),
          name: "month",
        },
        {
          label: "Año",
          type: "select",
          options: range(2023, 2033).map((year) => ({
            label: String(year),
            value: String(year),
          })),
          name: "year",
        },
      ]}
      model={Schema.Model({
        month: Schema.Types.StringType().isRequired("Seleccione un mes."),
        year: Schema.Types.StringType().isRequired("Seleccione un año."),
      })}
      visible={true}
      onCancel={onCancel}
      onOk={onOk}
    />
  );
};
