import {db} from "../db";
import {AccountType} from "../../../domain/account-type/AccountType";

export class AccountTypeDao {
    static async putAccountType(...accountType: AccountType[]) {
        await db.account_types.bulkPut(accountType);
    }

    static async getAccountType(reference: string) {
        return db.account_types.get({reference});
    }

    static async getAccountTypeById(id: number) {
        return db.account_types.where("id").equals(id).first()
    }

    static async clear() {
        await db.account_types.clear();
    }

    static async delete(reference: string) {
        await db.account_types.delete(reference);
    }

    static async update(reference: string, newData: Partial<AccountType>) {
        await db.account_types.update(reference, newData);
    }

    static async getList() {
        return db.account_types.toArray()
    }
}
