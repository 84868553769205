import {FirestoreSimpleCrudSource} from "../source/FirestoreSimpleCrudSource";
import {
    GetNewsItemDto,
    newsItemAsPostDto,
    newsItemAsPutDto,
    PostNewsItemDto,
    PutNewsItemDto,
} from "../../network/news/NewsItemDto";
import {NewsItem, newsItemFromDto, newsItemFromPartial,} from "../../domain/news/NewsItem";
import {orderBy, Timestamp, where} from "firebase/firestore";
import { DateTime } from "luxon";
import { NewsDao } from "../database/dao/News";
import { isEmpty } from "lodash";

export class NewsRepository {
    static firestoreNewsRepository = new FirestoreSimpleCrudSource<
        GetNewsItemDto,
        PostNewsItemDto,
        PutNewsItemDto
    >("news");

    static async postNewsItem(item: Partial<NewsItem>): Promise<void> {
        const newReference = await this.firestoreNewsRepository.create(
            newsItemAsPostDto(item)
        );
        await NewsDao.putNews(newsItemFromPartial(newReference.path, item))
    }

    static async deleteNewsItem(item: NewsItem) {
        await this.firestoreNewsRepository.delete(item.reference);
        await NewsDao.delete(item.reference)
    }

    static async updateNewsItem(oldItem: NewsItem, newItem: Partial<NewsItem>) {
        await this.firestoreNewsRepository.update(
            oldItem.reference,
            newsItemAsPutDto(newItem)
        );
        await NewsDao.update(oldItem.reference, newItem)
    }

    static async fetchNews(monthMillis: number, forceRefresh : boolean = false) {
        const dateTime = DateTime.fromMillis(monthMillis).setZone("America/Lima");
        const monthStartTimestamp = dateTime.startOf("month").toMillis();
        const monthEndTimestamp = dateTime.endOf("month").toMillis();
        const local = await NewsDao.getAll(monthStartTimestamp, monthEndTimestamp)
        if(isEmpty(local) || forceRefresh) {
            const newsDto = (await this.firestoreNewsRepository.getList([
                where("timestamp", ">=", Timestamp.fromMillis(monthStartTimestamp)),
                where("timestamp", "<=", Timestamp.fromMillis(monthEndTimestamp)),
                orderBy("timestamp", "desc"),
            ])).map((it) => newsItemFromDto(it.reference, it.data))
            await NewsDao.putNews(...newsDto)
        }
    }
}
