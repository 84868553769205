import { FC, useContext, useEffect } from "react";
import { useOverviewPatrolGroupViewModel } from "../../../../viewmodel/patrol-group/OverviewPatrolGroup";
import { PatrolOverviewPeriodicPatrol } from "./components/periodic";
import { PatrolOverviewLastWeek } from "./components/week";
import { PatrolOverviewLastEntries } from "./components/last";
import { PatrolOverviewPeriodicCount } from "./components/count";
import { PatrolOverviewByUnity } from "./components/unity";
import { faCalendarDay } from "@fortawesome/free-solid-svg-icons";
import { DashboardHeaderContext } from "../../../../hook/header";

export const PatrolGroupOverview: FC = () => {
  const {
    selectedPeriodicPatrol,
    lastWeekPatrol,
    fetchLastWeekPatrol,
    fetchPeriodicPatrol,
    todayEntries,
    fetchLastEntries,
    fetchByUnityPatrol,
    byUnityPatrol,
    fetchPatrolPeriodicCount,
    patrolPeriodicCount,
  } = useOverviewPatrolGroupViewModel();

  const dashboardHeaderContext = useContext(DashboardHeaderContext);

  /*eslint-disable */
  useEffect(() => {
    void fetchLastWeekPatrol();
    void fetchLastEntries();
    void fetchByUnityPatrol();
    void fetchPatrolPeriodicCount();
    dashboardHeaderContext.setTheme({
      className: "bg-secondary-header",
      title: "Rondas",
      dark: true,
      subtitle: "Resúmen",
    });
  }, []);
  /*eslint-enable */
  return (
    <div
      className={
        "w-full h-full grid grid-cols-1 md:grid-cols-4 lg:grid-cols-6 bg-neutral-100 gap-3"
      }
    >
      <div
        className={
          "w-full h-full grid grid-rows-1 md:grid-rows-2 col-span-1 md:col-span-3 lg:col-span-4 gap-3"
        }
      >
        <div className={"w-full h-full hidden md:inline-grid"}>
          <PatrolOverviewPeriodicPatrol
            periodicPatrol={selectedPeriodicPatrol}
            onDateChanged={fetchPeriodicPatrol}
          />
        </div>

        <div className={"grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-3"}>
          <div
            className={
              "w-full h-full md:col-span-2 lg:col-span-3 hidden md:inline-grid"
            }
          >
            <PatrolOverviewLastWeek lastWeekPatrol={lastWeekPatrol} />
          </div>
          <div
            className={"col-span-1 md:col-span-1 lg:col-span-2 w-full h-full"}
          >
            <PatrolOverviewLastEntries entries={todayEntries} />
          </div>
        </div>
      </div>
      <div
        className={
          "w-full h-full col-span-1 md:col-span-1 lg:col-span-2 grid grid-rows-1 md:grid-rows-2 gap-2"
        }
      >
        <div
          className={
            "w-full h-fit bg-white rounded shadow-sm flex flex-col divide-y divide-neutral-200 items-center content-center justify-center"
          }
        >
          {patrolPeriodicCount?.map((count, i) => (
            <PatrolOverviewPeriodicCount
              count={count}
              icon={faCalendarDay}
              key={`count-${i}`}
            />
          ))}
        </div>
        <div className={"w-full h-fit overflow-y-auto"}>
          <PatrolOverviewByUnity unityPatrolList={byUnityPatrol} />
        </div>
      </div>
    </div>
  );
};
