import {db} from "../db";
import {UserLocation} from "../../../domain/location/UserLocation";

export class UserLocationDao {
    static async putUserLocation(...location: UserLocation[]) {
        return db.location.bulkPut(location);
    }

    static async getUserLocationList(uid: string, start: number, end: number) {
        return db.location
            .where(["uid+timestamp"])
            .between([uid, start], [uid, end])
            .reverse()
            .sortBy("timestamp");
    }

    static async deleteAllUserLocation(uid: string, start: number, end: number) {
        return db.location
            .where(["uid+timestamp"])
            .between([uid, start], [uid, end])
            .delete();
    }

    static async getLocation(reference: string) {
        return db.location.where(reference).equals(reference).first();
    }

    static async getLocationList(start: number, end: number) {
        return db.location
            .where("timestamp")
            .between(start, end)
            .reverse()
            .sortBy("timestamp");
    }

    static async clearLocationList(start: number, end: number) {
        return db.location.where("timestamp").between(start, end).delete();
    }
}
