import { FC } from "react";
import { UserDto } from "../../../../network/user/User";
import { Loader } from "rsuite";
import { UserAvatar } from "../../../components/avatar/useravatar";
import { formatDateTime } from "../../../../util/date";
import { Link } from "react-router-dom";
import { AppTimestamp } from "../../../../domain/app/Timestamp";
import { DashboardDataTimestamp } from "./data-timestamp";

type Props = {
  userDataLoading?: boolean;
  lastUsers?: UserDto[];

  timestamp?: AppTimestamp;
  onRefreshClicked: () => void;
};

export const DashboardLatestUsersList: FC<Props> = ({
                                                      userDataLoading,
                                                      lastUsers,
                                                      timestamp,
                                                      onRefreshClicked
                                                    }) => {
  if (userDataLoading === true) {
    return (
      <div className={"w-full h-full flex justify-center items-center overflow-hidden"}>
        <Loader size={"md"} />
      </div>
    );
  }
  if (!lastUsers || lastUsers.length <= 0) {
    return (
      <div
        className={"w-full h-full p-2 md:p-4 flex items-center justify-center overflow-hidden"}
      >
        <span className={"text-lg font-normal text-neutral-700"}>
          No hay usuarios en la lista
        </span>
      </div>
    );
  }
  return (
    <div
      className={
        "w-full h-full flex flex-col gap-4 justify-between items-center relative p-4 overflow-hidden"
      }
    >
      <DashboardDataTimestamp
        position={"tr"}
        timestamp={timestamp}
        loading={userDataLoading}
        onRefreshButtonClicked={onRefreshClicked}
      />

      <span className={"text-lg font-semibold text-neutral-800"}>
       Usuarios registrados
      </span>
      <div
        className={"flex-1 flex flex-col w-full overflow-y-auto overflow-x-hidden divide-y divide-neutral-300 scrollbar-hide md:scrollbar-default"}>
        {lastUsers.map((user) => (
          <div
            className={
              "w-full p-2 flex flex-row gap-2 items-center justify-start"
            }
            key={user.uid}
          >
            <div className={"w-fit h-fit shrink-0"}><UserAvatar user={user} /></div>
            <div className={"flex flex-col shrink truncate"}>
              <span className={"text-sm font-semibold text-neutral-900 truncate"}>
                {user.displayName}
              </span>
              <span className={"text-xs font-normal text-neutral-700 truncate"}>
                {formatDateTime(new Date(user.creationTime))}
              </span>
            </div>
          </div>
        ))}
        <Link to={"/dashboard/users"}>
          <div
            className={
              "w-full py-2 flex justify-center items-center bg-neutral-100 rounded-b-lg"
            }
          >
            <span className={"text-neutral-600"}>Ver más...</span>
          </div>
        </Link>
      </div>
    </div>
  );
};
