import {InputDataList} from "../../../components/modal/FormModal";
import {QuillInput} from "../../../components/input/QuillInput";
import {Schema} from "rsuite";
import {isNotBlank} from "../../../../util/validation";

export const AddNewsItemInputList: InputDataList = [
    {
        name: "title",
        label: "Título",
        type: "text",
        defaultValue: "",
    },
    {
        name: "content",
        label: "Cuerpo",
        type: "custom",
        provide: QuillInput,
        defaultValue: "",
    },
];

export const AddNewsItemModel = Schema.Model({
    title: Schema.Types.StringType()
        .isRequired("Este campo es obligatorio.")
        .addRule(isNotBlank(5), "El título debe contener más de 5 letras."),
    content: Schema.Types.StringType()
        .isRequired("Este campo es obligatorio.")
        .addRule(isNotBlank(50), "El contenido debe contener más de 50 letras."),
});
