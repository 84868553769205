import {FC, useContext} from "react";
import {Unity} from "../../../../domain/unity";
import {BreakPointContext} from "../../../context/BreakPointContext";
import { UnityTable } from "../../data-display/unity-table";
import { UnityList } from "../../data-display/unity-list";

type Props = {
    items?: Unity[];
    onClick: (user: Unity) => void;
};

export const UnityDataName = "UnityData"

export const UnityData: FC<Props> = ({
                                         items,
                                         onClick,
                                     }) => {
    const screenSize = useContext(BreakPointContext);
    if (screenSize.isMobile) {
        return (
            <UnityList
                onClick={onClick}
                items={items}
                name={UnityDataName}
            />
        );
    } else {
        return (
            <UnityTable
                onSelect={onClick}
                items={items}
                name={UnityDataName}
            />
        );
    }
};
