import React, {FC} from "react";
import { UserGoodsControl } from "../../../../domain/control/Goods";

type Props = {
    item: UserGoodsControl;
};

export const GoodsControlRowUnity: FC<Props> = ({item}) => {
    const {unity, unityId} = item;
    return (
        <div className={"m-0 p-0 flex flex-row flex-wrap gap-2"}>
            <span className={"text-neutral-400 font-semibold"}>UNIDAD</span>
            <span>{unity ? unity.label : unityId}</span>
        </div>
    );
};
