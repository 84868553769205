import {FC, useCallback} from "react";
import {ButtonGroup, IconButton} from "rsuite";
import {FaIcon} from "../../../../../components/fontawesome/icon";
import {faCancel, faEdit, faQrcode, faTrash,} from "@fortawesome/free-solid-svg-icons";

type OnClick = () => void;

type Props = {
    selectedZones?: number[];
    onCancelClicked: OnClick;
    onEditClicked: OnClick;
    onDeleteClicked: OnClick;
    onDownloadClicked: OnClick;
};
export const UnityZoneTableMultiselectHeader: FC<Props> = ({
                                                               selectedZones,
                                                               onCancelClicked,
                                                               onDeleteClicked,
                                                               onEditClicked,
                                                               onDownloadClicked,
                                                           }) => {
    /* eslint-disable */
    const onCancel = useCallback(() => {
        onCancelClicked();
    }, [selectedZones]);
    const onDelete = useCallback(() => {
        onDeleteClicked();
    }, [selectedZones]);
    const onEdit = useCallback(() => {
        onEditClicked();
    }, [selectedZones]);
    /* eslint-enable */
    return selectedZones && selectedZones.length > 0 ? (
        <div
            className={
                "bg-app px-4 py-2 w-full rounded shadow flex flex-row justify-between flex-wrap items-center"
            }
        >
            <p className={"text-multi text-md"}>
                {selectedZones.length} zona(s) seleccionadas
            </p>
            <ButtonGroup>
                <IconButton
                    appearance="ghost"
                    size={"sm"}
                    onClick={onDownloadClicked}
                    icon={<FaIcon icon={faQrcode}/>}
                >
                    Descargar QR
                </IconButton>
                <IconButton
                    onClick={onEdit}
                    size={"sm"}
                    appearance={"primary"}
                    icon={<FaIcon icon={faEdit}/>}
                >
                    Editar
                </IconButton>
                <IconButton
                    onClick={onDelete}
                    size={"sm"}
                    appearance={"primary"}
                    icon={<FaIcon icon={faTrash}/>}
                >
                    Eliminar
                </IconButton>

                <IconButton
                    onClick={onCancel}
                    size={"sm"}
                    appearance={"primary"}
                    icon={<FaIcon icon={faCancel}/>}
                >
                    Cancelar
                </IconButton>
            </ButtonGroup>
        </div>
    ) : null;
};
