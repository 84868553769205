import Geocode from "react-geocode";
import {GOOGLE_MAPS_API_KEY} from "./map";

export function areLatLngEqual(
    obj1: google.maps.LatLngLiteral | null | undefined,
    obj2: google.maps.LatLngLiteral | null | undefined
) {
    return obj1?.lat === obj2?.lat && obj1?.lng === obj2?.lng;
}

export async function getAddressFromLocation(
    location: google.maps.LatLngLiteral
) {
    if (!location) return null;
    const key = `${location.lat}-${location.lng}`;
    if (localStorage.getItem(key)) return localStorage.getItem(key);
    Geocode.setApiKey(GOOGLE_MAPS_API_KEY);
    Geocode.setLanguage("es");
    Geocode.setRegion("es");
    try {
        const result = await Geocode.fromLatLng(
            String(location.lat),
            String(location.lng)
        );
        if (result.status === "OK" && result.results && result.results.length >= 1) {
            const address = result.results[0].formatted_address;
            localStorage.setItem(key, address);
            return address;
        }
        return "No es posible obtener la dirección de la ubicación en estos momentos.";
    } catch (e: any) {
        console.error(e)
        return "No es posible obtener la dirección de la ubicación en estos momentos.";
    }
}
