import { PentrackerUser } from "../user/User";
import { ManagerEventDto } from "../../network/event/ManagerEvent";

export type ManagerEvent = Omit<ManagerEventDto, "date"> & {
  reference: string;
  date: number;
};
export type AppManagerEvent = ManagerEvent & {
  user?: PentrackerUser;
};

export function managerEventDtoToManagerEvent(
  dto: ManagerEventDto,
  reference: string
): ManagerEvent {
  return {
    ...dto,
    date: dto.date.toDate().getTime(),
    reference,
  };
}

export function managerEventToAppManagerEvent(
  managerEvent: ManagerEvent,
  user?: PentrackerUser
): AppManagerEvent {
  return {
    ...managerEvent,
    user,
  };
}
