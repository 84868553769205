import {FC} from "react";
import {ReactComponent as Logo} from "../../../../resources/logo_nocolor.svg";

export const AuthPageBanner: FC = () => {
    return <div className={"w-full h-full relative flex"}>
        <div
            className={"absolute w-full bottom-0 left-0 right-0 bg-black bg-opacity-30 lg:bg-opacity-100 lg:bg-transparent lg:bg-gradient-to-b from-transparent to-black h-full lg:h-1/2 z-40"}/>
        <div
            className={"invisible lg:visible absolute w-full bottom-0 left-0 right-0 flex flex-col z-50 p-8 md:px-12 md:py-8 lg:px-24 lg:py-16 fill-white text-white justify-center items-center"}>
            <h1 className={"font-semibold md:text-3xl lg:text-4xl text-white"}>Pentracker Control</h1>
            <hr className={"w-full"}/>
            <p className={"text-center text-neutral-300 text-xl"}>Visualiza gráficos y controla los datos generados a
                través de
                Pentracker desde cualquier lugar
            </p>
            <p className={"text-neutral-300 text-xl"}>por</p>
            <Logo className={"w-48 md:w-56 h-fit"}/>
        </div>
    </div>
}
