import { FC } from "react";
import { IconButton } from "rsuite";
import { Plus, Reload } from "@rsuite/icons";
import { PrimaryHeader } from "../../../../components/header/primary";

type Props = {
  addDisabled: boolean;
  addLoading: boolean;
  onAdd: () => void;
  refreshDisabled: boolean;
  refreshLoading: boolean;
  onRefresh: () => void;
  stylePage?: any;
};

export const ShiftPageHeader: FC<Props> = ({
  addDisabled,
  addLoading,
  onAdd,
  refreshLoading,
  refreshDisabled,
  onRefresh,
  stylePage,
}) => {
  return (
    <div className={"w-full overflow-x-hidden"} style={stylePage}>
      <PrimaryHeader
        breadcrumbItems={[
          {
            active: true,
            label: "Turnos de Operador",
          },
        ]}
        title={"Turnos de Operador"}
        actions={[
          <IconButton
            icon={<Plus />}
            appearance={"primary"}
            disabled={addDisabled}
            loading={addLoading}
            onClick={onAdd}
          >
            Nuevo
          </IconButton>,
          <IconButton
            icon={<Reload />}
            appearance={"primary"}
            disabled={refreshDisabled}
            loading={refreshLoading}
            onClick={onRefresh}
          >
            Actualizar
          </IconButton>,
        ]}
      />
    </div>
  );
};
