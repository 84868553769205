import React, {
  FC,
  MutableRefObject,
  useCallback,
  useContext,
  useRef,
} from "react";
import { PentrackerUser, UserWrapper } from "../../../../domain/user/User";
import { UserRow } from "./row";
import { EmptyState } from "../../../components/state/empty";
import { Pagination } from "rsuite";
import { useDataPager } from "../../../../hook/pager";
import { UserAuthContext } from "../../../context/UserContext";

export type UserListProps = {
  name: string;
  items?: PentrackerUser[];
  onDeleteClick?: (item: PentrackerUser) => void;
  onEditClick?: (item: PentrackerUser) => void;
  onClick: (user: PentrackerUser) => void;
};

export type UserListRowProps = {
  items: PentrackerUser[];
  currentUser: UserWrapper;
  onDeleteClick?: React.MouseEventHandler<HTMLElement>;
  onEditClick?: React.MouseEventHandler<HTMLElement>;
  onClick: React.MouseEventHandler<HTMLDivElement>;
};

export const UserList: FC<UserListProps> = ({
  items,
  onClick,
  onDeleteClick,
  onEditClick,
  name,
}) => {
  const actionRef = useRef<string | null>(null) as MutableRefObject<
    string | null
  >;
  const { appUser } = useContext(UserAuthContext);
  /* eslint-disable */

  const { limits, changeLimit, limit, page, partialData, changePage } =
    useDataPager<PentrackerUser>({
      id: `user_list_${name}`,
      items,
    });

  const onRowClick = useCallback<React.MouseEventHandler<HTMLDivElement>>(
    (e) => {
      if (!partialData) return;
      const index = e.currentTarget.dataset.index;
      if (index) {
        const item = partialData[index as unknown as number];
        switch (actionRef.current) {
          case "edit":
            if (onEditClick) onEditClick(item);
            break;
          case "delete":
            if (onDeleteClick) onDeleteClick(item);
            break;
          default:
            if (onClick) onClick(item);
            break;
        }
      }
      actionRef.current = null;
    },
    [partialData]
  );

  const onRowDeleteClick = useCallback<React.MouseEventHandler<HTMLElement>>(
    (_) => {
      actionRef.current = "delete";
    },
    [partialData]
  );

  const onRowEditClick = useCallback<React.MouseEventHandler<HTMLElement>>(
    (_) => {
      actionRef.current = "edit";
    },
    [partialData]
  );

  /* eslint-enable */

  return items && items.length > 0 ? (
    <div className={"w-full h-auto"}>
      {partialData?.map((it, i) => (
        <UserRow
          index={i}
          style={{}}
          data={{
            items: partialData,
            onClick: onRowClick,
            onDeleteClick: onRowDeleteClick,
            onEditClick: onRowEditClick,
            currentUser: appUser,
          }}
          key={`user-row-${it.uid}`}
        />
      ))}
      <div className={"py-4 flex flex-col gap-4"}>
        <span className={"text-md"}>
          Mostrando {partialData?.length} elementos / Total {items.length}
        </span>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={["limit", "pager"]}
          total={items.length}
          limitOptions={limits}
          limit={limit}
          activePage={page}
          onChangePage={changePage}
          onChangeLimit={changeLimit}
        />
      </div>
    </div>
  ) : (
    <EmptyState
      title={"No hay usuarios"}
      message={"No hay ningún usuario registrado."}
    />
  );
};
