import {FC, useContext} from "react";
import {PrimaryHeader} from "../../../../components/header/primary";
import {faAdd, faFilter, faRefresh, faSearch,} from "@fortawesome/free-solid-svg-icons";
import {UserAuthContext} from "../../../../context/UserContext";

type OnClick = () => void;

type Props = {
    onCreateButtonClick: OnClick;
    onRefreshButtonClick: OnClick;
    onFilterButtonClick: OnClick;
    onSearchButtonClick: OnClick;
    userCount: number;
};

export const MainUserPaneHeader: FC<Props> = ({
                                                  onCreateButtonClick,
                                                  onSearchButtonClick,
                                                  onRefreshButtonClick,
                                                  onFilterButtonClick,
                                                  userCount,
                                              }) => {
    const {appUser} = useContext(UserAuthContext)

    return (
        <PrimaryHeader
            description={`${userCount} usuario(s)`}
            breadcrumbItems={[
                {
                    label: "Usuarios",
                    active: true,
                },
            ]}
            title={"Usuarios"}
            actionsSchema={[
                {
                    label: "Nuevo",
                    icon: faAdd,
                    onClick: onCreateButtonClick,
                    id: "add",
                    hide: appUser.isClient()
                },
                {
                    label: "Actualizar",
                    icon: faRefresh,
                    onClick: onRefreshButtonClick,
                    id: "refresh",
                },
                {
                    label: "Filtrar",
                    icon: faFilter,
                    onClick: onFilterButtonClick,
                    id: "filter",
                },
                {
                    label: "Buscar",
                    icon: faSearch,
                    onClick: onSearchButtonClick,
                    id: "search",
                },
            ]}
        />
    );
};
