import {createContext, FC, PropsWithChildren} from "react";
import {AvailableTailwindSize, useBreakpoints} from "../../hook/tailwind";
import {switchBreakpoint} from "../../util/breakpoint";

type BreakPointContextValue = {
    breakpoint: AvailableTailwindSize["sizes"];
    isExtraSmall: boolean;
    isSmall: boolean;

    isMedium: boolean;
    isLarge: boolean;

    isMobile: boolean;

    isDesktop: boolean;
};
export const BreakPointContext = createContext<BreakPointContextValue>(null!);

export const BreakPointContextProvider: FC<PropsWithChildren> = ({
                                                                     children,
                                                                 }) => {
    const bp = useBreakpoints();
    const size: "xSmall" | "small" | "large" | "medium" = switchBreakpoint(
        {bp, def: "xSmall"},
        [["xs"], "xSmall"],
        [["sm"], "small"],
        [["md"], "medium"],
        [["lg", "xl", "2xl"], "large"]
    )!;

    return (
        <BreakPointContext.Provider
            value={{
                breakpoint: bp,
                isExtraSmall: size === "xSmall",
                isSmall: size === "small",
                isMedium: size === "medium",
                isLarge: size === "large",
                isMobile: size === "xSmall" || size === "small" || size === "medium",
                isDesktop: size === "large",
            }}
        >
            {children}
        </BreakPointContext.Provider>
    );
};
