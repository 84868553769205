import {FC, ReactNode} from "react";

type Props = {
    icon: ReactNode;
    label: string;
};
export const DashboardHeaderPill: FC<Props> = ({icon, label}) => {
    return (
        <div
            className={
                "bg-black bg-opacity-20 px-2 py-1 md:px-3 md:py-2 flex flex-row gap-2 rounded-full fill-white text-neutral-50 items-center justify-center"
            }
        >
            {icon}
            <span className={"text-neutral-50 text-sm font-normal"}>{label}</span>
        </div>
    );
};
