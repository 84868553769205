import {Unity} from "../../domain/unity";
import {useState} from "react";
import {Response} from "../../domain/app/Response";
import UserRepository from "../../data/repository/UserRepository";
import {PentrackerUser} from "../../domain/user/User";

export function useUnityUserListViewModel(unity: Unity) {
    const [unityUserList, setUnityUserList] = useState<
        PentrackerUser[] | undefined
    >();
    const [fetchUnityUserListState, setFetchUnityUserListState] =
        useState<Response<boolean> | null>(null);

    async function fetchUnityUserList() {
        if (fetchUnityUserListState?.isLoading()) return;
        try {
            setUnityUserList(await UserRepository.getUnityUsers(unity.id));
            setFetchUnityUserListState(Response.success(true));
        } catch (e: any) {
            setFetchUnityUserListState(Response.failure(e));
        }
    }

    function onFetchUnityUserListStateReceived() {
        setFetchUnityUserListState(null);
    }

    return {
        unityUserList,
        fetchUnityUserListState,
        fetchUnityUserList,
        onFetchUnityUserListStateReceived,
    };
}
