import React, { FC, useContext, useEffect } from "react";
import { useLoaderData } from "react-router-dom";
import { AttendancePair } from "../../../domain/attendance/Attendance";
import { ContextHeader } from "../../components/header/secondary";
import { UserAttendanceView } from "./components/view";
import { DashboardHeaderContext } from "../../../hook/header";

export const AttendancePage: FC<any> = () => {
  const data = useLoaderData() as AttendancePair;

  /* eslint-disable */
  const dashboardHeaderContext = useContext(DashboardHeaderContext);
  useEffect(() => {
    dashboardHeaderContext.setTheme({
      className: "bg-gray-100",
      title: "Asistencia",
      dark: false,
      subtitle: data.entry.owner?.displayName,
      homeAsUpEnabled: true,
    });
  }, []);
  /* eslint-enable */
  return (
    <div className={"w-full h-full flex flex-col overflow-hidden px-3"}>
      <ContextHeader
        breadcrumbItems={[
          {
            label: "Asistencia",
            path: "/dashboard/attendance-group",
          },
          {
            label: "...",
            active: true,
          },
        ]}
        title={"Registro de Asistencia"}
      />
      <div className={"p-3 flex-1 w-full overflow-y-auto"}>
        <UserAttendanceView attendance={data} />
      </div>
    </div>
  );
};
