import {FC} from "react";
import {Link} from "react-router-dom";
import {CellProps, Table} from "rsuite";
import {UserIncident} from "../../../../domain/incident/Incident";

export const IncidentTableUnityCell: FC<CellProps<UserIncident>> = ({rowData, ...props}) => {
    if (!rowData) {
        return null
    }
    const {unityId, unity} = rowData
    return <Table.Cell {...props}>
        {unityId ? (
            <Link to={`/dashboard/unity/${unityId}`}>
                {unity ? unity.label : unityId}
            </Link>
        ) : (
            <span className={"italic"}>No especificado</span>
        )}
    </Table.Cell>
}
