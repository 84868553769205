import {TableColumnRelation} from "../../ui/context/TableContext";

export type UnityTableColumns = {
    name: string;
    client: string;
    operatorCount: string;
    actions: string;
};

export const UnityTableSchema: TableColumnRelation<UnityTableColumns> = {
    large: {
        breakpoints: ["lg", "xl", "2xl"],
        columns: [
            {
                name: "name",
                label: "Nombre",
                weight: 0.4,
            },
            {
                name: "client",
                label: "Nombre Cliente",
                weight: 0.35,
            },
            {
                name: "operatorCount",
                label: "Total de Operadores",
                weight: 0.25,
            },
        ],
    },
};
