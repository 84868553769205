import React, {FC, Fragment} from "react";
import {Link} from "react-router-dom";
import {Unity} from "../../../../domain/unity";

type Props = {
    unity: Unity
};

export const UnityRowClient: FC<Props> = ({unity}) => {
    const {client} = unity;
    let hasMoreThanThree = false;
    if (client!.length > 3) {
        hasMoreThanThree = true;
    }
    return (
        <div className={"m-0 p-0 flex flex-row flex-wrap gap-2"}>
      <span className={"text-neutral-400 font-semibold"}>
        CLIENTES
      </span>
            {client ? (
                <div className={"flex flex-row flex-wrap gap-2"}>
                    {hasMoreThanThree ? (
                        <Fragment>
                            {client.map((user) => (
                                <Link
                                    key={`client-${user.uid}`}
                                    to={`/dashboard/users/${user.uid}/main`}
                                >
                                    {user.displayName ? user.displayName : user.uid}
                                </Link>
                            ))}
                            <Link to={`/dashboard/unity/${unity.id}`}>...más</Link>
                        </Fragment>
                    ) : (
                        client.map((user) => (
                            <Link
                                key={`client-${user.uid}`}
                                to={`/dashboard/users/${user.uid}/main`}
                            >
                                {user.displayName ? user.displayName : user.uid}
                            </Link>
                        ))
                    )}
                </div>
            ) : (
                <span>La unidad no tiene clientes asignados</span>
            )}
        </div>
    );
};
