import {FC} from "react";
import {toTime} from "../../../../lib/date";
import {CellProps, Table} from "rsuite";
import {UserIncident} from "../../../../domain/incident/Incident";

export const IncidentTableTimestampCell: FC<CellProps<UserIncident>> = ({
                                                                            rowData,
                                                                            ...props
                                                                        }) => {
    if (!rowData) {
        return null
    }
    const {timestamp} = rowData;
    return (
        <Table.Cell {...props}>
            <span>{toTime(timestamp)}</span>
        </Table.Cell>
    );
};
