import {FC, MouseEventHandler, useCallback} from "react";
import {AutoSizer} from "react-virtualized";
import {Shift} from "../../../../../../domain/user/Shift";
import {ShiftListRowItem} from "./row";
import {FixedSizeList} from "react-window";
import {isNil, toInteger} from "lodash";
import {EmptyState} from "../../../../../components/state/empty";

type Props = {
    items?: Array<Shift>;
    onDeleteClicked: (shift: Shift) => void;
    onEditClicked: (shift: Shift) => void;
    onClicked: (shift: Shift) => void;
};

export const ShiftList: FC<Props> = ({
                                         items,
                                         onDeleteClicked,
                                         onEditClicked,
                                         onClicked,
                                     }) => {
    /* eslint-disable */
    const onRowClicked = useCallback<MouseEventHandler<HTMLDivElement>>(
        (event) => {
            event.stopPropagation();
            const index = event.currentTarget.dataset.index as string;
            if (!isNil(index) && !!items) {
                const item = items[toInteger(index)];
                if (!!item) onClicked(item);
            }
        },
        []
    );

    const onRowDeleteClicked = useCallback<MouseEventHandler<HTMLElement>>(
        (event) => {
            event.stopPropagation();
            const index =
                event.currentTarget?.parentElement?.parentElement?.dataset.index;
            if (!isNil(index) && !!items) {
                const item = items[toInteger(index)];
                if (!!item) onDeleteClicked(item);
            }
        },
        []
    );

    const onRowEditClicked = useCallback<MouseEventHandler<HTMLElement>>(
        (event) => {
            event.stopPropagation();
            const index =
                event.currentTarget?.parentElement?.parentElement?.dataset.index;
            if (!isNil(index) && !!items) {
                const item = items[toInteger(index)];
                if (!!item) onEditClicked(item);
            }
        },
        []
    );

    /* eslint-enable */

    return !!items && items.length > 0 ? (
        // @ts-ignore
        <AutoSizer>
            {({width, height}) => {
                return (
                    <FixedSizeList
                        itemSize={120}
                        height={height}
                        itemCount={items.length}
                        width={width}
                        itemData={{
                            onClick: onRowClicked,
                            onDeleteClick: onRowDeleteClicked,
                            onEditClick: onRowEditClicked,
                            items,
                        }}
                    >
                        {ShiftListRowItem}
                    </FixedSizeList>
                );
            }}
        </AutoSizer>
    ) : (
        <EmptyState
            title={"Sin turnos de operador"}
            message={"No hay turnos de operador registrados."}
        />
    );
};
