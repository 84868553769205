import {FC, useCallback, useEffect, useState} from "react";
import {formatDate} from "../../../../util/date";
import {DatePicker, IconButton, Loader} from "rsuite";
import {FaIcon} from "../../../components/fontawesome/icon";
import {faRefresh} from "@fortawesome/free-solid-svg-icons";
import {Unity} from "../../../../domain/unity";
import {useUnityAttendanceViewModel} from "../../../../viewmodel/unity/UnityAttendance";
import {toRelativeCalendar} from "../../../../lib/date";
import {UnityAttendanceData} from "./components/data";
import {AttendancePair} from "../../../../domain/attendance/Attendance";
import {useNavigate, useSearchParams} from "react-router-dom";
import {DateTime} from "luxon";
import {toNumber} from "lodash";
import {useQueryNavigate} from "../../../../hook/navigate";
import {UnityUserAttendanceModal} from "./components/modal";

type Props = {
    unity: Unity;
};

export const UnityAttendanceTabContent: FC<Props> = ({unity}) => {
    const [searchParams] = useSearchParams();
    const initialTimestamp = searchParams.get("timestamp");
    const [selectedTime, setSelectedTime] = useState(
        !!initialTimestamp
            ? DateTime.fromMillis(toNumber(initialTimestamp)).toJSDate()
            : new Date()
    );
    const queryNavigate = useQueryNavigate();
    const navigate = useNavigate();
    const {
        fetchState,
        fetchAttendance,
        onFetchStateReceived,
        userAttendanceList,
        userAttendanceTimestamp,
        refreshEvent,
        requestRefreshEvent,
        onRefreshEventCompleted,
        selectedItem,
        requestSelectItem,
        onSelectItemCompleted,
    } = useUnityAttendanceViewModel(unity);

    /* eslint-disable */
    useEffect(() => {
        void fetchAttendance(selectedTime.getTime());
    }, [selectedTime]);

    useEffect(() => {
        if (fetchState && !fetchState.isLoading()) {
            if (fetchState.isFailed()) {
                window.toast.error(
                    "Ocurrió un error al obtener la lista de asistencia de la unidad."
                );
                console.log(fetchState?.error);
            }
            onFetchStateReceived();
        }
    }, [fetchState]);

    useEffect(() => {
        if (refreshEvent) {
            void fetchAttendance(selectedTime.getTime(), true);
            onRefreshEventCompleted();
        }
    }, [refreshEvent]);

    const onItemSelected = useCallback((item: AttendancePair) => {
        if (!!item.departure) {
            navigate(
                `/dashboard/attendance?entry=${encodeURIComponent(
                    item.entry.reference
                )}&departure=${encodeURIComponent(item.departure.reference)}`
            );
        } else {
            navigate(
                `/dashboard/attendance?entry=${encodeURIComponent(
                    item.entry.reference
                )}`
            );
        }
    }, []);

    const onItemPreview = useCallback((item: AttendancePair) => {
        requestSelectItem(item);
    }, []);
    /* eslint-enable */

    return (
        <div className={"relative w-full"}>
            {selectedItem ? (
                <UnityUserAttendanceModal
                    onClose={onSelectItemCompleted}
                    attendancePair={selectedItem}
                />
            ) : null}
            <div
                className={
                    "w-full flex flex-row flex-wrap gap-2 p-2 border-b-2 border-neutral-100 overflow-x-hidden items-center justify-between"
                }
            >
        <span className={"text-lg font-normal text-neutral-600 uppercase"}>
          {formatDate(selectedTime)}
        </span>
                <div className={"flex flex-row flex-wrap items-center gap-2"}>
                    <div className={"flex flex-row flex-wrap gap-2 items-center z-0"}>
                        <span>Seleccionar fecha</span>
                        <DatePicker
                            value={selectedTime}
                            onChange={(newDate) => {
                                if (
                                    !!newDate &&
                                    newDate?.getTime() !== selectedTime.getTime()
                                ) {
                                    setSelectedTime(newDate);
                                    queryNavigate({
                                        timestamp: newDate.getTime(),
                                    });
                                }
                            }}
                        />
                    </div>
                    <IconButton
                        onClick={requestRefreshEvent}
                        icon={<FaIcon icon={faRefresh}/>}
                    >
                        Actualizar
                    </IconButton>
                </div>
            </div>
            {fetchState?.isLoading() ? (
                <Loader center backdrop size={"sm"} content={"Cargando..."}/>
            ) : (
                <div className={"w-full overflow-x-hidden"}>
                    {userAttendanceTimestamp ? (
                        <div className={"w-full flex-none p-2"}>
                            <p>
                <span className={"text-neutral-400 uppercase"}>
                  Última actualización:{" "}
                </span>
                                {toRelativeCalendar(userAttendanceTimestamp.timestamp, true)}
                            </p>
                        </div>
                    ) : null}

                    <UnityAttendanceData
                        items={userAttendanceList}
                        onClick={onItemSelected}
                        onPreview={onItemPreview}
                    />
                </div>
            )}
        </div>
    );
};
