import {FC, useCallback, useEffect, useRef} from "react";
import {Zone} from "../../../../../domain/patrol/Zone";
import {IconButton, Modal} from "rsuite";
import {faDownload, faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FaIcon} from "../../../../components/fontawesome/icon";
import {previewZoneDocument} from "../document/document";

type Props = {
    zone: Zone;
    onEditClicked: (item: Zone) => void;
    onDeleteClicked: (reference: number[]) => void;
    onDownloadClicked: (reference: number[]) => void;
    onClose: () => void;
};

export const UnityZoneModal: FC<Props> = ({
                                              zone,
                                              onEditClicked,
                                              onDownloadClicked,
                                              onDeleteClicked,
                                              onClose,
                                          }) => {
    /* eslint-disable */
    const containerRef = useRef<HTMLDivElement>(null);

    const onDelete = useCallback(() => {
        onDeleteClicked([zone.id]);
    }, []);

    const onDownload = useCallback(() => {
        onDownloadClicked([zone.id]);
    }, []);

    const onEdit = useCallback(() => {
        onEditClicked(zone);
    }, []);

    useEffect(() => {
        void previewZoneDocument(zone, containerRef);
    }, [containerRef.current]);

    /* eslint-enable */
    return (
        <Modal onClose={onClose} open={true}>
            <Modal.Header>
                <Modal.Title>Zona</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={"w-full"}>
                    <div className={"flex flex-row gap-2 flex-wrap"}>
                        <span className={"font-semibold uppercase"}>NOMBRE</span>
                        {zone.label}
                    </div>
                    <div className={"flex flex-row gap-2 flex-wrap"}>
                        <span className={"font-semibold uppercase"}>ID</span>
                        {zone.id}
                    </div>
                    <div className={"flex flex-row gap-2 flex-wrap"}>
                        <span className={"font-semibold uppercase"}>DESCRIPCIÓN</span>
                        {zone.description ? zone.description : "No asignado."}
                    </div>
                    <div className={"w-full h-96"} ref={containerRef}></div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className={"w-full justify-end flex flex-row flex-wrap gap-2"}>
                    <IconButton
                        size={"sm"}
                        appearance={"primary"}
                        onClick={onEdit}
                        icon={<FaIcon icon={faEdit}/>}
                    >
                        Editar
                    </IconButton>
                    <IconButton
                        size={"sm"}
                        appearance={"primary"}
                        onClick={onDelete}
                        icon={<FaIcon icon={faTrash}/>}
                    >
                        Eliminar
                    </IconButton>
                    <IconButton
                        size={"sm"}
                        appearance={"primary"}
                        onClick={onDownload}
                        icon={<FaIcon icon={faDownload}/>}
                    >
                        Descargar QR
                    </IconButton>
                </div>
            </Modal.Footer>
        </Modal>
    );
};
