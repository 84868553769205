import { useState } from "react";
import { Response } from "../../domain/app/Response";
import { UserNotificationFormData } from "../../domain/user/UserNotification";
import UserRepository from "../../data/repository/UserRepository";


export function useNotificationCenterViewModel(){
  const [sendNotificationState, setSendNotificationState] = useState<Response<boolean> | null>(null)

  async function sendNotification(formData: UserNotificationFormData) {
    if(sendNotificationState?.loading) return
    setSendNotificationState(Response.loading())
    try {
      await UserRepository.sendNotification(formData)
      setSendNotificationState(Response.success(true))
    } catch (e: any) {
      setSendNotificationState(Response.failure(e))
    }
  }

  function onSendNotificationStateReceived() {
    setSendNotificationState(null)
  }

  return {
    sendNotificationState, sendNotification, onSendNotificationStateReceived
  }
}
