import {FirestoreSimpleCrudSource} from "../source/FirestoreSimpleCrudSource";
import {Timestamp, where} from "firebase/firestore";
import {UserLocation, userLocationDtoAsDomain,} from "../../domain/location/UserLocation";
import {GetUserLocationDto} from "../../network/location/UserLocation";
import {AppTimestamp} from "../../domain/app/Timestamp";
import {DateTime} from "luxon";
import {AppTimestampDao} from "../database/dao/AppTimestamp";
import {isEmpty} from "lodash";
import {orderBy as firestoreOrderBy} from "@firebase/firestore";
import {UserLocationDao} from "../database/dao/UserLocation";

export class UserLocationRepository {
    private static locationSource = new FirestoreSimpleCrudSource<GetUserLocationDto>(
        "location"
    );

    static async getList(
        timestamp: number,
        forceRefresh?: boolean
    ): Promise<{ locationList: UserLocation[]; timestamp?: AppTimestamp } | undefined> {
        const dateTime = DateTime.fromMillis(timestamp).setZone("America/Lima");
        const dayStartTimestamp = dateTime.startOf("day").toMillis();
        const dayEndTimestamp = dateTime.endOf("day").toMillis();
        const appTimestampKey = `locationlist-${dayStartTimestamp}`;
        let appTimestamp = await AppTimestampDao.getTimestamp(appTimestampKey);
        let locationList = await UserLocationDao.getLocationList(
            dayStartTimestamp,
            dayEndTimestamp
        );
        if (isEmpty(locationList) || forceRefresh) {
            const locationDocs = await this.locationSource.getList(
                [
                    where("timestamp", ">=", Timestamp.fromMillis(dayStartTimestamp)),
                    where("timestamp", "<=", Timestamp.fromMillis(dayEndTimestamp)),
                    firestoreOrderBy("timestamp", "desc"),
                ]
            );
            const locationArray =
                locationDocs?.map((it) => userLocationDtoAsDomain(it.data, it.reference)) || [];
            await UserLocationDao.clearLocationList(
                dayStartTimestamp,
                dayEndTimestamp
            );
            await AppTimestampDao.putTimestamp({
                key: appTimestampKey,
                timestamp: Date.now(),
            });
            await UserLocationDao.putUserLocation(...locationArray);
            locationList = await UserLocationDao.getLocationList(
                dayStartTimestamp,
                dayEndTimestamp
            );
            appTimestamp = await AppTimestampDao.getTimestamp(appTimestampKey);
        }
        return {
            locationList,
            timestamp: appTimestamp,
        };
    }

    static async getUserList(
        timestamp: number,
        uid: string,
        forceRefresh?: boolean
    ): Promise<{ locationList: UserLocation[]; timestamp?: AppTimestamp } | undefined> {
        const dateTime = DateTime.fromMillis(timestamp).setZone("America/Lima");
        const dayStartTimestamp = dateTime.startOf("day").toMillis();
        const dayEndTimestamp = dateTime.endOf("day").toMillis();
        const appTimestampKey = `user-locationlist-${dayStartTimestamp}`;
        let appTimestamp = await AppTimestampDao.getTimestamp(appTimestampKey);
        let locationList = await UserLocationDao.getUserLocationList(
            uid,
            dayStartTimestamp,
            dayEndTimestamp
        );
        if (isEmpty(locationList) || forceRefresh) {
            const locationDocs = await this.locationSource.getList(
                [
                    where("uid", "==", uid),
                    where("timestamp", ">=", Timestamp.fromMillis(dayStartTimestamp)),
                    where("timestamp", "<=", Timestamp.fromMillis(dayEndTimestamp)),
                    firestoreOrderBy("timestamp", "desc"),
                ]
            );
            const locationArray =
                locationDocs?.map((it) => userLocationDtoAsDomain(it.data, it.reference)) || [];
            await UserLocationDao.deleteAllUserLocation(
                uid,
                dayStartTimestamp,
                dayEndTimestamp
            );
            await AppTimestampDao.putTimestamp({
                key: appTimestampKey,
                timestamp: Date.now(),
            });
            await UserLocationDao.putUserLocation(...locationArray);
            locationList = await UserLocationDao.getUserLocationList(
                uid,
                dayStartTimestamp,
                dayEndTimestamp
            );
            appTimestamp = await AppTimestampDao.getTimestamp(appTimestampKey);
        }
        return {
            locationList,
            timestamp: appTimestamp,
        };
    }

    static async getLocation(reference: string): Promise<UserLocation | undefined> {
        return UserLocationDao.getLocation(reference);
    }
}
