import React, { FC, useCallback, useContext, useEffect } from "react";
import { AppLoader } from "../../../components/loading/LoadingOverlay";
import { useUserListViewModel } from "../../../../viewmodel/user/UserListData";
import { FormModal } from "../../../components/modal/FormModal";
import { FilterUserInputList } from "./modal/FilterUserInputList";
import { SearchUserModal } from "./modal/SearchUserModal";
import { useNavigate } from "react-router-dom";
import { MainUserPaneHeader } from "./components/header";
import { NewUserModal } from "./modal/NewUserModal";
import { EditUserModal } from "./modal/EditUserModal";
import { UserListData } from "./components/data";
import { PentrackerUser } from "../../../../domain/user/User";
import { DeleteUserConfirmationModal } from "./components/delete";
import { DashboardHeaderContext } from "../../../../hook/header";
import { UsersTableMultiselectHeader } from "./components/multiselect";
import { DeleteUserFormSchema, DeleteUserInputList } from "./modal/InputList";
import { useStyleContext } from "../../../context/StyleContext";

export const MainUserPaneContent: FC = () => {
  const { stylePage } = useStyleContext();
  const {
    fetchListState,
    createState,
    deleteState,
    editState,
    refreshEvent,
    searchState,
    requestParams,
    requestRefreshEvent,
    onRequestRefreshEventCompleted,
    onFetchStateReceived,
    loadObserver: userViewModelLoadObserver,
    requestCreateEvent,
    requestSearchEvent,
    requestDeleteUser,
    requestEditUser,
    updateRequestParams,
    fetchUserList,
    createEvent,
    createUser,
    onCreateEventCompleted,
    onCreateStateReceived,
    deleteEvent,
    onDeleteEventCompleted,
    onDeleteStateReceived,
    deleteUser,
    editUser,
    editEvent,
    onEditEventCompleted,
    onEditStateReceived,
    requestFilterUsersEvent,
    onFilterUsersEventCompleted,
    filterUsersEvent,
    searchEvent,
    onSearchEventCompleted,
    searchUser,
    onSearchStateReceived,
    userList,
    searchResults,
    selectedUsers,
    switchUserSelection,
    switchSelectAll,
    deleteSelection,
  } = useUserListViewModel();

  const navigate = useNavigate();

  const headerContext = useContext(DashboardHeaderContext);

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    headerContext.setTheme({
      className: "bg-secondary-header",
      dark: true,
      title: "Usuarios",
    });
    void requestRefreshEvent();
  }, []);

  useEffect(() => {
    if (refreshEvent === true) {
      void fetchUserList(undefined, true);
      onRequestRefreshEventCompleted();
    }
  }, [refreshEvent]);

  useEffect(() => {
    if (!!fetchListState && !fetchListState.isLoading()) {
      if (fetchListState.isFailed()) {
        const error = fetchListState.error;
        window.toast.error(error?.message);
      }
      onFilterUsersEventCompleted();
      onFetchStateReceived();
    }
  }, [fetchListState]);

  useEffect(() => {
    if (createState && !createState.loading) {
      if (createState.isSuccess()) {
        window.toast.success("Usuario registrado...");
        onCreateEventCompleted();
      } else {
        const error = createState.error;
        window.toast.error(error?.message);
      }
      onCreateStateReceived();
    }
  }, [createState]);

  useEffect(() => {
    if (deleteState && !deleteState.loading) {
      if (deleteState.isSuccess()) {
        window.toast.success("Usuario(s) eliminado(s).");
      } else if (deleteState.isSuccessWithWarnings()) {
        window.toast.info(deleteState.message);
      } else {
        window.toast.error(deleteState.error?.message);
      }
      onDeleteStateReceived();
      onDeleteEventCompleted();
    }
  }, [deleteState]);

  useEffect(() => {
    if (editState && !editState.loading) {
      if (editState.isSuccess()) {
        window.toast.success("Usuario editado...");
        onEditEventCompleted();
      } else {
        const error = editState.error;
        window.toast.error(error?.message);
      }
      onEditStateReceived();
    }
  }, [editState]);

  useEffect(() => {
    void fetchUserList();
  }, []);

  useEffect(() => {
    if (searchState && !searchState.loading) {
      if (searchState.isFailed()) {
        const error = searchState.error;
        window.toast.error(error?.message);
      }
      onSearchStateReceived();
    }
  }, [searchState]);

  useEffect(() => {
    if (requestParams) onFilterUsersEventCompleted();
  }, [requestParams]);

  const onRowEditClick = useCallback((user: PentrackerUser) => {
    void requestEditUser([user.uid]);
  }, []);

  const onRowDeleteClick = useCallback((user: PentrackerUser) => {
    void requestDeleteUser([user.uid]);
  }, []);
  /* eslint-enable */

  const loading = userViewModelLoadObserver || searchState?.loading;

  return (
    <div
      className={
        "w-full overflow-y-auto md:overflow-y-hidden overflow-x-hidden bg-neutral-100 scrollbar-hide"
      }
      style={stylePage as any}
    >
      <AppLoader isActive={loading} />
      <MainUserPaneHeader
        onCreateButtonClick={requestCreateEvent}
        onRefreshButtonClick={requestRefreshEvent}
        onFilterButtonClick={requestFilterUsersEvent}
        onSearchButtonClick={requestSearchEvent}
        userCount={userList?.length || 0}
      />

      <div className={"w-full p-2 md:p-3 lg:p-5 scrollbar-hide"}>
        <UsersTableMultiselectHeader
          onCancelClicked={deleteSelection}
          onDeleteClicked={requestDeleteUser}
          onEditClicked={requestEditUser}
          selectedItems={selectedUsers}
        />
        <UserListData
          onClick={(user) => navigate(`/dashboard/users/${user.uid}/main`)}
          items={userList}
          onDeleteClick={onRowDeleteClick}
          onEditClick={onRowEditClick}
          
          onItemSelectionSwitch={switchUserSelection}
          onSelectAllSwitch={switchSelectAll}
          selectedItems={selectedUsers}
        />
      </div>

      {createEvent ? (
        <NewUserModal onOk={createUser} onCancel={onCreateEventCompleted} />
      ) : null}
      {deleteEvent ? (
        <FormModal
          visible={true}
          onCancel={onDeleteEventCompleted}
          title={"Eliminar Usuario"}
          onOk={deleteUser}
          inputList={DeleteUserInputList}
          model={DeleteUserFormSchema}
        />
      ) : null}

      {editEvent ? (
        <EditUserModal
          onOk={editUser}
          onCancel={onEditEventCompleted}
          initialValues={editEvent}
        />
      ) : null}

      {filterUsersEvent ? (
        <FormModal
          onCancel={onFilterUsersEventCompleted}
          visible={true}
          inputList={FilterUserInputList}
          disabled={fetchListState?.isLoading()}
          title={"Filtrar Usuarios"}
          initialValues={{
            order: requestParams.order,
            orderBy: requestParams.orderBy,
          }}
          onOk={(values: any) => {
            updateRequestParams({ ...values });
          }}
        />
      ) : null}
      {searchEvent ? (
        <SearchUserModal
          onClose={onSearchEventCompleted}
          loading={searchState?.loading}
          onSearch={searchUser}
          results={searchResults}
        />
      ) : null}
    </div>
  );
};
