import React, {
  FC,
  Fragment,
  MutableRefObject,
  useCallback,
  useContext,
  useRef,
} from "react";
import { Pagination, Table } from "rsuite";
import { useTableSize } from "../../../../hook/width";
import { TableInstance } from "rsuite/Table";
import { AttendancePair } from "../../../../domain/attendance/Attendance";
import { TableColumnContext } from "../../../context/TableContext";
import { AttendanceGroupTableEntryCell } from "./entry";
import { AttendanceGroupTableDepartureCell } from "./departure";
import { AttendanceGroupTableOperatorCell } from "./operator";
import { AttendanceGroupTableUnityCell } from "./unity";
import { AttendanceGroupTableObservationCell } from "./observation";
import { AttendanceGroupTableActionCell } from "./action";
import { EmptyState } from "../../../components/state/empty";
import { useDataPager } from "../../../../hook/pager";
import { AttendanceTableColumns } from "../../../../schema/table/AttendanceTable";

import { useStyleContext } from "../../../context/StyleContext";

type Props = {
  userAttendanceList?: AttendancePair[];
  onSelect: (item: AttendancePair) => void;
  onPreviewClicked: (item: AttendancePair) => void;
  name?: string;
};

export const AttendanceGroupTableName = "AttendanceGroupTableName";

export const AttendanceTable: FC<Props> = ({
  userAttendanceList,
  onSelect,
  onPreviewClicked,
  name = AttendanceGroupTableName,
}) => {
  const actionRef = useRef<string | null>(null) as MutableRefObject<
    string | null
  >;
  const tableRef = useRef<TableInstance<any, any>>(null);
  const tableSize = useTableSize(tableRef);
  const tableContext = useContext(TableColumnContext);
  const tablePreferences =
    tableContext.getTablePreferences<AttendanceTableColumns>(name);
  /* eslint-disable */
  const { tableHeaderStyleSecondary } = useStyleContext();

  const { partialData, changePage, page, changeLimit, limits, limit } =
    useDataPager({
      id: `attendance_table_${name}`,
      items: userAttendanceList,
    });

  const onRowSelect = useCallback(
    (item: AttendancePair) => {
      if (actionRef.current) onPreviewClicked(item);
      else onSelect(item);
      actionRef.current = null;
    },
    [partialData]
  );

  const onPreviewActionSelected = useCallback(() => {
    actionRef.current = "preview";
  }, [partialData]);
  /* eslint-enable */
  // style={TableHeaderStyleSecondary}
  return (
    <Fragment>
      <Table
        className={"bg-white shadow"}
        autoHeight
        data={partialData}
        ref={tableRef}
        onRowClick={onRowSelect as any}
        renderEmpty={() => (
          <EmptyState
            title={"Sin reportes de asistencia"}
            message={
              "No se encontraron reportes de asistencia para la fecha seleccionada."
            }
          />
        )}
        bordered
        cellBordered
      >
        {tablePreferences.map((column) => {
          switch (column.name) {
            case "entry":
              return (
                <Table.Column
                  resizable
                  align={"center"}
                  verticalAlign={"middle"}
                  width={tableSize.calculateWeight(column.weight)}
                  key={`columns-${column.name}`}
                >
                  <Table.HeaderCell style={tableHeaderStyleSecondary}>
                    Ingreso
                  </Table.HeaderCell>
                  <AttendanceGroupTableEntryCell />
                </Table.Column>
              );
            case "departure":
              return (
                <Table.Column
                  resizable
                  align={"center"}
                  verticalAlign={"middle"}
                  width={tableSize.calculateWeight(column.weight)}
                  key={`columns-${column.name}`}
                >
                  <Table.HeaderCell style={tableHeaderStyleSecondary}>
                    Salida
                  </Table.HeaderCell>
                  <AttendanceGroupTableDepartureCell />
                </Table.Column>
              );
            case "name":
              return (
                <Table.Column
                  resizable
                  align={"center"}
                  verticalAlign={"middle"}
                  width={tableSize.calculateWeight(column.weight)}
                  key={`columns-${column.name}`}
                >
                  <Table.HeaderCell style={tableHeaderStyleSecondary}>
                    Operador
                  </Table.HeaderCell>
                  <AttendanceGroupTableOperatorCell />
                </Table.Column>
              );
            case "unity":
              return (
                <Table.Column
                  resizable
                  align={"center"}
                  verticalAlign={"middle"}
                  width={tableSize.calculateWeight(column.weight)}
                  key={`columns-${column.name}`}
                >
                  <Table.HeaderCell style={tableHeaderStyleSecondary}>
                    Unidad
                  </Table.HeaderCell>
                  <AttendanceGroupTableUnityCell />
                </Table.Column>
              );
            case "observation":
              return (
                <Table.Column
                  resizable
                  align={"center"}
                  verticalAlign={"middle"}
                  width={tableSize.calculateWeight(column.weight)}
                  key={`columns-${column.name}`}
                >
                  <Table.HeaderCell style={tableHeaderStyleSecondary}>
                    Observación
                  </Table.HeaderCell>
                  <AttendanceGroupTableObservationCell />
                </Table.Column>
              );
            default:
              return (
                <Table.Column
                  resizable
                  align={"center"}
                  verticalAlign={"middle"}
                  width={tableSize.calculateWeight(column.weight)}
                  key={`columns-${column.name}`}
                >
                  <Table.HeaderCell style={tableHeaderStyleSecondary}>
                    Acciones
                  </Table.HeaderCell>
                  <AttendanceGroupTableActionCell
                    onPreviewActionClicked={onPreviewActionSelected}
                  />
                </Table.Column>
              );
          }
        })}
      </Table>

      {userAttendanceList ? (
        <div className={"py-4 flex flex-col gap-4"}>
          <span className={"text-md"}>
            Mostrando {partialData?.length} elementos / Total{" "}
            {userAttendanceList.length}
          </span>
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="xs"
            layout={["limit", "|", "pager", "skip"]}
            total={userAttendanceList.length}
            limitOptions={limits}
            limit={limit}
            activePage={page}
            onChangePage={changePage}
            onChangeLimit={changeLimit}
          />
        </div>
      ) : null}
    </Fragment>
  );
};
