import { FC, MouseEventHandler, useCallback, useState } from "react";
import { OnClick } from "../../../../components/table/header";
import { AppTicketFileType, PendingTicketFile } from "../../../../../domain/ticket/UserTicket";
import {
  Button,
  IconButton,
  Modal,
  SelectPicker,
  Uploader
} from "rsuite";
import { UserDto } from "../../../../../network/user/User";
import { FileType } from "rsuite/Uploader";
import { FaIcon } from "../../../../components/fontawesome/icon";
import { faPaperclip, faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import { humanFileSize } from "../../../../../util/file";
import { EmptyState } from "../../../../components/state/empty";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {FixedSizeList, ListChildComponentProps} from "react-window"
import {AutoSizer} from "react-virtualized"
import Fuse from "fuse.js"
type Props = {
  onOk: OnClick;
  onCancel: OnClick;
  pendingTicketList: Array<PendingTicketFile>;
  users?: Array<UserDto>;
  setPendingTickets: (tickets: PendingTicketFile[]) => void
  uploadedList?: Array<AppTicketFileType>
};

export const TicketContentAddFilesModal: FC<Props> = ({
                                                        onCancel,
                                                        onOk,
                                                        pendingTicketList,
                                                        users,
                                                        setPendingTickets,
                                                        uploadedList
                                                      }) => {
  const [showCloseConfirmation, setShowCloseConfirmation] = useState<boolean>(false);

  const handleSubmit = () => {
    const filter = pendingTicketList.filter(it=>it.user === undefined || !it.user)
    if(filter.length !== 0) {
      window.toast.error("Algunas boletas no se han asignado a ningún usuario.")
    } else {
      onOk()
    }
  };


  const onFilePicked = (fileT: FileType[]) => {
    const currentItems = [...pendingTicketList];
    const search = new Fuse(users!!, {
      keys: [
        "displayName"
      ]
    })
    for (let f of fileT) {
      const file = f.blobFile;
      if (!file) continue;
      const existing = currentItems.filter(it => it.file === file || it.file.name === file.name);
      if (existing.length <= 0) {
        const result = search.search(file.name)
        console.log(result)
        const matchingUser = result.length > 0 ? result[0].item : undefined
        currentItems.push(
          {
            file,
            user: matchingUser
          }
        );
      }
    }
    setPendingTickets(currentItems);
  };

  const onClose = () => {
    if (pendingTicketList.length >= 1) {
      setShowCloseConfirmation(true);
    } else {
      onCancel();
    }
  };

  const onUserSelect = (index: number, v: UserDto) => {
    const using = pendingTicketList.filter(it=>it.user?.uid === v.uid)
    const usingItem = using && using.length > 0 ? using[0] : null
    const usingIndex = usingItem ? pendingTicketList.indexOf(usingItem) : -1
    const copy = [...pendingTicketList]
    if(!!usingItem) {
      usingItem.user = undefined
      copy[usingIndex] = usingItem
    }
    const curr = copy[index]
    curr.user = v
    copy[index] = curr
    setPendingTickets(copy)
  };

  const onTrashClick: MouseEventHandler<HTMLDivElement> = (e) => {
    const item = pendingTicketList[Number(e.currentTarget.dataset["index"])];
    const currentItems = [...pendingTicketList];
    setPendingTickets(currentItems.filter(it => it !== item));
  };


  return (
    <Modal open={true} onClose={onClose} overflow>
      <Modal open={showCloseConfirmation}>
        <Modal.Header>
          <Modal.Title>Confirmar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Hay {pendingTicketList.length} boletas pendientes de subir. ¿Desea cerrar sin guardar los cambios?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowCloseConfirmation(false)}>Cancelar</Button>
          <Button onClick={() => {
            setShowCloseConfirmation(false);
            onCancel();
          }} appearance={"primary"}>Confirmar</Button>
        </Modal.Footer>
      </Modal>
      <Modal.Header>
        <Modal.Title>Cola de subida</Modal.Title>
      </Modal.Header>
      <Modal.Body className={"w-full h-full flex flex-col"}>
        <div className={"sticky top-0 left-0 bg-white z-50"}>
          <Uploader
            name={"file"}
            accept={".pdf"}
            fileList={[]}
            autoUpload={false}
            onChange={onFilePicked}
            multiple
            block
            action={""}
            fileListVisible={false}
          >
            <IconButton icon={<FaIcon icon={faPaperclip} />} appearance={"primary"}>
              Seleccionar archivo(s)
            </IconButton>
          </Uploader>
          <hr />

          <p className={"text-sm font-normal"}>En cola ({pendingTicketList.length})</p>
        </div>

        <div className={"w-full h-96"}>
          {pendingTicketList.length <= 0 ? (
            <EmptyState
              title={"Vacío"}
              message={"No hay boletas pendientes."}
            />
          ) : (
            <AutoSizer>
              {
                ({height, width}) => <FixedSizeList itemSize={160} height={height} itemCount={pendingTicketList.length} width={width} itemData={
                  {
                    list: pendingTicketList, users, onTrashClick, onUserSelect, uploadedList
                  }
                }>
                  {Row}
                </FixedSizeList>
              }
            </AutoSizer>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose}>Cerrar</Button>
        <Button appearance={"primary"} onClick={handleSubmit} endIcon={<FontAwesomeIcon icon={faSave} />}>
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Row: FC<ListChildComponentProps> = (props) => {
  const {data, index} = props
  const { list, users, onTrashClick, onUserSelect , uploadedList} = data
  const item = list[index]
  const alreadyUploaded = item.user ? uploadedList.filter((it: any)=>it.user.uid === item.user.uid).length > 0 : false
  const onUser = useCallback((v: any, item: any) => {
    onUserSelect(index, item)
  }, [])

  return (
    <div
      style={props.style}
      className={
        "w-full py-2"
      }
    >
      <div
        className={
          "w-full flex flex-col p-2 border border-neutral-300 rounded flex-nowrap gap-2 items-start"
        }
      >
        <p className={"p-0 m-0"}>
          {item.file.name} {humanFileSize(item.file.size)}
        </p>
        <SelectPicker virtualized label="Usuario" data={users || []} valueKey={"uid"} value={item?.user?.uid}
                      onSelect={onUser as any} labelKey={"displayName"} className={"w-full"}/>
        {alreadyUploaded ? <span className={"text-red-500"}>AVISO: Ya se ha subido un archivo para este usuario antes.</span> : null}
        <IconButton icon={<FaIcon icon={faTrash} size={"2x"} />} appearance={"default"} onClick={onTrashClick} data-index={index}>
          Eliminar
        </IconButton>
      </div>
    </div>
  );
};
