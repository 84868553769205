import {FC} from "react";
import {CellProps, Table} from "rsuite";
import {AttendancePair} from "../../../../domain/attendance/Attendance";
import {Link} from "react-router-dom";

export const AttendanceGroupTableUnityCell: FC<
    CellProps<AttendancePair>
> = ({rowData, ...props}) => {
    const {entry} = rowData!;
    return (
        <Table.Cell {...props}>
            {
                entry.unityId ? <Link to={`/dashboard/unity/${entry.unityId}/main`}>
                    {entry.unity
                        ? entry.unity.label
                        : entry.unityId}
                </Link> : null
            }
        </Table.Cell>
    );
};
