import {FC, useRef} from "react";
import {Shift} from "../../../../../domain/user/Shift";
import {AccountType} from "../../../../../domain/account-type/AccountType";
import {TableInstance} from "rsuite/Table";
import {useTableWidth} from "../../../../../hook/width";
import {IconButton, Table} from "rsuite";
import {Trash} from "@rsuite/icons";
import {TableHeaderStyleSecondary} from "../../../../components/table/style";
import {EmptyState} from "../../../../components/state/empty";

type Props = {
    data?: AccountType[];
    deleteDisabled: boolean;
    onDelete: (arg: AccountType) => void;
    loading: boolean;
};

const Actions: { [key: string]: string } = {
    edit: "edit",
};

export const AccountTypeTable: FC<Props> = ({
                                                data,
                                                deleteDisabled,
                                                onDelete,
                                                loading,
                                            }) => {
    const actionRef = useRef<keyof typeof Actions | null>(null);
    const tableRef = useRef<TableInstance<any, any>>(null);
    const columnsRelation = useTableWidth(tableRef, {
        0: {
            label: 0.9,
            actions: 0.1,
        },
    });

    function onDeleteButtonClick() {
        actionRef.current = Actions.delete;
    }

    function onTableRowClick(item: any) {
        if (actionRef.current === Actions.delete) {
            onDelete(item as Shift);
        }
        actionRef.current = null;
    }

    return (
        <Table
            className={"rounded-xl shadow overflow-hidden"}
            autoHeight
            ref={tableRef}
            data={data}
            onRowClick={onTableRowClick}
            loading={loading}
            renderEmpty={() => (
                <EmptyState
                    title={"Sin etiquetas de operador"}
                    message={"Aún no se crearon etiquetas para operador"}
                />
            )}
        >
            <Table.Column width={columnsRelation.label}>
                <Table.HeaderCell style={TableHeaderStyleSecondary}>
                    Etiqueta
                </Table.HeaderCell>
                <Table.Cell dataKey={"label"}/>
            </Table.Column>
            <Table.Column
                width={columnsRelation.actions}
                verticalAlign={"middle"}
                align={"center"}
            >
                <Table.HeaderCell style={TableHeaderStyleSecondary}>
                    {""}
                </Table.HeaderCell>
                <Table.Cell
                    style={{
                        padding: 0,
                        display: "flex",
                    }}
                >
                    {(_) => (
                        <IconButton
                            appearance={"ghost"}
                            icon={<Trash/>}
                            disabled={deleteDisabled}
                            onClick={onDeleteButtonClick}
                            className={"m-auto"}
                        />
                    )}
                </Table.Cell>
            </Table.Column>
        </Table>
    );
};
