import { FC } from "react";
import { CellProps, Table } from "rsuite";
import { toTime } from "../../../../lib/date";
import { AttendancePair } from "../../../../domain/attendance/Attendance";
import { faUserClock } from "@fortawesome/free-solid-svg-icons";
import { FaIcon } from "../../../components/fontawesome/icon";

export const AttendanceGroupTableDepartureCell: FC<
  CellProps<AttendancePair>
> = ({ rowData, ...props }) => {
  const { departure } = rowData!;

  if (!!departure) {
    const { timestamp } = departure;
    return <Table.Cell {...props}>{toTime(timestamp)}</Table.Cell>;
  } else {
    return (
      <Table.Cell {...props}>
        <div className={"flex gap-1 justify-center items-center"}>
          <FaIcon icon={faUserClock} />
          <span className={"italic"}>Pendiente</span>
        </div>
      </Table.Cell>
    );
  }
};
