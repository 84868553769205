import { UserDto } from "../../network/user/User";

export type TicketFileType = {
  name: string;
  path: string;
  temp: number;
};

export type AppTicketFileType = TicketFileType & {
  user?: UserDto
}

export type PendingTicketFile = {
  user?: UserDto,
  file: File
}

export const UserTicketMonths = ["ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO", "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"];
