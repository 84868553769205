import {FC} from "react";
import {CellProps, IconButton, Table} from "rsuite";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {FaIcon} from "../../../components/fontawesome/icon";
import {UserPatrol} from "../../../../domain/patrol/Patrol";

type Props = CellProps<UserPatrol> & {
    onPreviewActionClicked: () => void;
};

export const PatrolGroupTableActionCell: FC<Props> = ({
                                                          rowData,
                                                          onPreviewActionClicked,
                                                          ...props
                                                      }) => {
    return (
        <Table.Cell
            {...props}
            style={{
                padding: 0,
                margin: 0,
            }}
        >
            <IconButton
                icon={<FaIcon icon={faEye}/>}
                appearance={"ghost"}
                size={"xs"}
                onClick={onPreviewActionClicked}
            >
                Ver
            </IconButton>
        </Table.Cell>
    );
};
