import { StyleDto } from "../../network/style/Style";

export type Style = StyleDto & {
  reference: string;
};

export const styleDtoAsDomain = (
  styleDto: StyleDto,
  reference: string
): Style => {
  return {
    ...styleDto,
    reference,
  };
};
