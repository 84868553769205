import {db} from "../db";
import {Role} from "../../../domain/user/Role";

export class RoleDao {
    static async putRole(...role: Role[]) {
        await db.roles.bulkPut(role);
    }

    static async getRoleById(id: number) {
        return db.roles.where("id").equals(id).first()
    }

    static async clear() {
        await db.roles.clear();
    }

    static async delete(reference: string) {
        await db.roles.delete(reference);
    }

    static async update(reference: string, newData: Partial<Role>) {
        await db.roles.update(reference, newData);
    }

    static async getList() {
        return db.roles.toArray()
    }
}
