import { db } from "../db";
import { StyleDto } from "../../../network/style/Style";
import { Style } from "../../../domain/style/Styles";

export default class StyleDao {
  static async getStyleByUID(uid: string) {
    return db.stylesClient.get(uid);
  }

  static async putStyles(...style: Style[]) {
    await db.stylesClient.bulkPut(style);
  }

  static async clear() {
    await db.stylesClient.clear();
  }

  static async getList() {
    return db.stylesClient.toArray();
  }
}
