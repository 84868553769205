import {PathUrlBindingDao} from "../database/dao/PathUrlBinding";
import {FirebaseStorageSource} from "../source/FirebaseStorageSource";

export class StorageRepository {
    static async getDownloadUrl(path: string, forceRefresh: boolean = false) {
        const local = await PathUrlBindingDao.getBinding(path);
        if (!local || forceRefresh) {
            const remote = await FirebaseStorageSource.getFileUrl(path);
            if (remote) {
                await PathUrlBindingDao.putBinding({
                    path,
                    url: remote,
                });
                return remote;
            }
        }
        return local?.url;
    }
}
