// src/components/LogoLoader.tsx
import React, { Suspense, useState, useEffect, useContext } from "react";
import logoMap from "./logoMap";
import { UserAuthContext } from "../../context/UserContext";

interface LogoLoaderProps {
  // logoName: string;
  className?: string;
}

const LogoLoader: React.FC<LogoLoaderProps> = ({ ...rest }) => {
  const { appUser } = useContext(UserAuthContext);

  const [LogoComponent, setLogoComponent] = useState<React.ComponentType<
    React.SVGProps<SVGSVGElement>
  > | null>(null);

  useEffect(() => {
    const loadLogo = async () => {
      if (appUser.user.unityIdParent) {
        if (logoMap[appUser.user.unityIdParent.toString()]) {
          const { ReactComponent } = await logoMap[
            appUser.user.unityIdParent.toString()
          ]();
          setLogoComponent(() => ReactComponent);
        }
      } else {
        // en caso no exista unityIdParent
        const { ReactComponent } = await logoMap["logoPentracker2"]();
        setLogoComponent(() => ReactComponent);
      }
    };

    loadLogo();
  }, []);

  if (!LogoComponent) {
    return <div>Loading...</div>;
  }

  return <LogoComponent {...rest} />;
};

export default LogoLoader;
