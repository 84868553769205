import { TableColumnRelation } from "../../ui/context/TableContext";
import { VehicleControl } from "../../domain/control/Vehicle";

export const VehicleControlTableSchema: TableColumnRelation<VehicleControl> = {
  large: {
    breakpoints: ["lg", "xl", "2xl"],
    columns: [
      {
        name: "unityId",
        label: "Unidad",
        weight: 0.2,
      },
      {
        name: "timestamp",
        label: "Añadido en",
        weight: 0.1,
      },
      {
        name: "driver",
        label: "Conductor",
        weight: 0.4,
      },
      {
        name: "derivation",
        label: "Derivación",
        weight: 0.2,
      },
      {
        name: "entry",
        label: "Ingreso",
        weight: 0.1,
      }
    ],
  },
}
