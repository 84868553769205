import { FC, useCallback, useContext, useEffect, useState } from "react";
import { AttendancePair } from "../../../../domain/attendance/Attendance";
import { useByRequestAttendanceViewModel } from "../../../../viewmodel/attendance-group/ByRequestAttendanceGroup";
import { AppLoader } from "../../../components/loading/LoadingOverlay";
import { DatePicker, IconButton, Loader } from "rsuite";
import { formatDate } from "../../../../util/date";
import { FaIcon } from "../../../components/fontawesome/icon";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { toRelativeCalendar } from "../../../../lib/date";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../../../../hook/query";
import { DateTime } from "luxon";
import { toInteger } from "lodash";
import { useQueryNavigate } from "../../../../hook/navigate";
import { ByRequestAttendanceModal } from "./components/modal";
import { AttendanceGroupByRequestData } from "./components/data";
import { DashboardHeaderContext } from "../../../../hook/header";

import { useStyleContext } from "../../../context/StyleContext";

export const ByRequestAttendanceGroup: FC = () => {
  const queryTimestamp = useQuery().get("timestamp");
  const initialSelectedTime = queryTimestamp
    ? DateTime.fromMillis(toInteger(queryTimestamp)).toJSDate()
    : new Date();
  const [selection, setSelection] = useState<AttendancePair | null>(null);
  const [selectedTime, setSelectedTime] = useState(initialSelectedTime);
  const dashboardHeaderContext = useContext(DashboardHeaderContext);
  const {
    fetchState,
    fetchAttendance,
    onFetchStateReceived,
    userAttendanceList,
    userAttendanceTimestamp,
    refreshEvent,
    requestRefreshEvent,
    onRefreshEventCompleted,
  } = useByRequestAttendanceViewModel();
  const navigate = useNavigate();
  const queryNavigate = useQueryNavigate();
  const { stylePage } = useStyleContext();
  /* eslint-disable */
  useEffect(() => {
    void fetchAttendance(selectedTime.getTime());
  }, [selectedTime]);

  useEffect(() => {
    if (fetchState && !fetchState.isLoading()) {
      if (fetchState.isFailed()) {
        window.toast.error(
          "Ocurrió un error al obtener la lista de asistencia."
        );
        console.log(fetchState?.error);
      }
      onFetchStateReceived();
    }
  }, [fetchState]);

  useEffect(() => {
    if (refreshEvent) {
      void fetchAttendance(selectedTime.getTime(), true);
      onRefreshEventCompleted();
    }
  }, [refreshEvent]);

  useEffect(() => {
    dashboardHeaderContext.setTheme({
      className: "bg-secondary-header",
      title: "Asistencia",
      dark: true,
      subtitle: "Seleccionar por fecha",
    });
  }, []);

  const onTableItemSelected = useCallback((item: AttendancePair) => {
    if (item.departure) {
      navigate(
        `/dashboard/attendance?entry=${encodeURIComponent(
          item.entry.reference
        )}&departure=${encodeURIComponent(item.departure.reference)}`
      );
    } else {
      navigate(`/dashboard/attendance?entry=${item.entry.reference}`);
    }
  }, []);

  const onTablePreviewActionClicked = useCallback((item: AttendancePair) => {
    setSelection(item);
  }, []);

  const onModalClosed = useCallback(() => {
    setSelection(null);
  }, []);
  /* eslint-enable */

  const loading = fetchState?.loading || undefined;

  return (
    <div className={"w-full overflow-x-hidden"} style={stylePage}>
      <AppLoader isActive={loading} />
      {selection ? (
        <ByRequestAttendanceModal
          attendancePair={selection}
          onClose={onModalClosed}
        />
      ) : null}
      <div
        className={
          "flex-none w-full flex flex-row flex-wrap gap-2 p-2 border-b-2 border-neutral-100 overflow-x-hidden items-center justify-between"
        }
      >
        <span className={"text-lg font-normal text-neutral-600 uppercase"}>
          {formatDate(selectedTime)}
        </span>
        <div className={"flex flex-row flex-wrap items-center gap-2"}>
          <div className={"flex flex-row flex-wrap gap-2 items-center z-0"}>
            <span>Seleccionar fecha</span>
            <DatePicker
              value={selectedTime}
              onChange={(newDate) => {
                if (
                  !!newDate &&
                  newDate?.getTime() !== selectedTime.getTime()
                ) {
                  setSelectedTime(newDate);
                  queryNavigate({
                    timestamp: newDate.getTime(),
                  });
                }
              }}
            />
          </div>
          <IconButton
            size={"sm"}
            appearance={"primary"}
            onClick={requestRefreshEvent}
            icon={<FaIcon icon={faRefresh} />}
          >
            Actualizar
          </IconButton>
        </div>
      </div>

      {fetchState?.isLoading() ? (
        <Loader center backdrop size={"sm"} content={"Cargando..."} />
      ) : null}
      {userAttendanceTimestamp ? (
        <div className={"w-full flex-none p-2"}>
          <p>
            <span className={"text-neutral-400 uppercase"}>
              Última actualización:{" "}
            </span>
            {toRelativeCalendar(userAttendanceTimestamp.timestamp, true)}
          </p>
        </div>
      ) : null}
      <AttendanceGroupByRequestData
        onClick={onTableItemSelected}
        onPreview={onTablePreviewActionClicked}
        items={userAttendanceList}
      />
    </div>
  );
};
