import { FC, useCallback, useEffect } from "react";
import { CustomInputProps } from "../../../../../components/modal/FormModal";
import { NewUser } from "../../../../../../domain/user/User";
import { SelectPicker, Tag } from "rsuite";
import { filter, first, toNumber } from "lodash";
import { UserRoles } from "../../../../../../domain/user/Role";
import { useUnityListViewModel } from "../../../../../../viewmodel/unity/UnityList";
import { removeInArray } from "../../../../../../util/array";

const Component: FC<CustomInputProps<NewUser>> = ({
  name,
  updateValue,
  values,
}) => {
  const viewModel = useUnityListViewModel();

  /* eslint-disable */
  useEffect(() => {
    //   console.log("values", values);
    void viewModel.fetchUnityList();
  }, []);

  useEffect(() => {
    if (viewModel.fetchListState && !viewModel.fetchListState.loading) {
      if (viewModel.fetchListState.isFailed()) {
        window.toast.error("Ocurrió un error al obtener la lista de unidades.");
      }
    }
  }, [viewModel.fetchListState]);
  const addUnityId = useCallback(
    (id: number) => {
      updateValue(name, id);
    },
    [values[name]]
  );

  /*eslint-enable */

  return (
    <div className={"flex flex-col gap-1"}>
      <p>Unidad Padre</p>
      <SelectPicker
        block
        data={viewModel.unityList || []}
        onSelect={(selection) => {
          addUnityId(toNumber(selection));
        }}
        labelKey={"label"}
        valueKey={"id"}
        cleanable={false}
        value={values[name]}
      />
    </div>
  );
};

export const NewUserUnityParentSelectInput: FC<CustomInputProps<NewUser>> = (
  props
) => {
  if (props.values.roleId === UserRoles.client) {
    return <Component {...props} />;
  }
  return null;
};
