import {
  FC,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { AppLoader } from "../../components/loading/LoadingOverlay";
import { DatePicker, IconButton, Loader } from "rsuite";
import { formatDate } from "../../../util/date";
import { FaIcon } from "../../components/fontawesome/icon";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { toRelativeCalendar } from "../../../lib/date";
import { ManagerEventModal } from "./request/component/modal";
import { ManagerEventListData } from "./request/component/data";
import { useNavigate } from "react-router-dom";
import { DashboardHeaderContext } from "../../../hook/header";
import { useQueryNavigate } from "../../../hook/navigate";
import { useQuery } from "../../../hook/query";
import { DateTime } from "luxon";
import { toInteger } from "lodash";
import { useManagerEventGroupViewModel } from "../../../viewmodel/event/ManagerEvent";
import { AppManagerEvent } from "../../../domain/event/ManagerEvent";
import { useStyleContext } from "../../context/StyleContext";

export const EventsPage: FC<any> = () => {
  const queryTimestamp = useQuery().get("timestamp");
  const initialSelectedTime = queryTimestamp
    ? DateTime.fromMillis(toInteger(queryTimestamp)).toJSDate()
    : new Date();
  const [selectedTime, setSelectedTime] = useState(initialSelectedTime);
  const navigate = useNavigate();
  const {
    fetchState,
    fetchManagerEventList,
    onFetchStateReceived,
    managerEventList,
    managerEventListTimestamp,
    refreshEvent,
    requestRefreshEvent,
    onRefreshEventCompleted,
    onSelectItemCompleted,
    requestSelectItem,
    selectedItem,
  } = useManagerEventGroupViewModel();
  const loading = fetchState?.loading || undefined;
  const dashboardHeaderContext = useContext(DashboardHeaderContext);
  const { stylePage } = useStyleContext();

  const queryNavigate = useQueryNavigate();
  /* eslint-disable */
  useEffect(() => {
    void fetchManagerEventList(selectedTime.getTime());
  }, [selectedTime]);

  useEffect(() => {
    if (fetchState && !fetchState.isLoading()) {
      if (fetchState.isFailed()) {
        window.toast.error("Ocurrió un error al obtener la lista de eventos.");
        console.log(fetchState?.error);
      }
      onFetchStateReceived();
    }
  }, [fetchState]);

  useEffect(() => {
    if (refreshEvent) {
      void fetchManagerEventList(selectedTime.getTime(), true);
      onRefreshEventCompleted();
    }
  }, [refreshEvent]);

  const onTableItemSelected = useCallback((event: AppManagerEvent) => {
    requestSelectItem(event);
  }, []);

  useEffect(() => {
    dashboardHeaderContext.setTheme({
      className: "bg-neutral-100",
      title: "Historial de Eventos",
      dark: false,
      subtitle: "Por administradores",
    });
  }, []);

  /* eslint-enable */
  return (
    <div className={"p-2 md:p-3 lg:py-5"} style={stylePage}>
      <AppLoader isActive={loading} />
      {!!selectedItem ? (
        <ManagerEventModal
          item={selectedItem}
          onClose={onSelectItemCompleted}
        />
      ) : null}
      <div
        className={
          "flex-none w-full flex flex-row flex-wrap gap-2 p-2 border-b-2 border-neutral-100 overflow-x-hidden items-center justify-between"
        }
      >
        <span className={"text-lg font-normal text-neutral-600 uppercase"}>
          {formatDate(selectedTime)}
        </span>
        <div className={"flex flex-row flex-wrap items-center gap-2"}>
          <div className={"flex flex-row flex-wrap gap-2 items-center z-0"}>
            <span>Seleccionar fecha</span>
            <DatePicker
              size={"sm"}
              appearance={"subtle"}
              value={selectedTime}
              onChange={(newDate) => {
                if (
                  !!newDate &&
                  newDate?.getTime() !== selectedTime.getTime()
                ) {
                  setSelectedTime(newDate);
                  queryNavigate({
                    timestamp: newDate.getTime(),
                  });
                }
              }}
            />
          </div>
          <IconButton
            size={"sm"}
            appearance={"primary"}
            onClick={requestRefreshEvent}
            icon={<FaIcon icon={faRefresh} />}
          >
            Actualizar
          </IconButton>
        </div>
      </div>
      {fetchState?.isLoading() ? (
        <Loader center backdrop size={"sm"} content={"Cargando..."} />
      ) : (
        <Fragment>
          {managerEventListTimestamp ? (
            <div className={"w-full flex-none p-2"}>
              <p>
                <span className={"text-neutral-400 uppercase"}>
                  Última actualización:{" "}
                </span>
                {toRelativeCalendar(managerEventListTimestamp.timestamp, true)}
              </p>
            </div>
          ) : null}
          <ManagerEventListData
            items={managerEventList}
            onClick={onTableItemSelected}
          />
        </Fragment>
      )}
    </div>
  );
};
