import { FC, useCallback, useContext, useEffect } from "react";
import { AppLoader } from "../../../components/loading/LoadingOverlay";
import { ShiftPageHeader } from "./components/header";
import { useShiftViewModel } from "../../../../viewmodel/shift/Shift";
import {
  EditShiftInputList,
  NewShiftInputList,
  NewShiftInputModel,
} from "./modal/InputList";
import { FormModal } from "../../../components/modal/FormModal";
import { EditableFormModal } from "../../../components/modal/EditableFormModal";
import { ShiftListData } from "./components/data";
import { Shift } from "../../../../domain/user/Shift";
import { ShiftTableMultiselectHeader } from "./components/table/header";
import { DeleteShiftConfirmationModal } from "./components/delete";
import { DashboardHeaderContext } from "../../../../hook/header";
import { useStyleContext } from "../../../context/StyleContext";

export const ShiftPaneContent: FC = (_) => {
  const {
    postState,
    editState,
    deleteState,
    fetchState,
    requestDeleteEvent,
    requestRefreshEvent,
    onListStateReceived,
    onAddEventCompleted,
    onDeleteEventCompleted,
    onDeleteStateReceived,
    onEditEventCompleted,
    onEditStateReceived,
    fetchList,
    onPostStateReceived,
    postShift,
    editEvent,
    requestEditEvent,
    deleteEvent,
    deleteShift,
    requestAddEvent,
    refreshEvent,
    onRefreshEventCompleted,
    addEvent,
    allLoadingObserver,
    putShift,
    list,
    selectedItems,
    switchItemSelection,
    switchSelectAll,
    clearSelection,
  } = useShiftViewModel();

  const headerContext = useContext(DashboardHeaderContext);
  const { stylePage } = useStyleContext();

  /* eslint-disable */
  useEffect(() => {
    if (postState && !postState.loading) {
      const error = postState?.error;
      if (error) {
        window.toast.error(error.message);
      } else {
        window.toast.success("Turno añadido correctamente.");
      }
      onPostStateReceived();
      onAddEventCompleted();
    }
  }, [postState]);

  useEffect(() => {
    if (deleteState && !deleteState.loading) {
      const error = deleteState?.error;
      if (error) {
        window.toast.error(error.message);
      } else {
        window.toast.success("Turno eliminado correctamente.");
      }
      onDeleteEventCompleted();
      onDeleteStateReceived();
    }
  }, [deleteState]);

  useEffect(() => {
    if (editState && !editState.loading) {
      const error = editState?.error;
      if (error) {
        window.toast.error(error.message);
      } else {
        window.toast.success("Turno editado correctamente.");
      }
      onEditEventCompleted();
      onEditStateReceived();
    }
  }, [editState]);

  useEffect(() => {
    if (fetchState && !fetchState.loading) {
      const error = fetchState?.error;
      if (error) {
        window.toast.error(error.message);
      }
      onListStateReceived();
    }
  }, [fetchState]);

  useEffect(() => {
    if (refreshEvent) {
      void fetchList(true);
      onRefreshEventCompleted();
    }
  }, [refreshEvent]);

  useEffect(() => {
    void fetchList();
    headerContext.setTheme({
      className: "bg-secondary-header",
      dark: true,
      title: "Turnos",
    });
  }, []);

  const onDataDeleteClicked = useCallback((item: Shift) => {
    void requestDeleteEvent([item.reference]);
  }, []);

  const onDataEditClicked = useCallback((item: Shift) => {
    void requestEditEvent([item.reference]);
  }, []);

  /* eslint-enable */
  return (
    <div className={"w-full overflow-x-hidden scrollbar-hide"}>
      <AppLoader isActive={allLoadingObserver} />
      {addEvent ? (
        <FormModal
          title={"Nuevo Turno"}
          onCancel={onAddEventCompleted}
          onOk={postShift}
          visible={true}
          disabled={postState?.loading}
          inputList={NewShiftInputList}
          model={NewShiftInputModel}
        />
      ) : null}
      {editEvent ? (
        <EditableFormModal
          title={"Editar Turno"}
          onCancel={onEditEventCompleted}
          onOk={putShift}
          visible={true}
          disabled={editState?.loading}
          inputList={EditShiftInputList}
          initialValues={editEvent}
          model={NewShiftInputModel}
        />
      ) : null}
      {deleteEvent ? (
        <DeleteShiftConfirmationModal
          onClose={onDeleteEventCompleted}
          onConfirmation={deleteShift}
          shiftListToDelete={deleteEvent}
        />
      ) : null}
      <ShiftPageHeader
        addDisabled={Boolean(postState?.loading)}
        addLoading={Boolean(postState?.loading)}
        refreshDisabled={Boolean(fetchState?.loading)}
        refreshLoading={Boolean(fetchState?.loading)}
        onAdd={requestAddEvent}
        onRefresh={requestRefreshEvent}
        stylePage={stylePage}
      />
      <div className={"w-full bg-neutral-100 p-2 md:p-3 lg:p-5 scrollbar-hide"}>
        <ShiftTableMultiselectHeader
          selectedItems={selectedItems}
          onCancelClicked={clearSelection}
          onEditClicked={requestEditEvent}
          onDeleteClicked={requestDeleteEvent}
        />
        <ShiftListData
          onEditClick={onDataEditClicked}
          items={list}
          onClick={() => {}}
          onDeleteClick={onDataDeleteClicked}
          selectedItems={selectedItems}
          onItemSelectionSwitch={switchItemSelection}
          onSelectAllSwitch={switchSelectAll}
        />
      </div>
    </div>
  );
};
