import { db } from "../db";
import { NewsItem } from "../../../domain/news/NewsItem";

export class NewsDao {
  static async putNews(...news: NewsItem[]) {
    return db.news.bulkPut(news);
  }

  static async delete(reference: string) {
    return db.news.delete(reference);
  }

  static async getAll(start: number, end: number) {
    return db.news
      .where("timestamp")
      .between(start, end, true, true)
      .reverse()
      .sortBy("timestamp");
  }

  static async update(reference: string, changes: Partial<NewsItem>) {
    return db.news.update(reference, changes);
  }

  static async deleteAll(start: number, end: number) {
    return db.news
      .where("timestamp")
      .between([start], [end], true, true)
      .delete();
  }
}
