import { FC, useCallback, useEffect } from "react";
import { CustomInputProps } from "../../../../../components/modal/FormModal";
import { NewUser } from "../../../../../../domain/user/User";
import { SelectPicker, Tag } from "rsuite";
import { filter, first, toNumber } from "lodash";
import { UserRoles } from "../../../../../../domain/user/Role";
import { useUnityListViewModel } from "../../../../../../viewmodel/unity/UnityList";
import { removeInArray } from "../../../../../../util/array";

const Component: FC<CustomInputProps<NewUser>> = ({
  name,
  updateValue,
  values,
}) => {
  const viewModel = useUnityListViewModel();
  
  /* eslint-disable */
  useEffect(() => {
    // console.log("values", values);
    void viewModel.fetchUnityList();
  }, []);

  useEffect(() => {
    if (viewModel.fetchListState && !viewModel.fetchListState.loading) {
      if (viewModel.fetchListState.isFailed()) {
        window.toast.error("Ocurrió un error al obtener la lista de unidades.");
      }
    }
  }, [viewModel.fetchListState]);
  const addUnityId = useCallback(
    (id: number) => {
      const currentArray: Array<number> = values[name]
        ? ([...values[name]] as Array<number>)
        : [];
      if (!currentArray.includes(id)) {
        currentArray.push(id);
        updateValue(name, currentArray);
      }
    },
    [values[name]]
  );

  const removeUnityId = useCallback(
    (id: number) => {
      if (values[name]) {
        const currentArray = values[name] as Array<number>;
        updateValue(name, removeInArray(currentArray, id));
      }
    },
    [values[name]]
  );
  /*eslint-enable */

  return (
    <div className={"flex flex-col gap-1"}>
      <p>Unidades designadas al cliente</p>
      <SelectPicker
        block
        data={viewModel.unityList || []}
        onSelect={(selection) => {
          addUnityId(toNumber(selection));
        }}
        labelKey={"label"}
        valueKey={"id"}
        cleanable={false}
      />
      <div className={"w-full flex flex-row flex-wrap gap-2"}>
        {(values[name] as Array<number>)?.map((id) => {
          const unity = first(
            filter(viewModel.unityList, (it) => it.id === id)
          );
          return (
            <Tag
              color={"red"}
              size={"lg"}
              key={`tag-${id}`}
              closable
              onClose={() => {
                removeUnityId(id);
              }}
            >
              {unity ? unity.label : id}
            </Tag>
          );
        })}
      </div>
    </div>
  );
};

export const NewUserClientUnitySelectInput: FC<CustomInputProps<NewUser>> = (
  props
) => {
  if (props.values.roleId === UserRoles.client) {
    return <Component {...props} />;
  }
  return null;
};
