import React, { FC, useContext, useEffect } from "react";
import {useLoaderData} from "react-router-dom";
import {ContextHeader} from "../../components/header/secondary";
import { DashboardHeaderContext } from "../../../hook/header";
import { GoodsControlView } from "./components/view";
import { UserGoodsControl } from "../../../domain/control/Goods";

export const GoodsControlPage: FC<any> = () => {
    const data = useLoaderData() as UserGoodsControl;
  /* eslint-disable */
  const dashboardHeaderContext = useContext(DashboardHeaderContext);
  useEffect(() => {
    dashboardHeaderContext.setTheme({
      className: "bg-gray-100",
      title: "Control de Bienes y Equipos",
      dark: false,
      subtitle: data.owner?.displayName,
      homeAsUpEnabled: true
    });
  }, []);
  /* eslint-enable */
    return (
        <div className={"w-full h-full flex flex-col overflow-hidden px-3"}>
            <ContextHeader
                breadcrumbItems={[
                    {
                        label: "Control de Bienes y Equipos",
                        path: "/dashboard/goods-control-group",
                    },
                    {
                        label: "...",
                        active: true,
                    },
                ]}
                title={"Registro de Control de Bienes / Equipos"}
            />
            <div className={"p-3 flex-1 w-full overflow-y-auto"}>
                <GoodsControlView goodsControl={data}/>
            </div>
        </div>
    );
};
