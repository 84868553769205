import { FC, useContext } from "react";
import {
  EditOperatorProfileInputDataList,
  EditProfileInputDataList,
  EditUserInputDataList,
} from "./EditUserInputList";
import { NewUser } from "../../../../../domain/user/User";
import { Schema } from "rsuite";
import { EditableFormModal } from "../../../../components/modal/EditableFormModal";
import { UserAuthContext } from "../../../../context/UserContext";
import { InputDataList } from "../../../../components/modal/FormModal";
import { isNotBlank } from "../../../../../util/validation";

type Props = {
  onOk: (data: Partial<NewUser>) => void;
  onCancel: () => void;
  initialValues: NewUser;
};

export const EditUserInputSchema = Schema.Model<NewUser>({
  uid: Schema.Types.StringType()
    .minLength(8)
    .isRequired("Complete este campo."),
  displayName: Schema.Types.StringType().isRequired("Complete este campo."),
  password: Schema.Types.StringType().minLength(8),
  phoneNumber: Schema.Types.StringType().pattern(
    new RegExp(/^\+51\d{9}$/),
    "Ingrese un número de teléfono válido."
  ),
  email: Schema.Types.StringType()
    .isRequired("Complete este campo")
    .isEmail("Ingrese un email válido."),
  reason: Schema.Types.StringType()
    .isRequired("Es necesario completar este campo.")
    .addRule(isNotBlank(10), "Es nombre debe contener al menos 10 caracteres."),
});

export const EditUserModal: FC<Props> = ({ onOk, onCancel, initialValues }) => {
  // console.log("initialValues", initialValues);
  const { appUser } = useContext(UserAuthContext);
  let title = "Editar Información de Usuario";
  let model: InputDataList<NewUser>;
  if (appUser.isMainAdmin()) {
    model = EditUserInputDataList;
  } else if (appUser.uid() === initialValues.uid) {
    title = "Editar perfil";
    model = EditProfileInputDataList;
  } else if (appUser.isManager()) {
    title = "Editar información de Operador";
    model = EditOperatorProfileInputDataList;
  } else {
    model = [];
  }
  return (
    <EditableFormModal
      inputList={model}
      title={title}
      visible={true}
      onOk={onOk}
      onCancel={onCancel}
      model={EditUserInputSchema}
      initialValues={initialValues}
    />
  );
};
