import { useState } from "react";
import { Response } from "../../domain/app/Response";
import FirebaseAuth from "../../data/source/FirebaseAuth";

export function useProfileViewModel() {
  const [sendState, setSendState] = useState<Response<boolean> | null>(null);

  async function requestSendConfirmation() {
    setSendState(Response.loading())
    try {
      await FirebaseAuth.requestResetPassword()
      setSendState(Response.success(true))
    } catch (e: any) {
      setSendState(Response.failure(e))
    }
  }

  function onSendStateReceived() {
    setSendState(null)
  }
  return {
    sendState, requestSendConfirmation, onSendStateReceived
  }
}
