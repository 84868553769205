import {TableColumnRelation} from "../../ui/context/TableContext";

export type IncidentTableColumns = {
    timestamp: string;
    date: string;
    time: string;
    type: string;
    unity: string;
    zone: string;
    actions: string;
};

export const IncidentTableSchema: TableColumnRelation<IncidentTableColumns> = {
    large: {
        breakpoints: ["lg", "xl", "2xl"],
        columns: [
            {
                name: "timestamp",
                label: "Publicación",
                weight: 0.1,
            },
            {
                name: "date",
                label: "Día",
                weight: 0.1,
            },
            {
                name: "time",
                label: "Hora",
                weight: 0.1
            },
            {
                name: "type",
                label: "Tipo",
                weight: 0.2,
            },
            {
                name: "unity",
                label: "Unidad",
                weight: 0.2,
            },
            {
                name: "zone",
                label: "Zone",
                weight: 0.2
            },
            {
                name: "actions",
                label: "Acciones",
                weight: 0.1
            }
        ],
    },
}
