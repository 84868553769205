import {FC, Fragment, useState} from "react";
import {getFileType} from "../../../util/file";
import {removeInArray} from "../../../util/array";
import {isEmpty} from "lodash";
import {IconButton, Uploader, UploaderProps} from "rsuite";
import {FileType} from "rsuite/Uploader";
import {FileUpload, Plus} from "@rsuite/icons";

export type Props = {
    value?: FileType;
    onChangeValue: (arg0?: FileType) => void;
    listType?: UploaderProps["listType"];
};

type DefaultFileType = {
    name: string;
    url: string;
    fileKey: number;
};

export type ImageUploaderProps = {
    defaultFile?: DefaultFileType;
    onDeleteFile?: (
        fileType: DefaultFileType | FileType,
        setLoading: (loading: boolean) => void
    ) => Promise<boolean>;
    onUploadRequest: (
        file: File,
        setLoading: (loading: boolean) => void
    ) => Promise<DefaultFileType | undefined>;
    onSelectionError: (err: Error) => void;
    disabled?: boolean;
};

export const SingleImageUploaderControl: FC<ImageUploaderProps> = ({
                                                                       defaultFile,
                                                                       onUploadRequest,
                                                                       onDeleteFile,
                                                                       onSelectionError,
                                                                       disabled,
                                                                   }) => {
    const [fileList, setFileList] = useState<(FileType | DefaultFileType)[]>([]);
    const [uploaded, setUploaded] = useState(false);
    const [uploading, setUploading] = useState(false);

    function updateUploadingState(uploading: boolean) {
        setUploading(uploading);
    }

    const handleUpload = async () => {
        if (
            isEmpty(fileList) ||
            fileList[0].url ||
            (!fileList[0] as unknown as FileType).blobFile
        ) {
            if (onSelectionError)
                onSelectionError(
                    Error("El archivo seleccionado no es una imagen válida.")
                );
        } else {
            const result = await onUploadRequest(
                (fileList[0] as FileType).blobFile!!,
                updateUploadingState
            );
            if (result) setFileList([result]);
            setUploaded(!!result);
        }
    };

    return (
        <div className={"flex flex-wrap gap-2 items-end w-full"}>
            <Uploader
                disabledFileItem={disabled}
                className={"text-ellipsis overflow-hidden"}
                loading={uploading}
                accept={"image/*"}
                size={"lg"}
                autoUpload={false}
                action={""}
                fileList={fileList}
                defaultFileList={defaultFile ? [defaultFile] : undefined}
                onChange={(fileList) => {
                    if (fileList && fileList.length >= 1) {
                        if (
                            fileList[0].blobFile &&
                            getFileType(fileList[0].blobFile).includes("image")
                        ) {
                            setFileList(fileList);
                            setUploaded(false);
                        } else {
                            if (onSelectionError)
                                onSelectionError(
                                    Error("El archivo seleccionado no es una imagen válida.")
                                );
                            setFileList([]);
                        }
                    } else {
                        setFileList([]);
                    }
                }}
                listType={"picture-text"}
                onRemove={(fileType) => {
                    setFileList(removeInArray(fileList, fileType));
                    if (onDeleteFile) void onDeleteFile(fileType, updateUploadingState);
                }}
                disabled={fileList.length >= 1}
                appearance={"primary"}
            >
                <IconButton disabled={disabled} icon={<Plus/>}>
                    Seleccionar Imagen
                </IconButton>
            </Uploader>
            <IconButton
                onClick={handleUpload}
                loading={uploading}
                disabled={
                    uploaded || isEmpty(fileList) || !!fileList[0].url || disabled
                }
                className={isEmpty(fileList) ? "invisible" : "visible"}
                icon={<FileUpload/>}
            >
                Subir Imagen
            </IconButton>
        </div>
    );
};

type ImagePickerProps = {
    onChange: (image?: FileType) => void;
    disabled?: boolean;
    onSelectionError?: (error: Error) => void;
    imageFile?: FileType;
    label?: string;
};

export const SingleImagePickerControl: FC<ImagePickerProps> = ({
                                                                   onChange,
                                                                   disabled,
                                                                   onSelectionError,
                                                                   imageFile,
                                                                   label,
                                                               }) => {
    return (
        <Uploader
            disabledFileItem={disabled}
            className={"text-ellipsis overflow-hidden"}
            loading={disabled}
            accept={"image/*"}
            size={"md"}
            autoUpload={false}
            action={""}
            fileList={imageFile ? [imageFile] : []}
            onChange={(fileList) => {
                if (fileList && fileList.length >= 1) {
                    const fileType = fileList[0];
                    if (
                        fileType.blobFile &&
                        getFileType(fileType.blobFile).includes("image")
                    ) {
                        onChange(fileType);
                    } else {
                        if (onSelectionError)
                            onSelectionError(
                                Error("El archivo seleccionado no es una imagen válida.")
                            );
                        onChange(undefined);
                    }
                } else {
                    onChange(undefined);
                }
            }}
            listType={"picture-text"}
            onRemove={(fileType) => {
                onChange(undefined);
            }}
            disabled={!!imageFile}
            appearance={"primary"}
        >
            <IconButton disabled={disabled} icon={<Plus/>}>
                {label ? label : "Seleccionar Imagen"}
            </IconButton>
        </Uploader>
    );
};

export const SingleUploadInput: FC<Props> = ({
                                                 value,
                                                 onChangeValue,
                                                 listType = "picture",
                                             }) => {
    return (
        <Fragment>
            <Uploader
                className={"text-ellipsis overflow-hidden"}
                fileList={!!value ? [value] : []}
                autoUpload={false}
                listType={listType}
                onChange={(fileList) => {
                    if (fileList.length === 1) {
                        const file = fileList[0];
                        if (!!file) onChangeValue(file.blobFile);
                    }
                }}
                action={""}
            >
                <IconButton icon={<FileUpload/>}>Subir Archivo</IconButton>
            </Uploader>
        </Fragment>
    );
};
