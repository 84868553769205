import { Schema } from "rsuite";
import { DeleteZone } from "../../../../../domain/patrol/Zone";
import { isNotBlank } from "../../../../../util/validation";
import { InputDataList } from "../../../../components/modal/FormModal";
import { ReasonInputSelector } from "../../../../components/input/ReasonSelector";

export const DeleteZoneInputSchema = Schema.Model<Partial<DeleteZone>>(
  {
    reason: Schema.Types.StringType().isRequired(
      "Debe especificar el motivo."
    ).addRule(isNotBlank(10), "El motivo de la edición debe tener al menos 10 letras.")
  }
);


export const DeleteZoneInputList: InputDataList<DeleteZone> = [
  {
    name: "reason",
    label: "Motivo de cambio",
    type: "custom",
    help: "Detalle el motivo de la eliminación",
    defaultValue: undefined,
    provide: (props) => <ReasonInputSelector {...props as any}
                                             options={["Nuevo cliente", "Adición de cliente existente"]} />
  }
];
