import {TableColumnRelation} from "../../ui/context/TableContext";

export type ShiftTableColumns = {
    selection: string;
    label: string;
    description: string;
};

export const ShiftTableSchema: TableColumnRelation<ShiftTableColumns> = {
    large: {
        breakpoints: ["lg", "xl", "2xl"],
        columns: [
            {
                name: "selection",
                label: "",
                weight: 0.05,
            },
            {
                name: "label",
                label: "Etiqueta",
                weight: 0.5,
            },
            {
                name: "description",
                label: "Descripción",
                weight: 0.45
            },
        ],
    },
}
