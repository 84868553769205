import {useContext, useState} from "react";
import {Response} from "../../domain/app/Response";
import {AttendancePair} from "../../domain/attendance/Attendance";
import {AttendanceRepository} from "../../data/repository/AttendanceRepository";
import {PentrackerUser} from "../../domain/user/User";
import {UserAuthContext} from "../../ui/context/UserContext";

export function useUserAttendanceViewModel(user: PentrackerUser) {
    const [fetchState, setFetchState] = useState<Response<boolean> | null>(null);
    const [userAttendance, setUserAttendance] = useState<
        AttendancePair | undefined
    >();
    const [refreshEvent, setRefreshEvent] = useState<boolean | null>(null);
    const {appUser} = useContext(UserAuthContext)

    async function fetchUserAttendance(
        timestamp: number,
        forceRefresh: boolean = false
    ) {
        if (fetchState?.isLoading()) return;
        setFetchState(Response.loading());
        try {
            const result = await AttendanceRepository.getUserAttendance(
                user.uid,
                timestamp,
                forceRefresh,
                appUser
            );
            setUserAttendance(result);
            setFetchState(Response.success(true));
        } catch (e: any) {
            setFetchState(Response.failure(e));
        }
    }

    function onFetchStateReceived() {
        setFetchState(null);
    }

    function requestRefreshEvent() {
        setRefreshEvent(true);
    }

    function onRefreshEventCompleted() {
        setRefreshEvent(null);
    }

    return {
        fetchState,
        onFetchStateReceived,
        userAttendance,
        fetchUserAttendance,
        refreshEvent,
        requestRefreshEvent,
        onRefreshEventCompleted,
    };
}
