import { UserIncident } from "../../../../domain/incident/Incident";
import React, { FC, MutableRefObject, useCallback, useRef } from "react";
import { IncidentRow } from "./row";
import { EmptyState } from "../../../components/state/empty";
import { useDataPager } from "../../../../hook/pager";
import { Pagination } from "rsuite";

type IncidentListProps = {
  userIncidentList?: UserIncident[];
  onPreviewClicked: (item: UserIncident) => void;
  onSelect: (item: UserIncident) => void;
  name?: string;
};

export const IncidentList: FC<IncidentListProps> = ({
  userIncidentList,
  onPreviewClicked,
  onSelect,
  name,
}) => {
  const actionRef = useRef<string | null>(null) as MutableRefObject<
    string | null
  >;

  /* eslint-disable */

  const { limits, changeLimit, limit, page, partialData, changePage } =
    useDataPager<UserIncident>({
      id: `inc_list_${name}`,
      items: userIncidentList,
    });

  const onSelectRow = useCallback<React.MouseEventHandler<HTMLDivElement>>(
    (event) => {
      if (!partialData) return;
      const index = event.currentTarget.dataset["index"] as unknown as number;
      const item = partialData[index];
      if (item) {
        if (actionRef.current === "preview") {
          onPreviewClicked(item);
        } else {
          onSelect(item);
        }
      }
      actionRef.current = null;
    },
    [partialData]
  );

  const onPreviewRowClicked = useCallback<
    React.MouseEventHandler<HTMLElement>
    >(() => {
    actionRef.current = "preview";
  }, [partialData]);


  /* eslint-enable */

  return userIncidentList && userIncidentList.length > 0 ? ( //@ts-ignore
    <div className={"w-full h-auto"}>
      {
        partialData?.map((inc, i) =>
          <IncidentRow
            index={i}
            style={{}}
            key={`incident-row-${inc.reference}`}
            data={{
              userIncidentList: partialData,
              onSelect: onSelectRow,
              onPreviewClicked: onPreviewRowClicked,
            }}
          />
        )
      }
      <div className={"py-4 flex flex-col gap-4"}>
        <span className={"text-md"}>
          Mostrando {partialData?.length} elementos / Total {userIncidentList.length}
        </span>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={["limit", "pager"]}
          total={userIncidentList.length}
          limitOptions={limits}
          limit={limit}
          activePage={page}
          onChangePage={changePage}
          onChangeLimit={changeLimit}
        />
      </div>
    </div>
  ) : (
    <EmptyState
      title={"No hay incidencias"}
      message={
        "No se encontraron reportes de incidencias para la fecha seleccionada."
      }
    />
  );
};
