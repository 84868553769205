import {FC} from "react";
import {Modal} from "rsuite";
import { UserIncident } from "../../../../../domain/incident/Incident";
import { UserIncidentView } from "../../../incident/components/view";


export const ByRequestIncidentModal: FC<{
    onClose: () => void,
    incident: UserIncident
}> = ({onClose, incident}) => {

    return <Modal open={true} onClose={onClose}>
        <Modal.Header>
            <Modal.Title>Incidencia</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <UserIncidentView incident={incident}/>
        </Modal.Body>
    </Modal>
}
