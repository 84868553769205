import { FC } from "react";
import { SelectPicker, Input } from "rsuite";
import { CustomInputProps } from "../modal/FormModal";
import { FaIcon } from "../fontawesome/icon";
import { faCheck, faX } from "@fortawesome/free-solid-svg-icons";

type Props = CustomInputProps<any> & {
  options: Array<string>
}
export const ReasonInputSelector: FC<Props> = ({ values, updateValue, options, errorWrapper }) => {
  const value = values["reason"];
  const pickerOptions = options.map(k => ({
    label: k,
    value: k
  }));
  return (
    <div className={"flex flex-row flex-col gap-2"}>
      <SelectPicker data={pickerOptions} value={"Ver opciones"} placeholder={"Ver opciones"} onSelect={(value, _) => {
        updateValue("reason", value);
      }} />
      <Input as={"textarea"} value={value}
             onChange={(value, _) => {
               updateValue("reason", value);
             }} className={"relative"} />
      <p className={"text-sm"}>{value?.length >= 10 ? <FaIcon icon={faCheck} color={"green"} /> :
        <FaIcon icon={faX} color={"red"} />} Mínimo: 10 / Tamaño: {value?.length || 0} </p>
      {
        errorWrapper.formError?.reason ? <span className={"text-red-500"}>{errorWrapper.formError.reason}</span> : null
      }
    </div>
  );
};

