import React, { FC, useCallback, useEffect } from "react";
import { PrimaryHeader } from "../../components/header/primary";
import { useLiveQuery } from "dexie-react-hooks";
import { preferencesDb } from "../../../data/database/db";
import { IconButton, Panel } from "rsuite";
import { toRelativeCalendar } from "../../../lib/date";
import { FaIcon } from "../../components/fontawesome/icon";
import {
  faBellSlash,
  faMessage,
  faTrash,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { AppNotificationDao } from "../../../data/database/dao/AppNotification";
import { MouseEventHandler } from "react/index";
import { EmptyState } from "../../components/state/empty";
import { UserNotificationForm } from "./components/form";
import { useNotificationCenterViewModel } from "../../../viewmodel/notifications/NotificationCenter";
import { AppLoader } from "../../components/loading/LoadingOverlay";
import { useStyleContext } from "../../context/StyleContext";

type Props = {};

export const NotificationCenterPage: FC = () => {
  const notificaciones = useLiveQuery(() =>
    preferencesDb.notifications.orderBy("date").reverse().toArray()
  );
  const {
    sendNotificationState,
    sendNotification,
    onSendNotificationStateReceived,
  } = useNotificationCenterViewModel();
  const deleteAll = useCallback(async () => {
    await AppNotificationDao.deleteAll();
  }, []);

  const onSingleDeleteButtonEvent = useCallback<MouseEventHandler<HTMLElement>>(
    (event) => {
      const id = event?.currentTarget?.dataset["id"];
      if (!!id) {
        void AppNotificationDao.deleteNotification(Number(id));
      }
    },
    []
  );
  const { stylePage } = useStyleContext();

  useEffect(() => {
    if (sendNotificationState && !sendNotificationState?.loading) {
      if (sendNotificationState?.isSuccess()) {
        window.toast.success("La notificación se ha enviado.");
      } else {
        console.log(sendNotificationState.error);
        window.toast.error("Ocurrió un error al enviar las notificaciones.");
      }
      onSendNotificationStateReceived();
    }
  }, [sendNotificationState]);

  return (
    <div className={"w-full overflow-x-hidden"} style={stylePage}>
      <AppLoader isActive={sendNotificationState?.loading} />
      <PrimaryHeader
        breadcrumbItems={[
          {
            label: "Notificaciones",
            active: true,
          },
        ]}
        title={"Notificaciones"}
      />
      <div className={"grid grid-cols-1 md:grid-cols-2 p-3 overflow-hidden"}>
        <div className={"p-3"}>
          <div className={"flex-col"}>
            <h2 className={"font-normal text-lg"}>Notificar usuarios</h2>
            <p className={"font-normal text-md"}>
              Envia notificaciones a los usuarios de la app móvil Pentracker
            </p>
          </div>
          <div className={"p-3"}>
            <UserNotificationForm onSubmit={sendNotification} />
          </div>
        </div>

        <div className={"p-3 divide-neutral-300 divide-y-2 overflow-hidden"}>
          <div className={"sticky top-0 left-0 p-2"}>
            <div
              className={
                "flex flex-row flex-wrap gap-2 justify-between items-start"
              }
            >
              <div className={"flex-col"}>
                <h2 className={"font-normal text-lg"}>Mis notificaciones</h2>
                <p className={"font-normal text-md"}>
                  Tienes {notificaciones?.length} notificaciones sin leer.
                </p>
              </div>
              <IconButton
                size={"sm"}
                appearance={"primary"}
                icon={<FaIcon icon={faTrashAlt} />}
                onClick={deleteAll}
              >
                Eliminar todo
              </IconButton>
            </div>
          </div>
          {!!notificaciones && notificaciones?.length > 0 ? (
            notificaciones?.map((notificacion) => {
              return (
                <div
                  key={`noti-${notificacion.id}`}
                  className={"w-full flex flex-col p-3 my-2 relative"}
                >
                  <IconButton
                    data-id={notificacion.id}
                    onClick={onSingleDeleteButtonEvent}
                    size={"xs"}
                    appearance={"primary"}
                    circle
                    icon={<FaIcon icon={faTrash} />}
                    className={"w-fit absolute ml-auto top-0"}
                  />
                  <p className={"text-sm font-semibold"}>
                    <FaIcon icon={faMessage} />
                    {"   "}
                    {notificacion.title}
                  </p>
                  <p className={"text-xs text-neutral-500"}>
                    {toRelativeCalendar(new Date(notificacion.date!!), true)}
                  </p>
                  {notificacion.body ? (
                    notificacion.body.length > 50 ? (
                      <Panel
                        className={"my-2"}
                        header={<p className={"text-xs"}>Ver mensaje</p>}
                        collapsible
                        bordered
                      >
                        {notificacion.body}
                      </Panel>
                    ) : (
                      <p className={"text-sm"}>{notificacion.body}</p>
                    )
                  ) : null}
                </div>
              );
            })
          ) : (
            <EmptyState
              message={"No tienes notificaciones"}
              title={"Vacío"}
              icon={faBellSlash}
            />
          )}
        </div>
      </div>
    </div>
  );
};
