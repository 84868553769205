import {FC} from "react";
import {IconButton} from "rsuite";
import {faAdd, faQrcode, faRefresh, faTrash,} from "@fortawesome/free-solid-svg-icons";
import {FaIcon} from "../../../../components/fontawesome/icon";

type OnClick = () => void;

export const UnityZonePaneHeader: FC<{
    onAddClick: OnClick;
    onRefreshClick: OnClick;
    onDownloadClick: OnClick;
    onDeleteClick: OnClick;
}> = ({onRefreshClick, onAddClick, onDownloadClick, onDeleteClick}) => {
    return (
        <div
            className={
                "flex-none w-full flex flex-row flex-wrap gap-2 p-2 border-b-2 border-neutral-100 overflow-x-hidden items-center justify-between"
            }
        >
            <div className={"flex flex-row flex-wrap items-center gap-2"}>
                <IconButton
                    appearance="ghost"
                    size={"sm"}
                    onClick={onAddClick}
                    icon={<FaIcon icon={faAdd}/>}
                >
                    Nuevo
                </IconButton>
                <IconButton
                    appearance="ghost"
                    size={"sm"}
                    onClick={onRefreshClick}
                    icon={<FaIcon icon={faRefresh}/>}
                >
                    Actualizar
                </IconButton>
                <IconButton
                    appearance="ghost"
                    size={"sm"}
                    onClick={() => onDownloadClick()}
                    icon={<FaIcon icon={faQrcode}/>}
                >
                    Descargar QR
                </IconButton>
                <IconButton
                    appearance={"ghost"}
                    size={"sm"}
                    onClick={() => onDeleteClick()}
                    icon={<FaIcon icon={faTrash}/>}
                >
                    Eliminar
                </IconButton>
            </div>
        </div>
    );
};
