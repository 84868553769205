import {isEmpty, keysIn} from "lodash";
import {useLocation, useNavigate} from "react-router-dom";

export function useQueryNavigate() {
    const navigate = useNavigate()
    const location = useLocation()

    function navigateWithQueries(
        queryObject: { [key: string]: any },
        reactive: boolean = false
    ) {
        if (isEmpty(queryObject)) return;
        if (reactive) {
            if (isEmpty(queryObject)) return;
            const queries = new URLSearchParams(queryObject).toString();
            navigate(`${location.pathname}?${queries}`);
        } else {
            const url = new URL(window.location as unknown as string);
            const queryKeys = keysIn(queryObject);
            for (let queryKey of queryKeys) {
                url.searchParams.set(queryKey, queryObject[queryKey]);
            }
            window.history.pushState({}, "", url);
        }
    }

    return navigateWithQueries;
}
