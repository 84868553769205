import React, { FC, useCallback, useEffect } from "react";
import { Breadcrumb, IconButton, Loader } from "rsuite";
import { FaIcon } from "../../../components/fontawesome/icon";
import {
  faAdd,
  faRefresh,
  faTicketAlt,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useLoaderData } from "react-router-dom";
import { TicketContainerContentList } from "./components/content-list";
import { TicketFileType } from "../../../../domain/ticket/UserTicket";
import { TicketContentAddFilesModal } from "./components/modal";
import { useUserTicketContentViewModel } from "../../../../viewmodel/tickets/TicketsContainerContent";
import { usePendingTicketsViewModel } from "../../../../viewmodel/tickets/PendingTickets";
import { StorageRepository } from "../../../../data/repository/StorageRepository";
import { previewPdfFile } from "../../../../util/file";
import { AppLoader } from "../../../components/loading/LoadingOverlay";
import { SearchTicketModal } from "./components/SearchTicketModal";

export const UserTicketsContainerContent: FC<any> = () => {
  const container = useLoaderData() as TicketFileType;
  const {
    fetchState,
    fetchContentList,
    onFetchStateReceived,
    list,
    refreshEvent,
    requestRefreshEvent,
    onRefreshEventCompleted,
    addState,
    addEvent,
    requestAddEvent,
    onAddEventCompleted,
    searchState,
    searchTicket,
    searchResults,
    searchEvent,
    requestSearchEvent,
    onSearchEventCompleted,
    postTickets,
    onAddStateReceived,
  } = useUserTicketContentViewModel(container);

  const { setPendingTickets, pendingTickets, users } =
    usePendingTicketsViewModel();

  /* eslint-disable */

  useEffect(() => {
    if (fetchState && !fetchState.isLoading()) {
      if (fetchState.isFailed()) {
        if (container.temp === 1) {
          window.toast.info("Aún no se han añadido boletas.");
        } else {
          window.toast.error(
            "Ocurrió un error al obtener la lista de archivos de boletas."
          );
        }
        console.log(fetchState?.error);
      }
      onFetchStateReceived();
    }
  }, [fetchState]);

  useEffect(() => {
    if (addState && !addState.isLoading()) {
      if (addState.isFailed()) {
        window.toast.error(addState.error?.message);
        console.log(addState?.error);
      } else {
        const { uploaded, messages } = addState.data!!;
        if (messages.length > 0) {
          window.toast.info(
            `Algunos archivos (${messages.length}) no se subieron. Revise la cola de subida.`
          );
        } else {
          window.toast.success("Los archivos se subieron con éxito.");
        }
        const uploadedUids = uploaded.map((it) => it.user!!.uid);
        const items = [...pendingTickets].filter(
          (it) => !uploadedUids.includes(it.user!!.uid)
        );
        setPendingTickets(items);
      }
      void fetchContentList(true);
      onAddStateReceived();
      onAddEventCompleted();
    }
  }, [addState]);

  useEffect(() => {
    void fetchContentList();
  }, []);

  useEffect(() => {
    if (refreshEvent) {
      void fetchContentList(true);
      void onRefreshEventCompleted();
    }
  }, [refreshEvent]);

  const onListItemSelected = (item: TicketFileType) => {
    StorageRepository.getDownloadUrl(item.path).then((url) => {
      if (url) {
        void previewPdfFile({ fileUrl: url, title: `Boleta - ${item.name}` });
      }
    });
  };
  const onPublishTickets = useCallback(() => {
    if (pendingTickets.length <= 0) {
      window.toast.info("No hay boletas pendientes de agregar.");
    } else {
      void postTickets(pendingTickets);
    }
    onAddEventCompleted();
  }, [pendingTickets]);

  /* eslint-enable */
  return (
    <div className={"w-full h-full flex flex-col gap-2 overflow-hidden p-3"}>
      <AppLoader
        isActive={
          fetchState?.isLoading() ||
          addState?.isLoading() ||
          searchState?.isLoading()
        }
      />
      {addEvent ? (
        <TicketContentAddFilesModal
          onOk={onPublishTickets}
          onCancel={onAddEventCompleted}
          setPendingTickets={setPendingTickets}
          users={users}
          pendingTicketList={pendingTickets}
          uploadedList={list}
        />
      ) : null}

      {searchEvent ? (
        <SearchTicketModal
          onClose={onSearchEventCompleted}
          // loading={searchState?.loading}
          onSearch={searchTicket}
          results={searchResults}
        />
      ) : null}

      <Breadcrumb>
        <Breadcrumb.Item>...</Breadcrumb.Item>
        <Link to={"/dashboard/tickets"}>
          <FaIcon icon={faTicketAlt} /> Boletas
        </Link>
        <Breadcrumb.Item active>{container.name}</Breadcrumb.Item>
      </Breadcrumb>
      <div className={"flex flex-row justify-end"}>
        <div className={"flex flex-row flex-wrap items-center gap-2"}>
          <IconButton
            appearance={"primary"}
            size={"sm"}
            onClick={requestRefreshEvent}
            icon={<FaIcon icon={faRefresh} />}
            disabled={fetchState?.isLoading()}
          >
            Actualizar
          </IconButton>

          <IconButton
            appearance={"primary"}
            size={"sm"}
            onClick={requestAddEvent}
            icon={<FaIcon icon={faAdd} />}
            disabled={fetchState?.isLoading()}
          >
            Añadir
          </IconButton>

          <IconButton
            appearance={"primary"}
            size={"sm"}
            onClick={requestSearchEvent}
            icon={<FaIcon icon={faSearch} />}
            disabled={fetchState?.isLoading()}
          >
            Buscar
          </IconButton>
        </div>
      </div>
      {fetchState?.isLoading() ? (
        <Loader center backdrop size={"sm"} content={"Cargando..."} />
      ) : (
        <div
          className={
            "flex-1 w-full h-full flex justify-center sm:items-center overflow-hidden"
          }
        >
          <div className={"w-full h-full flex flex-col"}>
            <div className={"w-full flex-1 overflow-x-hidden"}>
              <TicketContainerContentList
                name={container.name}
                items={list}
                onClick={onListItemSelected}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
