import React, {
  FC,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { UserAuthContext } from "../../context/UserContext";
import { FaIcon } from "../../components/fontawesome/icon";
import {
  faCircleInfo,
  faFaceSmile,
  faSignOut,
  faUserEdit,
  faUserCheck,
  faKey,
} from "@fortawesome/free-solid-svg-icons";
import { UserAvatar } from "../../components/avatar/useravatar";
import { AppLoader } from "../../components/loading/LoadingOverlay";
import { DashboardHeaderContext } from "../../../hook/header";
import { formatDateTime } from "../../../util/date";
import { Link } from "react-router-dom";
import { IconButton } from "rsuite";
import { useProfileViewModel } from "../../../viewmodel/profile/Profile";
import { useStyleContext } from "../../context/StyleContext";

const IconSize = 170;
export const ProfilePage: FC = () => {
  const { appUser, requestSignOut } = useContext(UserAuthContext);
  const { sendState, onSendStateReceived, requestSendConfirmation } =
    useProfileViewModel();
  const [resetPasswordLocked, setResetPasswordLocked] = useState(false);
  const dashboardHeaderContext = useContext(DashboardHeaderContext);
  const user = appUser?.user;
  const { stylePage } = useStyleContext();

  /* eslint-disable */
  useEffect(() => {
    dashboardHeaderContext.setTheme({
      className: "bg-neutral-100",
      title: "Perfil",
    });
  }, []);

  useEffect(() => {
    if (!!sendState && !sendState.isLoading()) {
      if (sendState.isSuccess()) {
        setResetPasswordLocked(true);
        window.toast.success(
          "La confirmación fue enviada a su correo electrónico."
        );
      } else {
        window.toast.error(
          "No se pudo enviar el correo de confirmación. Intente nuevamente."
        );
      }
      onSendStateReceived();
    }
  }, [sendState]);

  const onPasswordResetClicked = useCallback(() => {
    void requestSendConfirmation();
  }, []);
  /* eslint-enable */
  return (
    <div
      className={
        "overflow-y-auto overflow-x-hidden px-2 md:px-4 lg:px-12 py-3 w-full break-all"
      }
      style={stylePage}
    >
      <AppLoader isActive={!user || sendState?.isLoading()} />
      {user ? (
        <Fragment>
          <p className={"text-lg md:text-xl lg:text-2xl w-full"}>
            Información Personal
          </p>
          <hr className="my-2 md:my-3 border-neutral-400" />

          <div className={"my-2 py-2 md:py-4 lg:py-8"}>
            <div
              className={
                "md:flex flex-wrap md:flex-row gap-4 md:gap-8 lg:gap-12 items-center justify-start"
              }
            >
              <div
                className={
                  "flex flex-col flex-wrap gap-3 justify-center items-center"
                }
              >
                <UserAvatar
                  user={user}
                  size={IconSize}
                  errorElement={<FaIcon icon={faFaceSmile} size={"xs"} />}
                />
                <span className={"font-bold text-sm break-words"}>
                  Información Básica
                </span>
              </div>
              <div className={"flex flex-col md:flex-1"}>
                <div
                  className={
                    "flex flex-row gap-2 flex-wrap text-sm p-2 break-words w-full"
                  }
                >
                  <strong>Nombre</strong>
                  {user.displayName
                    ? user.displayName
                    : "No tiene asignado un nombre de usuario."}
                </div>
                <hr className={"my-2 w-full"} />
                <div
                  className={
                    "flex flex-row gap-2 flex-wrap text-sm p-2 break-words w-full"
                  }
                >
                  <strong>Desde</strong>
                  {formatDateTime(new Date(user.creationTime))}
                </div>
                <hr className={"my-2 w-full"} />
                <div
                  className={
                    "flex flex-row gap-2 flex-wrap text-sm p-2 break-all"
                  }
                >
                  <strong>Email</strong>
                  {user.email ? user.email : "No tiene un email asignado."}
                </div>
                <hr className={"my-2 w-full"} />

                <div
                  className={
                    "flex flex-row gap-2 flex-wrap text-sm p-2 break-words w-full"
                  }
                >
                  <strong>Teléfono</strong>
                  {user.phoneNumber
                    ? user.phoneNumber
                    : "No tiene un teléfono asignado."}
                </div>
              </div>
            </div>
          </div>

          <hr className={"border-app"} />

          <div className={"my-2 w-full py-2 md:py-4 lg:py-8"}>
            <div
              className={
                "flex flex-col md:flex-row gap-4 md:gap-8 lg:gap-12 items-center justify-start"
              }
            >
              <div
                className={"flex flex-col gap-3 justify-center items-center"}
              >
                <FaIcon
                  icon={faCircleInfo}
                  className={
                    "text-white bg-orange-500 p-8 rounded-full overflow-hidden"
                  }
                  style={{
                    width: IconSize,
                    height: IconSize,
                  }}
                />
                <span className={"font-bold text-sm"}>Detalles de cuenta</span>
              </div>
              <div className={"flex flex-col md:flex-1"}>
                <div
                  className={
                    "flex flex-row gap-2 flex-wrap text-sm p-2 items-center"
                  }
                >
                  <FaIcon icon={faUserCheck} /> Cuenta verificada
                </div>
                <hr className={"my-2"} />
                <div className={"flex flex-row gap-2 flex-wrap text-sm p-2"}>
                  <strong>Hora inicio de sesión</strong>
                  {formatDateTime(new Date(user.lastSignInTime))}
                </div>
                {appUser?.isClient() === true ? (
                  <Fragment>
                    <hr className={"my-2"} />
                    <div
                      className={"flex flex-row gap-2 flex-wrap text-sm p-2"}
                    >
                      <strong>Unidades</strong>
                      {user.clientUnityArray ? (
                        <div className={"flex flex-row flex-wrap gap-2"}>
                          {user.clientUnityArray.map((unity) => (
                            <Link
                              to={`/dashboard/unity/${unity.id}/main`}
                              key={unity.id}
                            >
                              {unity.label}
                            </Link>
                          ))}
                        </div>
                      ) : user.clientUnity ? (
                        <div className={"flex flex-row flex-wrap gap-2"}>
                          {user.clientUnity.map((id) => (
                            <Link
                              to={`/dashboard/unity/${id}/main`}
                              key={`cli-${id}`}
                            >
                              {id}
                            </Link>
                          ))}
                        </div>
                      ) : (
                        <span className={"text-red-500"}>
                          No hay unidades vinculadas a su cuenta.
                        </span>
                      )}
                    </div>
                  </Fragment>
                ) : null}
              </div>
            </div>
          </div>
          <hr className={"border-app"} />
          <div className={"my-2 w-full py-2 md:py-4 lg:py-8"}>
            <div className={"flex flex-row gap-2 flex-wrap text-sm p-2"}>
              <span className={"font-semibold w-full"}>Editar Perfil</span>
              <span className={"w-full"}>
                Sigue el siguiente link para editar la información del perfil
              </span>
              <Link to={`/dashboard/users/${user.uid}/main`}>
                <IconButton
                  icon={<FaIcon icon={faUserEdit} />}
                  appearance={"primary"}
                >
                  Editar
                </IconButton>
              </Link>
            </div>

            <div className={"flex flex-row gap-2 flex-wrap text-sm p-2"}>
              <span className={"font-semibold w-full"}>Cambiar contraseña</span>
              <span className={"w-full"}>
                Solicita un cambio de contraseña. Presiona el botón a
                continuación y recibirás un correo de confirmación en tu cuenta.
              </span>
              <IconButton
                icon={<FaIcon icon={faKey} />}
                appearance={"primary"}
                disabled={resetPasswordLocked}
                onClick={onPasswordResetClicked}
              >
                Cambiar Contraseña
              </IconButton>
            </div>

            <div
              className={"flex flex-row gap-2 flex-wrap text-sm p-2"}
              id={"signout"}
            >
              <span className={"font-semibold w-full"}>Cerrar Sesión</span>
              <span className={"w-full"}>
                A continuación, puedes cerrar la sesión en este dispositivo.
              </span>
              <IconButton
                onClick={requestSignOut}
                icon={<FaIcon icon={faSignOut} />}
                appearance={"primary"}
              >
                Cerrar Sesión
              </IconButton>
            </div>
          </div>
        </Fragment>
      ) : null}
    </div>
  );
};
