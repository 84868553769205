import {FC, useContext} from "react";
import {BreakPointContext} from "../../../../context/BreakPointContext";
import {Shift} from "../../../../../domain/user/Shift";
import {ShiftList} from "./list";
import {ShiftTable} from "./table";

type Props = {
    items?: Shift[];
    onDeleteClick: (item: Shift) => void;
    onEditClick: (item: Shift) => void;
    onClick: (item: Shift) => void;
    onSelectAllSwitch: () => void;
    onItemSelectionSwitch: (reference: string) => void;
    selectedItems: string[];
};

export const ShiftListData: FC<Props> = ({
                                             items,
                                             onDeleteClick,
                                             onEditClick,
                                             onClick,
                                             onSelectAllSwitch,
                                             onItemSelectionSwitch,
                                             selectedItems,
                                         }) => {

    const screenSize = useContext(BreakPointContext);
    if (screenSize.isMobile) {
        return (
            <ShiftList items={items} onDeleteClicked={onDeleteClick} onClicked={onClick} onEditClicked={onEditClick}/>
        );
    } else {
        return (
            <ShiftTable
                data={items}
                onItemSelected={onClick}
                selectedItems={selectedItems}
                onSwitchItemSelection={onItemSelectionSwitch}
                onSwitchSelectAll={onSelectAllSwitch}
            />
        );
    }
};
