import { useState } from "react";
import ZoneRepository from "../../data/repository/ZoneRepository";
import { Response } from "../../domain/app/Response";
import { DeleteZone, PutZone, Zone } from "../../domain/patrol/Zone";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../data/database/db";
import { Unity } from "../../domain/unity";
import { useOutletContext } from "react-router-dom";
import { removeInArray } from "../../util/array";
import { createZoneDocument } from "../../ui/dashboard/unity/zone/document/document";
import { PostZoneDto } from "../../network/patrol/Zone";
import { ignorePaths } from "../../util/object";

export function useUnitySubmoduleListViewModel() {
  const initialUnity = useOutletContext() as Unity;
  const zoneList = useLiveQuery(() =>
    db.zone.where("unityId").equals(initialUnity.id).toArray()
  );
  const [selectedZonesReferences, setSelectedZonesReferences] = useState<number[]>([]);
  const [createEvent, setCreateEvent] = useState<boolean | null>(null);
  const [refreshEvent, setRefreshEvent] = useState<boolean | null>(null);
  const [deleteEvent, setDeleteEvent] = useState<number[] | null>(null);
  const [fetchListState, setFetchListState] =
    useState<Response<boolean> | null>(null);
  const [createState, setCreateState] = useState<Response<string | null> | null>(
    null
  );
  const [deleteState, setDeleteState] = useState<Response<string[]> | null>(
    null
  );
  const [editEvent, setEditEvent] = useState<Zone | null>(null);
  const [editState, setEditState] = useState<Response<boolean> | null>(null);
  const [downloadEvent, setDownloadEvent] = useState<number[] | null>();
  const [downloadState, setDownloadState] = useState<Response<string[]> | null>(
    null
  );
  const [selectedItem, setSelectedItem] = useState<Zone | null>(null);

  async function fetchZoneList(forceRefresh: boolean = false) {
    if (fetchListState?.loading) return;
    setFetchListState(Response.loading());
    try {
      await ZoneRepository.fetchList(initialUnity.id, forceRefresh);
      setFetchListState(Response.success(true));
    } catch (e: any) {
      setFetchListState(Response.failure(e));
    }
  }

  function onFetchStateReceived() {
    setFetchListState(null);
  }

  function requestCreateEvent() {
    setCreateEvent(true);
  }

  function onCreateEventCompleted() {
    setCreateEvent(null);
  }

  async function createZone(definition: PostZoneDto) {
    if (createState?.isLoading()) return;
    setCreateState(Response.loading());
    try {
      const result = await ZoneRepository.create(initialUnity.id, definition);
      setCreateState(Response.success(result));
    } catch (e: any) {
      setCreateState(Response.failure(e));
    }
  }

  function onCreateStateReceived() {
    setCreateState(null);
  }

  function requestDeleteEvent(ids: number[] = selectedZonesReferences) {
    setDeleteEvent(ids);
  }

  function onDeleteEventCompleted() {
    setDeleteEvent(null);
  }

  async function deleteZone(object: DeleteZone) {
    if (deleteState?.loading) return;
    setDeleteState(Response.loading());
    const result: string[] = [];
    const zoneIds = deleteEvent;
    if (!zoneIds) {
      setDeleteState(
        Response.failure(
          new Error("No se seleccionó un elemento para eliminar.")
        )
      );
      return;
    }
    try {
      if (zoneIds.length <= 0) {
        setDeleteState(
          Response.failure(
            new Error("No se seleccionó un elemento para eliminar.")
          )
        );
      } else {
        const tasks = zoneIds.map(async (id) => {
          try {
            await ZoneRepository.delete(initialUnity.id, {
              id, reason: object.reason
            });
            setSelectedZonesReferences((old) => removeInArray(old, id));
            result.push(`La zona con referencia '${id}' fue eliminada.`);
          } catch (e: any) {
            console.log(e);
            result.push(
              `Ocurrió un error al eliminar la zona con id '${id}'.`
            );
          }
        });
        await Promise.allSettled(tasks);
      }
      setDeleteState(Response.success(result));
    } catch (e: any) {
      console.log(e);
      setDeleteState(Response.failure(e));
    }
  }

  function onDeleteStateReceived() {
    setDeleteState(null);
  }

  async function requestEditZone(references: number[] = selectedZonesReferences) {
    if (references.length <= 0) {
      setEditState(
        Response.failure(
          new Error("No hay zonas seleccionadas para editar.")
        )
      );
    } else if (references.length === 1) {
      const user = await ZoneRepository.getZone(
        initialUnity.id,
        references[0]
      );
      if (!!user) setEditEvent(user);
      else
        setEditState(
          Response.failure(new Error("La zona no fue encontrado."))
        );
    } else {
      setEditState(
        Response.failure(new Error("No es posible editar más de una zona."))
      );
    }
  }

  function onEditEventCompleted() {
    setEditEvent(null);
  }

  async function editZone(object: PutZone) {
    if (editState?.loading) return;
    let item = editEvent;
    try {
      if (item == null) {
        setEditState(
          Response.failure(new Error("No se seleccionó un item para editar."))
        );
        return;
      }
      await ZoneRepository.update(initialUnity.id, {
        id: item.id,
        reason: object.reason,
        data:
          ignorePaths(object, "reason", "toUnityId")
        ,
        fromUnityId: initialUnity.id,
        toUnityId: object.toUnityId
      });
      setEditState(Response.success(true));
    } catch (e: any) {
      setEditState(Response.failure(e));
    }
  }

  function onEditStateReceived() {
    setEditState(null);
  }

  function requestRefreshEvent() {
    setRefreshEvent(true);
  }

  function onRefreshEventCompleted() {
    setRefreshEvent(null);
  }

  function requestDownloadEvent(
    ids: number[] = selectedZonesReferences
  ) {
    setDownloadEvent(ids);
  }

  function onDownloadEventCompleted() {
    setDownloadEvent(null);
  }

  async function downloadZone() {
    if (downloadState?.loading) return;
    setDownloadState(Response.loading());
    const result: string[] = [];
    const zoneReferences = downloadEvent;
    if (!zoneReferences) {
      setDownloadState(
        Response.failure(
          new Error("No se seleccionó un elemento para descargar.")
        )
      );
      return;
    }
    try {
      if (zoneReferences.length <= 0) {
        setDownloadState(
          Response.failure(
            new Error("No se seleccionó un elemento para descargar.")
          )
        );
      } else {
        const tasks = zoneReferences.map(async (ref) => {
          try {
            const zone = await ZoneRepository.getZone(
              initialUnity.id,
              ref
            );
            if (zone) {
              await createZoneDocument(zone, initialUnity);
              result.push(
                `La zona '${zone.label}' se añadió a la cola de descarga.`
              );
            } else {
              result.push(`La zona con referencia '${ref}' no existe.`);
            }
          } catch (e: any) {
            result.push(
              `Ocurrió un error al añadir la zona con referencia '${ref}'.`
            );
          }
        });
        await Promise.allSettled(tasks);
      }
      setDownloadState(Response.success(result));
    } catch (e: any) {
      setDownloadState(Response.failure(e));
    }
  }

  function onDownloadStateReceived() {
    setDownloadState(null);
  }

  function switchZoneSelected(reference: number) {
    if (selectedZonesReferences.includes(reference)) {
      setSelectedZonesReferences((old) => removeInArray(old, reference));
    } else {
      setSelectedZonesReferences((old) => [...old, reference]);
    }
  }

  function clearSelection() {
    setSelectedZonesReferences([]);
  }

  function switchSelectAll() {
    if (zoneList) {
      if (selectedZonesReferences.length === zoneList.length) {
        clearSelection();
      } else {
        setSelectedZonesReferences(zoneList.map((it) => it.id));
      }
    }
  }

  function requestSelectItem(zone: Zone) {
    setSelectedItem(zone);
  }

  function onSelectItemCompleted() {
    setSelectedItem(null);
  }

  return {
    zoneList,
    createEvent,
    refreshEvent,
    deleteEvent,
    fetchListState,
    createState,
    deleteState,
    editEvent,
    editState,
    fetchZoneList,
    onFetchStateReceived,
    requestCreateEvent,
    onCreateEventCompleted,
    createZone,
    onCreateStateReceived,
    requestDeleteEvent,
    onDeleteEventCompleted,
    deleteZone,
    onDeleteStateReceived,
    requestEditZone,
    onEditEventCompleted,
    editZone,
    onEditStateReceived,
    requestRefreshEvent,
    setRefreshEvent,
    downloadEvent,
    requestDownloadEvent,
    onDownloadEventCompleted,
    downloadState,
    downloadZone,
    onDownloadStateReceived,
    switchZoneSelected,
    switchSelectAll,
    selectedZonesReferences,
    onRefreshEventCompleted,
    selectedItem,
    requestSelectItem,
    onSelectItemCompleted,
    clearSelection,
    initialUnity
  };
}
