import {FC} from "react";
import Lottie from "react-lottie";
import animation404 from "../../../resources/91191-404-notfound.json";
import {useNavigate, useRouteError} from "react-router-dom";
import {ButtonGroup, IconButton, Panel} from "rsuite";
import {faBackward, faHomeAlt} from "@fortawesome/free-solid-svg-icons";
import {FaIcon} from "../fontawesome/icon";

export const BadRequestState: FC<{
    title?: string;
    subtitle?: string;
    message?: string;
}> = ({
          title = "Oh no! Error 404 :(",
          subtitle = "Recurso no encontrado",
          message = "No se pudo completar tu solicitud... El recurso que solicitaste no fue encontrado. Solicita otro recurso o inténtalo nuevamente más tarde.",
      }) => {
    const navigate = useNavigate();
    let error = useRouteError();
    // @ts-ignore
    return (
        <div className={"w-full h-full grid grid-cols-1 md:grid-cols-2"}>
            <div
                className={
                    "w-full flex items-center justify-center justify-items-center"
                }
            >
                <div className={"max-w-full sm:max-w-lg p-12 flex flex-col gap-2"}>
                    <p className={"font-extrabold text-lg text-neutral-800"}>
                        PENTRACKER
                    </p>
                    <p
                        className={
                            "text-3xl text-neutral-700 md:text-4xl lg:text-6xl font-bold"
                        }
                    >
                        {title}
                    </p>
                    <p className={"text-2xl text-neutral-600 lg:text-4xl font-semibold"}>
                        {subtitle}
                    </p>
                    <p
                        className={
                            "text-lg  text-neutral-500 md:text-xl lg:text-2xl text-justify font-normal"
                        }
                    >
                        {message}
                    </p>
                    {
                        error ? <Panel title={"Error"} bordered>
                            <code>
                                {(error as any).message}
                            </code>
                        </Panel> : null
                    }
                    <ButtonGroup className={"mt-4"}>
                        <IconButton
                            appearance={"primary"}
                            icon={<FaIcon icon={faBackward}/>}
                            onClick={() => navigate(-1)}
                        >
                            Volver
                        </IconButton>
                        <IconButton
                            appearance={"default"}
                            icon={<FaIcon icon={faHomeAlt}/>}
                            onClick={() => navigate("/dashboard")}
                        >
                            Ir a Inicio
                        </IconButton>
                    </ButtonGroup>
                </div>
            </div>
            <div
                className={
                    "flex flex-col w-full h-full overflow-y-hidden items-center justify-center"
                }
            >
                <Lottie
                    options={{
                        autoplay: true,
                        loop: true,
                        animationData: animation404,
                    }}
                />
            </div>
        </div>
    );
};
