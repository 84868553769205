import {FC} from "react";
import {AttendancePair} from "../../../../domain/attendance/Attendance";
import {Link} from "react-router-dom";
import {FaIcon} from "../../../components/fontawesome/icon";
import {faPerson} from "@fortawesome/free-solid-svg-icons";

type Props = {
    item: AttendancePair;
};

export const AttendanceListRowOperator: FC<Props> = ({item}) => {
    const {entry} = item!;
    return (
        <div className={"flex flex-row flex-wrap items-center"}>
            <FaIcon icon={faPerson} className={"mr-2"}/>
            <Link to={`/dashboard/users/${entry.ownerUid}/main`}>
                {entry.owner?.displayName ? entry.owner.displayName : entry.ownerUid}
            </Link>
        </div>
    );
};
