import { useState} from "react";
import {Response} from "../../domain/app/Response";
import {AppTimestamp} from "../../domain/app/Timestamp";
import { UserVisitControl } from "../../domain/control/Visit";
import { VisitControlRepository } from "../../data/repository/VisitControlRepository";
import { Unity } from "../../domain/unity";

export function useUnityVisitControlViewModel(unity: Unity) {
    const [fetchState, setFetchState] = useState<Response<boolean> | null>(null);
    const [visitControlList, setVisitControlList] = useState<UserVisitControl[] | undefined>();
    const [unityVisitControlTimestamp, setUnityVisitControlTimestamp] = useState<AppTimestamp | undefined>()
    const [refreshEvent, setRefreshEvent] = useState<boolean | null>(null);

    async function fetchUnityVisitControlList(
        timestamp: number,
        forceRefresh: boolean = false
    ) {
        if (fetchState?.isLoading()) return;
        setFetchState(Response.loading());
        try {
            const result = await VisitControlRepository.getList(
                timestamp,
                unity.id,
                forceRefresh
            );
            setVisitControlList(result?.visitControlList);
            setUnityVisitControlTimestamp(result?.timestamp)
            setFetchState(Response.success(true));
        } catch (e: any) {
            setFetchState(Response.failure(e));
        }
    }

    function onFetchStateReceived() {
        setFetchState(null);
    }

    function requestRefreshEvent() {
        setRefreshEvent(true);
    }

    function onRefreshEventCompleted() {
        setRefreshEvent(null);
    }

    return {
        fetchState,
        onFetchStateReceived,
        visitControlList,
        unityVisitControlTimestamp,
        fetchUnityVisitControlList,
        refreshEvent,
        requestRefreshEvent,
        onRefreshEventCompleted,
    };
}
