import {FC} from "react";
import {CellProps, Table} from "rsuite";
import {PentrackerUser} from "../../../../domain/user/User";
import {UserTable_CurrentUserCellStyle} from "./index";
import {formatDateTime} from "../../../../util/date";

type Props = CellProps<PentrackerUser> & {
    dataKey: keyof PentrackerUser;
    currentUid: string;
};
export const UserTableTimestampCell: FC<Props> = ({
                                                      rowData,
                                                      currentUid,
                                                      dataKey,
                                                      ...props
                                                  }) => {
    if (!rowData) return null;
    const timestamp = rowData[dataKey] as string;
    const uid = rowData.uid;
    let date = formatDateTime(new Date(timestamp));

    return (
        <Table.Cell
            {...props}
            style={{
                ...props.style,
                ...(currentUid === uid ? UserTable_CurrentUserCellStyle : {}),
            }}
        >
            <div className={`text-xs}`}>{date}</div>
        </Table.Cell>
    );
};
