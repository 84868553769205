import { FC, useEffect, useState } from "react";
import { ImageLoader, ImageLoaderProps } from "./index";
import { Response } from "../../../domain/app/Response";
import { StorageRepository } from "../../../data/repository/StorageRepository";
import { Loader } from "rsuite";

type Props = Omit<ImageLoaderProps, "src"> & {
  path?: string;
};

export const PathImageLoader: FC<Props> = ({ path, ...props }) => {
  const [fetchingState, setFetchingState] = useState<Response<boolean> | null>(
    null
  );
  const [src, setSrc] = useState<string | null | undefined>(null);
  /*eslint-disable*/
  useEffect(() => {
    // console.log("path", path);
    if (!path || fetchingState?.isLoading() || !!src) return;
    setFetchingState(Response.loading());
    StorageRepository.getDownloadUrl(path)
      .then((result) => {
        setSrc(result);
        setFetchingState(null);
      })
      .catch(() => {
        setSrc(null);
        setFetchingState(null);
      });
  }, [path]);
  /*eslint-enable*/
  return fetchingState?.isLoading() ? (
    <Loader />
  ) : (
    <ImageLoader {...props} src={src} />
  );
};
