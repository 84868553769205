import { db } from "../db";
import { ManagerEvent } from "../../../domain/event/ManagerEvent";

export class ManagerEventDao {
  static async getEventByReference(reference: string) {
    return db.manager_event.get(reference);
  }

  static async getAllManagerEvents(start: number, end: number) {
    return db.manager_event.where("date").between(start, end, true, true).reverse().sortBy("date");
  }

  static async putManagerEvent(...managerEvent: ManagerEvent[]) {
    return db.manager_event.bulkPut(managerEvent);
  }

  static async clear(start: number, end: number) {
    return db.manager_event.where("date").between([start], [end], true, true).delete();
  }
}
