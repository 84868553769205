import { FC, useCallback, useEffect, useState } from "react";
import { AppLoader } from "../../../components/loading/LoadingOverlay";
import { DatePicker, IconButton, Loader } from "rsuite";
import { formatDate } from "../../../../util/date";
import { FaIcon } from "../../../components/fontawesome/icon";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { toRelativeCalendar } from "../../../../lib/date";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../../../../hook/query";
import { DateTime } from "luxon";
import { toInteger } from "lodash";
import { useQueryNavigate } from "../../../../hook/navigate";
import { useGoodsControlViewModel } from "../../../../viewmodel/control/Goods";
import { UserGoodsControl } from "../../../../domain/control/Goods";
import { GoodsControlGroupByRequestData } from "./component/data";

export const ByRequestGoodsControlGroup: FC<any> = () => {
  const queryTimestamp = useQuery().get("timestamp");
  const initialSelectedTime = queryTimestamp
    ? DateTime.fromMillis(toInteger(queryTimestamp)).toJSDate()
    : new Date();
  const [selectedTime, setSelectedTime] = useState(initialSelectedTime);
  const queryNavigate = useQueryNavigate();
  const {
    fetchState,
    fetchGoodsControlList,
    onFetchStateReceived,
    goodsControlTimestamp,
    goodsControlList,
    refreshEvent,
    requestRefreshEvent,
    onRefreshEventCompleted
  } = useGoodsControlViewModel();
  const navigate = useNavigate();

  /* eslint-disable */
  useEffect(() => {
    void fetchGoodsControlList(selectedTime.getTime());
  }, [selectedTime]);

  useEffect(() => {
    if (fetchState && !fetchState.isLoading()) {
      if (fetchState.isFailed()) {
        window.toast.error(
          "Ocurrió un error al obtener la lista de control de bienes y equipos."
        );
        console.log(fetchState?.error);
      }
      onFetchStateReceived();
    }
  }, [fetchState]);

  useEffect(() => {
    if (refreshEvent) {
      void fetchGoodsControlList(selectedTime.getTime(), true);
      onRefreshEventCompleted();
    }
  }, [refreshEvent]);

  const onTableItemSelected = useCallback((item: UserGoodsControl) => {
    navigate(
      `/dashboard/goods-control?reference=${encodeURIComponent(item.reference)}`
    );
  }, []);

  /* eslint-enable */

  const loading = fetchState?.loading || undefined;

  return (
    <div
      className={"w-full h-full flex flex-col p-3 gap-0 overflow-hidden"}
    >
      <AppLoader isActive={loading}
      />
      <div
        className={
          "flex-none w-full flex flex-row flex-wrap gap-2 p-2 border-b-2 border-neutral-100 overflow-x-hidden items-center justify-between"
        }
      >
        <span className={"text-lg font-normal text-neutral-600 uppercase"}>
          {formatDate(selectedTime)}
        </span>
        <div className={"flex flex-row flex-wrap items-center gap-2"}>
          <div className={"flex flex-row flex-wrap gap-2 items-center z-0"}>
            <span>Seleccionar fecha</span>
            <DatePicker
              value={selectedTime}
              onChange={(newDate) => {
                if (
                  !!newDate &&
                  newDate?.getTime() !== selectedTime.getTime()
                ) {
                  setSelectedTime(newDate);
                  queryNavigate({
                    timestamp: newDate.getTime()
                  });
                }
              }}
            />
          </div>
          <IconButton
            onClick={requestRefreshEvent}
            icon={<FaIcon icon={faRefresh} />}
          >
            Actualizar
          </IconButton>
        </div>
      </div>
      {fetchState?.isLoading() ? (
        <Loader center backdrop size={"sm"} content={"Cargando..."} />
      ) : (
        <div
          className={
            "flex-1 w-full h-full flex justify-center sm:items-center overflow-hidden"
          }
        >
          <div className={"w-full h-full flex flex-col"}>
            {goodsControlTimestamp ? (
              <div className={"w-full flex-none p-2"}>
                <p>
                    <span className={"text-neutral-400 uppercase"}>
                      Última actualización:{" "}
                    </span>
                  {toRelativeCalendar(goodsControlTimestamp.timestamp, true)}
                </p>
              </div>
            ) : null}
            <div className={"w-full flex-1 overflow-x-hidden"}>
              <GoodsControlGroupByRequestData
                onClick={onTableItemSelected}
                items={goodsControlList}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
