import {FC, useCallback, useEffect, useState} from "react";
import {formatDate} from "../../../../util/date";
import {DatePicker, IconButton, Loader} from "rsuite";
import {FaIcon} from "../../../components/fontawesome/icon";
import {faRefresh} from "@fortawesome/free-solid-svg-icons";
import {toRelativeCalendar} from "../../../../lib/date";
import {Unity} from "../../../../domain/unity";
import {useUnityPatrolViewModel} from "../../../../viewmodel/unity/UnityPatrol";
import {UnityPatrolData} from "./components/data";
import {UserPatrol} from "../../../../domain/patrol/Patrol";
import {UnityPatrolModal} from "./components/modal";
import {useNavigate, useSearchParams} from "react-router-dom";
import {DateTime} from "luxon";
import {toNumber} from "lodash";
import {useQueryNavigate} from "../../../../hook/navigate";

type Props = {
    unity: Unity;
};

export const UnityPatrolTabContent: FC<Props> = ({unity}) => {
    const [searchParams] = useSearchParams();
    const initialTimestamp = searchParams.get("timestamp");
    const queryNavigate = useQueryNavigate();
    const navigate = useNavigate();
    const [selectedTime, setSelectedTime] = useState(
        !!initialTimestamp
            ? DateTime.fromMillis(toNumber(initialTimestamp)).toJSDate()
            : new Date()
    );
    const {
        fetchState,
        fetchUserPatrolList,
        onFetchStateReceived,
        userPatrolList,
        userPatrolTimestamp,
        refreshEvent,
        requestRefreshEvent,
        onRefreshEventCompleted,
        selectedItem,
        requestSelectItem,
        onSelectItemCompleted,
    } = useUnityPatrolViewModel(unity);

    /* eslint-disable */
    useEffect(() => {
        void fetchUserPatrolList(selectedTime.getTime());
    }, [selectedTime]);

    useEffect(() => {
        if (fetchState && !fetchState.isLoading()) {
            if (fetchState.isFailed()) {
                window.toast.error(
                    "Ocurrió un error al obtener la lista de rondas del usuario."
                );
                console.log(fetchState?.error);
            }
            onFetchStateReceived();
        }
    }, [fetchState]);

    useEffect(() => {
        if (refreshEvent) {
            void fetchUserPatrolList(selectedTime.getTime(), true);
            onRefreshEventCompleted();
        }
    }, [refreshEvent]);

    const onSelect = useCallback((item: UserPatrol) => {
        navigate(
            `/dashboard/patrol/?reference=${encodeURIComponent(item.reference)}`
        );
    }, []);

    const onPreviewClicked = useCallback((item: UserPatrol) => {
        requestSelectItem(item);
    }, []);
    /* eslint-enable */
    return (
        <div className={"relative w-full overflow-x-hidden"}>
            {selectedItem ? (
                <UnityPatrolModal
                    onClose={onSelectItemCompleted}
                    patrol={selectedItem}
                />
            ) : null}
            <div
                className={
                    "w-full flex flex-row flex-wrap gap-2 p-2 border-b-2 border-neutral-100 overflow-x-hidden items-center justify-between"
                }
            >
        <span className={"text-lg font-normal text-neutral-600 uppercase"}>
          {formatDate(selectedTime)}
        </span>
                <div className={"flex flex-row flex-wrap items-center gap-2"}>
                    <div className={"flex flex-row flex-wrap gap-2 items-center z-0"}>
                        <span>Seleccionar fecha</span>
                        <DatePicker
                            value={selectedTime}
                            onChange={(newDate) => {
                                if (
                                    !!newDate &&
                                    newDate?.getTime() !== selectedTime.getTime()
                                ) {
                                    setSelectedTime(newDate);
                                    queryNavigate({
                                        timestamp: newDate.getTime(),
                                    });
                                }
                            }}
                        />
                    </div>
                    <IconButton
                        onClick={requestRefreshEvent}
                        icon={<FaIcon icon={faRefresh}/>}
                    >
                        Actualizar
                    </IconButton>
                </div>
            </div>
            {fetchState?.isLoading() ? (
                <Loader center backdrop size={"sm"} content={"Cargando..."}/>
            ) : (
                <div className={"w-full"}>
                    {userPatrolTimestamp ? (
                        <div className={"w-full flex-none p-2"}>
                            <p>
                <span className={"text-neutral-400 uppercase"}>
                  Última actualización:{" "}
                </span>
                                {toRelativeCalendar(userPatrolTimestamp.timestamp, true)}
                            </p>
                        </div>
                    ) : null}
                    <UnityPatrolData
                        patrolList={userPatrolList}
                        onSelect={onSelect}
                        onPreviewClick={onPreviewClicked}
                    />
                </div>
            )}
        </div>
    );
};
