import {FC, useCallback, useEffect} from "react";
import {useUnityUserListViewModel} from "../../../../viewmodel/unity/UnityUserList";
import {useNavigate, useOutletContext} from "react-router-dom";
import {Unity} from "../../../../domain/unity";
import {UnityUserListData} from "./components/data";
import {PentrackerUser} from "../../../../domain/user/User";

export const UnityUserListTab: FC = () => {
    const unity = useOutletContext() as Unity
    const {
        fetchUnityUserList,
        fetchUnityUserListState,
        unityUserList,
        onFetchUnityUserListStateReceived,
    } = useUnityUserListViewModel(unity);
    const navigate = useNavigate();
    /*eslint-disable*/
    useEffect(() => {
        void fetchUnityUserList();
    }, []);

    useEffect(() => {
        if (fetchUnityUserListState && !fetchUnityUserListState.isLoading()) {
            onFetchUnityUserListStateReceived();
        }
    }, [fetchUnityUserListState]);

    const onSelected = useCallback((item: PentrackerUser) => {
        navigate(`/dashboard/users/${item.uid}/main`)
    }, [])

    /*eslint-enable*/
    return (
        <UnityUserListData onClick={onSelected} items={unityUserList} unity={unity}/>
    );
};
