import {FirestoreSimpleCrudSource} from "../source/FirestoreSimpleCrudSource";
import {QueryConstraint, Timestamp, where} from "firebase/firestore";
import {AppTimestamp} from "../../domain/app/Timestamp";
import {DateTime} from "luxon";
import {AppTimestampDao} from "../database/dao/AppTimestamp";
import {isEmpty} from "lodash";
import {orderBy as firestoreOrderBy} from "@firebase/firestore";
import UnityRepository from "./UnityRepository";
import ZoneRepository from "./ZoneRepository";
import {GetIncidentDto} from "../../network/incident/Incident";
import {Incident, incidentAsUserIncident, incidentDtoAsDomain, UserIncident,} from "../../domain/incident/Incident";
import {IncidentDao} from "../database/dao/Incident";
import UserRepository from "./UserRepository";
import {UserWrapper} from "../../domain/user/User";

export class IncidentRepository {
    private static incidentSource = new FirestoreSimpleCrudSource<GetIncidentDto>(
        "incident"
    );

    static async getList(
        timestamp: number,
        unityId: number,
        forceRefresh?: boolean
    ): Promise<
        { incidentList: UserIncident[]; timestamp?: AppTimestamp } | undefined
    > {
        const dateTime = DateTime.fromMillis(timestamp).setZone("America/Lima");
        const dayStartTimestamp = dateTime.startOf("day").toMillis();
        const dayEndTimestamp = dateTime.endOf("day").toMillis();
        const appTimestampKey = `incidentlist-${dayStartTimestamp}`;
        let appTimestamp = await AppTimestampDao.getTimestamp(appTimestampKey);
        let incidentList = await IncidentDao.getAllUnityIncident(
            unityId,
            dayStartTimestamp,
            dayEndTimestamp
        );
        if (isEmpty(incidentList) || forceRefresh) {
            await IncidentDao.deleteAllUnityIncident(
                unityId,
                dayStartTimestamp,
                dayEndTimestamp
            );
            await this.fetchRemoteIncidentList(dayStartTimestamp, dayEndTimestamp, [
                where("unityId", "==", unityId),
            ]);
            await AppTimestampDao.putTimestamp({
                key: appTimestampKey,
                timestamp: Date.now(),
            });
            incidentList = await IncidentDao.getAllUnityIncident(
                unityId,
                dayStartTimestamp,
                dayEndTimestamp
            );
            appTimestamp = await AppTimestampDao.getTimestamp(appTimestampKey);
        }
        const userIncidentArray = await this.incidentArrayToUserIncidentArray(
            incidentList
        );
        return {
            incidentList: userIncidentArray,
            timestamp: appTimestamp,
        };
    }

    static async getUserList(
        timestamp: number,
        uid: string,
        forceRefresh: boolean = false,
        appUser: UserWrapper
    ): Promise<
        { incidentList: UserIncident[]; timestamp?: AppTimestamp } | undefined
    > {
        if (appUser?.isClient()) {
            if (appUser!.user!.clientUnity) {
                return this.getUserListWithConstraints(timestamp, uid, forceRefresh, [
                    where("unityId", "in", appUser!.user.clientUnity),
                ]);
            }
        } else {
            return this.getUserListWithConstraints(timestamp, uid, forceRefresh);
        }
    }

    static async getGlobal(
        timestamp: number,
        forceRefresh: boolean = false,
        appUser: UserWrapper
    ): Promise<
        { incidentList: UserIncident[]; timestamp?: AppTimestamp } | undefined
    > {
        if (appUser.isClient()) {
            if (appUser.user.clientUnity) {
                return this.getGlobalWithConstraints(timestamp, forceRefresh, [
                    where("unityId", "in", appUser.user.clientUnity),
                ]);
            }
        } else {
            return this.getGlobalWithConstraints(timestamp, forceRefresh);
        }
    }

    static async getGlobalWithConstraints(
        timestamp: number,
        forceRefresh: boolean = false,
        constraints: QueryConstraint[] = []
    ): Promise<
        { incidentList: UserIncident[]; timestamp?: AppTimestamp } | undefined
    > {
        const dateTime = DateTime.fromMillis(timestamp).setZone("America/Lima");
        const dayStartTimestamp = dateTime.startOf("day").toMillis();
        const dayEndTimestamp = dateTime.endOf("day").toMillis();
        const appTimestampKey = `global-incidentlist-${dayStartTimestamp}`;
        let appTimestamp = await AppTimestampDao.getTimestamp(appTimestampKey);
        let incidentList = await IncidentDao.getAllIncident(
            dayStartTimestamp,
            dayEndTimestamp
        );
        if (isEmpty(incidentList) || forceRefresh) {
            await IncidentDao.deleteAllIncident(dayStartTimestamp, dayEndTimestamp);
            await this.fetchRemoteIncidentList(
                dayStartTimestamp,
                dayEndTimestamp,
                constraints
            );
            await AppTimestampDao.putTimestamp({
                key: appTimestampKey,
                timestamp: Date.now(),
            });
            incidentList = await IncidentDao.getAllIncident(
                dayStartTimestamp,
                dayEndTimestamp
            );
            appTimestamp = await AppTimestampDao.getTimestamp(appTimestampKey);
        }
        const userIncidentArray = await this.incidentArrayToUserIncidentArray(
            incidentList
        );
        return {
            incidentList: userIncidentArray,
            timestamp: appTimestamp,
        };
    }

    static async getIncident(
        reference: string
    ): Promise<UserIncident | undefined> {
        const local = await IncidentDao.getIncident(reference);
        if (!!local) {
            return await this.incidentToUserIncident(local);
        }
    }

    private static async getUserListWithConstraints(
        timestamp: number,
        uid: string,
        forceRefresh?: boolean,
        constraints: QueryConstraint[] = []
    ): Promise<
        { incidentList: UserIncident[]; timestamp?: AppTimestamp } | undefined
    > {
        const dateTime = DateTime.fromMillis(timestamp).setZone("America/Lima");
        const dayStartTimestamp = dateTime.startOf("day").toMillis();
        const dayEndTimestamp = dateTime.endOf("day").toMillis();
        const appTimestampKey = `user-incidentlist-${dayStartTimestamp}`;
        let appTimestamp = await AppTimestampDao.getTimestamp(appTimestampKey);
        let incidentList = await IncidentDao.getUserIncidentList(
            uid,
            dayStartTimestamp,
            dayEndTimestamp
        );
        if (isEmpty(incidentList) || forceRefresh) {
            await IncidentDao.deleteAllUserIncident(
                uid,
                dayStartTimestamp,
                dayEndTimestamp
            );
            await this.fetchRemoteIncidentList(dayStartTimestamp, dayEndTimestamp, [
                where("ownerUid", "==", uid),
                ...constraints,
            ]);
            await AppTimestampDao.putTimestamp({
                key: appTimestampKey,
                timestamp: Date.now(),
            });
            incidentList = await IncidentDao.getUserIncidentList(
                uid,
                dayStartTimestamp,
                dayEndTimestamp
            );
            appTimestamp = await AppTimestampDao.getTimestamp(appTimestampKey);
        }
        const userIncidentArray = await this.incidentArrayToUserIncidentArray(
            incidentList
        );
        return {
            incidentList: userIncidentArray,
            timestamp: appTimestamp,
        };
    }

    private static async incidentArrayToUserIncidentArray(
        incidentArray: Incident[]
    ): Promise<UserIncident[]> {
        return await Promise.all(
            incidentArray.map(async (it) => await this.incidentToUserIncident(it))
        );
    }

    private static async fetchRemoteIncidentList(
        dateStartMillis: number,
        dateEndMillis: number,
        constraints: QueryConstraint[] = []
    ): Promise<void> {
        const incidentList = await this.incidentSource.getList([
            where("timestamp", ">=", Timestamp.fromMillis(dateStartMillis)),
            where("timestamp", "<=", Timestamp.fromMillis(dateEndMillis)),
            firestoreOrderBy("timestamp", "desc"),
            ...constraints,
        ]);

        if (incidentList) {
            const entries = incidentList.map((it) =>
                incidentDtoAsDomain(it.data, it.reference)
            );
            await IncidentDao.putIncident(...entries);
        }
    }

    private static async incidentToUserIncident(
        incident: Incident
    ): Promise<UserIncident> {
        const unity = incident.unityId
            ? await UnityRepository.getUnity(incident.unityId)
            : undefined;
        const zone = incident.submoduleId
            ? await ZoneRepository.getZone(incident.unityId, incident.submoduleId)
            : undefined;
        const owner = await UserRepository.getUser(incident.ownerUid);
        return incidentAsUserIncident(incident, unity, zone, owner);
    }
}
