import {db} from "../db";
import {Zone} from "../../../domain/patrol/Zone";

export class ZoneDao {
    static async putZone(...zone: Zone[]) {
        await db.zone.bulkPut(zone);
    }

    static async getZone(unityId: number, id: number) {
        return db.zone
            .where("id")
          .equals(id)
            .first();
    }

    static async delete(unityId: number, id: number) {
        await  db.zone
            .where(["unityId", "id"])
            .between([unityId, id], [unityId, id], true, true).delete();
    }

    static async clear(unityId: number) {
        await db.zone.where("unityId").equals(unityId).delete()
    }

    static async update(id: number, newData: Partial<Zone>) {
        await db.zone.update(id, newData);
    }

    static async getList(unityId: number) {
        return db.zone.where("unityId").equals(unityId).toArray();
    }
}
