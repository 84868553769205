import { TableColumnRelation } from "../../ui/context/TableContext";
import { GoodsControl } from "../../domain/control/Goods";

export const GoodsControlTableSchema: TableColumnRelation<GoodsControl> = {
  large: {
    breakpoints: ["lg", "xl", "2xl"],
    columns: [
      {
        name: "unityId",
        label: "Unidad",
        weight: 0.2,
      },
      {
        name: "timestamp",
        label: "Añadido en",
        weight: 0.1,
      },
      {
        name: "provider",
        label: "Proveedor",
        weight: 0.4,
      },
      {
        name: "document",
        label: "Documento",
        weight: 0.2,
      },
      {
        name: "entry",
        label: "Ingreso",
        weight: 0.1,
      }
    ],
  },
}
