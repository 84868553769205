import { FC } from "react";
import { ButtonGroup, IconButton } from "rsuite";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FaIcon } from "../fontawesome/icon";

export type OnClick = () => void;

type Props = {
  selectedItems: Array<string>;
  buttons: Buttons[];
  entityName: string;
};

type Buttons = {
  icon: IconDefinition;
  onClick: OnClick;
  text: string;
};

export const TableMultiselectHeader: FC<Props> = ({
  selectedItems,
  entityName,
  buttons,
}) => {
  return selectedItems && selectedItems.length > 0 ? (
    <div
      className={
        "bg-app px-4 py-2 w-full rounded shadow flex flex-row justify-between flex-wrap items-center"
      }
    >
      <p className={"text-white text-md"}>
        {selectedItems.length} {entityName}
      </p>
      <ButtonGroup>
        {buttons.map((button, index) => (
          <IconButton
            key={`m_btn_${index}`}
            onClick={button.onClick}
            size={"sm"}
            appearance={"primary"}
            icon={<FaIcon icon={button.icon} />}
          >
            {button.text}
          </IconButton>
        ))}
      </ButtonGroup>
    </div>
  ) : null;
};
