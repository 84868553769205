import { UserDto } from "../../../network/user/User";
import { db } from "../db";

export class UserStoreDao {
  static async putUser(...userEntities: UserDto[]) {
    await db.users.bulkPut(userEntities);
  }

  static async deleteUser(uid: string) {
    await db.users.delete(uid);
  }

  static async getUser(uid: string) {
    return db.users.get(uid);
  }

  static async deleteAll() {
    await db.users.clear();
  }

  static async getList(orderBy?: string, order?: "asc" | "desc" | undefined) {
    let query = db.users.orderBy(orderBy ? orderBy : "");
    if (order && order === "desc") query = query.reverse();
    return query.toArray();
  }

  static async getClientsForUnity(unityId: number) {
    return db.users.where("clientUnity").anyOf(unityId).toArray();
  }

  static async getUserCountForUnity(unityId: number) {
    let query = db.users.where("unityId").equals(unityId);
    return query.count();
  }

  static async putSearchResults(...userEntities: UserDto[]) {
    await db.search_results.bulkPut(userEntities);
  }

  static async getSearchResults() {
    return db.search_results.toArray();
  }

  static async deleteSearchResults() {
    await db.search_results.clear();
  }

  static async getUnityUsers(unityId: number) {
    return db.users.where("unityId").equals(unityId).toArray();
  }
}
