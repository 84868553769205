import React, {
    createRef,
    FC,
    Fragment,
    MouseEventHandler,
    useCallback,
    useContext,
} from "react";
import {Pagination, Table} from "rsuite";
import {TableInstance} from "rsuite/esm/Table";
import {Unity} from "../../../../domain/unity";
import {useNavigate} from "react-router-dom";
import {TableColumnContext} from "../../../context/TableContext";
import {useTableSize} from "../../../../hook/width";
import {TableHeaderStyleSecondary} from "../../../components/table/style";
import {EmptyState} from "../../../components/state/empty";
import {UnityTableClientCell} from "./client";
import {useDataPager} from "../../../../hook/pager";
import {UnityTableColumns} from "../../../../schema/table/UnityTable";

const {Column, Cell, HeaderCell} = Table;

type UnityTableProps = {
    items?: Unity[];
    loading?: boolean;
    onSelect: (unity: Unity) => void;
    name: string;
};


export const UnityTable: FC<UnityTableProps> = ({
                                                    items,
                                                    loading,
                                                    onSelect,
                                                    name,
                                                }) => {
    const tableRef = createRef<TableInstance<any, any>>();
    const navigate = useNavigate();
    const tableContext = useContext(TableColumnContext);
    const currentPreferences =
        tableContext.getTablePreferences<UnityTableColumns>(name);
    const tableSize = useTableSize(tableRef);

    const {limits, changeLimit, limit, page, partialData, changePage} =
        useDataPager<Unity>({
            id: `unity_tab_${name}`,
            items: items,
        });

  function onSelected(unity: Unity) {
    onSelect(unity);
  }

  /* eslint-disable */
  const onClickNavigate = useCallback<MouseEventHandler<HTMLElement>>(
    (event) => {
      event.stopPropagation();
      const destination = event.currentTarget.dataset?.navigation;
      if (!!destination) navigate(destination);
    },
    [partialData]
  );
    /* eslint-enable */

    return (
        <Fragment>
            <Table
                data={partialData}
                rowKey={"id"}
                loading={loading}
                ref={tableRef}
                onRowClick={onSelected as any}
                autoHeight
                renderEmpty={() => (
                    <EmptyState
                        title={"Sin unidades"}
                        message={"Aún no se han registrado unidades."}
                    />
                )}
                className={"rounded md:rounded-xl shadow"}
                cellBordered
            >
                {currentPreferences.map((it) => {
                    switch (it.name) {
                        case "name":
                            return (
                                <Column
                                    key={`col-${it.name}`}
                                    resizable
                                    verticalAlign="top"
                                    width={tableSize.calculateWeight(it.weight)}
                                    onResize={(columnSize) => {
                                        if (columnSize)
                                            tableContext.updateTableColumnWeight(
                                                "unity_table",
                                                it.name,
                                                tableSize.sizeToWeight(columnSize)
                                            );
                                    }}
                                >
                                    <HeaderCell style={TableHeaderStyleSecondary}>
                                        Nombre
                                    </HeaderCell>
                                    <Cell dataKey="label"/>
                                </Column>
                            );
                        case "client":
                            return (
                                <Column
                                    key={`col-${it.name}`}
                                    resizable
                                    verticalAlign={"top"}
                                    width={tableSize.calculateWeight(it.weight)}
                                    onResize={(columnSize) => {
                                        if (columnSize)
                                            tableContext.updateTableColumnWeight(
                                                "unity_table",
                                                it.name,
                                                tableSize.sizeToWeight(columnSize)
                                            );
                                    }}
                                >
                                    <HeaderCell style={TableHeaderStyleSecondary}>
                                        Cliente(s)
                                    </HeaderCell>
                                    <UnityTableClientCell onNavigate={onClickNavigate}/>
                                </Column>
                            );
                        default:
                            return (
                                <Column
                                    key={`col-${it.name}`}
                                    width={tableSize.calculateWeight(it.weight)}
                                    resizable
                                    verticalAlign={"top"}
                                    onResize={(columnSize) => {
                                        if (columnSize)
                                            tableContext.updateTableColumnWeight(
                                                "unity_table",
                                                it.name,
                                                tableSize.sizeToWeight(columnSize)
                                            );
                                    }}
                                >
                                    <HeaderCell style={TableHeaderStyleSecondary}>
                                        # Operadores
                                    </HeaderCell>
                                    <Cell>
                                        {(data: any) => {
                                            const unity = data as Unity;
                                            return <span>{unity.operatorCount} ops.</span>;
                                        }}
                                    </Cell>
                                </Column>
                            );
                    }
                })}
            </Table>

            {items ? (
                <div className={"py-4 flex flex-col gap-4"}>
          <span className={"text-md"}>
            Mostrando {partialData?.length} elementos / Total {items.length}
          </span>
                    <Pagination
                        prev
                        next
                        first
                        last
                        ellipsis
                        boundaryLinks
                        maxButtons={5}
                        size="xs"
                        layout={["limit", "|", "pager", "skip"]}
                        total={items.length}
                        limitOptions={limits}
                        limit={limit}
                        activePage={page}
                        onChangePage={changePage}
                        onChangeLimit={changeLimit}
                    />
                </div>
            ) : null}
        </Fragment>
    );
};
