import React, {FC} from "react";
import {ListChildComponentProps} from "react-window";
import {ZoneRowName} from "./name";
import {ZoneRowId} from "./id";
import {Zone} from "../../../../../../domain/patrol/Zone";

export type ZoneRowProps = {
    zoneList: Zone[];
    onSelect: React.MouseEventHandler<HTMLDivElement>;
};

export const ZoneRow: FC<ListChildComponentProps<ZoneRowProps>> = ({
                                                                       data,
                                                                       index,
                                                                       style,
                                                                   }) => {
    const {zoneList, onSelect} = data;
    const item = zoneList[index];
    return (
        <div style={style} data-index={index} className={"py-2"} onClick={onSelect}>
            <div
                className={
                    "w-full h-full border border-neutral-300 rounded-lg p-2 flex flex-col justify-center content-between"
                }
            >
                <div className={"flex w-full flex-col gap-2"}>
                    <ZoneRowId item={item}/>
                    <ZoneRowName item={item}/>
                </div>
            </div>
        </div>
    );
};
