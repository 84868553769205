import { useState} from "react";
import {Response} from "../../domain/app/Response";
import {AppTimestamp} from "../../domain/app/Timestamp";
import { UserGoodsControl } from "../../domain/control/Goods";
import { GoodsControlRepository } from "../../data/repository/GoodsControlRepository";
import { Unity } from "../../domain/unity";

export function useUnityGoodsControlViewModel(unity: Unity) {
    const [fetchState, setFetchState] = useState<Response<boolean> | null>(null);
    const [goodsControlList, setGoodsControlList] = useState<UserGoodsControl[] | undefined>();
    const [unityGoodsControlTimestamp, setUnityGoodsControlTimestamp] = useState<AppTimestamp | undefined>()
    const [refreshEvent, setRefreshEvent] = useState<boolean | null>(null);

    async function fetchUnityGoodsControlList(
        timestamp: number,
        forceRefresh: boolean = false
    ) {
        if (fetchState?.isLoading()) return;
        setFetchState(Response.loading());
        try {
            const result = await GoodsControlRepository.getList(
                timestamp,
                unity.id,
                forceRefresh
            );
            setGoodsControlList(result?.goodsControlList);
            setUnityGoodsControlTimestamp(result?.timestamp)
            setFetchState(Response.success(true));
        } catch (e: any) {
            setFetchState(Response.failure(e));
        }
    }

    function onFetchStateReceived() {
        setFetchState(null);
    }

    function requestRefreshEvent() {
        setRefreshEvent(true);
    }

    function onRefreshEventCompleted() {
        setRefreshEvent(null);
    }

    return {
        fetchState,
        onFetchStateReceived,
        goodsControlList,
        unityGoodsControlTimestamp,
        fetchUnityGoodsControlList,
        refreshEvent,
        requestRefreshEvent,
        onRefreshEventCompleted,
    };
}
