import {UserDto} from "../network/user/User";
import {PentrackerUser, userWithClaimsAsPentrackerUser,} from "../domain/user/User";
import UnityRepository from "../data/repository/UnityRepository";
import RoleRepository from "../data/repository/RoleRepository";
import {AccountTypeRepository} from "../data/repository/AccountTypeRepository";
import ShiftRepository from "../data/repository/ShiftRepository";
import {isNil} from "lodash";
import {Unity} from "../domain/unity";
import {UserRoles} from "../domain/user/Role";

export async function mixWithPentrackerUserData(
    user: UserDto
): Promise<PentrackerUser> {
    const unity = !isNil(user.unityId)
        ? await UnityRepository.getUnity(user.unityId)
        : undefined;
    const shift = !isNil(user.shiftId)
        ? await ShiftRepository.getShift(user.shiftId)
        : undefined;
    const role = !isNil(user.roleId)
        ? await RoleRepository.getRole(user.roleId)
        : undefined;
    const accountType = !isNil(user.accountTypeId)
        ? await AccountTypeRepository.getAccountType(user.accountTypeId)
        : undefined;
    const clientUnityArray = !isNil(user.clientUnity)
        ? await Promise.resolve(
            Promise.all(
                user.clientUnity.map(async (unityId): Promise<Unity> => {
                    return (
                        (await UnityRepository.getUnity(unityId)) || {
                            label: "Desconocido",
                            id: unityId,
                            description: `Unidad desconocida ${unityId}`,
                            createdAt: Date.now()
                        }
                    );
                })
            )
        )
        : undefined;
    return userWithClaimsAsPentrackerUser(
        user,
        unity,
        shift,
        role,
        accountType,
        clientUnityArray
    );
}

export function isUserOperator(user?: PentrackerUser) {
    return !!user && !isNil(user.roleId) && [UserRoles.groupboss, UserRoles.CCTV, UserRoles.agent].includes(user.roleId!!);
}

export function isUserClient(user?: PentrackerUser) {
    return !!user && !isNil(user.roleId) && user.roleId!! === UserRoles.client;
}

export function isUserAdmin(user?: PentrackerUser) {
    return !!user && !isNil(user.roleId) && [UserRoles.mainadmin, UserRoles.admin].includes(user.roleId!!);
}

export function isUserControl(user?: PentrackerUser) {
    return !!user && !isNil(user.roleId) && user.roleId!! === UserRoles.control;
}

export function isUserSupervisor(user?: PentrackerUser) {
    return !!user && !isNil(user.roleId) && user.roleId!! === UserRoles.supervisor;
}
