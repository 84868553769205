import {FC, useCallback, useEffect, useState} from "react";
import {formatDate} from "../../../../util/date";
import {DatePicker, IconButton, Loader} from "rsuite";
import {FaIcon} from "../../../components/fontawesome/icon";
import {faRefresh} from "@fortawesome/free-solid-svg-icons";
import {toRelativeCalendar} from "../../../../lib/date";
import {PentrackerUser} from "../../../../domain/user/User";
import {useUserIncidentViewModel} from "../../../../viewmodel/user/UserIncident";
import {UserIncidentData} from "./components/data";
import {useNavigate, useSearchParams} from "react-router-dom";
import {UserIncident} from "../../../../domain/incident/Incident";
import {useQueryNavigate} from "../../../../hook/navigate";
import {DateTime} from "luxon";
import {toInteger} from "lodash";
import {UserIncidentPreviewModal} from "./components/preview";

type Props = {
    user: PentrackerUser;
};

export const UserIncidentTabContent: FC<Props> = ({user}) => {
    const navigate = useNavigate();
    const updateSearchParams = useQueryNavigate();
    const [params] = useSearchParams();
    const startingTimestamp = params.get("timestamp");
    const [selectedTime, setSelectedTime] = useState(
        startingTimestamp
            ? DateTime.fromMillis(toInteger(startingTimestamp)).toJSDate()
            : new Date()
    );
    const {
        fetchState,
        fetchUserIncidentList,
        onFetchStateReceived,
        userIncidentList,
        userIncidentTimestamp,
        refreshEvent,
        requestRefreshEvent,
        onRefreshEventCompleted,
        selectItemToPreview,
        onPreviewItemCompleted,
        selectedPreviewItem,
    } = useUserIncidentViewModel(user);

    /* eslint-disable */
    useEffect(() => {
        void fetchUserIncidentList(selectedTime.getTime());
    }, [selectedTime]);

    useEffect(() => {
        if (fetchState && !fetchState.isLoading()) {
            if (fetchState.isFailed()) {
                window.toast.error(
                    "Ocurrió un error al obtener la lista de incidencias del usuario."
                );
                console.log(fetchState?.error);
            }
            onFetchStateReceived();
        }
    }, [fetchState]);

    useEffect(() => {
        if (refreshEvent) {
            void fetchUserIncidentList(selectedTime.getTime(), true);
            onRefreshEventCompleted();
        }
    }, [refreshEvent]);

    const onTableItemSelected = useCallback((item: UserIncident) => {
        navigate(
            `/dashboard/incident?reference=${encodeURIComponent(item.reference)}`
        );
    }, []);

    const onTablePreviewClicked = useCallback((item: UserIncident) => {
        selectItemToPreview(item);
    }, []);
    /* eslint-enable */
    return (
        <div className={"relative w-full overflow-x-hidden overflow-y-auto"}>
            {selectedPreviewItem ? (
                <UserIncidentPreviewModal
                    incident={selectedPreviewItem}
                    onClose={onPreviewItemCompleted}
                />
            ) : null}
            <div
                className={
                    "w-full flex flex-row flex-wrap gap-2 p-2 border-b-2 border-neutral-100 overflow-x-hidden overflow-y-auto items-center justify-between"
                }
            >
        <span className={"text-lg font-normal text-neutral-600 uppercase"}>
          {formatDate(selectedTime)}
        </span>
                <div className={"flex flex-row flex-wrap items-center gap-2"}>
                    <div className={"flex flex-row flex-wrap gap-2 items-center z-0"}>
                        <span>Seleccionar fecha</span>
                        <DatePicker
                            appearance={"default"}
                            size={"sm"}
                            value={selectedTime}
                            onChange={(newDate) => {
                                if (
                                    !!newDate &&
                                    newDate?.getTime() !== selectedTime.getTime()
                                ) {
                                    setSelectedTime(newDate);
                                    updateSearchParams({
                                        timestamp: newDate.getTime(),
                                    });
                                }
                            }}
                        />
                    </div>
                    <IconButton
                        appearance={"ghost"}
                        size={"sm"}
                        onClick={requestRefreshEvent}
                        icon={<FaIcon icon={faRefresh}/>}
                    >
                        Actualizar
                    </IconButton>
                </div>
            </div>
            {fetchState?.isLoading() ? (
                <Loader center backdrop size={"sm"} content={"Cargando..."}/>
            ) : (
                <div className={"w-full"}>
                    <div className={"w-full"}>
                        {userIncidentTimestamp ? (
                            <div className={"w-full p-2"}>
                                <p>
                  <span className={"text-neutral-400 uppercase"}>
                    Última actualización:{" "}
                  </span>
                                    {toRelativeCalendar(userIncidentTimestamp.timestamp, true)}
                                </p>
                            </div>
                        ) : null}
                        <UserIncidentData
                            onPreviewClicked={onTablePreviewClicked}
                            onSelect={onTableItemSelected}
                            userIncidentList={userIncidentList}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};
