import { preferencesDb } from "../db";
import { AppNotification } from "../../../domain/app/AppNotification";

export class AppNotificationDao {
  static async putNotification(notification: AppNotification) {
    await preferencesDb.notifications.put(notification);
    return notification;
  }

  static async deleteNotification(id: number) {
    return preferencesDb.notifications.delete(id);
  }

  static async deleteAll() {
    await preferencesDb.notifications.clear();
  }
}
