import React, { FC } from "react";
import { ListChildComponentProps } from "react-window";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { AppTicketFileType, TicketFileType } from "../../../../../../domain/ticket/UserTicket";
import { FaIcon } from "../../../../../components/fontawesome/icon";
import { Link } from "react-router-dom";

export type TicketContentListRowProps = {
  items: AppTicketFileType[];
  onSelect: React.MouseEventHandler<HTMLDivElement>;
};

export const TicketContentListRow: FC<
  ListChildComponentProps<TicketContentListRowProps>
> = ({ data, index, style }) => {
  const { onSelect, items } = data;
  const item = items[index];
  return (
    <div style={style} className={"py-2 cursor-pointer"}>
      <div
        data-index={index}
        onClick={onSelect}
        className={
          "w-full h-full border border-neutral-300 rounded-lg p-2 flex flex-col justify-between content-between"
        }
      >
        <div
          className={"flex w-full flex-row gap-3 items-center content-center"}
        >
          <FaIcon icon={faFilePdf} size={"2x"} className={`text-neutral-600`} />
          <div className={"flex flex-col"}>
            <span className={`text-lg`}>{item.name}</span>
            {
              item.user ? <div>
                <Link to={`/dashboard/users/${item.user.uid}/main`}>{item.user.displayName || item.user.uid}</Link>
              </div> : <span className={"font-normal text-sm"}>No se encontró el usuario vinculado a este archivo.</span>
            }
          </div>
        </div>
      </div>
    </div>
  );
};
