import { FC, useCallback } from "react";
import { useParams } from "react-router-dom";
import { RoleBasedNavigator } from "../../../components/nav/navigator";
import {
  faBookBookmark,
  faCalendar,
  faMapLocationDot,
  faSync,
  faUser,
  faPeopleGroup,
  faCarAlt,
  faBoxesAlt,
} from "@fortawesome/free-solid-svg-icons";
import { UserRoles } from "../../../../domain/user/Role";
import { PentrackerUser, UserWrapper } from "../../../../domain/user/User";

export const UserPageNavigator: FC<{
  openingUser: PentrackerUser;
}> = ({ openingUser }) => {
  const { uid } = useParams();
  const allowIf = useCallback(
    (appUser?: UserWrapper) => {
      if (appUser?.user) {
        const wrapper = new UserWrapper(openingUser);
        return (
          (appUser.isManager() ||
            (appUser.isClient() &&
              !!appUser.user.clientUnity &&
              !!openingUser.unityId &&
              appUser.user.clientUnity.includes(openingUser.unityId))) &&
          wrapper.isOperator()
        );
      } else {
        return false;
      }
    },
    [openingUser]
  );

  return (
    <RoleBasedNavigator
      definition={[
        {
          id: `/dashboard/users/${uid}/main`,
          label: "Principal",
          icon: faUser,
          allowedRoles: UserRoles.all,
        },
        {
          id: `/dashboard/users/${uid}/attendance`,
          label: "Asistencia",
          icon: faCalendar,
          allowedRoles: UserRoles.manager,
          allowIf,
        },
        {
          id: `/dashboard/users/${uid}/incident`,
          label: "Incidencias",
          icon: faBookBookmark,
          allowedRoles: UserRoles.manager,
          allowIf,
        },
        {
          id: `/dashboard/users/${uid}/patrol`,
          label: "Rondas",
          icon: faSync,
          allowedRoles: UserRoles.manager,
          allowIf,
        },
        {
          id: `/dashboard/users/${uid}/location`,
          label: "Ubicación",
          icon: faMapLocationDot,
          allowedRoles: UserRoles.manager,
          allowIf,
        },
        {
          id: `/dashboard/users/${uid}/visit-control`,
          label: "Control de Visitas",
          icon: faPeopleGroup,
          allowedRoles: UserRoles.manager,
          allowIf,
        },
        {
          id: `/dashboard/users/${uid}/vehicle-control`,
          label: "Control Vehicular",
          icon: faCarAlt,
          allowedRoles: UserRoles.manager,
          allowIf,
        },
        {
          id: `/dashboard/users/${uid}/goods-control`,
          label: "Control de Bienes y Equipos",
          icon: faBoxesAlt,
          allowedRoles: UserRoles.manager,
          allowIf,
        },
      ]}
    />
  );
};
