import {generateRandomNumberId} from "../../../util/object";
import {LocalErrorLog} from "../../../domain/app/LocalErrorLog";
import {db} from "../db";


export class LocalErrorLogDao {
    static async putError(label: string, error: Error) {
        const {name, message, stack} = error
        const newId = generateRandomNumberId()
        const localErrorLog: LocalErrorLog = {
            id: newId,
            label,
            message,
            name,
            stack,
            timestamp: Date.now()
        }
        await db.local_error_log.put(localErrorLog)
    }

    static async getAll() {

    }
}
