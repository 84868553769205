import { FC, useContext, useEffect } from "react";
import { useNewsViewModel } from "../../../viewmodel/news/News";
import { NewsPageHeader } from "./header/Header";
import { Response } from "../../../domain/app/Response";
import { AppLoader } from "../../components/loading/LoadingOverlay";
import { AddNewsItemInputList, AddNewsItemModel } from "./modal/InputList";
import { FormModal } from "../../components/modal/FormModal";
import { DeleteConfirmationModal } from "../../components/modal/DeleteConfirmationModal";
import { EditableFormModal } from "../../components/modal/EditableFormModal";
import { NewsItemViewerModal } from "./modal/ViewerModal";
import { DashboardHeaderContext } from "../../../hook/header";
import { DatePicker } from "rsuite";
import { DateTime } from "luxon";
import { NewsContentList } from "./list";
import { useStyleContext } from "../../context/StyleContext";

export const NewsPage: FC = (_) => {
  const viewModel = useNewsViewModel();
  const dashboardHeaderContext = useContext(DashboardHeaderContext);
  const { stylePage } = useStyleContext();

  /* eslint-disable */
  useEffect(() => {
    void viewModel.fetchList();
    dashboardHeaderContext.setTheme({
      className: "bg-secondary-header",
      title: "Comunicados",
      dark: true,
      homeAsUpEnabled: true,
    });
  }, []);

  useEffect(() => {
    if (!!viewModel.fetchListState && viewModel.fetchListState.isIdle()) {
      if (viewModel.fetchListState?.isFailed()) {
        window.toast.error(
          "Ocurrió un error al obtener la lista de comunicados."
        );
        console.log(viewModel.fetchListState?.error);
      }
      viewModel.onListStateReceived();
    }
  }, [viewModel.fetchListState]);

  useEffect(() => {
    if (!!viewModel.refreshEvent) {
      void viewModel.fetchList(true);
      viewModel.onRefreshEventCompleted();
    }
  }, [viewModel.refreshEvent]);

  useEffect(() => {
    if (!!viewModel.addState && viewModel.addState.isIdle()) {
      if (viewModel.addState?.isFailed()) {
        window.toast.error("Ocurrió un error al publicar el comunicado. ");
        console.log(viewModel.addState?.error);
      } else {
        window.toast.success("Comunicado publicado.");
      }
      viewModel.onAddStateReceived();
      viewModel.onAddEventCompleted();
    }
  }, [viewModel.addState]);

  useEffect(() => {
    if (!!viewModel.deleteState && viewModel.deleteState.isIdle()) {
      if (viewModel.deleteState?.isFailed()) {
        window.toast.error("Ocurrió un error al eliminar el comunicado. ");
        console.log(viewModel.deleteState?.error);
      } else {
        window.toast.success("Comunicado eliminado.");
      }
      viewModel.onDeleteStateReceived();
      viewModel.onDeleteEventCompleted();
    }
  }, [viewModel.deleteState]);

  useEffect(() => {
    if (!!viewModel.editState && viewModel.editState.isIdle()) {
      if (viewModel.editState?.isFailed()) {
        window.toast.error("Ocurrió un error al editar el comunicado. ");
        console.log(viewModel.editState?.error);
      } else {
        window.toast.success("Comunicado editado.");
      }
      viewModel.onEditStateReceived();
      viewModel.onEditEventCompleted();
    }
  }, [viewModel.editState]);

  /* eslint-enable */
  return (
    <div className={"w-full h-full flex flex-col"}>
      <AppLoader
        isActive={
          viewModel.addState?.loading ||
          viewModel.editState?.loading ||
          viewModel.deleteState?.loading ||
          viewModel.fetchListState?.loading
        }
      />
      <NewsPageHeader
        onAdd={viewModel.requestAdd}
        onRefresh={viewModel.requestRefresh}
        addDisabled={Response.existsAndIsLoading(viewModel.addState)}
        refreshDisabled={Response.existsAndIsLoading(viewModel.fetchListState)}
        stylePage={stylePage}
      />
      <div className={"p-3 w-full"}>
        <div className="w-full items-center flex flex-row justify-start gap-4 my-5">
          <DatePicker
            format="MM-yyyy"
            placeholder={"Mes - Año"}
            value={viewModel.selectedDate}
            onChange={viewModel.updateSelectedDate}
          />
          <p className={"text-2xl font-semibold text-center w-fit text-center"}>
            {DateTime.fromJSDate(viewModel.selectedDate)
              .toLocaleString({
                month: "long",
                year: "numeric",
              })
              .toUpperCase()}
          </p>
        </div>
        {viewModel.addEvent ? (
          <FormModal
            inputList={AddNewsItemInputList}
            title={"Nueva noticia"}
            visible={true}
            onCancel={viewModel.onAddEventCompleted}
            onOk={viewModel.postNewsItem}
            disabled={viewModel.addState?.loading}
            model={AddNewsItemModel}
          />
        ) : null}
        {viewModel.deleteEvent ? (
          <DeleteConfirmationModal
            onClose={viewModel.onDeleteEventCompleted}
            onConfirmation={viewModel.deleteNewsItem}
            entityTitle={"Noticia"}
            visible={true}
          />
        ) : null}
        {viewModel.editEvent ? (
          <EditableFormModal
            title="Editar"
            inputList={AddNewsItemInputList}
            initialValues={viewModel.editEvent}
            disabled={viewModel.editState?.loading}
            visible={true}
            onCancel={viewModel.onEditEventCompleted}
            onOk={(newData, modified) => {
              if (modified) void viewModel.editNewsItem(newData);
            }}
          />
        ) : null}
        {viewModel.selectEvent ? (
          <NewsItemViewerModal
            item={viewModel.selectEvent}
            onClose={viewModel.onSelectEventCompleted}
          />
        ) : null}
        <div className={"flex-1"}>
          <NewsContentList
            items={viewModel.newsList}
            onClick={viewModel.requestSelectEvent}
            onDelete={viewModel.requestDelete}
            onEdit={viewModel.requestEdit}
          />
        </div>
      </div>
    </div>
  );
};
