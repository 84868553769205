import {FC, useContext} from "react";
import {BreakPointContext} from "../../../../context/BreakPointContext";
import { UserVehicleControl } from "../../../../../domain/control/Vehicle";
import { VehicleControlList } from "../../../data-display/vehicle-control-list";
import { VehicleControlTable } from "../../../data-display/vehicle-control-table";

type Props = {
    items?: UserVehicleControl[];
    onClick: (user: UserVehicleControl) => void;
};

export const VehicleControlGroupByRequestDataName = "VehicleControlGroupByRequestData"

export const VehicleControlGroupByRequestData: FC<Props> = ({
                                                        items,
                                                        onClick,
                                                    }) => {
    const screenSize = useContext(BreakPointContext);
    if (screenSize.isMobile) {
        return (
            <VehicleControlList
                onSelect={onClick}
                vehicleControlList={items}
                name={VehicleControlGroupByRequestDataName}
            />
        );
    } else {
        return (
            <VehicleControlTable
                vehicleControlList={items}
                onSelect={onClick}
                name={VehicleControlGroupByRequestDataName}
            />
        );
    }
};
