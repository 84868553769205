import {FC} from "react";
import {CellProps, Table} from "rsuite";
import {PentrackerUser} from "../../../../domain/user/User";
import {UserTable_CurrentUserCellStyle} from "./index";

type Props = CellProps<PentrackerUser> & {
    currentUid: string;
};
export const UserTableUidCell: FC<Props> = ({
                                                rowData,
                                                currentUid,
                                                ...props
                                            }) => {
    if (!rowData) return null;
    const {disabled, uid} = rowData;
    return (
        <Table.Cell
            {...props}
            style={{
                ...props.style,
                ...(currentUid === uid ? UserTable_CurrentUserCellStyle : {}),
            }}
        >
            <div
                className={
                    `text-xs w-full font-light ${disabled ? "line-through text-red-500" : ""}`
                }
            >
                {uid}
            </div>
        </Table.Cell>
    );
};
