import {GetVisitControlDto} from "../../network/control/Visit";
import {Unity} from "../unity";
import {PentrackerUser} from "../user/User";

export type VisitControl = Omit<GetVisitControlDto, "timestamp"> & {
    reference : string
    timestamp: number
}

export type UserVisitControl = VisitControl & {
    unity?: Unity;
    owner?: PentrackerUser
}

export function visitControlDtoAsDomain(visitDto: GetVisitControlDto, reference: string): VisitControl {
    return {
        ...visitDto,
        reference,
        timestamp: visitDto.timestamp.toDate().getTime()
    }
}

export function visitControlAsUserVisitControl(visit: VisitControl, unity ?: Unity, owner ?: PentrackerUser): UserVisitControl {
    return {
        ...visit,
        unity,
        owner
    }
}
