import {toNumber} from "lodash";

export class AccountType {
    reference: string
    id: number
    label: string

    constructor(reference: string, id: number = 0, label: string = "") {
        this.reference = reference
        this.id = id
        this.label = label
    }
}

export class FirestoreAccountType {
    id: number
    label: string

    constructor(id: number = 0, label: string = "") {
        this.id = toNumber(id)
        this.label = label
    }
}
