import {AttendancePair} from "../../../../../domain/attendance/Attendance";
import {FC, useContext} from "react";
import {BreakPointContext} from "../../../../context/BreakPointContext";
import {AttendanceList} from "../../../data-display/attendance-list";
import { AttendanceTable } from "../../../data-display/attendance-table";

type Props = {
    items?: AttendancePair[];
    onPreview: (item: AttendancePair) => void;
    onClick: (user: AttendancePair) => void;
};

export const AttendanceGroupLiveDataName = "AttendanceGroupLiveData"

export const AttendanceGroupLiveData: FC<Props> = ({
                                                       items,
                                                       onPreview,
                                                       onClick,
                                                   }) => {
    const screenSize = useContext(BreakPointContext);
    if (screenSize.isMobile) {
        return (
            <AttendanceList
                onSelect={onClick}
                attendanceList={items}
                onPreviewClicked={onPreview}
                name={AttendanceGroupLiveDataName}
            />
        );
    } else {
        return (
            <AttendanceTable
                userAttendanceList={items}
                onSelect={onClick}
                onPreviewClicked={onPreview}
                name={AttendanceGroupLiveDataName}
            />
        );
    }
};
