import React, {FC, useMemo} from "react";
import {ListChildComponentProps} from "react-window";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FaIcon} from "../../../components/fontawesome/icon";
import {UserRowRole} from "./role";
import {toRelativeCalendar} from "../../../../lib/date";
import {UserRowDisplayName} from "./name";
import {UserRowUid} from "./uid";
import {UserRowClientUnity} from "./clientunity";
import {UserRowUnity} from "./unity";
import {IconButton} from "rsuite";
import {UserListRowProps} from "./index";
import {UserWrapper} from "../../../../domain/user/User";

export const UserRow: FC<ListChildComponentProps<UserListRowProps>> = ({
                                                                           index,
                                                                           style,
                                                                           data,
                                                                       }) => {
    const {items, onClick, onDeleteClick, onEditClick, currentUser} = data;
    const item = items[index];
    const wrapper = useMemo(() => {
        return new UserWrapper(item);
    }, [item]);
    return (
        <div style={style} className={"pb-2"}>
            <div
                data-index={index}
                onClick={onClick}
                className={
                    `w-full ${currentUser.uid() === wrapper.uid() ? 'bg-green-100' : 'bg-white'} h-full shadow rounded-lg p-2 flex flex-col justify-between gap-2`
                }
            >
                <div className={"w-full flex flex-col gap-2 items-start"}>
                    <UserRowRole userWrapper={wrapper}/>
                    <UserRowDisplayName user={item}/>
                    <UserRowUid user={item}/>
                    {wrapper.isClient() ? (
                        <UserRowClientUnity user={item}/>
                    ) : (
                        <UserRowUnity user={item}/>
                    )}
                    {item.lastSignInTime ? (
                        <p className={"m-0 p-0"}>
              <span className={"mr-2 text-neutral-400 font-semibold"}>
                ULT. SESIÓN
              </span>
                            <span>{toRelativeCalendar(new Date(item.lastSignInTime))}</span>
                        </p>
                    ) : null}
                    <p className={"m-0 p-0"}>
            <span className={"mr-2 text-neutral-400 font-semibold"}>
              CREADO
            </span>
                        <span>{toRelativeCalendar(new Date(item.creationTime))}</span>
                    </p>
                </div>
                <div className={"w-full flex flex-wrap justify-evenly gap-x-2"}>
                    <IconButton
                        className={"flex-1"}
                        onClick={onEditClick}
                        appearance={"primary"}
                        icon={<FaIcon icon={faEdit}/>}
                    >
                        Editar
                    </IconButton>
                    <IconButton
                        className={"flex-1"}
                        onClick={onDeleteClick}
                        appearance={"primary"}
                        icon={<FaIcon icon={faTrash}/>}
                    >
                        Eliminar
                    </IconButton>
                </div>
            </div>
        </div>
    );
};
