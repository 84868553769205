import {db} from "../db";
import {PathUrlBinding} from "../../../domain/storage/PathUrlBinding";

export class PathUrlBindingDao {
    static async putBinding(binding: PathUrlBinding) {
        await db.path_url_bindings.put(binding);
        return binding;
    }

    static async getBinding(path: string): Promise<PathUrlBinding | undefined> {
        return db.path_url_bindings.get({path});
    }
}
