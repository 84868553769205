import {
  collection,
  doc,
  DocumentSnapshot,
  getDoc,
  getDocFromCache,
  onSnapshot,
  query,
  QueryConstraint,
  startAfter,
  Timestamp,
  Unsubscribe,
  where,
} from "firebase/firestore";
import FirestoreSource from "../source/FirestoreSource";
import { FirestoreSimpleCrudSource } from "../source/FirestoreSimpleCrudSource";
import { StyleDto } from "../../network/style/Style";
import { Style, styleDtoAsDomain } from "../../domain/style/Styles";
import StyleDao from "../database/dao/Style";
import { orderBy as firestoreOrderBy } from "@firebase/firestore";
import { isEmpty, toNumber } from "lodash";

export class StyleRepository {
  private static styleSource = new FirestoreSimpleCrudSource<StyleDto>(
    "stylesClient"
  );
  // Promise<Style | undefined>
  // unityIdParent: number
  static async getStyle(uid: string): Promise<Style | undefined> {
    await StyleRepository.fetchRemoteStylesList();
    const local = await StyleDao.getStyleByUID(uid);
    if (local) {
      console.log("local", local);
      return local;
    }
  }

  private static async fetchRemoteStylesList(
    constraints: QueryConstraint[] = []
  ): Promise<void> {
    const stylesList = await this.styleSource.getList([
      firestoreOrderBy("clientUID", "desc"),
      ...constraints,
    ]);
    if (stylesList) {
      const entries = stylesList.map((it) =>
        styleDtoAsDomain(it.data, it.reference)
      );
      await StyleDao.putStyles(...entries);
    }
  }

  static async getList(forced: boolean = false) {
    const localList = await StyleDao.getList();
    if (isEmpty(localList) || forced) {
      const firestoreList = await this.styleSource.getList(null);
      await StyleDao.clear();
      await StyleDao.putStyles(
        ...firestoreList.map((styleDto) =>
          styleDtoAsDomain(styleDto.data, styleDto.reference)
        )
      );
      return StyleDao.getList();
    } else {
      return localList;
    }
  }

  private static unsubscribeRef: Unsubscribe | null = null;
}
