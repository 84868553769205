import { FC, useCallback, useContext, useState } from "react";
import DashboardMenu, { DashboardMenuProps } from "./menu";
import { BreakPointContext } from "../../../context/BreakPointContext";

export type DashboardMenuPropsSite = {
  stylePage: any;
};

export const SideDashboardMenu: FC<Partial<DashboardMenuPropsSite>> = ({
  stylePage,
}) => {
  const breakpoint = useContext(BreakPointContext);
  const [expanded, setExpanded] = useState(true);
  const toggle = useCallback(() => {
    setExpanded((old) => !old);
  }, []);

  return breakpoint.isDesktop ? (
    <div className={"shrink-0 w-fit h-full overflow-hidden"}>
      <DashboardMenu
        expanded={expanded}
        onToggle={toggle}
        stylePage={stylePage}
      />
    </div>
  ) : null;
};
