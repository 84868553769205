import { db } from "../db";
import { Attendance } from "../../../domain/attendance/Attendance";

export class AttendanceStoreDao {
  static async putEntry(...entry: Attendance[]) {
    return db.entries.bulkPut(entry);
  }

  static async putDeparture(...departure: Attendance[]) {
    return db.departures.bulkPut(departure);
  }

  static async delete(unityId: number, start: number, end: number) {
    await db.entries
      .where(["unityId", "timestamp"])
      .between([unityId, start], [unityId, end], true, true)
      .delete();
    await db.departures
      .where(["unityId", "timestamp"])
      .between([unityId, start], [unityId, end], true, true)
      .delete();
  }

  static async getAllUnityEntries(unityId: number, start: number, end: number) {
    return db.entries
      .where(["unityId+timestamp"])
      .between([unityId, start], [unityId, end], true, true)
      .reverse()
      .sortBy("timestamp");
  }

  static async getAllEntries(start: number, end: number) {
    return db.entries
      .where("timestamp")
      .between(start, end, true, true)
      .reverse()
      .sortBy("timestamp");
  }

  static async getLastEntryAboveTimestamp(preferredTimestamp: number) {
    return db.entries
      .where("timestamp")
      .aboveOrEqual(preferredTimestamp)
      .last();
  }

  static async getLastDepartureAboveTimestamp(preferredTimestamp: number) {
    return db.departures
      .where("timestamp")
      .aboveOrEqual(preferredTimestamp)
      .last();
  }

  static async getAllUnityDepartures(
    unityId: number,
    start: number,
    end: number
  ) {
    return db.departures
      .where(["unityId", "timestamp"])
      .between([unityId, start], [unityId, end], true, true)
      .reverse()
      .sortBy("timestamp");
  }

  static async getAllDepartures(start: number, end: number) {
    return db.departures
      .where("timestamp")
      .between(start, end, true, true)
      .reverse()
      .sortBy("timestamp");
  }

  static async deleteEntries(start: number, end: number) {
    return db.entries
      .where(["timestamp"])
      .between([start], [end], true, true)
      .delete();
  }

  static async deleteUnityEntries(unityId: number, start: number, end: number) {
    return db.entries
      .where(["unityId+timestamp"])
      .between([unityId, start], [unityId, end], true, true)
      .delete();
  }

  static async deleteUnityDepartures(
    unityId: number,
    start: number,
    end: number
  ) {
    return db.departures
      .where(["unityId", "timestamp"])
      .between([unityId, start], [unityId, end], true, true)
      .delete();
  }

  static async deleteDepartures(start: number, end: number) {
    return db.departures
      .where(["timestamp"])
      .between([start], [end], true, true)
      .delete();
  }

  static async getEntry(ownerUid: string, start: number, end: number) {
    return db.entries
      .where(["ownerUid", "timestamp"])
      .between([ownerUid, start], [ownerUid, end])
      .first();
  }

  static async getEntryByReference(reference: string) {
    return db.entries.get(reference);
  }

  static async getDeparture(ownerUid: string, start: number, end: number) {
    return db.departures
      .where(["ownerUid", "timestamp"])
      .between([ownerUid, start], [ownerUid, end])
      .first();
  }

  static async getDepartureByReference(reference: string) {
    return db.departures.get(reference);
  }
}
