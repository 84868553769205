import { FC, useContext, useRef } from "react";
import { Shift } from "../../../../../../domain/user/Shift";
import { Table } from "rsuite";
import { TableInstance } from "rsuite/Table";
import { EmptyState } from "../../../../../components/state/empty";
import { TableHeaderStyleSecondary } from "../../../../../components/table/style";
import { TableColumnContext } from "../../../../../context/TableContext";
import { useTableSize } from "../../../../../../hook/width";
import { ShiftTableColumns } from "../../../../../../schema/table/ShiftTable";

type Props = {
  data: Shift[] | undefined;
  selectedItems: Array<string>;
  onSwitchItemSelection: (reference: string) => void;
  onSwitchSelectAll: () => void;
  onItemSelected: (item: Shift) => void;
};

export const ShiftTableName = "ShiftTable";

export const ShiftTable: FC<Props> = ({ data, onItemSelected }) => {
  const tableRef = useRef<TableInstance<any, any>>(null);
  const tableContext = useContext(TableColumnContext);
  const tablePreferences =
    tableContext.getTablePreferences<ShiftTableColumns>(ShiftTableName);
  const tableSize = useTableSize(tableRef);

  return (
    <Table
      className={"rounded-xl shadow overflow-hidden"}
      autoHeight
      ref={tableRef}
      data={data}
      onRowClick={onItemSelected as any}
      renderEmpty={() => (
        <EmptyState
          title={"No hay turnos"}
          message={"No hay turnos, registre uno nuevo."}
        />
      )}
    >
      {tablePreferences.map((column) => {
        switch (column.name) {
          case "label":
            return (
              <Table.Column
                width={tableSize.calculateWeight(column.weight)}
                resizable
                onResize={(nSize) => {
                  if (nSize !== undefined) {
                    tableContext.updateTableColumnWeight(
                      ShiftTableName,
                      column.name,
                      tableSize.sizeToWeight(nSize)
                    );
                  }
                }}
              >
                <Table.HeaderCell style={TableHeaderStyleSecondary}>
                  Etiqueta
                </Table.HeaderCell>
                <Table.Cell dataKey={"label"} />
              </Table.Column>
            );
          default:
            return (
              <Table.Column
                width={tableSize.calculateWeight(column.weight)}
                resizable
                onResize={(nSize) => {
                  if (nSize !== undefined) {
                    tableContext.updateTableColumnWeight(
                      ShiftTableName,
                      column.name,
                      tableSize.sizeToWeight(nSize)
                    );
                  }
                }}
              >
                <Table.HeaderCell style={TableHeaderStyleSecondary}>
                  Descripción
                </Table.HeaderCell>
                <Table.Cell dataKey={"schedule"} />
              </Table.Column>
            );
        }
      })}
    </Table>
  );
};
