import React, {FC} from "react";
import {FaIcon} from "../../../components/fontawesome/icon";
import {faPencil} from "@fortawesome/free-solid-svg-icons";
import {Unity} from "../../../../domain/unity";

type Props = {
    unity: Unity;
};

export const UnityRowName: FC<Props> = ({unity}) => {
    return (
        <p className={"m-0 p-0"}>
            <FaIcon icon={faPencil} className={"mr-2 text-neutral-400"}/>
            {unity.label ? (
                <span>{unity.label}</span>
            ) : (
                <span className={"italic"}>No establecido</span>
            )}
        </p>
    );
};
