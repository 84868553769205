import {FC} from "react";
import {CellProps, Table} from "rsuite";
import {UserPatrol} from "../../../../domain/patrol/Patrol";

export const PatrolGroupTableZoneCell: FC<CellProps<UserPatrol>> = ({
                                                                        rowData,
                                                                        ...props
                                                                    }) => {
    const {submoduleId, zone} = rowData!;
    return <Table.Cell {...props}>{zone ? zone.label : submoduleId}</Table.Cell>;
};
