import { FirestoreSimpleCrudSource } from "../source/FirestoreSimpleCrudSource";
import { isEmpty } from "lodash";
import { UserStoreDao } from "../database/dao/User";
import { Timestamp, where } from "firebase/firestore";
import { UserWrapper } from "../../domain/user/User";
import { DataWithReference } from "../source/FirestoreSource";
import { ManagerEventDao } from "../database/dao/ManagerEvent";
import {
  AppManagerEvent,
  ManagerEvent,
  managerEventDtoToManagerEvent,
  managerEventToAppManagerEvent
} from "../../domain/event/ManagerEvent";
import { DateTime } from "luxon";
import { ManagerEventDto } from "../../network/event/ManagerEvent";
import { AppTimestampDao } from "../database/dao/AppTimestamp";
import { AppTimestamp } from "../../domain/app/Timestamp";

export default class ManagerEventRepository {
  static firestoreRepository = new FirestoreSimpleCrudSource<ManagerEventDto>(
    "event_history"
  );

  static async getList(
    forced: boolean = false,
    timestamp: number,
    appUser: UserWrapper
  ): Promise<{
    data: AppManagerEvent[],
    timestamp: AppTimestamp
  } | undefined> {
    const datetime = DateTime.fromMillis(timestamp).setZone("America/Lima");
    const dateStart = datetime.startOf("day").toMillis();
    const dateEnd = datetime.endOf("day").toMillis();
    let localList = await ManagerEventDao.getAllManagerEvents(
      dateStart,
      dateEnd
    );
    console.log(localList)
    let fetchTimestamp = await AppTimestampDao.getTimestamp("manager_event_list")
    if (isEmpty(localList) || forced) {
      await ManagerEventDao.clear(dateStart, dateEnd);
      let remoteList: DataWithReference<ManagerEventDto>[] | undefined =
        await this.firestoreRepository.getList([
          where("allowed", "array-contains", appUser.user.roleId),
          where("date", ">=", Timestamp.fromMillis(dateStart)),
          where("date", "<=", Timestamp.fromMillis(dateEnd))
        ]);
      if (remoteList) {
        const list = remoteList.map((data) => managerEventDtoToManagerEvent(data.data, data.reference));
        await ManagerEventDao.putManagerEvent(...list);
        await AppTimestampDao.putTimestamp({
          key: "manager_event_list",
          timestamp: Date.now()
        })
        fetchTimestamp = await AppTimestampDao.getTimestamp("manager_event_list")
      }
      localList = await ManagerEventDao.getAllManagerEvents(dateStart, dateEnd);
    }
    const result: AppManagerEvent[] = [];
    await Promise.resolve(
      Promise.allSettled(
        localList.map(async (event) => {
          console.log(event)
          try {
            const temp = await this.injectEvent(event);
            if (!!temp) result.push(temp);
            console.log("done")
          } catch (e) {
            console.log(e)
          }
        })
      )
    );
    return {
      data: result,
      timestamp: fetchTimestamp!!
    }
  }

  private static async injectEvent(
    event: ManagerEvent
  ): Promise<AppManagerEvent> {
    const user = await UserStoreDao.getUser(event.uid);
    return managerEventToAppManagerEvent(event, user);
  }
}
