import React, { FC, useContext } from "react";
import { ContextHeader } from "../../../components/header/secondary";
import { useLoaderData } from "react-router-dom";
import { Unity } from "../../../../domain/unity";
import { faEdit, faRefresh, faTrash } from "@fortawesome/free-solid-svg-icons";
import { UserAuthContext } from "../../../context/UserContext";

type OnClick = () => void;

type Props = {
  onEditButtonClick: OnClick;
  onRefreshButtonClick: OnClick;
  onDeleteButtonClick: OnClick;
};

export const UnityPageHeader: FC<Props> = ({
                                             onEditButtonClick,
                                             onRefreshButtonClick,
                                             onDeleteButtonClick
                                           }) => {
  const unity = useLoaderData() as Unity;
  const { appUser } = useContext(UserAuthContext);
  return (
    <ContextHeader
      breadcrumbItems={[
        {
          label: "Unidades",
          path: "/dashboard/unity"
        },
        {
          label: unity!!.label,
          active: true
        }
      ]}
      title={"Unidad"}
      actionsSchema={[
        {
          id: "edit",
          label: "Editar",
          icon: faEdit,
          onClick: onEditButtonClick,
          hide: !appUser.isAdmin()
        },
        {
          id: "delete",
          label: "Eliminar",
          icon: faTrash,
          onClick: onDeleteButtonClick,
          hide: !appUser.isAdmin()
        },
        {
          id: "refresh",
          label: "Actualizar",
          icon: faRefresh,
          onClick: onRefreshButtonClick
        }
      ]}
    />
  );
};
