import {FC} from "react";
import {NewUserInputDataList, NewUserInputSchema} from "./NewUserInputList";
import {FormModal} from "../../../../components/modal/FormModal";
import {NewUser} from "../../../../../domain/user/User";

type Props = {
    onOk: (user: NewUser) => void
    onCancel: () => void
}

export const NewUserModal: FC<Props> = ({onOk, onCancel}) => {
    return <FormModal
        inputList={NewUserInputDataList}
        title={"Nuevo Usuario"}
        visible={true}
        onOk={onOk}
        onCancel={onCancel}
        model={NewUserInputSchema}
    />
}
