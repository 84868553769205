import {AvailableTailwindSize} from "../hook/tailwind";

type Relation<T> = [bp: Array<AvailableTailwindSize["sizes"]>, value: T];

type Config<T> = {
    bp: AvailableTailwindSize["sizes"];
    def?: T;
};

export function switchBreakpoint<T>(
    config: Config<T>,
    ...relation: Relation<T>[]
): T | undefined {
    for (let rel of relation) {
        if (rel[0].includes(config.bp)) {
            return rel[1];
        }
    }
    return config.def ? config.def : undefined;
}
