import { FC, useContext, useEffect } from "react";
import { useOverviewAttendanceGroupViewModel } from "../../../../viewmodel/attendance-group/OverviewAttendanceGroup";
import { faCalendarDay } from "@fortawesome/free-solid-svg-icons";
import { AttendanceOverviewPeriodicAttendance } from "./components/periodic";
import { AttendanceOverviewLastWeek } from "./components/week";
import { AttendanceOverviewLastEntries } from "./components/last";
import { AttendanceOverviewPeriodicCount } from "./components/count";
import { AttendanceOverviewByUnity } from "./components/unity";
import { DashboardHeaderContext } from "../../../../hook/header";

export type AttendanceGroupOverviewProps = {
  stylePage?: any;
};

export const AttendanceGroupOverview: FC = (_) => {
  const {
    selectedPeriodAttendance,
    lastWeekAttendance,
    fetchLastWeekAttendance,
    fetchPeriodAttendance,
    todayEntries,
    fetchLastEntries,
    fetchByUnityAttendance,
    byUnityAttendance,
    fetchAttendancePeriodicCount,
    attendancePeriodicCount,
  } = useOverviewAttendanceGroupViewModel();

  const dashboardHeaderContext = useContext(DashboardHeaderContext);

  /*eslint-disable */
  useEffect(() => {
    void fetchLastWeekAttendance();
    void fetchLastEntries();
    void fetchByUnityAttendance();
    void fetchAttendancePeriodicCount();
    dashboardHeaderContext.setTheme({
      className: "bg-secondary-header",
      title: "Asistencia",
      dark: true,
      subtitle: "Resúmen",
    });
  }, []);
  /*eslint-enable */
  return (
    <div
      className={
        "w-full grid grid-cols-1 md:grid-cols-4 lg:grid-cols-6 bg-neutral-100 gap-3"
      }
    >
      <div
        className={
          "w-full h-full grid grid-rows-1 md:grid-rows-2 col-span-1 md:col-span-3 lg:col-span-4 gap-3"
        }
      >
        <div className={"w-full h-full hidden md:inline-grid"}>
          <AttendanceOverviewPeriodicAttendance
            periodicAttendance={selectedPeriodAttendance}
            onDateChanged={fetchPeriodAttendance}
          />
        </div>

        <div className={"grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-3"}>
          <div
            className={
              "w-full h-full md:col-span-2 lg:col-span-3 hidden md:inline-grid"
            }
          >
            <AttendanceOverviewLastWeek
              lastWeekAttendance={lastWeekAttendance}
            />
          </div>
          <div
            className={"col-span-1 md:col-span-1 lg:col-span-2 w-full h-full"}
          >
            <AttendanceOverviewLastEntries entries={todayEntries} />
          </div>
        </div>
      </div>
      <div
        className={
          "w-full h-full col-span-1 md:col-span-1 lg:col-span-2 grid grid-rows-1 md:grid-rows-2 gap-2"
        }
      >
        <div
          className={
            "w-full h-fit bg-white rounded shadow-sm flex flex-col divide-y divide-neutral-200 items-center content-center justify-center"
          }
        >
          {attendancePeriodicCount?.map((count, i) => (
            <AttendanceOverviewPeriodicCount
              count={count}
              icon={faCalendarDay}
              key={`count-${i}`}
            />
          ))}
        </div>
        <div className={"w-full h-fit overflow-y-auto"}>
          <AttendanceOverviewByUnity unityAttendanceList={byUnityAttendance} />
        </div>
      </div>
    </div>
  );
};
