import {PentrackerUser} from "../../../../domain/user/User";
import {FC} from "react";
import {Button, CellProps, Table} from "rsuite";
import {isNil} from "lodash";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {FaIcon} from "../../../components/fontawesome/icon";
import {UserRoles} from "../../../../domain/user/Role";
import {UserTable_CurrentUserCellStyle} from "./index";

type Props = CellProps<PentrackerUser> & {
    currentUid: string;
};

export const UserTableUnityCell: FC<Props> = ({
                                                  rowData,
                                                  currentUid,
                                                  ...props
                                              }) => {
    if (!rowData) return null;
    const {unity, roleId, clientUnity, uid, clientUnityArray} = rowData;
    if (isNil(roleId)) {
        return (
            <Table.Cell
                {...props}
                style={{
                    ...props.style,
                    ...(currentUid === uid ? UserTable_CurrentUserCellStyle : {}),
                }}
            >
        <span className={"text-red-500"}>
          <FaIcon icon={faInfoCircle}/> Cargo no asignado
        </span>
            </Table.Cell>
        );
    } else {
        if (UserRoles.manager.includes(roleId)) {
            return (
                <Table.Cell
                    {...props}
                    style={{
                        ...props.style,
                        ...(currentUid === uid ? UserTable_CurrentUserCellStyle : {}),
                    }}
                >
          <span className={"text-amber-500"}>
            <FaIcon icon={faInfoCircle}/> Es administrador
          </span>
                </Table.Cell>
            );
        } else if (UserRoles.client === roleId) {
            if (!!clientUnityArray && clientUnityArray.length > 0) {
                return (
                    <Table.Cell
                        {...props}
                        style={{
                            ...props.style,
                            ...(currentUid === uid ? UserTable_CurrentUserCellStyle : {}),
                        }}
                    >
                        {clientUnityArray.map((cunity, i) => cunity.label).join(", ")}
                    </Table.Cell>
                );
            } else {
                return (
                    <Table.Cell
                        {...props}
                        style={{
                            ...props.style,
                            ...(currentUid === uid ? UserTable_CurrentUserCellStyle : {}),
                        }}
                    >
            <span className={"text-amber-500"}>
              <FaIcon icon={faInfoCircle}/> No se asignaron
            </span>
                    </Table.Cell>
                );
            }
        } else if (UserRoles.operator.includes(roleId)) {
            if (!!unity) {
                return (
                    <Table.Cell
                        {...props}
                        style={{
                            ...props.style,
                            ...(currentUid === uid ? UserTable_CurrentUserCellStyle : {}),
                        }}
                    >
                        <span>{unity.label}</span>
                    </Table.Cell>
                );
            } else {
                return (
                    <Table.Cell
                        {...props}
                        style={{
                            ...props.style,
                            ...(currentUid === uid ? UserTable_CurrentUserCellStyle : {}),
                        }}
                    >
                        <Button size={"xs"} color={"red"} appearance={"primary"}>
                            Asignar
                        </Button>
                    </Table.Cell>
                );
            }
        } else {
            return (
                <Table.Cell
                    {...props}
                    style={{
                        ...props.style,
                        ...(currentUid === uid ? UserTable_CurrentUserCellStyle : {}),
                    }}
                >
          <span className={"text-red-500"}>
            <FaIcon icon={faInfoCircle}/> Error
          </span>
                </Table.Cell>
            );
        }
    }
};
