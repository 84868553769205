import {useState} from "react";
import {Response} from "../../domain/app/Response";
import {AttendancePair} from "../../domain/attendance/Attendance";
import {AttendanceRepository} from "../../data/repository/AttendanceRepository";
import {Unity} from "../../domain/unity";
import {AppTimestamp} from "../../domain/app/Timestamp";

export function useUnityAttendanceViewModel(unity: Unity) {
    const [fetchState, setFetchState] = useState<Response<boolean> | null>(null);
    const [userAttendanceList, setUserAttendanceList] = useState<
        AttendancePair[] | undefined
    >();
    const [userAttendanceTimestamp, setUserAttendanceTimestamp] = useState<AppTimestamp | undefined>()
    const [refreshEvent, setRefreshEvent] = useState<boolean | null>(null);
    const [selectedItem, setSelectedItem] = useState<AttendancePair | null>(null)

    async function fetchAttendance(
        timestamp: number,
        forceRefresh: boolean = false
    ) {
        if (fetchState?.isLoading()) return;
        setFetchState(Response.loading());
        try {
            const result = await AttendanceRepository.getList(
                timestamp,
                unity.id,
                forceRefresh
            );
            setUserAttendanceList(result?.pairs);
            setUserAttendanceTimestamp(result?.timestamp)
            setFetchState(Response.success(true));
        } catch (e: any) {
            setFetchState(Response.failure(e));
        }
    }

    function onFetchStateReceived() {
        setFetchState(null);
    }

    function requestRefreshEvent() {
        setRefreshEvent(true);
    }

    function onRefreshEventCompleted() {
        setRefreshEvent(null);
    }

    function requestSelectItem(item: AttendancePair) {
        setSelectedItem(item)
    }

    function onSelectItemCompleted() {
        setSelectedItem(null)
    }

    return {
        fetchState,
        onFetchStateReceived,
        userAttendanceList,
        userAttendanceTimestamp,
        fetchAttendance,
        refreshEvent,
        requestRefreshEvent,
        onRefreshEventCompleted,
        selectedItem,
        requestSelectItem,
        onSelectItemCompleted
    };
}
