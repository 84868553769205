import {FC} from "react";
import {CellProps, Table} from "rsuite";
import {AttendancePair} from "../../../../domain/attendance/Attendance";
import {Link} from "react-router-dom";

export const AttendanceGroupTableOperatorCell: FC<
    CellProps<AttendancePair>
> = ({rowData, ...props}) => {
    const {entry} = rowData!;
    return (
        <Table.Cell {...props}>
            <Link to={`/dashboard/users/${entry.ownerUid}/main`}>
                {entry.owner
                    ? entry.owner.displayName
                    : entry.ownerUid}
            </Link>
        </Table.Cell>
    );
};
