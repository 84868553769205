import {FC} from "react";
import {CellProps, Table} from "rsuite";
import {Link} from "react-router-dom";
import {UserPatrol} from "../../../../domain/patrol/Patrol";

export const PatrolGroupTableOperatorCell: FC<CellProps<UserPatrol>> = ({
                                                                            rowData,
                                                                            ...props
                                                                        }) => {
    const {ownerUid, owner} = rowData!;
    return (
        <Table.Cell {...props}>
            <Link to={`/dashboard/users/${ownerUid}`}>
                {owner ? owner.displayName : ownerUid}
            </Link>
        </Table.Cell>
    );
};
