import { useState } from "react";
import { NewsItem } from "../../domain/news/NewsItem";
import { Response } from "../../domain/app/Response";
import { NewsRepository } from "../../data/repository/NewsRepository";
import { DateTime } from "luxon";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../data/database/db";

export function useNewsViewModel() {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const newsList = useLiveQuery(
    () =>
      db.news
        .where("timestamp")
        .between(
          DateTime.fromJSDate(selectedDate)
            .setZone("America/Lima")
            .startOf("month")
            .toMillis(),
          DateTime.fromJSDate(selectedDate)
            .setZone("America/Lima")
            .endOf("month")
            .toMillis(),
          true,
          true
        )
        .reverse()
        .sortBy("timestamp"),
    [selectedDate]
  );
  const [fetchListState, setFetchListState] =
    useState<Response<boolean> | null>(null);
  const [addEvent, setAddEvent] = useState<boolean | null>(null);
  const [addState, setAddState] = useState<Response<boolean> | null>(null);
  const [refreshEvent, setRefreshEvent] = useState<boolean | null>(null);
  const [deleteState, setDeleteState] = useState<Response<boolean> | null>(
    null
  );
  const [deleteEvent, setDeleteEvent] = useState<NewsItem | null>(null);
  const [editState, setEditState] = useState<Response<boolean> | null>(null);
  const [editEvent, setEditEvent] = useState<NewsItem | null>(null);
  const [selectEvent, setSelectEvent] = useState<NewsItem | null>(null);

  async function fetchList(forceRefresh: boolean = false) {
    if (Response.existsAndIsLoading(fetchListState)) return;
    setFetchListState(Response.loading());
    try {
      await NewsRepository.fetchNews(selectedDate.getTime(), forceRefresh);
      setFetchListState(Response.success(true));
    } catch (e: any) {
      setFetchListState(Response.failure(e));
    }
  }

  function updateSelectedDate(newDate: Date | null) {
    if (!newDate) return;
    const oldDateStart = DateTime.fromJSDate(selectedDate);
    const newDateStart = DateTime.fromJSDate(newDate);
    if (
      oldDateStart.month !== newDateStart.month ||
      oldDateStart.year !== newDateStart.year
    ) {
      setSelectedDate(newDate);
    }
  }

  function onListStateReceived() {
    setFetchListState(null);
  }

  function requestAdd() {
    setAddEvent(true);
  }

  function onAddEventCompleted() {
    setAddEvent(null);
  }

  async function postNewsItem(item: Partial<NewsItem>) {
    setAddState(Response.loading());
    try {
      await NewsRepository.postNewsItem(item);
      setAddState(Response.success(true));
    } catch (e: any) {
      setAddState(Response.failure(e));
    }
  }

  function onAddStateReceived() {
    setAddState(null);
  }

  function requestDelete(item?: NewsItem) {
    setDeleteEvent(item ? item : null);
  }

  function onDeleteEventCompleted() {
    setDeleteEvent(null);
  }

  async function deleteNewsItem() {
    setDeleteState(Response.loading());
    if (!!deleteEvent) {
      try {
        await NewsRepository.deleteNewsItem(deleteEvent);
        setDeleteState(Response.success(true));
      } catch (e: any) {
        setDeleteState(Response.failure(e));
      }
    } else {
      setDeleteState(
        Response.failure(
          new Error("No se ha seleccionado un elemento para eliminar.")
        )
      );
    }
  }

  function onDeleteStateReceived() {
    setDeleteState(null);
  }

  function requestEdit(item?: NewsItem) {
    setEditEvent(item ? item : null);
  }

  function onEditEventCompleted() {
    setEditEvent(null);
  }

  async function editNewsItem(newData: Partial<NewsItem>) {
    setEditState(Response.loading());
    if (!!editEvent) {
      try {
        await NewsRepository.updateNewsItem(editEvent, newData);
        setEditState(Response.success(true));
      } catch (e: any) {
        setEditState(Response.failure(e));
      }
    } else {
      setEditState(
        Response.failure(
          new Error("No se ha seleccionado un elemento para editar.")
        )
      );
    }
  }

  function onEditStateReceived() {
    setEditState(null);
  }

  function requestRefresh() {
    setRefreshEvent(true);
  }

  function onRefreshEventCompleted() {
    setRefreshEvent(null);
  }

  function requestSelectEvent(item: NewsItem) {
    setSelectEvent(item);
  }

  function onSelectEventCompleted() {
    setSelectEvent(null);
  }

  return {
    selectedDate,
    updateSelectedDate,
    newsList,
    fetchListState,
    onListStateReceived,
    fetchList,
    addEvent,
    addState,
    requestAdd,
    onAddEventCompleted,
    postNewsItem,
    onAddStateReceived,
    deleteEvent,
    deleteState,
    deleteNewsItem,
    onDeleteEventCompleted,
    onDeleteStateReceived,
    requestDelete,
    requestEdit,
    editEvent,
    editState,
    editNewsItem,
    onEditEventCompleted,
    onEditStateReceived,
    refreshEvent,
    requestRefresh,
    onRefreshEventCompleted,
    requestSelectEvent,
    onSelectEventCompleted,
    selectEvent,
  };
}
