import {FC, useEffect, useRef, useState} from "react";
import {CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis} from "recharts";
import {PeriodicPatrolWrapper} from "../../../../../viewmodel/patrol-group/OverviewPatrolGroup";

type Props = {
    lastWeekPatrol?: PeriodicPatrolWrapper
}

export const PatrolOverviewLastWeek: FC<Props> = ({lastWeekPatrol}) => {
    const secondaryGraphicParentRef = useRef<HTMLDivElement>(null);

    const [secondaryGraphicSize, setSecondaryGraphicSize] = useState<{
        width: number;
        height: number;
    }>({
        width: 300,
        height: 150,
    });

    /* eslint-disable */
    useEffect(() => {
        const div = secondaryGraphicParentRef.current;
        if (div) {
            setSecondaryGraphicSize({
                width: div.clientWidth - 40,
                height: div.clientHeight,
            });
        }
    }, [secondaryGraphicParentRef.current]);
    /*eslint-enable */
    return <div
        className={
            "w-full h-full bg-white rounded shadow-sm flex flex-col py-4"
        }
    >
        <div
            className={
                "w-full flex flex-row flex-wrap px-4 md:px-12 items-center justify-center md:justify-start"
            }
        >
            <p className={"m-0 p-0 text-lg font-semibold text-neutral-800"}>
                Esta Semana
            </p>
        </div>
        <div
            className={"w-full h-fit flex-1"}
            ref={secondaryGraphicParentRef}
        >
            <LineChart
                width={secondaryGraphicSize.width}
                height={secondaryGraphicSize.height}
                data={lastWeekPatrol?.data}
            >
                <Line type="monotone" dataKey="total" stroke="#C4191C"/>
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5"/>
                <XAxis dataKey="formattedDate"/>
                <YAxis/>
                <Tooltip/>
            </LineChart>
        </div>
    </div>
}
