import { FirestoreSimpleCrudSource } from "../source/FirestoreSimpleCrudSource";
import {
  GetRoleDto,
  PostRoleDto,
  PutRoleDto,
  roleAsPutDto,
} from "../../network/user/Role";
import { NewRole, Role, roleDtoAsDomain } from "../../domain/user/Role";
import { where } from "firebase/firestore";
import { RoleDao } from "../database/dao/Role";
import { isEmpty, toNumber } from "lodash";

export default class RoleRepository {
  static firestoreRepository = new FirestoreSimpleCrudSource<
    GetRoleDto,
    PostRoleDto,
    PutRoleDto
  >("role");

  static async create(object: NewRole) {
    if (!object.id) object.id = Date.now();
    else object.id = toNumber(object.id);
    const reference = await this.firestoreRepository.create(object, object.id);
    await RoleDao.putRole({
      ...object,
      reference: reference.path,
    });
  }

  static async delete(reference: string): Promise<void> {
    await this.firestoreRepository.delete(reference);
    await RoleDao.delete(reference);
  }

  static async getList(forced: boolean = false) {
    const localList = await RoleDao.getList();
    if (isEmpty(localList) || forced) {
      const firestoreList = await this.firestoreRepository.getList(null);
      await RoleDao.clear();
      await RoleDao.putRole(
        ...firestoreList.map((roleDto) =>
          roleDtoAsDomain(roleDto.data, roleDto.reference)
        )
      );
      return RoleDao.getList();
    } else {
      return localList;
    }
  }

  static async fetchList(forced: boolean = false) {
    const localList = await RoleDao.getList();
    if (isEmpty(localList) || forced) {
      const firestoreList = await this.firestoreRepository.getList(null);
      console.log("firestoreList", firestoreList);
      await RoleDao.clear();
      await RoleDao.putRole(
        ...firestoreList.map((roleDto) =>
          roleDtoAsDomain(roleDto.data, roleDto.reference)
        )
      );
    }
  }

  static async update(
    reference: string,
    newData: Partial<Role>
  ): Promise<void> {
    await this.firestoreRepository.update(reference, roleAsPutDto(newData));
    await RoleDao.update(reference, newData);
  }

  static async getRole(id: number, forcedRefresh: boolean = false) {
    const localRole = await RoleDao.getRoleById(id);
    if (!localRole || forcedRefresh) {
      const firestoreRole = await this.firestoreRepository.getByQuery([
        where("id", "==", id),
      ]);
      if (firestoreRole) {
        await RoleDao.putRole(
          roleDtoAsDomain(firestoreRole.data, firestoreRole.reference)
        );
      }
      return RoleDao.getRoleById(id);
    } else {
      return localRole;
    }
  }

  static async fetchRole(id: number, forcedRefresh: boolean = false) {
    const localRole = await RoleDao.getRoleById(id);

    if (!localRole || forcedRefresh) {
      const firestoreRole = await this.firestoreRepository.getByQuery([
        where("id", "==", id),
      ]);
      if (firestoreRole) {
        await RoleDao.putRole(
          roleDtoAsDomain(firestoreRole.data, firestoreRole.reference)
        );
      }
    }
  }
}
