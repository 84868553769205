import { FC } from "react";
import { Loader } from "rsuite";
import { AppTimestamp } from "../../../../domain/app/Timestamp";
import { DashboardDataTimestamp } from "./data-timestamp";
import { isNil } from "lodash";

type Props = {
  loading?: boolean;
  count?: number;
  title: string;
  suffix: string;
  onRefreshButtonClicked: () => void;
  timestamp?: AppTimestamp;
};

export const DashboardItemCount: FC<Props> = ({
  loading,
  count,
  title,
  suffix,
  onRefreshButtonClicked,
  timestamp,
}) => {
  if (loading === true) {
    return (
      <div className={"w-full h-full flex justify-center items-center"}>
        <Loader size={"md"} />
      </div>
    );
  }

  return (
    <div
      className={
        "w-full h-full flex flex-col justify-between items-center relative p-4"
      }
    >
      <div className={"flex flex-col w-full"}>
        <span className={"text-lg font-semibold text-multi"}>{title}</span>
        <span className={"text-sm font-normal text-multi"}>
          {isNil(count) ? 0 : count} {suffix}
        </span>
      </div>
      <DashboardDataTimestamp
        position={"bl"}
        timestamp={timestamp}
        loading={loading}
        onRefreshButtonClicked={onRefreshButtonClicked}
      />
    </div>
  );
};
