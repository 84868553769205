import {FC, useCallback, useState} from "react";
import {Form, InputGroup, Modal, Schema} from "rsuite";
import {PentrackerUser} from "../../../../../domain/user/User";
import {Search} from "@rsuite/icons";
import {useNavigate} from "react-router-dom";
import {UserSearchListWindow} from "../components/search-list";
import {isNotBlank} from "../../../../../util/validation";

type Props = {
    clear?: boolean;
    onSearch?: (keyword: string) => void;
    loading?: boolean;
    results: PentrackerUser[] | null;
    onClose?: () => void;
};

export const SearchUserModal: FC<Props> = ({onSearch, onClose, results}) => {
    const [keyword, setKeyword] = useState("");
    const navigate = useNavigate();
    /* eslint-disable */
    const onClick = useCallback((user: PentrackerUser) => {
        navigate(`/dashboard/users/${user.uid}/main`);
    }, []);
    /* eslint-enable */

    return (
        <Modal open={true} onClose={onClose}>
            <Modal.Header>
                <Modal.Title>Buscar usuario</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="flex flex-col gap-2 w-full h-fit">
                    <Form
                        onSubmit={(proceed, e) => {
                            e.preventDefault();
                            if (proceed && onSearch) onSearch(keyword);
                        }}
                        fluid
                        model={Schema.Model({
                            search: Schema.Types.StringType()
                                .isRequired("El término a buscar no puede estar vacío.")
                                .minLength(2, "Ingrese un término de al menos dos caracteres.")
                                .addRule(
                                    isNotBlank(2),
                                    "El término a buscar no puede estar vacío."
                                ),
                        })}
                    >
                        <Form.Group>
                            <InputGroup inside>
                                <Form.Control
                                    name={"search"}
                                    onChange={(value: string) => setKeyword(value)}
                                    value={keyword}
                                    placeholder="Ingrese nombre o ID de usuario"
                                />
                                <InputGroup.Button type="submit">
                                    <Search/>
                                </InputGroup.Button>
                            </InputGroup>
                        </Form.Group>
                    </Form>
                    <div className="w-full h-96">
                        <UserSearchListWindow onClick={onClick} items={results}/>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};
