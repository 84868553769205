import {Layout} from "react-grid-layout";

export const DashboardUserDataItem_Count = "UserDataItem_Count";
export const DashboardUserDataItem_Pie = "UserDataItem_Pie";
export const DashboardUserDataItem_Latest = "UserDataItem_Latest";
export const DashboardUnityDataItem_Count = "UnityDataItem_Count";
export const DashboardUnityDataItem_Pie = "UnityDataItem_Pie";
export const DashboardAttendanceDataItem_Today = "AttendanceDataItem_Today";
export const DashboardAttendanceDataItem_Yesterday =
    "AttendanceDataItem_Yesterday";
export const DashboardAttendanceDataItem_Latest = "AttendanceDataItem_Latest";
export const DashboardPatrolDataItem_Today = "PatrolDataItem_Today";
export const DashboardPatrolDataItem_Yesterday = "PatrolDataItem_Yesterday";
export const DashboardPatrolDataItem_Latest = "PatrolDataItem_Latest";
export const DashboardIncidentDataItem_Today = "IncidentDataItem_Today";
export const DashboardIncidentDataItem_Yesterday = "IncidentDataItem_Yesterday";
export const DashboardIncidentDataItem_Latest = "IncidentDataItem_Latest";
export const DashboardErrorLogsItem = "ErrorLogsItem";
export const DashboardEventListItem = "EventListItem";

export const DashboardMainLayoutSmall_1: Layout[] = [
    {
        i: DashboardUserDataItem_Count,
        x: 0,
        y: 0,
        w: 1,
        h: 3,
        minH: 3,

        isDraggable: false,
    },
    {
        i: DashboardUserDataItem_Pie,
        x: 0,
        y: 1,
        w: 1,
        h: 8,
        minH: 8,

        isDraggable: false,
    },
    {
        i: DashboardUserDataItem_Latest,
        x: 0,
        y: 2,
        w: 1,
        h: 8,
        minH: 8,

        isDraggable: false,
    },
    {
        i: DashboardUnityDataItem_Count,
        x: 0,
        y: 3,
        w: 1,
        h: 3,
        minH: 3,

        isDraggable: false,
    },
    {
        i: DashboardUnityDataItem_Pie,
        x: 0,
        y: 4,
        w: 1,
        h: 8,
        minH: 8,

        isDraggable: false,
    },
    {
        i: DashboardAttendanceDataItem_Today,
        x: 0,
        y: 5,
        w: 1,
        h: 3,
        minH: 3,

        isDraggable: false,
    },
    {
        i: DashboardAttendanceDataItem_Yesterday,
        x: 0,
        y: 6,
        w: 1,
        h: 3,
        minH: 3,

        isDraggable: false,
    },
    {
        i: DashboardAttendanceDataItem_Latest,
        x: 0,
        y: 7,
        w: 1,
        h: 8,
        minH: 8,

        isDraggable: false,
    },
    {
        i: DashboardPatrolDataItem_Today,
        x: 0,
        y: 8,
        w: 1,
        h: 3,
        minH: 3,

        isDraggable: false,
    },
    {
        i: DashboardPatrolDataItem_Yesterday,
        x: 0,
        y: 9,
        w: 1,
        h: 3,
        minH: 3,

        isDraggable: false,
    },
    {
        i: DashboardPatrolDataItem_Latest,
        x: 0,
        y: 10,
        w: 1,
        h: 8,
        minH: 8,

        isDraggable: false,
    },
    {
        i: DashboardIncidentDataItem_Today,
        x: 0,
        y: 11,
        w: 1,
        h: 3,
        minH: 3,

        isDraggable: false,
    },
    {
        i: DashboardIncidentDataItem_Yesterday,
        x: 0,
        y: 12,
        w: 1,
        h: 3,
        minH: 3,

        isDraggable: false,
    },
    {
        i: DashboardIncidentDataItem_Latest,
        x: 0,
        y: 12,
        w: 1,
        h: 8,
        minH: 8,

        isDraggable: false,
    },
    {
        i: DashboardErrorLogsItem,
        x: 0,
        y: 14,
        w: 1,
        h: 8,
        minH: 8,

        isDraggable: false,
    },
    {
        i: DashboardEventListItem,
        x: 0,
        y: 15,
        w: 1,
        h: 8,
        minH: 8,

        isDraggable: false,
    },
];

export const DashboardMainLayoutMedium_2: Layout[] = [
    {
        w: 1,
        h: 3,
        x: 0,
        y: 0,
        i: DashboardUserDataItem_Count,
        static: false,
        minH: 3,
    },
    {w: 1, h: 8, x: 1, y: 0, i: DashboardUserDataItem_Pie, static: false},
    {w: 1, h: 8, x: 0, y: 2, i: DashboardUserDataItem_Latest, static: false},
    {
        w: 1,
        h: 3,
        x: 1,
        y: 4,
        i: DashboardUnityDataItem_Count,
        static: false,
        minH: 3,
    },
    {w: 1, h: 5, x: 1, y: 5, i: DashboardUnityDataItem_Pie, static: false},
    {
        w: 1,
        h: 5,
        x: 0,
        y: 4,
        i: DashboardAttendanceDataItem_Today,
        static: false,
    },
    {
        w: 1,
        h: 6,
        x: 0,
        y: 8,
        i: DashboardAttendanceDataItem_Yesterday,
        static: false,
    },
    {
        w: 1,
        h: 4,
        x: 1,
        y: 10,
        i: DashboardAttendanceDataItem_Latest,
        static: false,
    },
    {w: 1, h: 6, x: 0, y: 15, i: DashboardPatrolDataItem_Today, static: false},
    {
        w: 1,
        h: 6,
        x: 1,
        y: 14,
        i: DashboardPatrolDataItem_Yesterday,
        static: false,
    },
    {w: 1, h: 4, x: 0, y: 17, i: DashboardPatrolDataItem_Latest, static: false},
    {
        w: 1,
        h: 6,
        x: 1,
        y: 20,
        i: DashboardIncidentDataItem_Today,
        static: false,
    },
    {
        w: 1,
        h: 6,
        x: 0,
        y: 21,
        i: DashboardIncidentDataItem_Yesterday,
        static: false,
    },
    {
        w: 1,
        h: 5,
        x: 1,
        y: 28,
        i: DashboardIncidentDataItem_Latest,
        static: false,
    },
    {w: 1, h: 4, x: 0, y: 21, i: DashboardErrorLogsItem, static: false},
    {w: 1, h: 4, x: 1, y: 33, i: DashboardEventListItem, static: false},
];

export const DashboardMainLayoutLarge_3: Layout[] = [
    {
        w: 1,
        h: 3,
        x: 0,
        y: 0,
        i: DashboardUserDataItem_Count,
        static: false,
        minH: 3,
    },
    {w: 1, h: 9, x: 1, y: 0, i: DashboardUserDataItem_Pie, static: false},
    {w: 1, h: 7, x: 0, y: 3, i: DashboardUserDataItem_Latest, static: false},
    {
        w: 1,
        h: 3,
        x: 0,
        y: 9,
        i: DashboardUnityDataItem_Count,
        static: false,
        minH: 3,
    },
    {w: 1, h: 9, x: 0, y: 12, i: DashboardUnityDataItem_Pie, static: false},
    {
        w: 1,
        h: 6,
        x: 2,
        y: 0,
        i: DashboardAttendanceDataItem_Today,
        static: false,
    },
    {
        w: 1,
        h: 6,
        x: 2,
        y: 6,
        i: DashboardAttendanceDataItem_Yesterday,
        static: false,
    },
    {
        w: 1,
        h: 7,
        x: 1,
        y: 9,
        i: DashboardAttendanceDataItem_Latest,
        static: false,
    },
    {w: 1, h: 6, x: 2, y: 12, i: DashboardPatrolDataItem_Today, static: false},
    {
        w: 1,
        h: 7,
        x: 2,
        y: 21,
        i: DashboardPatrolDataItem_Yesterday,
        static: false,
    },
    {w: 1, h: 7, x: 1, y: 16, i: DashboardPatrolDataItem_Latest, static: false},
    {
        w: 1,
        h: 6,
        x: 0,
        y: 22,
        i: DashboardIncidentDataItem_Today,
        static: false,
    },
    {
        w: 1,
        h: 6,
        x: 0,
        y: 28,
        i: DashboardIncidentDataItem_Yesterday,
        static: false,
    },
    {
        w: 1,
        h: 5,
        x: 1,
        y: 28,
        i: DashboardIncidentDataItem_Latest,
        static: false,
    },
    {w: 1, h: 6, x: 1, y: 28, i: DashboardErrorLogsItem, static: false},
    {w: 1, h: 9, x: 2, y: 25, i: DashboardEventListItem, static: false},
];

export const DashboardMainLayoutXLarge_4: Layout[] = [
    {
        w: 1,
        h: 3,
        x: 2,
        y: 1,
        i: DashboardUserDataItem_Count,
        static: false,
        minH: 3,
    },
    {
        w: 2,
        h: 14,
        x: 0,
        y: 0,
        i: DashboardUserDataItem_Pie,
        static: false,
        minH: 14,
    },
    {
        w: 1,
        h: 8,
        x: 0,
        y: 15,
        i: DashboardUserDataItem_Latest,
        static: false,
        minH: 8,
    },
    {
        w: 1,
        h: 3,
        x: 3,
        y: 1,
        i: DashboardUnityDataItem_Count,
        static: false,
        minH: 3,
    },
    {
        w: 2,
        h: 14,
        x: 2,
        y: 5,
        i: DashboardUnityDataItem_Pie,
        static: false,
        minH: 14,
    },
    {
        w: 1,
        h: 3,
        x: 0,
        y: 19,
        i: DashboardAttendanceDataItem_Today,
        static: false,
        minH: 3,
    },
    {
        w: 1,
        h: 3,
        x: 0,
        y: 20,
        i: DashboardAttendanceDataItem_Yesterday,
        static: false,
        minH: 3,
    },
    {
        w: 1,
        h: 8,
        x: 0,
        y: 25,
        i: DashboardAttendanceDataItem_Latest,
        static: false,
        minH: 8,
    },
    {
        w: 1,
        h: 3,
        x: 1,
        y: 14,
        i: DashboardPatrolDataItem_Today,
        static: false,
        minH: 3,
    },
    {
        w: 1,
        h: 3,
        x: 1,
        y: 32,
        i: DashboardPatrolDataItem_Yesterday,
        static: false,
        minH: 3,
    },
    {
        w: 1,
        h: 8,
        x: 1,
        y: 17,
        i: DashboardPatrolDataItem_Latest,
        static: false,
        minH: 8,
    },
    {
        w: 1,
        h: 3,
        x: 3,
        y: 19,
        i: DashboardIncidentDataItem_Today,
        static: false,
        minH: 3,
    },
    {
        w: 1,
        h: 3,
        x: 3,
        y: 33,
        i: DashboardIncidentDataItem_Yesterday,
        static: false,
        minH: 3,
    },
    {
        w: 1,
        h: 8,
        x: 3,
        y: 22,
        i: DashboardIncidentDataItem_Latest,
        static: false,
        minH: 8,
    },
    {
        w: 1,
        h: 9,
        x: 2,
        y: 24,
        i: DashboardErrorLogsItem,
        static: false,
        minH: 9,
    },
    {
        w: 1,
        h: 9,
        x: 2,
        y: 27,
        i: DashboardEventListItem,
        static: false,
        minH: 9,
    },
];
