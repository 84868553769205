import {Role, RoleProperties} from "../../domain/user/Role";
import {ignoreNilAndPaths, ignorePaths} from "../../util/object";

export type PostRoleDto = {
    id: number;
    label: string;
};

export type GetRoleDto = PostRoleDto;

export type PutRoleDto = Partial<PostRoleDto>

export function roleAsPostDto(role: Role): PostRoleDto {
    return ignorePaths(role, RoleProperties.reference);
}

export function roleAsPutDto(role: Partial<Role>): PutRoleDto {
    return ignoreNilAndPaths(role, RoleProperties.reference);
}
