import {DateRange} from "rsuite/DateRangePicker";
import {endOfDay, format, isAfter, isBefore, isToday, isYesterday, startOfDay, toDate,} from "date-fns";
import {DateTime, Duration} from "luxon";

export function dateRangeFromMillis(
    millisRange: number[] | null
): DateRange | null {
    return !!millisRange
        ? [toDate(millisRange[0]), toDate(millisRange[1])]
        : null;
}

export function dateRangeToMillis(range: DateRange | null): number[] | null {
    return !!range
        ? [startOfDay(range[0]).getTime(), endOfDay(range[1]).getTime()]
        : null;
}

export function formatDateTime(time: number | Date): string {
    return format(time, "dd MMM yyyy hh:mm");
}

export function formatDate(time: number | Date): string {
    if (isToday(time)) return "Hoy";
    else if (isYesterday(time)) return "Ayer";
    else {
        const dateTime = (typeof time === "number") ? DateTime.fromMillis(time) : DateTime.fromJSDate(time)
        return dateTime.setZone("America/Lima").setLocale("pe").toFormat("dd MMM yyyy");
    }
}

export function isInRange(
    date: number | Date,
    rangeStart: number | Date,
    rangeEnd: number | Date
): boolean {
    return isAfter(date, rangeStart) && isBefore(date, rangeEnd);
}

export function getTimeMilliseconds(date: number | Date) {
    const dateTime = (typeof date === "number") ? DateTime.fromMillis(date) : DateTime.fromJSDate(date)
    return Duration.fromObject({
        hour: dateTime.hour,
        minute: dateTime.minute,
        second: dateTime.second
    }).toMillis()
}
