import React, {FC, Fragment, MutableRefObject, SyntheticEvent, useCallback, useContext, useRef,} from "react";
import {Pagination, Table} from "rsuite";
import {Zone} from "../../../../../../domain/patrol/Zone";
import {TableColumnContext} from "../../../../../context/TableContext";
import {TableInstance} from "rsuite/Table";
import {useTableSize} from "../../../../../../hook/width";
import {TableHeaderStyle} from "../../../../../components/table/style";
import {CheckCell} from "../../../../../components/table/checkcell";
import {CheckHeaderCell} from "../../../../../components/table/checkheader";
import {EmptyState} from "../../../../../components/state/empty";
import {useDataPager} from "../../../../../../hook/pager";
import {Unity} from "../../../../../../domain/unity";
import { ZoneTableColumns } from "../../../../../../schema/table/ZoneTable";

const {Column, HeaderCell, Cell} = Table;

type OnActionClick = (item: Zone) => void;

type Props = {
    items: Zone[] | undefined;
    checkedZonesReferences: number[];
    onEditButtonClick: OnActionClick;
    onDeleteButtonClick: OnActionClick;
    onDownloadClick: OnActionClick;
    onRowClick: OnActionClick;
    onCheckedChange: (reference: number, checked: boolean) => void;
    onSwitchCheckAll: (value: any, checked: boolean) => void;
    unity: Unity;
};

const Actions = {
    edit: "edit",
    delete: "delete",
    download: "download",
    check: "check",
};

export const UnityZoneTableName = "zone_table"

export const UnityZoneTable: FC<Props> = ({
                                              items,
                                              checkedZonesReferences,
                                              onEditButtonClick,
                                              onDeleteButtonClick,
                                              onDownloadClick,
                                              onCheckedChange,
                                              onSwitchCheckAll,
                                              onRowClick,
                                              unity,
                                          }) => {
    const tableRef = useRef<TableInstance<any, any>>(null);
    const tableContext = useContext(TableColumnContext);
    const tableSize = useTableSize(tableRef);
    const currentTablePreferences =
        tableContext.getTablePreferences<ZoneTableColumns>(UnityZoneTableName);
    const actionRef = useRef<any>(null) as MutableRefObject<keyof typeof Actions>;
    const {partialData, changePage, page, changeLimit, limits, limit} =
        useDataPager({
            id: `zone_table_${unity.id}`,
            items,
        });
    /* eslint-disable */

    const onSelect = useCallback((item: Zone) => {
        switch (actionRef.current) {
            case "edit":
                if (onEditButtonClick) onEditButtonClick(item);
                break;
            case "delete":
                if (onDeleteButtonClick) onDeleteButtonClick(item);
                break;
            case "download":
                if (onDownloadClick) onDownloadClick(item);
                break;
            case "check":
                break;
            default:
                if (onRowClick) onRowClick(item);
        }
        (actionRef.current as any) = undefined;
    }, []);

    const onCheckboxClicked = (e: SyntheticEvent) => {
        e.stopPropagation();
    };

    /* eslint-enable */

    return (
        <Fragment>
            <Table
                ref={tableRef}
                data={partialData}
                autoHeight
                className={"w-full"}
                onRowClick={onSelect as any}
                bordered
                cellBordered
                renderEmpty={() => (
                    <EmptyState
                        title={"Sin zonas"}
                        message={"Aún no se registraron zonas"}
                    />
                )}
            >
                {currentTablePreferences.map((columnData) => {
                    switch (columnData.name) {
                        case "id":
                            return (
                                <Column
                                    width={tableSize.calculateWeight(columnData.weight)}
                                    resizable
                                    key={`${columnData.name}-col`}
                                >
                                    <HeaderCell style={TableHeaderStyle}>
                                      ID
                                    </HeaderCell>
                                    <Cell dataKey={"id"}/>
                                </Column>
                            );
                      default:
                            return (
                                <Column
                                    width={tableSize.calculateWeight(columnData.weight)}
                                    resizable
                                    key={`${columnData.name}-col`}
                                >
                                    <HeaderCell style={TableHeaderStyle}>
                                        Nombre
                                    </HeaderCell>
                                    <Cell dataKey={"label"}/>
                                </Column>
                            );
                    }
                })}
            </Table>
            {items ? (
                <div className={"py-4 flex flex-col gap-4"}>
          <span className={"text-md"}>
            Mostrando {partialData?.length} elementos
          </span>
                    <Pagination
                        prev
                        next
                        first
                        last
                        ellipsis
                        boundaryLinks
                        maxButtons={5}
                        size="xs"
                        layout={["total", "-", "limit", "|", "pager", "skip"]}
                        total={items.length}
                        limitOptions={limits}
                        limit={limit}
                        activePage={page}
                        onChangePage={changePage}
                        onChangeLimit={changeLimit}
                    />
                </div>
            ) : null}
        </Fragment>
    );
};
