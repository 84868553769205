import React, {FC} from "react";
import {ListChildComponentProps} from "react-window";
import {IconButton} from "rsuite";
import {FaIcon} from "../../../components/fontawesome/icon";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {PatrolRowTimestamp} from "./timestamp";
import {PatrolRowOperator} from "./operator";
import {PatrolRowUnity} from "./unity";
import {UserPatrol} from "../../../../domain/patrol/Patrol";
import {PatrolRowZone} from "./zone";
import {PatrolRowIncident} from "./incident";

export type PatrolRowProps = {
    patrolList: UserPatrol[];
    onPreviewClicked: React.MouseEventHandler<HTMLElement>;
    onSelect: React.MouseEventHandler<HTMLDivElement>;
};

export const PatrolRow: FC<ListChildComponentProps<PatrolRowProps>> = ({
                                                                           data,
                                                                           index,
                                                                           style,
                                                                       }) => {
    const {onPreviewClicked, onSelect, patrolList} = data;
    const item = patrolList[index];
    return (
        <div style={style} className={"py-2"}>
            <div
                data-index={index}
                onClick={onSelect}
                className={
                    "w-full h-full border border-neutral-300 rounded-lg p-2 flex flex-col justify-between content-between"
                }
            >
                <div className={"flex w-full flex-col gap-2"}>
                    <PatrolRowTimestamp item={item}/>
                    <PatrolRowOperator item={item}/>
                    <PatrolRowUnity item={item}/>
                    <PatrolRowZone item={item}/>
                    <PatrolRowIncident item={item}/>
                </div>
                <IconButton
                    appearance={"primary"}
                    icon={<FaIcon icon={faEye}/>}
                    onClick={onPreviewClicked}
                >
                    Vista Previa
                </IconButton>
            </div>
        </div>
    );
};
