import { FC, Fragment } from "react";
import {AppTimestamp} from "../../../../domain/app/Timestamp";
import {IconButton, Loader} from "rsuite";
import {formatDateTime} from "../../../../util/date";
import {FaIcon} from "../../../components/fontawesome/icon";
import {faRefresh, faWarning} from "@fortawesome/free-solid-svg-icons";
type Props = {
    timestamp?: AppTimestamp;
    loading?: boolean;
    onRefreshButtonClicked: () => void;

    position?: "tl" | "tr" | "bl" | "br";
};
export const DashboardDataTimestamp: FC<Props> = ({
                                                      timestamp,
                                                      loading,
                                                      onRefreshButtonClicked,
                                                      position = "tr",
                                                  }) => {
    let absoluteTwPos: string = "right-0 top-0";

    switch (position) {
        case "tr":
            absoluteTwPos = "right-0 top-0";
            break;
        case "tl":
            absoluteTwPos = "left-0 top-0";
            break;
        case "br":
            absoluteTwPos = "right-0 bottom-0";
            break;
        case "bl":
            absoluteTwPos = "left-0 bottom-0";
            break;
    }

    if (loading === true) {
        return (
            <div className={"flex justify-center items-center"}>
                <Loader size={"md"}/>
            </div>
        );
    }
    const exists = !!timestamp
    return (
        <div
            className={`flex flex-row flex-wrap justify-center items-center gap-2 p-3 absolute max-w-full ${absoluteTwPos} ${exists ? 'w-auto bg-neutral-300 bg-opacity-25' : 'max-w-full bg-amber-300 h-full w-full z-50'}`}
        >
            {
                exists ? <Fragment>
                    <IconButton
                      className={"text-xs font-normal text-neutral-900 p-0 m-0"}
                      size={"xs"}
                      appearance={"subtle"}
                      onClick={onRefreshButtonClicked}
                      icon={<FaIcon icon={faRefresh}/>}
                    >
                        Al {formatDateTime(timestamp!.timestamp)}
                    </IconButton>
                </Fragment> : <div className={"w-full h-full flex flex-row gap-2 cursor-pointer"} onClick={onRefreshButtonClicked}>

                        <FaIcon icon={faWarning}/>   Esta información aún no se ha actualizado, de click aquí para actualizar.
                </div>
            }
        </div>
    );
};
