import {db} from "../db";
import {AppTimestamp} from "../../../domain/app/Timestamp";

export class AppTimestampDao {
    static async putTimestamp(timestamp: AppTimestamp) {
        await db.timestamps.put(timestamp)
        return timestamp
    }

    static async getTimestamp(key: string) {
        return db.timestamps.get({key})
    }

    static async deleteAll() {
        await db.timestamps.clear()
    }

    static async delete(key: string) {
        return db.timestamps.delete(key)
    }
}
