import {
  faBars,
  faChevronRight,
  faClose,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import {
  FC,
  MouseEventHandler,
  useCallback,
  useContext,
  useState,
} from "react";
import { Drawer, IconButton } from "rsuite";
import { FaIcon } from "../fontawesome/icon";
import { UserAuthContext } from "../../context/UserContext";
import { useLocation, useNavigate } from "react-router-dom";
import { UserWrapper } from "../../../domain/user/User";
import { BreakPointContext } from "../../context/BreakPointContext";
import { filter, first } from "lodash";

export type RoleBasedNavigatorDefinition = {
  id: string;
  label: string;
  icon: IconDefinition;
  allowedRoles: number[];
  allowIf?: (appUser?: UserWrapper) => boolean;
};

type Props = {
  definition: RoleBasedNavigatorDefinition[];
};
export const RoleBasedNavigator: FC<Props> = ({ definition }) => {
  const breakpoint = useContext(BreakPointContext);
  const { appUser } = useContext(UserAuthContext);
  const filteredDefinition = definition.filter((it) => {
    if (it.allowIf) return it.allowIf(appUser);
    else
      return (
        appUser?.hasAssignedRole() &&
        it.allowedRoles.includes(appUser!.user!.roleId!)
      );
  });
  if (filteredDefinition.length <= 1) return null;
  return breakpoint.isMobile ? (
    <MobileDrawerNavigator definition={filteredDefinition} />
  ) : (
    <DesktopNavigator definition={filteredDefinition} />
  );
};

const MobileDrawerNavigator: FC<Props> = ({ definition }) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const selected = first(
    filter(definition, (it) => location.pathname === it.id)
  );
  /* eslint-disable */
  const switchDrawer = useCallback(() => {
    setDrawerOpen((old) => !old);
  }, [setDrawerOpen]);

  const onClick = useCallback<MouseEventHandler<HTMLElement>>((e) => {
    const id = e.currentTarget.id;
    navigate(id);
    switchDrawer();
  }, []);
  /* eslint-enable */
  return (
    <div className={"flex flex-row items-center gap-2"}>
      <IconButton
        size={"sm"}
        icon={<FaIcon icon={faBars} />}
        onClick={switchDrawer}
        appearance={"primary"}
      >
        Menú
      </IconButton>
      <span className={"text-sm font-normal text-neutral-500"}>
        <FaIcon icon={faChevronRight} size={"sm"} /> {selected?.label}
      </span>
      <Drawer
        open={isDrawerOpen}
        size={"full"}
        onClose={switchDrawer}
        placement={"bottom"}
      >
        <div
          className={
            "flex flex-col justify-between items-end w-full h-full p-2"
          }
        >
          <IconButton onClick={switchDrawer} icon={<FaIcon icon={faClose} />} />
          <div
            className={
              "flex-1 w-full flex flex-col justify-center items-center overflow-y-auto overflow-x-hidden"
            }
          >
            <div
              className={
                "w-fit h-fit flex flex-col divide-y divide-neutral-200"
              }
            >
              {definition.map((it) => (
                <div
                  key={`nav/${it.id}`}
                  onClick={onClick}
                  id={it.id}
                  className={`flex flex-row items-center divide-y gap-2 px-3 py-2 ${
                    selected === it
                      ? "color-user-tab text-white rounded-lg"
                      : "bg-transparent text-neutral-500 "
                  }`}
                >
                  <FaIcon icon={it.icon} />
                  {it.label}
                </div>
              ))}
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

const DesktopNavigator: FC<Props> = ({ definition }) => {
  const navigate = useNavigate();
  const location = useLocation();
  /* eslint-disable */
  const onClick = useCallback<MouseEventHandler<HTMLElement>>((e) => {
    const id = e.currentTarget.id;
    navigate(id);
  }, []);
  /* eslint-enable */
  return (
    <div className={"flex flex-col divide-y divide-neutral-300"}>
      {definition.map((it) => (
        <div
          key={`nav/${it.id}`}
          onClick={onClick}
          id={it.id}
          className={`flex flex-row gap-2 px-4 py-3 items-center cursor-pointer ${
            location.pathname === it.id
              ? "color-user-tab text-white"
              : "bg-transparent text-neutral-800"
          }`}
        >
          <FaIcon icon={it.icon} />
          {it.label}
        </div>
      ))}
    </div>
  );
};
