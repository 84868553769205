import { FC, useContext } from "react";
import { BreakPointContext } from "../../../../context/BreakPointContext";
import { UserVisitControl } from "../../../../../domain/control/Visit";
import { VisitControlList } from "../../../data-display/visit-control-list";
import { VisitControlTable } from "../../../data-display/visit-control-table";

type Props = {
  visitControlList?: UserVisitControl[];
  onSelect: (item: UserVisitControl) => void;
};

export const UserVisitControlDataName = "UserVisitControlData";

export const UserVisitControlData: FC<Props> = ({
  visitControlList,
  onSelect,
}) => {
  const screenSize = useContext(BreakPointContext);
  if (screenSize.isMobile) {
    return (
      <VisitControlList
        name={UserVisitControlDataName}
        onSelect={onSelect}
        visitControlList={visitControlList}
      />
    );
  } else {
    return (
      <VisitControlTable
        name={UserVisitControlDataName}
        visitControlList={visitControlList}
        onSelect={onSelect}
      />
    );
  }
};
