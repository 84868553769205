import {useState} from "react";
import ResponseBuilder, {Response} from "../../domain/app/Response";
import {NewShift, Shift} from "../../domain/user/Shift";
import ShiftRepository from "../../data/repository/ShiftRepository";
import {useLiveQuery} from "dexie-react-hooks";
import {db} from "../../data/database/db";
import {LocalErrorLogRepository} from "../../data/repository/LocalErrorLogRepository";

export function useShiftViewModel() {
    const list = useLiveQuery(() => db.shifts.toArray());
    const [selectedItems, setSelectedItems] = useState<Array<string>>([]);
    const [addEvent, setAddEvent] = useState<boolean | null>(null);
    const [refreshEvent, setRefreshEvent] = useState<boolean | null>(null);
    const [deleteEvent, setDeleteEvent] = useState<Array<{
        label: string;
        reference: string;
    }> | null>(null);
    const [editEvent, setEditEvent] = useState<Shift | null>(null);
    const [editState, setEditState] = useState<Response<boolean> | null>(null);
    const [fetchState, setFetchState] = useState<Response<boolean> | null>(null);
    const [postState, setCreateState] = useState<Response<boolean> | null>(null);
    const [deleteState, setDeleteState] = useState<Response<boolean> | null>(
        null
    );

    async function fetchList(forceRefresh: boolean = false) {
        if (fetchState?.loading) return;
        setFetchState(ResponseBuilder.loading());
        try {
            await ShiftRepository.fetchList(forceRefresh);
            setFetchState(ResponseBuilder.success(true));
        } catch (err: any) {
            const label = "Ocurrió un error al obtener la lista de turnos.";
            await LocalErrorLogRepository.registerError(label, err);
            setFetchState(ResponseBuilder.failure(new Error(label)));
        }
    }

    function onListStateReceived() {
        setFetchState(null);
    }

    function requestAddEvent() {
        setAddEvent(true);
    }

    function onAddEventCompleted() {
        setAddEvent(null);
    }

    function requestRefreshEvent() {
        setRefreshEvent(true);
    }

    function onRefreshEventCompleted() {
        setRefreshEvent(null);
    }

    async function requestDeleteEvent(
        itemsToDelete: Array<string> = selectedItems
    ) {
        if (itemsToDelete && itemsToDelete.length > 0) {
            const items = await db.shifts.bulkGet(itemsToDelete);
            setDeleteEvent(
                items
                    .filter((it) => !!it)
                    .map((it) => ({
                        label: it!.label,
                        reference: it!.reference,
                    }))
            );
        } else {
            setDeleteState(
                Response.failure(
                    new Error("No se seleccionó ningún turno para eliminar.")
                )
            );
        }
    }

    function onDeleteEventCompleted() {
        setDeleteEvent(null);
    }

    async function requestEditEvent(
        itemsToDelete: Array<string> = selectedItems
    ) {
        if (itemsToDelete && itemsToDelete.length === 1) {
            const reference = itemsToDelete[0];
            const item = await db.shifts.get(reference);
            if (!!item) {
                setEditEvent(item);
            } else {
                clearSelection();
            }
        } else {
            setEditState(
                Response.failure(new Error("No es posible editar más de un turno."))
            );
        }
    }

    function onEditEventCompleted() {
        setEditEvent(null);
    }

    const allLoadingObserver =
        (!!fetchState && fetchState.loading) ||
        (!!postState && postState.loading) ||
        (!!deleteState && deleteState.loading) ||
        (!!editState && editState.loading);

    async function postShift(shift: NewShift) {
        setCreateState(ResponseBuilder.loading());
        try {
            await ShiftRepository.create(shift);
            setCreateState(ResponseBuilder.success(true));
        } catch (e: any) {
            const label = `Ocurrió un error al intentar añadir el turno '${shift.label}' con id: '${shift.id}'`;
            await LocalErrorLogRepository.registerError(label, e);
            setCreateState(ResponseBuilder.failure(new Error(label)));
        }
    }

    function onPostStateReceived() {
        setCreateState(null);
    }

    async function putShift(newData: Partial<Shift>) {
        setEditState(ResponseBuilder.loading());
        if (editEvent == null)
            return setEditState(
                ResponseBuilder.failure(
                    new Error("No se seleccionó ningún elemento para editar.")
                )
            );
        try {
            await ShiftRepository.update(editEvent, newData);
            setEditState(ResponseBuilder.success(true));
        } catch (e: any) {
            const label = `Ocurrió un error al intentar editar el turno '${editEvent.label}'`;
            await LocalErrorLogRepository.registerError(label, e);
            setEditState(ResponseBuilder.failure(new Error(label)));
        }
    }

    function onEditStateReceived() {
        setEditState(null);
    }

    async function deleteShift() {
        setDeleteState(ResponseBuilder.loading());
        if (deleteEvent == null) {
            return setDeleteState(
                ResponseBuilder.failure(
                    new Error("No se seleccionó ningún elemento para eliminar.")
                )
            );
        }
        let count = deleteEvent.length;
        let errorCount = 0;

        const tasks = deleteEvent.map(async (it) => {
            try {
                await ShiftRepository.delete(it.reference);
                removeItemSelection(it.reference);
            } catch (e: any) {
                errorCount += 1;
                const label = `Error al intentar eliminar el turno '${it.label}' con referencia '${it.reference}'`;
                await LocalErrorLogRepository.registerError(label, e);
            }
        });

        await Promise.all(tasks);

        if (errorCount === 0) {
            clearSelection();
            setDeleteState(ResponseBuilder.success(true));
        } else if (count === errorCount) {
            setDeleteState(
                Response.failure(
                    new Error(`Error al eliminar los turnos ${errorCount}/${count}`)
                )
            );
        } else {
            setDeleteState(
                Response.successWithWarning(
                    `Error parcial al eliminar los turnos ${errorCount}/${count}`
                )
            );
        }
    }

    function onDeleteStateReceived() {
        setDeleteState(null);
    }

    function addItemSelection(reference: string) {
        const currentIndex = selectedItems.indexOf(reference);
        if (currentIndex === -1) {
            setSelectedItems((old) => [...old, reference]);
        }
    }

    function removeItemSelection(reference: string) {
        const currentIndex = selectedItems.indexOf(reference);
        if (currentIndex !== -1) {
            setSelectedItems((old) => {
                const newArray = [...old];
                newArray.splice(currentIndex, 1);
                return newArray;
            });
        }
    }

    function switchItemSelection(reference: string) {
        const index = selectedItems.indexOf(reference);
        if (index === -1) {
            addItemSelection(reference);
        } else {
            removeItemSelection(reference);
        }
    }

    function switchSelectAll() {
        if (list && list.length !== selectedItems.length) {
            setSelectedItems(list.map((it) => it.reference));
        } else {
            clearSelection();
        }
    }

    function clearSelection() {
        setSelectedItems([]);
    }

    return {
        list,
        fetchState,
        fetchList,
        onListStateReceived,
        addEvent,
        refreshEvent,
        requestAddEvent,
        onAddEventCompleted,
        requestRefreshEvent,
        onRefreshEventCompleted,
        postState,
        postShift,
        onPostStateReceived,
        allLoadingObserver,
        requestDeleteEvent,
        onDeleteEventCompleted,
        deleteShift,
        editState,
        editEvent,
        putShift,
        onEditStateReceived,
        requestEditEvent,
        onEditEventCompleted,
        onDeleteStateReceived,
        deleteEvent,
        deleteState,
        selectedItems,
        switchItemSelection,
        switchSelectAll,
        clearSelection,
    };
}
