import useLocalState from "use-local-storage-state";
import {useCallback} from "react";

const DefaultLimits = [10, 20, 30]

type Config<T> = {
    limits?: Array<number>;
    defaultLimit?: number;
    id: string;
    items: Array<T> | undefined
};

type PagerValue<T> = {
    limits: Array<number>;
    limit: number,
    page: number,
    changeLimit: (limit: number) => void

    changePage: (page: number) => void
    partialData: Array<T> | undefined
}

export function useDataPager<T>({
                                    limits = DefaultLimits,
                                    defaultLimit = DefaultLimits[0],
                                    id,
                                    items
                                }: Config<T>): PagerValue<T> {
    const [limit, setLimit] = useLocalState(`pager_limit_${id}`, {
        defaultValue: defaultLimit
    })
    const [page, setPage] = useLocalState(`pager_page_${id}`, {
        defaultValue: 1
    })
    const changeLimit = useCallback((limit: number) => {
        setPage(1);
        setLimit(limit);
    }, [])

    const changePage = useCallback((page: number) => {
        setPage(page)
    }, [])
    const partialData = items?.filter((v, i) => {
        const start = limit * (page - 1);
        const end = start + limit;
        return i >= start && i < end;
    });

    return ({
        limits, limit, page, changeLimit, partialData, changePage
    })
}
