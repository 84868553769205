import { FC, useContext } from "react";
import { PentrackerUser } from "../../../../../domain/user/User";
import { BreakPointContext } from "../../../../context/BreakPointContext";
import { UserList } from "../../../data-display/user-list";
import { UserTable } from "../../../data-display/user-table";

type Props = {
  items?: PentrackerUser[];
  onDeleteClick?: (item: PentrackerUser) => void;
  onEditClick?: (item: PentrackerUser) => void;
  onClick: (user: PentrackerUser) => void;
  onSelectAllSwitch: () => void;
  onItemSelectionSwitch: (uid: string) => void;
  selectedItems: string[];
};

export const UserListDataName = "UserListData";

export const UserListData: FC<Props> = ({
  items,
  onDeleteClick,
  onEditClick,
  onClick,
  onSelectAllSwitch,
  onItemSelectionSwitch,
  selectedItems,
}) => {
  const screenSize = useContext(BreakPointContext);
  if (screenSize.isMobile) {
    return (
      <UserList
        name={UserListDataName}
        onClick={onClick}
        items={items}
        onDeleteClick={onDeleteClick}
        onEditClick={onEditClick}
      />
    );
  } else {
    return (
      <UserTable
        name={UserListDataName}
        onClick={onClick}
        items={items}
        onDeleteClick={onDeleteClick}
        onEditClick={onEditClick}
        onItemSelectionSwitch={onItemSelectionSwitch}
        onSelectAllSwitch={onSelectAllSwitch}
        selectedUsers={selectedItems}
      />
    );
  }
};
