import {InputDataList} from "../../../../components/modal/FormModal";
import {List, Schema} from "rsuite";
import {isNotBlank} from "../../../../../util/validation";

export const NewShiftInputModel = Schema.Model({
    id: Schema.Types.NumberType(),
    label: Schema.Types.StringType()
        .isRequired("Es necesario completar este campo.")
        .addRule(isNotBlank(3), "Este campo debe contener 3 o más caracteres."),
    schedule: Schema.Types.StringType().isRequired(
        "Es necesario completar este campo."
    ),
});
export const NewShiftInputList: InputDataList = [
    {
        label: "ID",
        name: "id",
        type: "number",
    },
    {
        label: "Título",
        name: "label",
        type: "text",
    },
    {
        label: "Detalles (Opcional)",
        name: "schedule",
        type: "text",
        defaultValue: "-",
    },
];

export const EditShiftInputList: InputDataList = [
    {
        label: "Actual",
        name: "current",
        type: "custom",
        provide: ({initialValues: values}) =>
            values ? (
                <List>
                    <List.Item className={"flex flex-row flex-wrap gap-2"}>
                        <strong>ID</strong>
                        {values["id"]}
                    </List.Item>
                    <List.Item className={"flex flex-row flex-wrap gap-2"}>
                        <strong>Nombre</strong>
                        {values["label"]}
                    </List.Item>
                    <List.Item className={"flex flex-row flex-wrap gap-2"}>
                        <strong>Horario</strong>
                        {values["schedule"]}
                    </List.Item>
                </List>
            ) : null,
    },
    {
        label: "Título",
        name: "label",
        type: "text",
    },
    {
        label: "Horario",
        name: "schedule",
        type: "text",
    },
];
