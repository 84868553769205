import {FC, MouseEventHandler} from "react";
import {Button, CellProps, Table} from "rsuite";
import {Unity} from "../../../../domain/unity";
import {FaIcon} from "../../../components/fontawesome/icon";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {slice} from "lodash"

type Props = CellProps<Unity> & {
    onNavigate: MouseEventHandler<HTMLElement>
}

export const UnityTableClientCell: FC<Props> = ({rowData, onNavigate, ...props}) => {
    if (!rowData) return null
    const {client} = rowData
    if (!!client) {
        if (client.length === 1) {
            const {displayName, uid} = client[0]
            return (
                <Table.Cell {...props}>
                    <Button
                        size={"sm"}
                        appearance={"link"}
                        style={{
                            padding: 0,
                            margin: 0,
                        }}
                        onClick={onNavigate}
                        data-navigation={`/dashboard/users/${uid}/main`}
                    >
                        {displayName}
                    </Button>
                </Table.Cell>
            );
        } else {
            return (
                <Table.Cell {...props}>
                    {
                        slice(client, 0, 2).map((current) => (
                            <Button
                                size={"sm"}
                                appearance={"link"}
                                style={{
                                    padding: 0,
                                    margin: 0,
                                }}
                                onClick={onNavigate}
                                data-navigation={`/dashboard/users/${current.uid}/main`}
                            >
                                {current.displayName}
                            </Button>
                        ))
                    }

                </Table.Cell>
            );
        }
    } else {
        return (
            <Table.Cell {...props}>
                <span className={"text-xs italic text-red-500"}><FaIcon icon={faInfoCircle}/> No asignado</span>
            </Table.Cell>
        );
    }
}
