import { createRef, FC } from "react";
import { Role } from "../../../../../../domain/user/Role";
import { IconButton, Table } from "rsuite";
import { TableInstance } from "rsuite/esm/Table";
import { useTableWidth } from "../../../../../../hook/width";
import { TableHeaderStyleSecondary } from "../../../../../components/table/style";
import { EmptyState } from "../../../../../components/state/empty";
import { faAdd, faGear } from "@fortawesome/free-solid-svg-icons";
import { FaIcon } from "../../../../../components/fontawesome/icon";
import { CheckHeaderCell } from "../../../../../components/table/checkheader";
import { CheckCell } from "../../../../../components/table/checkcell";

import { useStyleContext } from "../../../../../context/StyleContext";

type Props = {
  data: Role[] | undefined;
  loading: boolean;
  selectedItems: string[];
  onSelectAllSwitch: () => void;
  onCreateClick: () => void;
  onItemSelectSwitch: (reference: string) => void;
};

export const UserRoleTable: FC<Props> = ({
  data,
  loading,
  selectedItems,
  onSelectAllSwitch,
  onItemSelectSwitch,
  onCreateClick,
}) => {
  const { tableHeaderStyleSecondary } = useStyleContext();

  const tableRef = createRef<TableInstance<any, any>>();
  const tableColumnsRelation = useTableWidth(tableRef, {
    0: {
      actions: 0.2,
      label: 0.8,
    },
    800: {
      actions: 0.05,
      label: 0.95,
    },
  });

  return (
    <Table
      className={"rounded-xl shadow overflow-hidden"}
      cellBordered
      loading={loading}
      data={data}
      ref={tableRef}
      autoHeight
      renderEmpty={() => (
        <EmptyState
          title={"No hay cargos de usuario"}
          message={"Sin cargos de usuario"}
          icon={faGear}
          footer={
            <div
              className={
                "flex flex-row text-md items-center justify-center flex-wrap gap-x-2"
              }
            >
              Presiona{" "}
              <IconButton
                onClick={onCreateClick}
                appearance={"primary"}
                size={"xs"}
                icon={<FaIcon icon={faAdd} size={"xs"} />}
              >
                Nuevo
              </IconButton>
              para añadir un cargo.
            </div>
          }
        />
      )}
    >
      <Table.Column resizable width={tableColumnsRelation.label}>
        <Table.HeaderCell style={tableHeaderStyleSecondary}>
          Nombre
        </Table.HeaderCell>
        <Table.Cell dataKey={"label"}></Table.Cell>
      </Table.Column>
    </Table>
  );
};
