import {createContext, FC, HTMLAttributes, PropsWithChildren, useState} from "react";

type DashboardHeaderTheme = Partial<{
    dark: boolean
    title: string
    subtitle: string
    className: HTMLAttributes<any>["className"]
    homeAsUpEnabled: boolean
}>

export type DashboardHeaderContextValue = {
    sticky: boolean
    setIsSticky: (isSticky: boolean) => void
    setTheme: (theme: DashboardHeaderTheme) => void
    theme: DashboardHeaderTheme
}
export const DashboardHeaderContext = createContext<DashboardHeaderContextValue>(null!)

export const DashboardHeaderContextProvider: FC<PropsWithChildren> = ({children}) => {
    const [sticky, setIsSticky] = useState<boolean>(false)
    const [theme, setTheme] = useState<DashboardHeaderTheme>({
        dark: false,
        subtitle: undefined,
        title: "Pentracker",
        homeAsUpEnabled: false
    })

    return <DashboardHeaderContext.Provider value={{
        setIsSticky,
        sticky,
        setTheme,
        theme
    }}>
        {children}
    </DashboardHeaderContext.Provider>
}
