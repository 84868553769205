import {FC, useCallback} from "react";
import {ButtonGroup, IconButton} from "rsuite";
import {faCancel, faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FaIcon} from "../../../../../components/fontawesome/icon";

type OnClick = () => void;

type Props = {
    selectedItems: Array<string>;
    onEditClicked: OnClick;
    onDeleteClicked: OnClick;
    onCancelClicked: OnClick;
};

export const ShiftTableMultiselectHeader: FC<Props> = ({
                                                           selectedItems,
                                                           onCancelClicked,
                                                           onDeleteClicked,
                                                           onEditClicked,
                                                       }) => {
    /* eslint-disable */
    const onCancel = useCallback(() => {
        onCancelClicked();
    }, [selectedItems]);
    const onDelete = useCallback(() => {
        onDeleteClicked();
    }, [selectedItems]);
    const onEdit = useCallback(() => {
        onEditClicked();
    }, [selectedItems]);
    /* eslint-enable */
    return selectedItems && selectedItems.length > 0 ? (
        <div
            className={
                "bg-app px-4 py-2 w-full rounded shadow flex flex-row justify-between flex-wrap items-center"
            }
        >
            <p className={"text-multi text-md"}>
                {selectedItems.length} turno(s) seleccionado(s)
            </p>
            <ButtonGroup>
                <IconButton
                    onClick={onDelete}
                    size={"sm"}
                    appearance={"primary"}
                    icon={<FaIcon icon={faTrash}/>}
                >
                    Eliminar
                </IconButton>
                <IconButton
                    onClick={onEdit}
                    size={"sm"}
                    appearance={"primary"}
                    icon={<FaIcon icon={faEdit}/>}
                >
                    Editar
                </IconButton>
                <IconButton
                    onClick={onCancel}
                    size={"sm"}
                    appearance={"primary"}
                    icon={<FaIcon icon={faCancel}/>}
                >
                    Cancelar
                </IconButton>
            </ButtonGroup>
        </div>
    ) : null;
};
