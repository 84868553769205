import {FC, ReactNode} from "react";
import {FaIcon} from "../fontawesome/icon";
import {faHashtag, IconDefinition} from "@fortawesome/free-solid-svg-icons";

export const EmptyState: FC<{
    title?: string;
    message?: string;
    icon?: IconDefinition;
    footer?: ReactNode;
}> = ({
          title = "La lista se encuentra vacía",
          message = "...",
          icon = faHashtag,
          footer,
      }) => {
    return (
        <div className={"w-full bg-neutral-50 border-neutral-200 border break-all"}>
            <div
                className={
                    "w-full flex items-center justify-center justify-items-center p-2 md:p-4"
                }
            >
                <div
                    className={
                        "max-w-full sm:max-w-lg flex flex-col gap-0.5 justify-center items-center"
                    }
                >
                    <div className={"rounded-full p-4 bg-red-200 text-app"}>
                        <FaIcon icon={icon} size={"2x"}/>
                    </div>
                    <p
                        className={
                            "p-0 m-0 text-lg text-neutral-700 font-semibold text-center"
                        }
                    >
                        {title}
                    </p>
                    <p
                        className={
                            "p-0 m-0 text-md text-neutral-500 text-justify font-normal text-center"
                        }
                    >
                        {message}
                    </p>
                    <div className={"w-full flex items-center justify-center mt-2"}>
                        {footer}
                    </div>
                </div>
            </div>
        </div>
    );
};
