import {PentrackerUser} from "../../domain/user/User";
import {useContext, useState} from "react";
import {Response} from "../../domain/app/Response";
import {AppTimestamp} from "../../domain/app/Timestamp";
import {UserAuthContext} from "../../ui/context/UserContext";
import { UserVisitControl } from "../../domain/control/Visit";
import { VisitControlRepository } from "../../data/repository/VisitControlRepository";

export function useUserVisitControlViewModel(user: PentrackerUser) {
    const [fetchState, setFetchState] = useState<Response<boolean> | null>(null);
    const [visitControlList, setVisitControlList] = useState<UserVisitControl[] | undefined>();
    const [userVisitControlTimestamp, setUserVisitControlTimestamp] = useState<AppTimestamp | undefined>()
    const [refreshEvent, setRefreshEvent] = useState<boolean | null>(null);
    const {appUser} = useContext(UserAuthContext)

    async function fetchUserVisitControlList(
        timestamp: number,
        forceRefresh: boolean = false
    ) {
        if (fetchState?.isLoading()) return;
        setFetchState(Response.loading());
        try {
            const result = await VisitControlRepository.getUserList(
                timestamp,
                user.uid,
                forceRefresh,
                appUser
            );
            setVisitControlList(result?.userVisitControlList);
            setUserVisitControlTimestamp(result?.timestamp)
            setFetchState(Response.success(true));
        } catch (e: any) {
            setFetchState(Response.failure(e));
        }
    }

    function onFetchStateReceived() {
        setFetchState(null);
    }

    function requestRefreshEvent() {
        setRefreshEvent(true);
    }

    function onRefreshEventCompleted() {
        setRefreshEvent(null);
    }

    return {
        fetchState,
        onFetchStateReceived,
        visitControlList,
        userVisitControlTimestamp,
        fetchUserVisitControlList,
        refreshEvent,
        requestRefreshEvent,
        onRefreshEventCompleted,
    };
}
