import React, { FC, useCallback } from "react";
import { Pagination } from "rsuite";
import { NewsItem } from "../../../../domain/news/NewsItem";
import { useDataPager } from "../../../../hook/pager";
import { NewsContentListRow } from "./row";
import { EmptyState } from "../../../components/state/empty";

type NewsContentListProps = {
  items?: NewsItem[];
  onClick: (item: NewsItem) => void;
  onDelete: (item: NewsItem) => void;
  onEdit: (item: NewsItem) => void;
};

export const NewsContentList: FC<NewsContentListProps> = ({
  items,
  onClick,
  onDelete,
  onEdit
}) => {
  /* eslint-disable */

  const onSelectRow = useCallback<React.MouseEventHandler<HTMLDivElement | HTMLElement>>(
    (event) => {
      event.stopPropagation()
      if (!items) return;
      const index = event.currentTarget.dataset["index"] as unknown as number;
      const item = items[index];
      if (item) {
        const id = event.currentTarget.id
        if(id==="row") onClick(item);
        else if (id === "delete") onDelete(item)
        else onEdit(item)
      }
    },
    [items]
  );

  const { limits, changeLimit, limit, page, partialData, changePage } =
    useDataPager<NewsItem>({
      id: `news_item`,
      items,
    });

  /* eslint-enable */

  return items && items.length > 0 ? ( //@ts-ignore
    <div className={"w-full h-auto"}>
      {partialData?.map((inc, i) => (
        <NewsContentListRow
          index={i}
          style={{}}
          key={`news-row-${inc.reference}`}
          data={{
            items: partialData,
            onSelect: onSelectRow,
            onEdit: onSelectRow,
            onDelete: onSelectRow
          }}
        />
      ))}
      <div className={"py-4 flex flex-col gap-4"}>
        <span className={"text-md"}>
          Mostrando {partialData?.length} elementos
        </span>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={["limit", "pager"]}
          total={items.length}
          limitOptions={limits}
          limit={limit}
          activePage={page}
          onChangePage={changePage}
          onChangeLimit={changeLimit}
        />
      </div>
    </div>
  ) : (
    <EmptyState
      title={"Sin elementos"}
      message={"Pulsa sobre Nuevo para agregar un comunicado"}
    />
  );
};
