import React, { FC } from "react";
import { ListChildComponentProps } from "react-window";
import { RowCell } from "../../../components/row/Cell";
import { UserGoodsControl } from "../../../../domain/control/Goods";
import { GoodsControlRowTimestamp } from "./timestamp";
import { GoodsControlRowUnity } from "./unity";

export type GoodsControlRowProps = {
  userGoodsControlList: UserGoodsControl[];
  onSelect: React.MouseEventHandler<HTMLDivElement>;
};

export const GoodsControlRow: FC<
  ListChildComponentProps<GoodsControlRowProps>
> = ({ data, index, style }) => {
  const { onSelect, userGoodsControlList } = data;
  const item = userGoodsControlList[index];
  return (
    <div style={style} className={"py-2"}>
      <div
        data-index={index}
        onClick={onSelect}
        className={
          "w-full h-full border border-neutral-300 rounded-lg p-2 flex flex-col justify-between content-between"
        }
      >
        <div className={"flex w-full flex-col gap-2"}>
          <GoodsControlRowTimestamp item={item} />
          <GoodsControlRowUnity item={item} />
          <RowCell label={"PROVEEDOR"} data={item} dataKey={"provider"} />
          <RowCell label={"DOCUMENTO"} data={item} dataKey={"document"} />
          <RowCell label={"INGRESO"} data={item} dataKey={"entry"} />
        </div>
      </div>
    </div>
  );
};
